import {defineRoutes} from '@imperium/router';

export const routes = defineRoutes({
	personalTaxes: {
		path: `/account/:accountInfoId/personal-taxes/`,
		params: ['accountInfoId'] as const,
	},
	createT1: {
		path: `/account/:accountInfoId/personal-taxes/t1/create`,
		params: ['accountInfoId'] as const,
	},
	editT1: {
		path: `/account/:accountInfoId/personal-taxes/t1/:t1Id/edit`,
		params: ['accountInfoId', 't1Id'] as const,
	},
});
