import {MonthDayPicker, useTForm} from '@thx/controls';
import debug from 'debug';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button, Form, FormInput} from 'semantic-ui-react';
import {useAsyncError} from '~lib/useAsyncError';
import {useAccountingState} from '../../../../accounting/state';
import type {routes} from '../../../routes';
import {useEditSelfEmployedYearEndMutation} from './editYearEnd';
import {useGetEditYearEndLazyQuery} from './getEditYearEnd';

const d = debug('tacs.web.accounts.components.accounts.EditYearEnd');

interface EditSelfEmployedYearEndProps {
	onSave?: () => void;
}

export function EditYearEnd(props: EditSelfEmployedYearEndProps) {
	const throwError = useAsyncError();
	const {accountInfoId} = useParams<typeof routes.types.account>();
	const [editMutation, {loading: mutationLoading}] = useEditSelfEmployedYearEndMutation();
	const [getQuery, {data, loading, error, called}] = useGetEditYearEndLazyQuery({variables: {accountInfoId}});
	const [editClicked, setEditClicked] = useState(false);
	const {historicClosingDate} = useAccountingState();
	const {hasErrors, hasWarnings, handleSubmit, setFieldValue, values, resetForm} = useTForm({
		initialValues: {YearEnd: data?.getYearEndByAccountInfoId},
		onSubmit: formValues => {
			editMutation({
				variables: {
					accountInfoId,
					YearEnd: formValues.YearEnd,
				},
				refetchQueries: ['getAccountState'],
			})
				.then(() => {
					toast.success('Year End saved.');
					setEditClicked(false);
					props.onSave && props.onSave();
				})
				.catch(throwError);
		},
		enableReinitialize: true,
	});

	if (editClicked) {
		if (!loading && !error && !data?.getYearEndByAccountInfoId && !called) {
			getQuery().catch(throwError);
		}
		return (
			<Form error={hasErrors} warning={hasWarnings} onSubmit={handleSubmit}>
				<FormInput autoFocus label="Year End">
					<MonthDayPicker fluid value={values.YearEnd || ''} onChange={val => setFieldValue('YearEnd', val)} />
				</FormInput>
				<Button positive type="submit" floated="right">
					Save
				</Button>
				<Button
					disabled={mutationLoading}
					type="button"
					onClick={() => {
						resetForm();
						setEditClicked(false);
					}}
				>
					Cancel
				</Button>
			</Form>
		);
	}

	return (
		<Button fluid color="blue" disabled={!!historicClosingDate} onClick={() => setEditClicked(true)}>
			Edit Year End
		</Button>
	);
}
