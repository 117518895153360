import {defineRoutes} from '@imperium/router';

export const routes = defineRoutes({
	calendar: {
		path: `/account/:accountInfoId/calendar`,
		params: ['accountInfoId'] as const,
	},
	pvlReport: {
		path: `/account/:accountInfoId/calendar/report/pvl`,
		params: ['accountInfoId'] as const,
	},
	pvlJournalEntries: {
		path: `/account/:accountInfoId/calendar/journal-entries/pvl`,
		params: ['accountInfoId'] as const,
	},
	perDiemReport: {
		path: `/account/:accountInfoId/calendar/report/per-diem`,
		params: ['accountInfoId'] as const,
	},
	perDiemJournalEntries: {
		path: `/account/:accountInfoId/calendar/journal-entries/per-diem`,
		params: ['accountInfoId'] as const,
	},
	maintenanceReport: {
		path: `/account/:accountInfoId/calendar/report/maintenance`,
		params: ['accountInfoId'] as const,
	},
});
