import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './sendContactPasswordResetEmail.graphql';

const defaultOptions = {} as const;
export type SendContactPasswordResetEmailMutationVariables = Types.Exact<{
	email: Types.Scalars['String'];
	hostname: Types.Scalars['String'];
	passwordResetEndpoint: Types.Scalars['String'];
}>;

export type SendContactPasswordResetEmailMutation = {sendPasswordResetEmail: boolean};

export type SendContactPasswordResetEmailType = boolean;

export type SendContactPasswordResetEmailMutationFn = Apollo.MutationFunction<
	SendContactPasswordResetEmailMutation,
	SendContactPasswordResetEmailMutationVariables
>;

/**
 * __useSendContactPasswordResetEmailMutation__
 *
 * To run a mutation, you first call `useSendContactPasswordResetEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendContactPasswordResetEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendContactPasswordResetEmailMutation, { data, loading, error }] = useSendContactPasswordResetEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      hostname: // value for 'hostname'
 *      passwordResetEndpoint: // value for 'passwordResetEndpoint'
 *   },
 * });
 */
export function useSendContactPasswordResetEmailMutation(
	baseOptions?: Apollo.MutationHookOptions<SendContactPasswordResetEmailMutation, SendContactPasswordResetEmailMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<SendContactPasswordResetEmailMutation, SendContactPasswordResetEmailMutationVariables>(Operations, options);
}
export type SendContactPasswordResetEmailMutationHookResult = ReturnType<typeof useSendContactPasswordResetEmailMutation>;
export type SendContactPasswordResetEmailMutationResult = Apollo.MutationResult<SendContactPasswordResetEmailMutation>;
export type SendContactPasswordResetEmailMutationOptions = Apollo.BaseMutationOptions<
	SendContactPasswordResetEmailMutation,
	SendContactPasswordResetEmailMutationVariables
>;
