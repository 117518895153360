import type {LayoutData} from '@imperium/layout';
import {debug} from 'debug';
import {Permission} from '~core/graphql';
import {isLoggedIn, needsPermission} from '~lib/permissionHelpers';
import {SearchBar} from './components/SearchBar';

const d = debug('tacs.web.search.layout');

export const layout: LayoutData = {
	permissions: [Permission.ReadAllAccount],
	primaryMenu: [
		{
			stickOnMobile: true,
			position: 'right',
			visible: {...isLoggedIn(), ...needsPermission(Permission.ReadAllAccount)},
			render: () => <SearchBar />,
		},
	],
};
