import {LocalDate} from '@js-joda/core';
import debug from 'debug';
import {toast} from 'react-toastify';
import {Button, List} from 'semantic-ui-react';
import {ConfirmModal} from '~common/components';
import {useAsyncError} from '~lib/useAsyncError';
import type {GetSpacesCleanupTableQuery} from '../SpacesCleanupTable/getSpacesCleanupTable';
import getSpacesCleanupTable from '../SpacesCleanupTable/getSpacesCleanupTable.graphql';
import {useSpacesCleanupCleanAllMutation} from './spacesCleanupCleanAll';
import {useSpacesCleanupCleanExpiredMutation} from './spacesCleanupCleanExpired';

const d = debug('tacs.web.tools.components.SpacesCleanupSidebar');

export function SpacesCleanupSidebar() {
	const throwError = useAsyncError();
	const [spacesCleanupCleanAllMutation, {called: cleanAll}] = useSpacesCleanupCleanAllMutation();
	const [spacesCleanupCleanExpiredMutation, {called: cleanExpired}] = useSpacesCleanupCleanExpiredMutation();

	function onCleanAllConfirmClicked() {
		spacesCleanupCleanAllMutation({
			update: cache => {
				cache.writeQuery({
					query: getSpacesCleanupTable,
					data: {getSpacesCleanup: null},
				});
			},
		})
			.then(() => {
				toast.success('All files have been cleaned!');
			})
			.catch(throwError);
	}

	function onCleanExpiredConfirmClicked() {
		spacesCleanupCleanExpiredMutation({
			update: cache => {
				const query = cache.readQuery<GetSpacesCleanupTableQuery>({query: getSpacesCleanupTable});
				const queryResult = query?.getSpacesCleanup?.filter(t => t.expiryDate.isBefore(LocalDate.now().plusDays(1)));

				cache.writeQuery({
					query: getSpacesCleanupTable,
					data: {getSpacesCleanup: queryResult},
				});
			},
		})
			.then(() => {
				toast.success('Expired files have been cleaned!');
			})
			.catch(throwError);
	}

	return (
		<List>
			<List.Item>
				<ConfirmModal
					headerText="Confirm Delete"
					text="Are you sure you want to clean all expired files? Only files older than 5 days will be removed."
					onConfirm={onCleanExpiredConfirmClicked}
					trigger={
						<Button disabled={cleanExpired} fluid color="orange">
							Clean Expired
						</Button>
					}
				/>
			</List.Item>
			<List.Item>
				<ConfirmModal
					headerText="Confirm Clean"
					text="Are you sure you want to clean all files? Some of these files may not have expired and could be needed for the document processor."
					onConfirm={onCleanAllConfirmClicked}
					trigger={
						<Button disabled={cleanAll} fluid color="red">
							Clean All
						</Button>
					}
				/>
			</List.Item>
		</List>
	);
}
