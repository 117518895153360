import * as Apollo from '@apollo/client';

import Operations from '~common/components/OfficeDropdown/getOfficeDropdown.graphql';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

const defaultOptions = {} as const;
export type GetOfficeDropdownQueryVariables = Types.Exact<{
	organizationId?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type GetOfficeDropdownQuery = {getOfficesByOrganization?: Array<{id: string; version: number; name: string}> | null};

export type GetOfficeDropdownType = {id: string; version: number; name: string};

/**
 * __useGetOfficeDropdownQuery__
 *
 * To run a query within a React component, call `useGetOfficeDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfficeDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfficeDropdownQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetOfficeDropdownQuery(baseOptions?: Apollo.QueryHookOptions<GetOfficeDropdownQuery, GetOfficeDropdownQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetOfficeDropdownQuery, GetOfficeDropdownQueryVariables>(Operations, options);
}
export function useGetOfficeDropdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOfficeDropdownQuery, GetOfficeDropdownQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetOfficeDropdownQuery, GetOfficeDropdownQueryVariables>(Operations, options);
}
export type GetOfficeDropdownQueryHookResult = ReturnType<typeof useGetOfficeDropdownQuery>;
export type GetOfficeDropdownLazyQueryHookResult = ReturnType<typeof useGetOfficeDropdownLazyQuery>;
export type GetOfficeDropdownQueryResult = Apollo.QueryResult<GetOfficeDropdownQuery, GetOfficeDropdownQueryVariables>;
