import {toMoney} from '@thx/money';
import debug from 'debug';
import type Money from 'js-money';
import {GeneralLedgerAccountGroupEnum} from '~core/graphql';

const d = debug('tacs.web.accounting.components.payStatement.useCalculatePayStatementLines');

export interface PayStatementLine {
	glAccount?: {id: string; group: GeneralLedgerAccountGroupEnum};
	total: Money;
	uiTotal: Money;
	gst?: Money | null;
}

export function useCalculatePayStatementLines() {
	function calculatePayStatementLines(payStatementLines: PayStatementLine[]) {
		const {gstCollected, gstItc, revenue, equity, expenses, asset, liabilities} = payStatementLines.reduce(
			(acc, psl) => {
				const subtotal = (psl.total || toMoney()).subtract(psl.gst || toMoney());

				switch (psl.glAccount?.group) {
					case GeneralLedgerAccountGroupEnum.Asset:
						acc.asset = acc.asset.add(subtotal);
						acc.gstItc = acc.gstItc.add(psl.gst || toMoney());
						break;
					case GeneralLedgerAccountGroupEnum.Liability:
						acc.liabilities = acc.liabilities.add(subtotal);
						acc.gstItc = acc.gstItc.add(psl.gst || toMoney());
						break;
					case GeneralLedgerAccountGroupEnum.Equity:
						acc.equity = acc.equity.add(subtotal);
						acc.gstItc = acc.gstItc.add(psl.gst || toMoney());
						break;
					case GeneralLedgerAccountGroupEnum.Revenue:
						acc.revenue = acc.revenue.add(subtotal);
						acc.gstCollected = acc.gstCollected.add(toMoney(psl.gst));
						break;
					case GeneralLedgerAccountGroupEnum.Expense:
						acc.expenses = acc.expenses.add(subtotal);
						acc.gstItc = acc.gstItc.add(psl.gst || toMoney());
						break;
					default:
						break;
				}
				return acc;
			},
			{
				revenue: toMoney(),
				gstCollected: toMoney(),
				gstItc: toMoney(),
				liabilities: toMoney(),
				equity: toMoney(),
				expenses: toMoney(),
				asset: toMoney(),
			},
		);

		const totalDeposit = revenue.add(gstCollected).subtract(gstItc).subtract(equity).subtract(expenses).subtract(asset).subtract(liabilities);

		return {revenue, gstCollected, gstItc, equity, expenses, asset, liabilities, totalDeposit};
	}

	return [calculatePayStatementLines];
}
