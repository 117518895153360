import * as Apollo from '@apollo/client';

import type {LocalDate} from '@js-joda/core';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getEditIncorporationDate.graphql';

const defaultOptions = {} as const;
export type GetEditIncorporationDateQueryVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
}>;

export type GetEditIncorporationDateQuery = {getIncorporationDate?: LocalDate | null};

export type GetEditIncorporationDateType = LocalDate;

/**
 * __useGetEditIncorporationDateQuery__
 *
 * To run a query within a React component, call `useGetEditIncorporationDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditIncorporationDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditIncorporationDateQuery({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *   },
 * });
 */
export function useGetEditIncorporationDateQuery(
	baseOptions: Apollo.QueryHookOptions<GetEditIncorporationDateQuery, GetEditIncorporationDateQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetEditIncorporationDateQuery, GetEditIncorporationDateQueryVariables>(Operations, options);
}
export function useGetEditIncorporationDateLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetEditIncorporationDateQuery, GetEditIncorporationDateQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetEditIncorporationDateQuery, GetEditIncorporationDateQueryVariables>(Operations, options);
}
export type GetEditIncorporationDateQueryHookResult = ReturnType<typeof useGetEditIncorporationDateQuery>;
export type GetEditIncorporationDateLazyQueryHookResult = ReturnType<typeof useGetEditIncorporationDateLazyQuery>;
export type GetEditIncorporationDateQueryResult = Apollo.QueryResult<GetEditIncorporationDateQuery, GetEditIncorporationDateQueryVariables>;
