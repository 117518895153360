import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getAuthorizedRepresentativeAddSidebarAuthorizedRepresentativesAndContacts.graphql';

const defaultOptions = {} as const;
export type GetAuthorizedRepresentativeAddSidebarAuthorizedRepresentativesAndContactsQueryVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
}>;

export type GetAuthorizedRepresentativeAddSidebarAuthorizedRepresentativesAndContactsQuery = {
	getAccountInfoById?: {
		id: string;
		version: number;
		contacts?: Array<{id: string; version: number; givenName: string; surname: string; preferredName?: string | null}> | null;
		authorizedRepresentatives?: Array<{
			id: string;
			version: number;
			contact: {id: string; givenName: string; surname: string; preferredName?: string | null};
		}> | null;
	} | null;
};

export type GetAuthorizedRepresentativeAddSidebarAuthorizedRepresentativesAndContactsType = {
	id: string;
	version: number;
	contacts?: Array<{id: string; version: number; givenName: string; surname: string; preferredName?: string | null}> | null;
	authorizedRepresentatives?: Array<{
		id: string;
		version: number;
		contact: {id: string; givenName: string; surname: string; preferredName?: string | null};
	}> | null;
};

/**
 * __useGetAuthorizedRepresentativeAddSidebarAuthorizedRepresentativesAndContactsQuery__
 *
 * To run a query within a React component, call `useGetAuthorizedRepresentativeAddSidebarAuthorizedRepresentativesAndContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthorizedRepresentativeAddSidebarAuthorizedRepresentativesAndContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthorizedRepresentativeAddSidebarAuthorizedRepresentativesAndContactsQuery({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *   },
 * });
 */
export function useGetAuthorizedRepresentativeAddSidebarAuthorizedRepresentativesAndContactsQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetAuthorizedRepresentativeAddSidebarAuthorizedRepresentativesAndContactsQuery,
		GetAuthorizedRepresentativeAddSidebarAuthorizedRepresentativesAndContactsQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<
		GetAuthorizedRepresentativeAddSidebarAuthorizedRepresentativesAndContactsQuery,
		GetAuthorizedRepresentativeAddSidebarAuthorizedRepresentativesAndContactsQueryVariables
	>(Operations, options);
}
export function useGetAuthorizedRepresentativeAddSidebarAuthorizedRepresentativesAndContactsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetAuthorizedRepresentativeAddSidebarAuthorizedRepresentativesAndContactsQuery,
		GetAuthorizedRepresentativeAddSidebarAuthorizedRepresentativesAndContactsQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		GetAuthorizedRepresentativeAddSidebarAuthorizedRepresentativesAndContactsQuery,
		GetAuthorizedRepresentativeAddSidebarAuthorizedRepresentativesAndContactsQueryVariables
	>(Operations, options);
}
export type GetAuthorizedRepresentativeAddSidebarAuthorizedRepresentativesAndContactsQueryHookResult = ReturnType<
	typeof useGetAuthorizedRepresentativeAddSidebarAuthorizedRepresentativesAndContactsQuery
>;
export type GetAuthorizedRepresentativeAddSidebarAuthorizedRepresentativesAndContactsLazyQueryHookResult = ReturnType<
	typeof useGetAuthorizedRepresentativeAddSidebarAuthorizedRepresentativesAndContactsLazyQuery
>;
export type GetAuthorizedRepresentativeAddSidebarAuthorizedRepresentativesAndContactsQueryResult = Apollo.QueryResult<
	GetAuthorizedRepresentativeAddSidebarAuthorizedRepresentativesAndContactsQuery,
	GetAuthorizedRepresentativeAddSidebarAuthorizedRepresentativesAndContactsQueryVariables
>;
