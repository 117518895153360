import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getAllAccountRatings.graphql';

const defaultOptions = {} as const;
export type GetAllAccountRatingsQueryVariables = Types.Exact<{[key: string]: never}>;

export type GetAllAccountRatingsQuery = {getAllAccountRatings?: Array<{id: string; letter: string; description: string; details: string}> | null};

export type GetAllAccountRatingsType = {id: string; letter: string; description: string; details: string};

/**
 * __useGetAllAccountRatingsQuery__
 *
 * To run a query within a React component, call `useGetAllAccountRatingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAccountRatingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAccountRatingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllAccountRatingsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllAccountRatingsQuery, GetAllAccountRatingsQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetAllAccountRatingsQuery, GetAllAccountRatingsQueryVariables>(Operations, options);
}
export function useGetAllAccountRatingsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetAllAccountRatingsQuery, GetAllAccountRatingsQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetAllAccountRatingsQuery, GetAllAccountRatingsQueryVariables>(Operations, options);
}
export type GetAllAccountRatingsQueryHookResult = ReturnType<typeof useGetAllAccountRatingsQuery>;
export type GetAllAccountRatingsLazyQueryHookResult = ReturnType<typeof useGetAllAccountRatingsLazyQuery>;
export type GetAllAccountRatingsQueryResult = Apollo.QueryResult<GetAllAccountRatingsQuery, GetAllAccountRatingsQueryVariables>;
