import {useAuthenticatedState} from '@imperium/auth-client';
import debug from 'debug';
import {toast} from 'react-toastify';
import {Button} from 'semantic-ui-react';
import {ConfirmModal} from '~common/components';
import {useAsyncError} from '~lib/useAsyncError';
import {routes as userRoutes} from '../../../../user/routes';
import {useSendContactPasswordResetEmailMutation} from './sendContactPasswordResetEmail';

const d = debug('tacs.web.accounts.components.contacts.SendPasswordResetEmailButton');

type Props = {
	email: string | undefined;
	userId?: string | undefined;
};

export function SendPasswordResetEmailButton({email, userId}: Props) {
	const throwError = useAsyncError();
	const {id} = useAuthenticatedState();
	const [sendPasswordResetEmail, {called}] = useSendContactPasswordResetEmailMutation();

	const sendResetEmail = async () => {
		if (!id) throw new Error('You must be logged in to send a Reset Password Email.');

		if (email) {
			sendPasswordResetEmail({
				variables: {
					email,
					hostname: window.location.origin.toString(),
					passwordResetEndpoint: userRoutes.to.passwordReset(),
				},
			})
				.then(res => {
					if (res.data?.sendPasswordResetEmail) {
						toast.success(`Success! A reset email has been sent to ${email}.`);
					} else {
						throwError(new Error('Password reset failed.'));
					}
				})
				.catch(throwError);
		}
	};
	return (
		<ConfirmModal
			onConfirm={sendResetEmail}
			headerText="Confirm Invitation"
			text={`Are you sure you want to reset ${userId && userId === id ? 'your' : `this user's`} password?`}
			trigger={
				<Button fluid color={userId ? 'blue' : 'green'} disabled={!email} compact>
					Send Password Reset Email
				</Button>
			}
		/>
	);
}
