import {isArray} from 'lodash-es';
import {useDispatch} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {Button, Divider, Label, List, Header, Checkbox} from 'semantic-ui-react';
import {GeneralLedgerAccountsDropdown} from '~common/components/GeneralLedgerAccountsDropdown';
import {useCommonState} from '~common/state';
import {useAccountState} from '../../../../accounts/state';
import {routes} from '../../../routes';
import {clearGeneralLedgerFilter, setGeneralLedgerFilter, useAccountingState} from '../../../state';

export function GeneralLedgerSidebar() {
	const {accountInfoId} = useParams<typeof routes.types.generalLedger>();
	const {generalLedgerFilter, historicClosingDate, fiscalOpenDate} = useAccountingState();
	const {yearEnd} = useAccountState();
	const {endDate} = useCommonState();

	const {showDetailed, glAccounts} = generalLedgerFilter;
	const {push} = useHistory();
	const dispatch = useDispatch();

	if (!historicClosingDate) {
		return null;
	}

	return (
		<List>
			{(!yearEnd || !fiscalOpenDate || (yearEnd && fiscalOpenDate && !endDate.isBefore(fiscalOpenDate))) && (
				<>
					<List.Item>
						<Button
							icon="add"
							content="Create Journal Entry"
							positive
							fluid
							onClick={() => push(routes.to.createGeneralJournalEntry({accountInfoId}))}
						/>
					</List.Item>
					<Divider />
				</>
			)}
			<Header size="small">Filter General Ledger Accounts</Header>
			<List.Item>
				<Label>GL Account</Label>
				<GeneralLedgerAccountsDropdown
					hideCurrentEarningAccounts
					multiple
					accountInfoId={accountInfoId}
					placeholder="GL Account"
					clearable
					selection
					value={glAccounts || []}
					onChange={glAcc => {
						dispatch(setGeneralLedgerFilter({glAccounts: glAcc && isArray(glAcc) && glAcc.length ? glAcc : undefined}));
					}}
				/>
			</List.Item>
			<List.Item>
				<Checkbox
					label="Display Revision Entries"
					checked={showDetailed}
					onChange={(e, checkbox) => dispatch(setGeneralLedgerFilter({showDetailed: checkbox.checked}))}
				/>
			</List.Item>
			<List.Item>
				<Button content="Clear Selection" onClick={() => dispatch(clearGeneralLedgerFilter())} />
			</List.Item>
		</List>
	);
}
