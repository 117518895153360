import {formatMoney} from '@thx/money';
import type Money from 'js-money';
import {Table, TableHeader, TableRow, TableHeaderCell, TableBody} from 'semantic-ui-react';
import {LinkedTableCell} from '~common/components/LinkedTableCell';
import {routes} from '../../../routes';

interface SourceDeductionTableOpeningBalanceProps {
	values?: {
		amount: Money;
		id: string;
		description?: string | null;
	} | null;
	year: number;
	accountInfoId: string;
}

export function SourceDeductionTableOpeningBalance(props: SourceDeductionTableOpeningBalanceProps) {
	const {year, values, accountInfoId} = props;
	if (!values) return null;

	return (
		<Table selectable>
			<TableHeader>
				<TableRow>
					<TableHeaderCell>Opening Balance ({year})</TableHeaderCell>
					<TableHeaderCell>Arrears</TableHeaderCell>
				</TableRow>
			</TableHeader>
			<TableBody>
				<TableRow>
					<LinkedTableCell
						to={routes.to.editSourceDeductionOpeningBalance({accountInfoId, openingBalanceId: values.id})}
						value={values.description}
					/>
					<LinkedTableCell
						textAlign="right"
						to={routes.to.editSourceDeductionOpeningBalance({accountInfoId, openingBalanceId: values.id})}
						value={formatMoney(values.amount)}
					/>
				</TableRow>
			</TableBody>
		</Table>
	);
}
