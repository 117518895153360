import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getDirectorAddSidebarExistingContactListData.graphql';

const defaultOptions = {} as const;
export type GetDirectorAddSidebarExistingContactListDataQueryVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
}>;

export type GetDirectorAddSidebarExistingContactListDataQuery = {
	getCorporateAccountByAccountInfoId?: {
		id: string;
		version: number;
		directors?: Array<{id: string; version: number; contact: {id: string; version: number}}> | null;
		accountInfo: {
			id: string;
			version: number;
			contacts?: Array<{id: string; version: number; givenName: string; surname: string; preferredName?: string | null}> | null;
			employees?: Array<{id: string; version: number; contact: {id: string; version: number}}> | null;
		};
	} | null;
};

export type GetDirectorAddSidebarExistingContactListDataType = {
	id: string;
	version: number;
	directors?: Array<{id: string; version: number; contact: {id: string; version: number}}> | null;
	accountInfo: {
		id: string;
		version: number;
		contacts?: Array<{id: string; version: number; givenName: string; surname: string; preferredName?: string | null}> | null;
		employees?: Array<{id: string; version: number; contact: {id: string; version: number}}> | null;
	};
};

/**
 * __useGetDirectorAddSidebarExistingContactListDataQuery__
 *
 * To run a query within a React component, call `useGetDirectorAddSidebarExistingContactListDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDirectorAddSidebarExistingContactListDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDirectorAddSidebarExistingContactListDataQuery({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *   },
 * });
 */
export function useGetDirectorAddSidebarExistingContactListDataQuery(
	baseOptions: Apollo.QueryHookOptions<GetDirectorAddSidebarExistingContactListDataQuery, GetDirectorAddSidebarExistingContactListDataQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetDirectorAddSidebarExistingContactListDataQuery, GetDirectorAddSidebarExistingContactListDataQueryVariables>(
		Operations,
		options,
	);
}
export function useGetDirectorAddSidebarExistingContactListDataLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetDirectorAddSidebarExistingContactListDataQuery,
		GetDirectorAddSidebarExistingContactListDataQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetDirectorAddSidebarExistingContactListDataQuery, GetDirectorAddSidebarExistingContactListDataQueryVariables>(
		Operations,
		options,
	);
}
export type GetDirectorAddSidebarExistingContactListDataQueryHookResult = ReturnType<typeof useGetDirectorAddSidebarExistingContactListDataQuery>;
export type GetDirectorAddSidebarExistingContactListDataLazyQueryHookResult = ReturnType<
	typeof useGetDirectorAddSidebarExistingContactListDataLazyQuery
>;
export type GetDirectorAddSidebarExistingContactListDataQueryResult = Apollo.QueryResult<
	GetDirectorAddSidebarExistingContactListDataQuery,
	GetDirectorAddSidebarExistingContactListDataQueryVariables
>;
