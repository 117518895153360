import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './createTimeCard.graphql';

const defaultOptions = {} as const;
export type CreateTimeCardMutationVariables = Types.Exact<{
	agentId: Types.Scalars['String'];
}>;

export type CreateTimeCardMutation = {createTimeCard: {id: string}};

export type CreateTimeCardType = {id: string};

export type CreateTimeCardMutationFn = Apollo.MutationFunction<CreateTimeCardMutation, CreateTimeCardMutationVariables>;

/**
 * __useCreateTimeCardMutation__
 *
 * To run a mutation, you first call `useCreateTimeCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimeCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimeCardMutation, { data, loading, error }] = useCreateTimeCardMutation({
 *   variables: {
 *      agentId: // value for 'agentId'
 *   },
 * });
 */
export function useCreateTimeCardMutation(baseOptions?: Apollo.MutationHookOptions<CreateTimeCardMutation, CreateTimeCardMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<CreateTimeCardMutation, CreateTimeCardMutationVariables>(Operations, options);
}
export type CreateTimeCardMutationHookResult = ReturnType<typeof useCreateTimeCardMutation>;
export type CreateTimeCardMutationResult = Apollo.MutationResult<CreateTimeCardMutation>;
export type CreateTimeCardMutationOptions = Apollo.BaseMutationOptions<CreateTimeCardMutation, CreateTimeCardMutationVariables>;
