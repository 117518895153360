import {useLogout} from '@imperium/auth-client';
import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';

export function Logout() {
	const logout = useLogout();
	const history = useHistory();

	useEffect(() => {
		logout()
			.then(() => {
				history.push('/');
			})
			.catch(e => {
				throw new Error(e);
			});
	}, [history, logout]);
	return <>Logout</>;
}
