import {TForm, TFormProps} from '@thx/controls';
import {debug} from 'debug';
import {useHistory} from 'react-router-dom';
import {Button, Dropdown, Form, FormCheckbox, FormInput, Grid, GridColumn, GridRow} from 'semantic-ui-react';
import {object, string, boolean} from 'yup';
import {type CreateDocumentTypeInput, DocumentGroupEnum} from '~core/graphql';
import {createDropdownOptionsFromEnum} from '~lib/createDropdownOptionsFromEnum';
import {useAsyncError} from '~lib/useAsyncError';
import {routes} from '../../routes';
import {useCreateDocumentTypeFormMutation} from './createDocumentTypeForm';

const d = debug('tacs.web.tools.components.DocumentTypeAddForm');

const documentTypeFormValidation = object().shape({
	name: string().required('Name is required.'),
	group: string().required('Group is required.'),
	amount: boolean().required('Amount is required.'),
	statement: boolean().required('Statement field is required.'),
	expected: boolean().required('Expected field is required.'),
});

export function DocumentTypeAddForm() {
	const throwError = useAsyncError();
	const {push} = useHistory();

	const [createDocumentTypeFormMutation, {loading: mutationLoading}] = useCreateDocumentTypeFormMutation();

	function handleFormSubmit(documentTypeData: CreateDocumentTypeInput) {
		const data = {...documentTypeData};
		createDocumentTypeFormMutation({variables: {data}})
			.then(() => push(routes.to.documentTypes()))
			.catch(throwError);
	}

	return (
		<TForm<CreateDocumentTypeInput>
			initialValues={{
				name: '',
				group: '',
				amount: false,
				statement: false,
				expected: false,
			}}
			onSubmit={handleFormSubmit}
			validationSchema={documentTypeFormValidation}
		>
			{(tFormProps: TFormProps<CreateDocumentTypeInput>) => {
				const {handleSubmit, values, hasErrors, hasWarnings, renderWarnings, handleChange, fieldError, setFieldValue} = tFormProps;
				return (
					<Form onSubmit={handleSubmit} error={hasErrors} warning={hasWarnings}>
						<Grid columns="equal" stackable>
							<GridRow>
								<GridColumn>
									<FormInput
										autoFocus
										onChange={handleChange}
										name="name"
										label="Document Type Name"
										error={fieldError('name')}
										value={values?.name || ''}
									/>
								</GridColumn>
								<GridColumn>
									<FormInput required label="Group" error={fieldError('group')}>
										<Dropdown
											fluid
											selection
											options={createDropdownOptionsFromEnum(DocumentGroupEnum, true)}
											value={values.group}
											onChange={(e, val) => setFieldValue('group', val.value)}
										/>
									</FormInput>
								</GridColumn>
							</GridRow>
							<GridRow>
								<GridColumn>
									<FormInput label="Amount" name="amount">
										<FormCheckbox
											toggle
											onChange={(e, val) => setFieldValue('amount', val.checked)}
											name="amount"
											error={fieldError('amount')}
											checked={values?.amount || false}
										/>
									</FormInput>
								</GridColumn>
								<GridColumn>
									<FormInput label="Statement (usually only bank and credit card statements)" name="statement">
										<FormCheckbox
											toggle
											onChange={(e, val) => setFieldValue('statement', val.checked)}
											name="statement"
											error={fieldError('statement')}
											checked={values?.statement || false}
										/>
									</FormInput>
								</GridColumn>
								<GridColumn>
									<FormInput label="Expected (Determines if document can be used in expected documents)" name="expected">
										<FormCheckbox
											toggle
											onChange={(e, val) => setFieldValue('expected', val.checked)}
											name="expected"
											error={fieldError('expected')}
											checked={values?.expected || false}
										/>
									</FormInput>
								</GridColumn>
							</GridRow>
							<GridRow>
								<GridColumn>{renderWarnings()}</GridColumn>
							</GridRow>
							<GridRow>
								<GridColumn>
									<Button type="submit" floated="right" positive disabled={mutationLoading}>
										Save
									</Button>
									<Button type="button" floated="right" onClick={() => push(routes.to.documentTypes())} disabled={mutationLoading}>
										Cancel
									</Button>
								</GridColumn>
							</GridRow>
						</Grid>
					</Form>
				);
			}}
		</TForm>
	);
}
