import {formatMoney} from '@thx/money';
import {useHistory, useParams} from 'react-router-dom';
import {Button, Divider, Icon, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow} from 'semantic-ui-react';
import {DeleteModal} from '~common/components';
import {LinkedTableCell} from '~common/components/LinkedTableCell';
import {useAsyncError} from '~lib/useAsyncError';
import {routes} from '../../../routes';
import {useDeleteStatementByIdMutation} from './deleteStatementById';
import {useGetStatementByDocumentIdQuery} from './getStatementByDocumentId';

export default function DocumentSidebarStatement() {
	const throwError = useAsyncError();
	const {push} = useHistory();
	const {documentId, accountInfoId} = useParams<typeof routes.types.documentViewer>();

	const {data, error, refetch} = useGetStatementByDocumentIdQuery({variables: {documentId}});
	const [deleteMutation] = useDeleteStatementByIdMutation();

	if (error) throw error;

	function handleDelete(id: string, version: number) {
		deleteMutation({variables: {id, version}})
			.then(() => refetch())
			.catch(e => throwError(e));
	}

	return (
		<>
			<Table selectable basic="very">
				<TableHeader>
					<TableRow>
						<TableHeaderCell>Account</TableHeaderCell>
						<TableHeaderCell>Starting Balance</TableHeaderCell>
						<TableHeaderCell>Ending Balance</TableHeaderCell>
						<TableHeaderCell />
					</TableRow>
				</TableHeader>
				<TableBody>
					{data?.getStatementByDocumentId?.map(statement => {
						return (
							<TableRow key={statement.id}>
								<LinkedTableCell
									to={routes.to.statementEdit({statementId: statement.id, documentId, accountInfoId})}
									value={statement.financialAccount.accountNickname}
								/>
								<LinkedTableCell
									to={routes.to.statementEdit({statementId: statement.id, documentId, accountInfoId})}
									value={formatMoney(statement.openingBalance)}
								/>
								<LinkedTableCell
									to={routes.to.statementEdit({statementId: statement.id, documentId, accountInfoId})}
									value={formatMoney(statement.closingBalance)}
								/>
								<TableCell>
									<DeleteModal
										onDelete={() => handleDelete(statement.id, statement.version)}
										headerText="Confirm Delete"
										text="Are you sure you want to delete this statement?"
										trigger={<Icon name="trash alternate" color="red" style={{cursor: 'pointer'}} />}
									/>
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
			<Divider />
			<Button color="blue" type="submit" fluid onClick={() => push(routes.to.statementCreate({accountInfoId, documentId}))}>
				Add Statement
			</Button>
		</>
	);
}
