import * as Apollo from '@apollo/client';

import type Money from 'js-money';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getTimeCardStats.graphql';

const defaultOptions = {} as const;
export type GetTimeCardStatsQueryVariables = Types.Exact<{
	agentId: Types.Scalars['String'];
}>;

export type GetTimeCardStatsQuery = {
	getStatHolidayPay: Money;
	getAgentById: {id: string; bankedHours: number; bankedVacationPay: Money};
	getLatestTimeCard?: {id: string; vacationPayRate: number; timeCardLines?: Array<{id: string; hourlyPay: Money}> | null} | null;
};

export type GetTimeCardStatsType = Money;

/**
 * __useGetTimeCardStatsQuery__
 *
 * To run a query within a React component, call `useGetTimeCardStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimeCardStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimeCardStatsQuery({
 *   variables: {
 *      agentId: // value for 'agentId'
 *   },
 * });
 */
export function useGetTimeCardStatsQuery(baseOptions: Apollo.QueryHookOptions<GetTimeCardStatsQuery, GetTimeCardStatsQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetTimeCardStatsQuery, GetTimeCardStatsQueryVariables>(Operations, options);
}
export function useGetTimeCardStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTimeCardStatsQuery, GetTimeCardStatsQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetTimeCardStatsQuery, GetTimeCardStatsQueryVariables>(Operations, options);
}
export type GetTimeCardStatsQueryHookResult = ReturnType<typeof useGetTimeCardStatsQuery>;
export type GetTimeCardStatsLazyQueryHookResult = ReturnType<typeof useGetTimeCardStatsLazyQuery>;
export type GetTimeCardStatsQueryResult = Apollo.QueryResult<GetTimeCardStatsQuery, GetTimeCardStatsQueryVariables>;
