import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getDocumentAmountInputDocumentType.graphql';

const defaultOptions = {} as const;
export type GetDocumentAmountInputDocumentTypeQueryVariables = Types.Exact<{
	documentTypeId: Types.Scalars['String'];
}>;

export type GetDocumentAmountInputDocumentTypeQuery = {getDocumentTypeById?: {id: string; amount: boolean; name: string; statement: boolean} | null};

export type GetDocumentAmountInputDocumentTypeType = {id: string; amount: boolean; name: string; statement: boolean};

/**
 * __useGetDocumentAmountInputDocumentTypeQuery__
 *
 * To run a query within a React component, call `useGetDocumentAmountInputDocumentTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentAmountInputDocumentTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentAmountInputDocumentTypeQuery({
 *   variables: {
 *      documentTypeId: // value for 'documentTypeId'
 *   },
 * });
 */
export function useGetDocumentAmountInputDocumentTypeQuery(
	baseOptions: Apollo.QueryHookOptions<GetDocumentAmountInputDocumentTypeQuery, GetDocumentAmountInputDocumentTypeQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetDocumentAmountInputDocumentTypeQuery, GetDocumentAmountInputDocumentTypeQueryVariables>(Operations, options);
}
export function useGetDocumentAmountInputDocumentTypeLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentAmountInputDocumentTypeQuery, GetDocumentAmountInputDocumentTypeQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetDocumentAmountInputDocumentTypeQuery, GetDocumentAmountInputDocumentTypeQueryVariables>(Operations, options);
}
export type GetDocumentAmountInputDocumentTypeQueryHookResult = ReturnType<typeof useGetDocumentAmountInputDocumentTypeQuery>;
export type GetDocumentAmountInputDocumentTypeLazyQueryHookResult = ReturnType<typeof useGetDocumentAmountInputDocumentTypeLazyQuery>;
export type GetDocumentAmountInputDocumentTypeQueryResult = Apollo.QueryResult<
	GetDocumentAmountInputDocumentTypeQuery,
	GetDocumentAmountInputDocumentTypeQueryVariables
>;
