import {useState} from 'react';
import type {DropdownProps} from 'semantic-ui-react';
import {VendorAddField} from './VendorAddField';
import {VendorDropdownField} from './VendorDropdownField';

interface VendorDropdownProps extends Omit<DropdownProps, 'onChange'> {
	onChange?: (val?: string) => void;
	addNewVendorButton?: boolean;
	accountInfoId: string;
	includeGlobalVendors?: boolean;
}

export function VendorDropdown(props: VendorDropdownProps) {
	const {onChange, setVendorEnabled, addNewVendorButton, accountInfoId, ...rest} = props;
	const [addNewVendor, setAddNewVendor] = useState<boolean>(false);
	const [newVendorName, setNewVendorName] = useState<string>('');

	if (addNewVendor) {
		return <VendorAddField setAddNewVendor={setAddNewVendor} newVendorName={newVendorName} accountInfoId={accountInfoId} onChange={onChange} />;
	}

	return (
		<VendorDropdownField
			{...rest}
			onChange={onChange}
			addNewVendorButton={addNewVendorButton}
			accountInfoId={accountInfoId}
			setNewVendorName={setNewVendorName}
			newVendorName={newVendorName}
			setAddNewVendor={setAddNewVendor}
		/>
	);
}
