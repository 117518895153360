import {TForm, TFormProps} from '@thx/controls';
import {formatDate} from '@thx/date';
import {toMoney} from '@thx/money';
import {localDateSchemaType, moneySchemaType} from '@thx/yup-types';
import {useHistory, useParams} from 'react-router-dom';
import {Card, CardContent, CardDescription, CardGroup, CardMeta} from 'semantic-ui-react';
import {array, boolean, object, string} from 'yup';
import type {CreateStatementInput} from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import {useGetDocumentByIdQuery} from '../../../graphql/getDocumentById';
import type {routes} from '../../../routes';
import {StatementFormFields} from '../StatementFormFields';
import {useCreateStatementFormMutation} from './createStatementForm';

const statementLinesCreateType = object().shape({
	financialAccountId: string().required('The account is required'),
	openingBalance: moneySchemaType().required('The opening balance is required'),
	closingBalance: moneySchemaType().required('The closing balance is required'),
	statementLines: array().of(
		object().shape({
			description: string(),
			date: localDateSchemaType(),
			debit: moneySchemaType().nullable(),
			credit: moneySchemaType().nullable(),
			document: string(),
			status: string().required('Status is required'),
			reconciled: boolean().required('Reconciled is required'),
		}),
	),
});

export function StatementCreate() {
	const throwError = useAsyncError();
	const {goBack} = useHistory();
	const {documentId, accountInfoId} = useParams<typeof routes.types.statementCreate>();

	const {data, error} = useGetDocumentByIdQuery({
		variables: {documentId},
	});
	if (error) throwError(error);

	const [createStatementFormMutation, {called}] = useCreateStatementFormMutation();

	function handleFormSubmit(statementLineData: CreateStatementInput) {
		createStatementFormMutation({variables: {data: {...statementLineData, document: documentId}}})
			.then(() => goBack())
			.catch(throwError);
	}
	return (
		<>
			<CardGroup style={{paddingBottom: 10}}>
				<Card>
					<CardContent>
						<CardMeta>Date</CardMeta>
						<CardDescription>{formatDate(data?.getDocumentById?.date)}</CardDescription>
					</CardContent>
				</Card>
				<Card>
					<CardContent>
						<CardMeta>Type</CardMeta>
						<CardDescription>{data?.getDocumentById?.type.name}</CardDescription>
					</CardContent>
				</Card>
				{data?.getDocumentById?.vendor?.name && (
					<Card>
						<CardContent>
							<CardMeta>Vendor</CardMeta>
							<CardDescription>{data?.getDocumentById?.vendor?.name}</CardDescription>
						</CardContent>
					</Card>
				)}
			</CardGroup>
			<TForm<CreateStatementInput>
				initialValues={{
					openingBalance: toMoney(0),
					closingBalance: toMoney(0),
					statementLines: [{description: '', date: null, debit: toMoney(), credit: toMoney(), status: 'DocumentRequired', reconciled: false}],
				}}
				onSubmit={handleFormSubmit}
				validationSchema={statementLinesCreateType}
			>
				{(tFormProps: TFormProps<CreateStatementInput>) => {
					return <StatementFormFields {...tFormProps} called={called} accountInfoId={accountInfoId} documentType={data?.getDocumentById?.type.id} />;
				}}
			</TForm>
		</>
	);
}
