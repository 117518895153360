import {defineRoutes} from '@imperium/router';

export const routes = defineRoutes({
	login: {
		path: '/login',
		exact: true,
		isPublic: true,
	},
	logout: {
		path: '/logout',
		exact: true,
		isPublic: true,
	},
	accountRegistration: {
		path: '/login/register-account',
		isPublic: true,
	},
	passwordReset: {
		path: '/login/password-reset',
		isPublic: true,
	},
	user: {
		path: '/user/:userId',
		params: ['userId'] as const,
	},
	userEdit: {
		path: '/user/:userId/edit',
		params: ['userId'] as const,
	},
	agentActivity: {
		path: '/agent/:agentId/activity/start-date/:startDate/end-date/:endDate',
		params: ['agentId', 'startDate', 'endDate'] as const,
	},
	userNotifications: {
		path: '/user/:userId/notifications',
		params: ['userId'] as const,
	},
	userAlertSettings: {
		path: '/user/:userId/alert-settings',
		params: ['userId'] as const,
	},
	agentReminders: {
		path: '/user/:userId/reminders',
		params: ['userId'] as const,
	},
	agentReminderAdd: {
		path: '/user/:userId/reminders/add',
		params: ['userId'] as const,
	},
	agentReminderEdit: {
		path: '/user/:userId/reminders/edit/:reminderId',
		params: ['userId', 'reminderId'] as const,
	},
	timeline: {
		path: '/user/:userId/timeline',
		params: ['userId'] as const,
	},
});
