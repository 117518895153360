import {useDispatch} from 'react-redux';
import {Checkbox, List} from 'semantic-ui-react';
import {
	useSearchState,
	setSearchCorporate,
	setSearchInactive,
	setSearchLead,
	setSearchUser,
	setSearchPersonal,
	setSearchSelfEmployed,
	setSearchAuthorizedRepresentative,
	setSearchBasic,
} from '../state';

export function SearchResultsSidebar() {
	const dispatch = useDispatch();
	const {searchInactive, searchSelfEmployed, searchCorporate, searchPersonal, searchBasic, searchUser, searchAuthorizedRepresentative, searchLead} =
		useSearchState();

	return (
		<>
			<h3>Status</h3>
			<List.Item>
				<Checkbox toggle label="Inactive" checked={searchInactive} onChange={() => dispatch(setSearchInactive(!searchInactive))} />
			</List.Item>
			<h3>Accounts</h3>
			<List>
				<List.Item>
					<Checkbox toggle label="Corporate" checked={searchCorporate} onChange={() => dispatch(setSearchCorporate(!searchCorporate))} />
				</List.Item>
				<List.Item>
					<Checkbox toggle label="Self Employed" checked={searchSelfEmployed} onChange={() => dispatch(setSearchSelfEmployed(!searchSelfEmployed))} />
				</List.Item>
				<List.Item>
					<Checkbox toggle label="Personal" checked={searchPersonal} onChange={() => dispatch(setSearchPersonal(!searchPersonal))} />
				</List.Item>
				<List.Item>
					<Checkbox toggle label="Basic" checked={searchBasic} onChange={() => dispatch(setSearchBasic(!searchBasic))} />
				</List.Item>
			</List>
			<h3>Contacts</h3>
			<List>
				<List.Item>
					<Checkbox
						toggle
						label="Authorized Representatives"
						checked={searchAuthorizedRepresentative}
						onChange={() => dispatch(setSearchAuthorizedRepresentative(!searchAuthorizedRepresentative))}
					/>
				</List.Item>
				<List.Item>
					<Checkbox toggle label="Leads" checked={searchLead} onChange={() => dispatch(setSearchLead(!searchLead))} />
				</List.Item>
				<List.Item>
					<Checkbox toggle label="Users" checked={searchUser} onChange={() => dispatch(setSearchUser(!searchUser))} />
				</List.Item>
			</List>
		</>
	);
}
