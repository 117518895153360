import {Header} from 'semantic-ui-react';
import {useGetUserNameQuery} from './getUserName';

interface Props {
	title: string;
	userId: string;
}

export function UserHeader({title, userId}: Props) {
	const {data, error} = useGetUserNameQuery({variables: {userId}, fetchPolicy: 'cache-first'});
	if (error) throw error;

	return (
		<Header size="large">
			{title} - {data?.getUserById?.name}
		</Header>
	);
}
