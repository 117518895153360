import {startCase} from 'lodash-es';
import {useHistory, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button, Form} from 'semantic-ui-react';
import {ConfirmModal, Loading} from '~common/components';
import {useAsyncError} from '~lib/useAsyncError';
import {routes} from '../../routes';
import {useArchiveFinancialAccountByIdMutation} from './ViewFinancialAccount/archiveFinancialAccountById';
import {useGetFinancialAccountByIdQuery} from './ViewFinancialAccount/getFinancialAccountById';

export default function ViewFinancialAccountSidebar() {
	const {accountInfoId, financialAccountId} = useParams<typeof routes.types.viewFinancialAccount>();
	const {push} = useHistory();
	const throwError = useAsyncError();

	const {data, error, loading} = useGetFinancialAccountByIdQuery({variables: {id: financialAccountId}});

	const [archiveMutation, {loading: mutationLoading}] = useArchiveFinancialAccountByIdMutation();

	if (error) throwError(error);
	if (loading || !data) return <Loading />;

	async function archive(id: string, version: number) {
		if (data?.getFinancialAccountById) {
			await archiveMutation({
				variables: {id, version},
			})
				.then(() => {
					toast.success('Financial account archived.');
					push(routes.to.financialAccounts({accountInfoId}));
				})
				.catch(throwError);
		}
	}

	const accountLabel = startCase(data?.getFinancialAccountById.type);

	return (
		<Form>
			<Form.Input>
				<Button
					loading={loading}
					fluid
					positive
					onClick={() => {
						push(routes.to.editFinancialAccount({accountInfoId, financialAccountId}));
					}}
				>
					{`Edit ${accountLabel}`}
				</Button>
			</Form.Input>
			<Form.Input>
				<ConfirmModal
					onConfirm={() => archive(data?.getFinancialAccountById.id, data?.getFinancialAccountById.version)}
					headerText={`Archive this ${accountLabel}`}
					text={`Are you sure you want to archive this ${accountLabel}?`}
					trigger={
						<Button
							loading={loading}
							disabled={mutationLoading || data.getFinancialAccountById.archived}
							fluid
							secondary
							content={`Archive ${accountLabel}`}
						/>
					}
				/>
			</Form.Input>
		</Form>
	);
}
