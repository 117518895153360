import {defineRoutes} from '@imperium/router';

export const routes = defineRoutes({
	t5: {
		path: `/account/:accountInfoId/t5`,
		params: ['accountInfoId'] as const,
	},
	createT5: {
		path: `/account/:accountInfoId/t5/create`,
		params: ['accountInfoId'] as const,
	},
	editT5: {
		path: `/account/:accountInfoId/t5/:t5Id/edit`,
		params: ['accountInfoId', 't5Id'] as const,
	},
});
