import debug from 'debug';
import {Dropdown, DropdownProps} from 'semantic-ui-react';
import {useGetOfficeDropdownQuery} from '~common/components/OfficeDropdown/getOfficeDropdown';

const d = debug('tacs.web.common.components.OfficeDropdown');

interface OfficeDropdownProps extends Omit<DropdownProps, 'options' | 'onChange'> {
	onChange?: (managerId: string) => void;
	organizationId?: string;
}

export function OfficeDropdown(props: OfficeDropdownProps) {
	const {onChange, loading, organizationId, ...rest} = props;
	const {data, loading: queryLoading, error} = useGetOfficeDropdownQuery({variables: {organizationId}});

	function handleChange(val?: string) {
		if (val && onChange) onChange(val);
	}

	const options = data?.getOfficesByOrganization?.map(office => {
		return {key: office.id, value: office.id, text: office.name};
	});

	return (
		<Dropdown
			search
			{...rest}
			error={!!error}
			loading={loading || queryLoading}
			options={options || []}
			onChange={(e, val) => handleChange(val.value?.toString())}
		/>
	);
}
