import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getRemoveDirectorButtonDirector.graphql';

const defaultOptions = {} as const;
export type GetRemoveDirectorButtonDirectorQueryVariables = Types.Exact<{
	directorId: Types.Scalars['String'];
}>;

export type GetRemoveDirectorButtonDirectorQuery = {getDirectorById?: {id: string; version: number} | null};

export type GetRemoveDirectorButtonDirectorType = {id: string; version: number};

/**
 * __useGetRemoveDirectorButtonDirectorQuery__
 *
 * To run a query within a React component, call `useGetRemoveDirectorButtonDirectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRemoveDirectorButtonDirectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRemoveDirectorButtonDirectorQuery({
 *   variables: {
 *      directorId: // value for 'directorId'
 *   },
 * });
 */
export function useGetRemoveDirectorButtonDirectorQuery(
	baseOptions: Apollo.QueryHookOptions<GetRemoveDirectorButtonDirectorQuery, GetRemoveDirectorButtonDirectorQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetRemoveDirectorButtonDirectorQuery, GetRemoveDirectorButtonDirectorQueryVariables>(Operations, options);
}
export function useGetRemoveDirectorButtonDirectorLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetRemoveDirectorButtonDirectorQuery, GetRemoveDirectorButtonDirectorQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetRemoveDirectorButtonDirectorQuery, GetRemoveDirectorButtonDirectorQueryVariables>(Operations, options);
}
export type GetRemoveDirectorButtonDirectorQueryHookResult = ReturnType<typeof useGetRemoveDirectorButtonDirectorQuery>;
export type GetRemoveDirectorButtonDirectorLazyQueryHookResult = ReturnType<typeof useGetRemoveDirectorButtonDirectorLazyQuery>;
export type GetRemoveDirectorButtonDirectorQueryResult = Apollo.QueryResult<
	GetRemoveDirectorButtonDirectorQuery,
	GetRemoveDirectorButtonDirectorQueryVariables
>;
