import {createPages} from '@imperium/layout';
import loadable from '@loadable/component';
import {PaymentScheduleSidebar} from './components/PaymentScheduleSidebar';
import {PaymentSidebar} from './components/PaymentSidebar';
import {TransactionsSidebar} from './components/TransactionsSidebar';
import {ContractEditSidebar} from './components/contract/ContractEditSidebar';
import {routes} from './routes';

const ContractAddForm = loadable(() => import('./components/contract/ContractAddForm'));
const ContractEditForm = loadable(() => import('./components/contract/ContractEditForm'));
const ContractTable = loadable(() => import('./components/contract/ContractTable'));
const ContractTemplateAddForm = loadable(() => import('./components/contractTemplate/ContractTemplateAddForm'));
const ContractTemplateEditForm = loadable(() => import('./components/contractTemplate/ContractTemplateEditForm'));
const ContractTemplateTable = loadable(() => import('./components/contractTemplate/ContractTemplateTable'));
const InvoiceAddForm = loadable(() => import('./components/InvoiceAddForm'));
const InvoiceDetailsView = loadable(() => import('./components/InvoiceDetailsView'));
const PaymentAddForm = loadable(() => import('./components/PaymentAddForm'));
const PaymentDetailsView = loadable(() => import('./components/PaymentDetailsView'));
const PaymentScheduleTable = loadable(() => import('./components/PaymentScheduleTable'));
const ScheduledPaymentDetailsView = loadable(() => import('./components/ScheduledPaymentDetailsView'));
const TransactionTable = loadable(() => import('./components/TransactionTable'));

export const routeProps = createPages(routes, {
	contractTemplates: {
		header: 'Contract Templates',
		content: () => <ContractTemplateTable />,
		sidebar: [
			{
				to: routes.to.contractTemplateAdd(),
				color: 'green',
				text: 'Add Contract Template',
			},
		],
	},
	contractTemplateAdd: {
		header: 'Add Contract Template',
		content: () => <ContractTemplateAddForm />,
	},
	contractTemplateEdit: {
		header: 'Edit Contract Template',
		content: () => <ContractTemplateEditForm />,
	},
	invoiceAdd: {
		header: 'Add Invoice',
		content: () => <InvoiceAddForm />,
	},
	paymentAdd: {
		header: 'Add Payment',
		content: () => <PaymentAddForm />,
	},
	transactions: {
		header: 'Transactions',
		content: () => <TransactionTable />,
		sidebar: [{render: TransactionsSidebar}],
	},
	paymentSchedule: {
		header: 'Payment Schedule',
		content: () => <PaymentScheduleTable />,
	},
	paymentDetails: {
		header: 'Payment',
		content: () => <PaymentDetailsView />,
		sidebar: [{render: PaymentSidebar}],
	},
	scheduledPaymentDetails: {
		header: 'Scheduled Payment',
		content: () => <ScheduledPaymentDetailsView />,
		sidebar: [{render: PaymentScheduleSidebar}],
	},
	invoiceDetails: {
		header: 'Invoice',
		content: () => <InvoiceDetailsView />,
	},
	contracts: {
		header: 'Contracts',
		content: () => <ContractTable />,
		sidebar: [
			{
				to: dat => routes.to.contractAdd({accountInfoId: dat.params.accountInfoId}),
				color: 'green',
				text: 'Add Contract',
			},
		],
	},
	contractAdd: {
		header: 'Contract Add',
		content: () => <ContractAddForm />,
	},
	contractEdit: {
		header: 'Contract Edit',
		content: () => <ContractEditForm />,
		sidebar: [{render: ContractEditSidebar}],
	},
});
