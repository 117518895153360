import {useFetch} from '@imperium/auth-client';
import {LocalDate} from '@js-joda/core';
import {LocalDatePicker} from '@thx/controls';
import {env} from '@thx/env';
import {sequential} from '@thx/promise-sequential';
import debug from 'debug';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {Grid, GridColumn, GridRow, Button, Icon, Card, CardGroup, CardDescription, Divider, FormInput, Form, Dropdown} from 'semantic-ui-react';
import {ReceiveMethodEnum} from '~core/graphql';
import {defaults} from '../../../defaults';
import type {routes} from '../../routes';
import {DropZone} from '../DropZone';
import {VendorDropdown} from '../documents/VendorDropdown';
import styles from './LogBookUploader.module.css';

const d = debug('tacs.web.documents.components.LogBookUploader');

enum FileStatusEnum {
	Pending = 'Pending',
	Success = 'Success',
	Failure = 'Failure',
	Duplicate = 'Duplicate',
	Uploading = 'Uploading',
}

type Upload = {
	status: FileStatusEnum;
	file: File;
	date: LocalDate;
	type: string;
	vendor: string;
};

export default function LogBookUploader() {
	const unsortedFileUploadUrl = env.getString('unsortedFileUploadUrl', defaults.unsortedFileUploadUrl);
	const {accountInfoId} = useParams<typeof routes.types.logBookUpload>();
	const [uploads, setUploads] = useState<Upload[]>([]);
	const [disabled, setDisabled] = useState(false);
	const fetch = useFetch();
	const maxDocUploadSizeMb = env.getInt('maxDocUploadSizeMb', defaults.maxDocUploadSizeMb);

	function cardColor(status: FileStatusEnum) {
		switch (status) {
			case FileStatusEnum.Pending:
				return undefined;
			case FileStatusEnum.Success:
				return 'green';
			case FileStatusEnum.Failure:
				return 'red';
			case FileStatusEnum.Duplicate:
				return 'yellow';
			case FileStatusEnum.Uploading:
				return 'blue';
			default:
				return undefined;
		}
	}

	async function uploadFiles() {
		setDisabled(true);
		await sequential(
			uploads.map((upload, index) => async () => {
				const currentFile = uploads;
				if (upload.file.size > maxDocUploadSizeMb * 1048576) {
					currentFile[index].status = FileStatusEnum.Failure;
					setUploads([...currentFile]);
				} else if (upload.status === FileStatusEnum.Pending) {
					currentFile[index].status = FileStatusEnum.Uploading;
					setUploads([...currentFile]);

					const formData = new FormData();
					formData.append('file', upload.file);

					try {
						const response = await fetch(unsortedFileUploadUrl, {
							method: 'POST',
							mode: 'cors',
							credentials: 'include',
							headers: {
								Account: accountInfoId,
								method: ReceiveMethodEnum.Website,
								documentDate: upload.date.toEpochDay().toString(),
								documentType: upload.type,
								documentVendor: upload.vendor,
							},
							body: formData,
						});

						if (response.status === 200) {
							currentFile[index].status = FileStatusEnum.Success;
							setUploads([...currentFile]);
						} else if (response.status === 522) {
							currentFile[index].status = FileStatusEnum.Duplicate;
							setUploads([...currentFile]);
						} else {
							currentFile[index].status = FileStatusEnum.Failure;
							setUploads([...currentFile]);
						}
					} catch (err) {
						currentFile[index].status = FileStatusEnum.Failure;
						setUploads([...currentFile]);
					}
				}
			}),
		);

		setDisabled(false);
	}
	function editUploadArray(upload: Upload, {date, vendor}: {vendor?: string; date?: LocalDate | null}) {
		return uploads.map(old => {
			if (old.file === upload.file) {
				if (vendor) {
					return {
						...old,
						vendor,
					};
				}
				if (date) {
					return {
						...old,
						date: date || LocalDate.now(),
					};
				}
			}
			return old;
		});
	}

	return (
		<div className={styles.OuterDivStyle}>
			<Grid columns="equal" stackable>
				<GridRow style={{paddingBottom: 5}}>
					<GridColumn width={16}>
						<DropZone
							onFilesAdded={filesToUpload => {
								const filterDuplicateFiles = filesToUpload.filter(fileToUpload => !uploads.some(upload => upload.file.name === fileToUpload.name));
								setUploads(
									uploads.concat(
										filterDuplicateFiles.map(file => {
											return {file, status: FileStatusEnum.Pending, date: LocalDate.now(), vendor: '', type: '8a1c1929-0917-4c84-81a9-325af43bfe00'};
										}),
									),
								);
							}}
							disabled={disabled}
							multiple
						/>
					</GridColumn>
				</GridRow>
				<GridRow>
					<GridColumn>
						<CardGroup>
							{uploads.map(upload => {
								return (
									<Card key={upload.file.name} color={cardColor(upload.status)}>
										<Card.Content>
											<CardDescription>
												<Icon name="file" color={cardColor(upload.status)} size="large" />
												<Icon
													style={{cursor: 'pointer', float: 'right'}}
													name="delete"
													onClick={() => setUploads(uploads.filter(currentFiles => currentFiles.file.name !== upload.file.name))}
													color="red"
												/>
												{upload.file.name}
											</CardDescription>
											<Divider />
											<CardDescription>
												<Form>
													<FormInput label="Type" required>
														<Dropdown value="log-book" selection fluid disabled options={[{key: '1', text: 'Log Book', value: 'log-book'}]} />
													</FormInput>
													<FormInput label="Date" required>
														<LocalDatePicker
															maxDate={LocalDate.now()}
															value={upload.date}
															onChange={date => setUploads(editUploadArray(upload, {date}))}
															disabled={upload.status !== FileStatusEnum.Pending}
														/>
													</FormInput>
													<FormInput label="Vendor" required>
														<VendorDropdown
															addNewVendorButton
															value={upload.vendor}
															accountInfoId={accountInfoId}
															fluid
															onChange={vendor => setUploads(editUploadArray(upload, {vendor}))}
															disabled={upload.status !== FileStatusEnum.Pending}
														/>
													</FormInput>
												</Form>
											</CardDescription>
											<Divider />
											<Card.Meta>{upload.status}</Card.Meta>
										</Card.Content>
									</Card>
								);
							})}
						</CardGroup>
					</GridColumn>
				</GridRow>
				<GridRow style={{paddingTop: 5}}>
					<GridColumn>
						<Button
							positive
							type="button"
							disabled={uploads.length === 0 || !!uploads.find(upload => upload.vendor === '')}
							onClick={uploadFiles}
							fluid
						>
							Upload
						</Button>
					</GridColumn>
				</GridRow>
			</Grid>
		</div>
	);
}
