import {capitalize} from 'lodash-es';
import {Fragment} from 'react';
import {
	Button,
	Card,
	CardContent,
	Grid,
	GridColumn,
	GridRow,
	Header,
	Icon,
	Label,
	List,
	type SemanticICONS,
	Table,
	TableBody,
	TableCell,
	TableHeader,
	TableHeaderCell,
	TableRow,
} from 'semantic-ui-react';
import {ColoredSpan} from '~common/components';
import type {GeneralLedgerAccountTypeEnum} from '~core/graphql';

export interface PreviewGlAccountsProps {
	onConfirm: () => void;
	onCancel: () => void;
	headerText?: string;
	icon?: SemanticICONS;
	name: string;
	headerName?: string;
	details?: {name: string; description?: string | null}[];
	glAccounts?: {name: string; code: number; type: GeneralLedgerAccountTypeEnum; notice?: string}[] | null;
	saveDisabled?: boolean;
	warningMessage?: string | undefined;
}

export function PreviewGlAccounts({
	saveDisabled,
	onConfirm,
	headerText,
	icon,
	name,
	headerName,
	details,
	glAccounts,
	onCancel,
	warningMessage,
}: PreviewGlAccountsProps) {
	return (
		<Grid>
			<GridRow>
				<GridColumn>
					<h3>
						{!!icon && <Icon color="green" name={icon} />}
						{headerText}
					</h3>
				</GridColumn>
			</GridRow>
			<GridRow>
				{details && headerName && (
					<GridColumn width={8}>
						<Header>{headerName} Details</Header>
						<Card>
							<CardContent>
								<Header size="tiny">{name}</Header>
							</CardContent>
							<CardContent>
								<List>
									{details.map(detail => {
										if (!detail.description) return null;
										return (
											<List.Item key={detail.name}>
												<ColoredSpan labelText={detail.name} contentText={detail.description} />
											</List.Item>
										);
									})}
								</List>
							</CardContent>
						</Card>
					</GridColumn>
				)}
			</GridRow>
			{glAccounts && glAccounts.length > 0 && (
				<GridRow>
					<GridColumn width={16}>
						<Header>Linked General Ledger Account{(glAccounts?.length || 0) > 1 ? 's' : ''}</Header>
						<Table>
							<TableHeader>
								<TableRow>
									<TableHeaderCell>Code</TableHeaderCell>
									<TableHeaderCell>Name</TableHeaderCell>
									<TableHeaderCell>Type</TableHeaderCell>
								</TableRow>
							</TableHeader>
							<TableBody>
								{glAccounts?.map(acc => {
									return (
										<Fragment key={acc.code}>
											<TableRow>
												<TableCell>{acc.code}</TableCell>
												<TableCell>{acc.name}</TableCell>
												<TableCell>{capitalize(acc.type)}</TableCell>
											</TableRow>
											{acc.notice && (
												<TableRow>
													<TableCell />
													<TableCell>
														<Label icon="info" content={acc.notice} />
													</TableCell>
													<TableCell />
												</TableRow>
											)}
										</Fragment>
									);
								})}
							</TableBody>
						</Table>
					</GridColumn>
				</GridRow>
			)}
			{warningMessage && (
				<GridRow>
					<GridColumn>
						<Label icon="info" content={warningMessage} />
					</GridColumn>
				</GridRow>
			)}
			<GridRow>
				<GridColumn textAlign="right">
					<Button
						disabled={saveDisabled}
						color="green"
						onClick={() => {
							onConfirm();
						}}
					>
						<Icon name="checkmark" /> Save
					</Button>
					<Button
						color="orange"
						floated="right"
						onClick={() => {
							onCancel();
						}}
					>
						<Icon name="dont" /> Cancel
					</Button>
				</GridColumn>
			</GridRow>
		</Grid>
	);
}
