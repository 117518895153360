import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './cloneAccount.graphql';

const defaultOptions = {} as const;
export type CloneAccountMutationVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
}>;

export type CloneAccountMutation = {cloneAccount: {id: string; friendlyId: number}};

export type CloneAccountType = {id: string; friendlyId: number};

export type CloneAccountMutationFn = Apollo.MutationFunction<CloneAccountMutation, CloneAccountMutationVariables>;

/**
 * __useCloneAccountMutation__
 *
 * To run a mutation, you first call `useCloneAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneAccountMutation, { data, loading, error }] = useCloneAccountMutation({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *   },
 * });
 */
export function useCloneAccountMutation(baseOptions?: Apollo.MutationHookOptions<CloneAccountMutation, CloneAccountMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<CloneAccountMutation, CloneAccountMutationVariables>(Operations, options);
}
export type CloneAccountMutationHookResult = ReturnType<typeof useCloneAccountMutation>;
export type CloneAccountMutationResult = Apollo.MutationResult<CloneAccountMutation>;
export type CloneAccountMutationOptions = Apollo.BaseMutationOptions<CloneAccountMutation, CloneAccountMutationVariables>;
