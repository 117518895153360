import * as Apollo from '@apollo/client';

import type {LocalDate} from '@js-joda/core';
import type Money from 'js-money';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getDocumentById.graphql';

const defaultOptions = {} as const;
export type GetDocumentByIdQueryVariables = Types.Exact<{
	documentId: Types.Scalars['String'];
}>;

export type GetDocumentByIdQuery = {
	getDocumentURL?: string | null;
	getDocumentById?: {
		id: string;
		pages: Array<number>;
		version: number;
		amount?: Money | null;
		status: Types.DocumentStatusEnum;
		cash: boolean;
		date: LocalDate;
		sortedOn: LocalDate;
		type: {id: string; name: string; statement: boolean};
		vendor: {id: string; name: string};
		employee?: {id: string} | null;
		corporateAsset?: {id: string} | null;
		fileInfo: {id: string};
		sortedBy: {id: string; name: string};
	} | null;
};

export type GetDocumentByIdType = string;

/**
 * __useGetDocumentByIdQuery__
 *
 * To run a query within a React component, call `useGetDocumentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentByIdQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useGetDocumentByIdQuery(baseOptions: Apollo.QueryHookOptions<GetDocumentByIdQuery, GetDocumentByIdQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetDocumentByIdQuery, GetDocumentByIdQueryVariables>(Operations, options);
}
export function useGetDocumentByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentByIdQuery, GetDocumentByIdQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetDocumentByIdQuery, GetDocumentByIdQueryVariables>(Operations, options);
}
export type GetDocumentByIdQueryHookResult = ReturnType<typeof useGetDocumentByIdQuery>;
export type GetDocumentByIdLazyQueryHookResult = ReturnType<typeof useGetDocumentByIdLazyQuery>;
export type GetDocumentByIdQueryResult = Apollo.QueryResult<GetDocumentByIdQuery, GetDocumentByIdQueryVariables>;
