import {createPages} from '@imperium/layout';
import loadable from '@loadable/component';
import {CorporateAssetsTableSidebar} from './components/corporateAssets/CorporateAssetsTable/CorporateAssetsTableSidebar';
import FinancialAccountsOverviewSidebar from './components/financialAccount/FinancialAccountsOverviewSidebar';
import ViewFinancialAccountSidebar from './components/financialAccount/ViewFinancialAccountSidebar';

import {PersonalVehiclesTableSidebar} from './components/personalVehicles/PersonalVehicleTable/PersonalVehiclesTableSidebar';
import {routes} from './routes';

const CorporateAssetCreateForm = loadable(() => import('./components/corporateAssets/CorporateAssetCreateForm'));
const CorporateAssetEditForm = loadable(() => import('./components/corporateAssets/CorporateAssetEditForm'));
const CorporateAssetsTable = loadable(() => import('./components/corporateAssets/CorporateAssetsTable'));
const PersonalVehicleCreateForm = loadable(() => import('./components/personalVehicles/PersonalVehicleCreateForm'));
const PersonalVehicleEditForm = loadable(() => import('./components/personalVehicles/PersonalVehicleEditForm'));
const PersonalVehicleTable = loadable(() => import('./components/personalVehicles/PersonalVehicleTable'));

const FinancialAccountsTable = loadable(() => import('./components/financialAccount/FinancialAccounts/FinancialAccounts'));
const CreateFinancialAccount = loadable(() => import('./components/financialAccount/createFinancialAccount'));
const EditFinancialAccounts = loadable(() => import('./components/financialAccount/EditFinancialAccount'));
const ViewFinancialAccount = loadable(() => import('./components/financialAccount/ViewFinancialAccount/ViewFinancialAccount'));

export const routeProps = createPages(routes, {
	corporateAssets: {
		header: 'Corporate Assets',
		content: () => <CorporateAssetsTable />,
		sidebar: [{render: CorporateAssetsTableSidebar}],
	},
	createCorporateAsset: {
		header: 'Create Corporate Asset',
		content: () => <CorporateAssetCreateForm />,
	},
	editCorporateAsset: {
		header: 'Edit Corporate Asset',
		content: () => <CorporateAssetEditForm />,
	},
	personalVehicles: {
		header: 'Personal Vehicles',
		content: () => <PersonalVehicleTable />,
		sidebar: [{render: PersonalVehiclesTableSidebar}],
	},
	createPersonalVehicle: {
		header: 'Create Personal Vehicle',
		content: () => <PersonalVehicleCreateForm />,
	},
	editPersonalVehicle: {
		header: 'Edit Personal Vehicle',
		content: () => <PersonalVehicleEditForm />,
	},
	financialAccounts: {
		header: 'Financial Accounts',
		content: () => <FinancialAccountsTable />,
		sidebar: [{render: FinancialAccountsOverviewSidebar}],
	},
	viewCreditCardAccount: {
		header: 'View Credit Card',
		content: () => <ViewFinancialAccount />,
		sidebar: [{render: ViewFinancialAccountSidebar}],
	},
	viewFinancialAccount: {
		header: 'View Bank Account',
		content: () => <ViewFinancialAccount />,
		sidebar: [{render: ViewFinancialAccountSidebar}],
	},
	viewShortTermLoan: {
		header: 'View Short Term Loan',
		content: () => <ViewFinancialAccount />,
		sidebar: [{render: ViewFinancialAccountSidebar}],
	},
	viewLongTermLoan: {
		header: 'View Long Term Loan',
		content: () => <ViewFinancialAccount />,
		sidebar: [{render: ViewFinancialAccountSidebar}],
	},
	createFinancialAccount: {
		header: 'Create Financial Account',
		content: () => <CreateFinancialAccount />,
	},
	editFinancialAccount: {
		header: 'Edit Financial Account',
		content: () => <EditFinancialAccounts />,
	},
});
