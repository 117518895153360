import {Dropdown, DropdownProps} from 'semantic-ui-react';
import {CurrencyCountryEnum} from '~core/graphql';

export default function CurrencyCountryDropdown(props: Omit<DropdownProps, 'options'>) {
	const {value, onChange, fluid, rest} = props;
	const options = Object.values(CurrencyCountryEnum).map(cur => {
		return {text: cur, value: cur};
	});

	return (
		<Dropdown
			{...rest}
			fluid={fluid}
			search
			selection
			value={value}
			options={options}
			onChange={(_e, val) => {
				onChange(val.value);
			}}
		/>
	);
}
