import type {ImperiumLayoutClientModule} from '@imperium/layout';
import type {ImperiumStateClientModule} from '@imperium/state';
import {layout} from '~common/layout';
import {state} from '~common/state';

export function commonModule(): ImperiumStateClientModule & ImperiumLayoutClientModule {
	return {
		name: 'Common',
		layout,
		state,
	};
}
