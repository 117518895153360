import {startCase} from 'lodash-es';
import {Dropdown, DropdownProps} from 'semantic-ui-react';
import {FinancialAccountTypeEnum} from '~core/graphql';

interface Props extends Omit<DropdownProps, 'value' | 'onChange'> {
	value: FinancialAccountTypeEnum;
	onChange: (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => void;
}
export function FinancialAccountTypeDropdown({value, onChange, ...rest}: Props) {
	const options = Object.keys(FinancialAccountTypeEnum).map(o => {
		return {text: startCase(o), value: o};
	});
	return <Dropdown selection fluid value={value} onChange={onChange} options={options} {...rest} />;
}
