import {defineRoutes} from '@imperium/router';

export const routes = defineRoutes({
	gst: {
		path: `/account/:accountInfoId/gst`,
		params: ['accountInfoId'] as const,
	},
	depositGST: {
		path: `/account/:accountInfoId/gst/deposit`,
		params: ['accountInfoId'] as const,
	},
	editDepositedGST: {
		path: `/account/:accountInfoId/gst/deposit/:depositedGstId`,
		params: ['accountInfoId', 'depositedGstId'] as const,
	},
	fileGST: {
		path: `/account/:accountInfoId/gst/file`,
		params: ['accountInfoId'] as const,
	},
	editFiledGST: {
		path: `/account/:accountInfoId/gst/file/:filedGstId`,
		params: ['accountInfoId', 'filedGstId'] as const,
	},
	payGST: {
		path: `/account/:accountInfoId/gst/pay`,
		params: ['accountInfoId'] as const,
	},
	editPaidGST: {
		path: `/account/:accountInfoId/gst/pay/:paidGstId`,
		params: ['accountInfoId', 'paidGstId'] as const,
	},
	holdGST: {
		path: `/account/:accountInfoId/gst/hold`,
		params: ['accountInfoId'] as const,
	},
	editHeldGST: {
		path: `/account/:accountInfoId/gst/hold/:heldGstId`,
		params: ['accountInfoId', 'heldGstId'] as const,
	},
});
