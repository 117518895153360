import {EmployeeStatusFilterEnum} from '~core/graphql';

export function getEmployeeStatus() {
	return [
		{
			text: 'Employed',
			key: EmployeeStatusFilterEnum.Employed,
			value: EmployeeStatusFilterEnum.Employed,
		},
		{
			text: 'Previously Employed',
			key: EmployeeStatusFilterEnum.PreviouslyEmployed,
			value: EmployeeStatusFilterEnum.PreviouslyEmployed,
		},
		{
			text: 'All',
			key: EmployeeStatusFilterEnum.All,
			value: EmployeeStatusFilterEnum.All,
		},
	];
}
