import {useHistory, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button, List} from 'semantic-ui-react';
import {ConfirmModal} from '~common/components';
import {useAsyncError} from '~lib/useAsyncError';
import type {routes} from '../../routes';
import {useGetPaymentDetailsQuery} from '../PaymentDetailsView/getPaymentDetails';
import {useMarkNsfMutation} from './markNSF';

export function PaymentSidebar() {
	const throwError = useAsyncError();
	const {paymentId} = useParams<typeof routes.types.paymentDetails>();
	const [markNSF] = useMarkNsfMutation();
	const {goBack} = useHistory();

	const {data} = useGetPaymentDetailsQuery({
		variables: {paymentId},
		onError: error => {
			throw error;
		},
	});

	function OntModalConfirmClicked() {
		markNSF({variables: {paymentId}})
			.then(() => {
				toast.success('Payment marked as NSF.');
				goBack();
			})
			.catch(throwError);
	}

	if (data?.getPaymentById?.total.isPositive() && !data?.getPaymentById?.reversed) {
		return (
			<List>
				<List.Item>
					<ConfirmModal
						onConfirm={OntModalConfirmClicked}
						headerText="Confirm NFS"
						text="Are you sure you want to mark this payment NSF?"
						trigger={
							<Button fluid color="grey">
								NSF Payment
							</Button>
						}
					/>
				</List.Item>
			</List>
		);
	}

	return null;
}
