import debug from 'debug';
import {useHistory, useParams} from 'react-router-dom';
import {Button} from 'semantic-ui-react';
import {useAsyncError} from '~lib/useAsyncError';
import {routes} from '../../../routes';
import {useGetRemoveAuthorizedRepresentativeAuthorizedRepresentativeQuery} from './getAuthorizedRepresentativeById';
import {useRemoveAuthorizedRepresentativeMutation} from './removeAuthorizedRepresentative';

const d = debug('tacs.web.accounts.components.authorizedRepresentatives.RemoveAuthorizedRepresentative');

interface RemoveAuthorizedRepresentativeProps {
	authorizedRepresentativeId: string;
}

export function RemoveAuthorizedRepresentative(props: RemoveAuthorizedRepresentativeProps) {
	const {accountInfoId} = useParams<typeof routes.types.authorizedRepresentatives>();
	const throwError = useAsyncError();
	const {push} = useHistory();
	const {authorizedRepresentativeId} = props;
	const {data, loading, error} = useGetRemoveAuthorizedRepresentativeAuthorizedRepresentativeQuery({variables: {authorizedRepresentativeId}});
	const [removeAuthorizedRepresentativeMutation] = useRemoveAuthorizedRepresentativeMutation();

	if (error) throw error;

	const handleDeleteClick = () => {
		const authorizedRepresentativeVersion = data?.getAuthorizedRepresentativeById?.version;
		removeAuthorizedRepresentativeMutation({variables: {id: authorizedRepresentativeId || '', version: authorizedRepresentativeVersion || 0}})
			.then(() => push(routes.to.authorizedRepresentatives({accountInfoId})))
			.catch(throwError);
	};

	return (
		<Button loading={loading} disabled={loading} fluid color="red" onClick={handleDeleteClick}>
			Remove from Authorized Representatives list
		</Button>
	);
}
