import type {FocusEvent} from 'react';
import {FormInput, GridColumn, GridRow} from 'semantic-ui-react';
import type {CreateFinancialAccountInput} from '~core/graphql';
import {VendorDropdown} from '../../../documents/components/documents/VendorDropdown';
import CurrencyCountryDropdown from './CurrencyCountryDropdown';
import {FinancialAccountTypeDropdown} from './FinancialAccountTypeDropdown';

interface FinancialAccountFormProps {
	values: CreateFinancialAccountInput;
	setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
	fieldError: (val: string | number) => boolean;
	accountInfoId: string;
	renderWarnings: () => JSX.Element | null;
	refetch?: (val: string) => void;
	typeDropdownDisabled?: boolean;
}

export function FinancialAccountForm({
	typeDropdownDisabled,
	refetch,
	renderWarnings,
	accountInfoId,
	fieldError,
	setFieldValue,
	values,
}: FinancialAccountFormProps) {
	return (
		<>
			<GridRow>
				<GridColumn>
					<FormInput autoFocus required label="Institution" error={fieldError('institutionId')}>
						<VendorDropdown
							accountInfoId={accountInfoId}
							onChange={val => {
								setFieldValue('institutionId', val);
							}}
							value={values.institutionId}
							addNewVendorButton
							fluid
						/>
					</FormInput>
				</GridColumn>
				<GridColumn>
					<FormInput required label="Account Type" error={fieldError('type')}>
						<FinancialAccountTypeDropdown
							disabled={typeDropdownDisabled}
							value={values.type}
							onChange={(e, val) => setFieldValue('type', val.value)}
						/>
					</FormInput>
				</GridColumn>
				<GridColumn>
					<FormInput
						required
						label="Account Number"
						error={fieldError('accountNumber')}
						value={values.accountNumber}
						onChange={val => setFieldValue('accountNumber', val.target.value)}
					/>
				</GridColumn>
				<GridColumn>
					<FormInput
						required
						label="Nickname"
						error={fieldError('accountNickname')}
						value={values.accountNickname}
						onBlur={(val: FocusEvent<HTMLInputElement>) => {
							val.target?.value && refetch && refetch(val.target.value);
						}}
						onChange={val => setFieldValue('accountNickname', val.target.value)}
					/>
				</GridColumn>
				<GridColumn width={2}>
					<FormInput required label="Currency" error={fieldError('accountCurrency')}>
						<CurrencyCountryDropdown fluid value={values.accountCurrency} onChange={(val: string) => setFieldValue('accountCurrency', val)} />
					</FormInput>
				</GridColumn>
			</GridRow>
			<GridRow>
				<GridColumn>{renderWarnings()}</GridColumn>
			</GridRow>
		</>
	);
}
