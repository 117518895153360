import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './editLinkedAccount.graphql';

const defaultOptions = {} as const;
export type EditLinkedAccountMutationVariables = Types.Exact<{
	data: Types.LinkedGlAccountEditInput;
}>;

export type EditLinkedAccountMutation = {
	editLinkedGlAccount?: {
		id: string;
		version: number;
		linkedAccounts: Array<{type: Types.LinkedGlAccountType; account?: {id: string; version: number; name: string; code: number} | null}>;
	} | null;
};

export type EditLinkedAccountType = {
	id: string;
	version: number;
	linkedAccounts: Array<{type: Types.LinkedGlAccountType; account?: {id: string; version: number; name: string; code: number} | null}>;
};

export type EditLinkedAccountMutationFn = Apollo.MutationFunction<EditLinkedAccountMutation, EditLinkedAccountMutationVariables>;

/**
 * __useEditLinkedAccountMutation__
 *
 * To run a mutation, you first call `useEditLinkedAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditLinkedAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editLinkedAccountMutation, { data, loading, error }] = useEditLinkedAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditLinkedAccountMutation(
	baseOptions?: Apollo.MutationHookOptions<EditLinkedAccountMutation, EditLinkedAccountMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<EditLinkedAccountMutation, EditLinkedAccountMutationVariables>(Operations, options);
}
export type EditLinkedAccountMutationHookResult = ReturnType<typeof useEditLinkedAccountMutation>;
export type EditLinkedAccountMutationResult = Apollo.MutationResult<EditLinkedAccountMutation>;
export type EditLinkedAccountMutationOptions = Apollo.BaseMutationOptions<EditLinkedAccountMutation, EditLinkedAccountMutationVariables>;
