import {useAuthenticatedState} from '@imperium/auth-client';
import {ContentRouter} from '@imperium/router';
import {useCallback, useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';
import {toast} from 'react-toastify';
import {ErrorBoundary} from '~common/components';
import {useVersionPoll} from '~core/useVersionPoll';
import {routes} from '../user/routes';

export function Root(props: any) {
	const {id} = useAuthenticatedState();
	const [currentVer, setCurrentVer] = useState<string | null>(null);
	const hasNewVersion = useVersionPoll(currentVer);

	useEffect(() => {
		const fetchInitialVersion = async (): Promise<void> => {
			try {
				const response = await fetch('/release.txt');
				const text = await response.text();
				if (text.trim().length === 0) {
					setCurrentVer('0.0.0');
				} else {
					setCurrentVer(text.trim());
				}
			} catch (error) {
				setCurrentVer('0.0.0');
			}
		};

		void fetchInitialVersion();
	}, []);

	const redirect = useCallback(({location}: any) => {
		return <Redirect to={{pathname: routes.to.login(), state: {from: location}}} />;
	}, []);

	useEffect(() => {
		if (hasNewVersion) {
			toast.info(
				<div>
					There is a new version available. Please{' '}
					<a
						href="#"
						onClick={e => {
							e.preventDefault();
							window.location.reload();
						}}
					>
						refresh your page
					</a>
					.
				</div>,
				{
					position: 'top-center',
					autoClose: false,
					hideProgressBar: true,
					closeOnClick: false,
					pauseOnHover: true,
					draggable: false,
					progress: undefined,
					theme: 'dark',
				},
			);
		}
	}, [hasNewVersion]);

	return <ContentRouter {...props} errorBoundary={ErrorBoundary} isAuthenticated={!!id} renderOnUnauth={redirect} />;
}
