import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getAgentName.graphql';

const defaultOptions = {} as const;
export type GetAgentNameQueryVariables = Types.Exact<{
	agentId: Types.Scalars['String'];
}>;

export type GetAgentNameQuery = {getAgentById: {id: string; name: string}};

export type GetAgentNameType = {id: string; name: string};

/**
 * __useGetAgentNameQuery__
 *
 * To run a query within a React component, call `useGetAgentNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgentNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgentNameQuery({
 *   variables: {
 *      agentId: // value for 'agentId'
 *   },
 * });
 */
export function useGetAgentNameQuery(baseOptions: Apollo.QueryHookOptions<GetAgentNameQuery, GetAgentNameQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetAgentNameQuery, GetAgentNameQueryVariables>(Operations, options);
}
export function useGetAgentNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAgentNameQuery, GetAgentNameQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetAgentNameQuery, GetAgentNameQueryVariables>(Operations, options);
}
export type GetAgentNameQueryHookResult = ReturnType<typeof useGetAgentNameQuery>;
export type GetAgentNameLazyQueryHookResult = ReturnType<typeof useGetAgentNameLazyQuery>;
export type GetAgentNameQueryResult = Apollo.QueryResult<GetAgentNameQuery, GetAgentNameQueryVariables>;
