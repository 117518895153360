import {JournalEntryTypeEnum} from '~core/graphql';

interface JournalEntryType {
	correctedBy?: {id: string} | null;
	reversedBy?: {id: string} | null;
	reversalOf?: {id: string} | null;
	type: JournalEntryTypeEnum;
}

export function canEditJournalEntry(je: JournalEntryType) {
	return (
		!(je.correctedBy || je.reversedBy || je.reversalOf) &&
		[
			JournalEntryTypeEnum.General,
			JournalEntryTypeEnum.Payable,
			JournalEntryTypeEnum.Receivable,
			JournalEntryTypeEnum.Payment,
			JournalEntryTypeEnum.Receipt,
		].includes(je.type)
	);
}
