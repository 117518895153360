import {useCan} from '@imperium/auth-client';
import debug from 'debug';
import {useHistory, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button, List} from 'semantic-ui-react';
import {DeleteModal} from '~common/components';
import {Permission} from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import {routes} from '../../../routes';
import {useDeleteLeadCascadeMutation} from './deleteLeadCascade';

const d = debug('tacs.web.accounts.components.leads.DeleteLeadButton');

export function DeleteLeadButton() {
	const {leadId} = useParams<typeof routes.types.viewLead>();
	const {push} = useHistory();
	const throwError = useAsyncError();
	const [confirmMutation, {loading: mutationLoading}] = useDeleteLeadCascadeMutation();
	const [sysadmin] = useCan(Permission.Sysadmin);

	if (!sysadmin) return null;

	function onConfirmClicked() {
		confirmMutation({
			variables: {leadId},
		})
			.then(() => {
				toast.success('The lead was removed.');
				push(routes.to.leads());
			})
			.catch(throwError);
	}

	return (
		<DeleteModal
			onDelete={onConfirmClicked}
			headerText="Confirm Delete"
			text="Are you sure you want to delete this lead?"
			trigger={
				<List>
					<List.Item>
						<Button disabled={mutationLoading} fluid negative compact>
							Delete Lead
						</Button>
					</List.Item>
				</List>
			}
		/>
	);
}
