import {LayoutData, useLayoutState} from '@imperium/layout';
import {debug} from 'debug';
import {Permission} from '~core/graphql';
import {needsPermission, needsRoute} from '~lib/permissionHelpers';
import {useUserState} from '../user/state';
import {routes} from './routes';

const d = debug('tacs.web.administration.layout');

export const layout: LayoutData = {
	permissions: [Permission.ManageOwnOrganization, Permission.ManageAgents],
	primaryMenu: [],
	secondaryMenu: [
		{
			text: 'Organizations',
			visible: {...needsPermission([Permission.Sysadmin]), ...needsRoute('admin')},
			stateSelectorHook: useLayoutState,
			to: routes.to.organizations,
		},
		{
			text: 'Manage Organization',
			visible: {...needsPermission([Permission.ManageOwnOrganization]), ...needsRoute('management')},
			stateSelectorHook: useUserState,
			to: data =>
				data.state?.organizationId && data.state?.officeId ? routes.to.organizationOverview({organizationId: data.state.organizationId}) : '',
		},
		{
			text: 'Manage Office',
			visible: {...needsPermission([Permission.ManageAgents]), ...needsRoute('management')},
			stateSelectorHook: useUserState,
			to: data =>
				data.state?.organizationId && data.state?.officeId
					? routes.to.officeOverview({organizationId: data.state.organizationId, officeId: data.state?.officeId})
					: '',
		},
	],
};
