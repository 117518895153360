import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './changeVacationPayRateByAgentId.graphql';

const defaultOptions = {} as const;
export type ChangeVacationPayRateByAgentIdMutationVariables = Types.Exact<{
	agentId: Types.Scalars['String'];
	vacationPayRate: Types.Scalars['Int'];
	startingDate: Types.Scalars['LocalDate'];
}>;

export type ChangeVacationPayRateByAgentIdMutation = {changeVacationPayRateByAgentId: number};

export type ChangeVacationPayRateByAgentIdType = number;

export type ChangeVacationPayRateByAgentIdMutationFn = Apollo.MutationFunction<
	ChangeVacationPayRateByAgentIdMutation,
	ChangeVacationPayRateByAgentIdMutationVariables
>;

/**
 * __useChangeVacationPayRateByAgentIdMutation__
 *
 * To run a mutation, you first call `useChangeVacationPayRateByAgentIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeVacationPayRateByAgentIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeVacationPayRateByAgentIdMutation, { data, loading, error }] = useChangeVacationPayRateByAgentIdMutation({
 *   variables: {
 *      agentId: // value for 'agentId'
 *      vacationPayRate: // value for 'vacationPayRate'
 *      startingDate: // value for 'startingDate'
 *   },
 * });
 */
export function useChangeVacationPayRateByAgentIdMutation(
	baseOptions?: Apollo.MutationHookOptions<ChangeVacationPayRateByAgentIdMutation, ChangeVacationPayRateByAgentIdMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<ChangeVacationPayRateByAgentIdMutation, ChangeVacationPayRateByAgentIdMutationVariables>(Operations, options);
}
export type ChangeVacationPayRateByAgentIdMutationHookResult = ReturnType<typeof useChangeVacationPayRateByAgentIdMutation>;
export type ChangeVacationPayRateByAgentIdMutationResult = Apollo.MutationResult<ChangeVacationPayRateByAgentIdMutation>;
export type ChangeVacationPayRateByAgentIdMutationOptions = Apollo.BaseMutationOptions<
	ChangeVacationPayRateByAgentIdMutation,
	ChangeVacationPayRateByAgentIdMutationVariables
>;
