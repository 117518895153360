/* eslint-disable no-param-reassign */
import {createSliceHook} from '@imperium/state';
import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {DocumentStatusEnum} from '~core/graphql';

export const state = createSlice({
	name: 'documents',
	initialState: {
		filteredType: '',
		filteredVendor: '',
		filteredEmployee: '',
		filteredCorporateAsset: '',
		filteredStatus: [] as DocumentStatusEnum[],
	},
	reducers: {
		setFilteredType: (st, action: PayloadAction<string>) => {
			st.filteredType = action.payload;
		},
		setFilteredVendor: (st, action: PayloadAction<string>) => {
			st.filteredVendor = action.payload;
		},
		setFilteredEmployee: (st, action: PayloadAction<string>) => {
			st.filteredEmployee = action.payload;
		},
		setFilteredCorporateAsset: (st, action: PayloadAction<string>) => {
			st.filteredCorporateAsset = action.payload;
		},
		setFilteredStatus: (st, action: PayloadAction<DocumentStatusEnum[]>) => {
			st.filteredStatus = action.payload;
		},
	},
});

export const useDocumentState = createSliceHook(state);

export const {setFilteredCorporateAsset, setFilteredEmployee, setFilteredStatus, setFilteredType, setFilteredVendor} = state.actions;
