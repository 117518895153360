import debug from 'debug';
import {Link} from 'react-router-dom';
import {List, Placeholder} from 'semantic-ui-react';
import {getName} from '~lib/getName';
import {routes} from '../../../routes';
import {useGetDisplayPartnerListAccountQuery} from './getDisplayPartnerListAccount';

const d = debug('tacs.web.accounts.components.partners.DisplayPartnerList');

interface DisplayEmployeeListProps {
	accountInfoId: string;
}

export function DisplayPartnerList(props: DisplayEmployeeListProps) {
	const {accountInfoId} = props;
	const {data, loading, error} = useGetDisplayPartnerListAccountQuery({
		variables: {accountInfoId},
	});

	if (loading)
		return (
			<Placeholder>
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
			</Placeholder>
		);
	if (error) throw error;

	return (
		<List bulleted>
			{data?.getPartnersByAccountInfoId?.length ? (
				data?.getPartnersByAccountInfoId?.map(partner => {
					return (
						<List.Item key={partner?.id}>
							<Link to={routes.to.viewPartner({accountInfoId, partnerId: partner?.id || ''})}>
								{partner?.contact ? getName(partner.contact) : 'name not found'}
							</Link>
						</List.Item>
					);
				})
			) : (
				<List.Item>(No Partners added)</List.Item>
			)}
		</List>
	);
}
