import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getEditBusinessNumber.graphql';

const defaultOptions = {} as const;
export type GetEditBusinessNumberQueryVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
}>;

export type GetEditBusinessNumberQuery = {getBusinessNumber?: string | null};

export type GetEditBusinessNumberType = string;

/**
 * __useGetEditBusinessNumberQuery__
 *
 * To run a query within a React component, call `useGetEditBusinessNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditBusinessNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditBusinessNumberQuery({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *   },
 * });
 */
export function useGetEditBusinessNumberQuery(baseOptions: Apollo.QueryHookOptions<GetEditBusinessNumberQuery, GetEditBusinessNumberQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetEditBusinessNumberQuery, GetEditBusinessNumberQueryVariables>(Operations, options);
}
export function useGetEditBusinessNumberLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetEditBusinessNumberQuery, GetEditBusinessNumberQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetEditBusinessNumberQuery, GetEditBusinessNumberQueryVariables>(Operations, options);
}
export type GetEditBusinessNumberQueryHookResult = ReturnType<typeof useGetEditBusinessNumberQuery>;
export type GetEditBusinessNumberLazyQueryHookResult = ReturnType<typeof useGetEditBusinessNumberLazyQuery>;
export type GetEditBusinessNumberQueryResult = Apollo.QueryResult<GetEditBusinessNumberQuery, GetEditBusinessNumberQueryVariables>;
