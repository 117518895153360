import debug from 'debug';
import {useHistory, useParams} from 'react-router-dom';
import {Button} from 'semantic-ui-react';
import {useAsyncError} from '~lib/useAsyncError';
import {routes} from '../../../routes';
import {useGetRemovePartnerButtonPartnerQuery} from './getRemovePartnerButtonPartner';
import {useRemovePartnerMutation} from './removePartner';

const d = debug('tacs.web.accounts.components.partners.RemovePartnerButton');

export function RemovePartnerButton() {
	const throwError = useAsyncError();
	const {partnerId, accountInfoId} = useParams<typeof routes.types.editPartner>();
	const {push} = useHistory();
	const [removePartner] = useRemovePartnerMutation();
	const {data, loading, error} = useGetRemovePartnerButtonPartnerQuery({variables: {partnerId}});

	if (error) throw error;

	function handleRemoveClick() {
		if (data?.getPartnerById) {
			removePartner({
				variables: {id: data.getPartnerById.id, version: data.getPartnerById.version},
			})
				.then(() => {
					push(routes.to.partners({accountInfoId}));
				})
				.catch(throwError);
		}
	}

	return (
		<Button loading={loading} negative fluid onClick={handleRemoveClick}>
			Remove From Partner List
		</Button>
	);
}
