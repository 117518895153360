import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './moveFileInfoToAccountInfoId.graphql';

const defaultOptions = {} as const;
export type MoveFileInfoToAccountInfoIdMutationVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
	fileInfoId: Types.Scalars['String'];
}>;

export type MoveFileInfoToAccountInfoIdMutation = {moveFileInfoToAccountInfoId: {id: string; version?: number | null}};

export type MoveFileInfoToAccountInfoIdType = {id: string; version?: number | null};

export type MoveFileInfoToAccountInfoIdMutationFn = Apollo.MutationFunction<
	MoveFileInfoToAccountInfoIdMutation,
	MoveFileInfoToAccountInfoIdMutationVariables
>;

/**
 * __useMoveFileInfoToAccountInfoIdMutation__
 *
 * To run a mutation, you first call `useMoveFileInfoToAccountInfoIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveFileInfoToAccountInfoIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveFileInfoToAccountInfoIdMutation, { data, loading, error }] = useMoveFileInfoToAccountInfoIdMutation({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *      fileInfoId: // value for 'fileInfoId'
 *   },
 * });
 */
export function useMoveFileInfoToAccountInfoIdMutation(
	baseOptions?: Apollo.MutationHookOptions<MoveFileInfoToAccountInfoIdMutation, MoveFileInfoToAccountInfoIdMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<MoveFileInfoToAccountInfoIdMutation, MoveFileInfoToAccountInfoIdMutationVariables>(Operations, options);
}
export type MoveFileInfoToAccountInfoIdMutationHookResult = ReturnType<typeof useMoveFileInfoToAccountInfoIdMutation>;
export type MoveFileInfoToAccountInfoIdMutationResult = Apollo.MutationResult<MoveFileInfoToAccountInfoIdMutation>;
export type MoveFileInfoToAccountInfoIdMutationOptions = Apollo.BaseMutationOptions<
	MoveFileInfoToAccountInfoIdMutation,
	MoveFileInfoToAccountInfoIdMutationVariables
>;
