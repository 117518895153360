import debug from 'debug';
import {toast} from 'react-toastify';
import {Button, List} from 'semantic-ui-react';
import {ConfirmModal} from '~common/components';
import {useAsyncError} from '~lib/useAsyncError';
import {useDeleteSearchIndexMutation} from './deleteSearchIndex';
import {useRecreateSearchIndexMutation} from './recreateSearchIndex';

const d = debug('tacs.web.tools.components.IndexerSidebar');

export function IndexerSidebar() {
	const throwError = useAsyncError();
	const [recreateSearch] = useRecreateSearchIndexMutation();
	const [deleteIndex] = useDeleteSearchIndexMutation();

	function onConfirmRecreateIndexClicked() {
		recreateSearch()
			.then(() => {
				toast.success('Recreate index job successfully started!');
			})
			.catch(throwError);
	}

	function onConfirmDeleteIndexClicked() {
		deleteIndex()
			.then(() => {
				toast.success('Delete index job successfully started!');
			})
			.catch(throwError);
	}

	return (
		<List>
			<List.Item>
				<ConfirmModal
					onConfirm={onConfirmRecreateIndexClicked}
					headerText="Confirm Index"
					text="Are you sure you want to recreate the search index?"
					trigger={
						<Button color="orange" fluid>
							Recreate Index
						</Button>
					}
				/>
			</List.Item>
			<List.Item>
				<ConfirmModal
					onConfirm={onConfirmDeleteIndexClicked}
					headerText="Confirm Delete"
					text="Are you sure you want to delete the search index?"
					trigger={
						<Button color="red" fluid>
							Delete Index
						</Button>
					}
				/>
			</List.Item>
		</List>
	);
}
