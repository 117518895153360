import {useAuthenticatedState} from '@imperium/auth-client';
import debug from 'debug';
import {useState} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {toast} from 'react-toastify';
import {Button, Grid, GridColumn, GridRow, Icon, Modal, Placeholder} from 'semantic-ui-react';
import {useAsyncError} from '~lib/useAsyncError';
import {routes as registrationRoutes} from '../../../../registration/routes';
import {useGetContactByIdInviteQuery} from '../graphql/getContactByIdInvite';
import {useGetInvitationLinkQuery} from './getInvitiationLink';
import {useSendContactUserInvitationMutation} from './sendContactUserInvitation';

const d = debug('tacs.web.accounts.components.contacts.SendInvitationEmailButton');

type Props = {
	contactId: string;
};

export function SendInvitationEmailButton({contactId}: Props) {
	const [open, setOpen] = useState(false);
	const throwError = useAsyncError();
	const {id} = useAuthenticatedState();
	const [SendContactUserInvitation] = useSendContactUserInvitationMutation();
	const {data, error, loading} = useGetContactByIdInviteQuery({variables: {id: contactId}});
	const {
		data: invitationData,
		error: invitationError,
		loading: invitationLoading,
	} = useGetInvitationLinkQuery({
		variables: {contactId, registrationPageEndpoint: `${window.location.origin.toString()}${registrationRoutes.to.register()}`},
	});

	if (loading || invitationLoading)
		return (
			<Placeholder>
				<Placeholder.Line />
				<Placeholder.Line />
			</Placeholder>
		);
	if (error) throw error;
	if (invitationError) throw invitationError;
	if (!data) throw new Error("Data couldn't be loaded");
	if (!invitationData) throw new Error("Data couldn't be loaded");

	const sendInvitation = async () => {
		if (!id) throw new Error('You must be logged in to invite a user.');
		if (!data.getContactById?.id) throw new Error('No contact is associated with this employee.');

		SendContactUserInvitation({
			variables: {
				contactId: data.getContactById?.id,
				registrationPageEndpoint: `${window.location.origin.toString()}${registrationRoutes.to.register()}`,
			},
		})
			.then(res => {
				if (res.data?.sendContactInvitation) {
					toast.success(`Success! An invitation has been sent to ${data.getContactById?.primaryEmail}.`);
				} else {
					throwError(new Error('Contact could not be invited.'));
				}
			})
			.catch(throwError);
	};

	function copyToClipboard() {}

	return (
		<Modal
			open={open}
			size="small"
			dimmer
			trigger={
				<Button
					fluid
					color="green"
					onClick={() => setOpen(true)}
					disabled={!!data.getContactById?.authorizedUser || !data.getContactById?.primaryEmail}
				>
					Send Invitation Email
				</Button>
			}
		>
			<Modal.Content>
				<Grid columns="2">
					<GridRow>
						<GridColumn>
							<h3>
								<Icon color="green" name="user" />
								Invite user to MYMC
							</h3>
						</GridColumn>
					</GridRow>
					<GridRow>
						<GridColumn width={16}>
							You can invite a client to MYMC by automatically sending the invitation email, or manually copying it to your clipboard.
						</GridColumn>
					</GridRow>
					<GridRow>
						<GridColumn width={16} style={{wordWrap: 'break-word'}}>
							<code>{invitationData?.getRegistrationInviteLink}</code>
						</GridColumn>
					</GridRow>
					<GridRow>
						<GridColumn width={10}>
							<Button
								color="green"
								onClick={() => {
									setOpen(false);
									sendInvitation().catch(e => throwError(e));
								}}
							>
								<Icon name="mail" /> Send Invitation Email
							</Button>
							<CopyToClipboard
								text={invitationData.getRegistrationInviteLink}
								onCopy={() => {
									toast.success('Registration link copied to clipboard');
								}}
							>
								<Button
									color="blue"
									onClick={() => {
										setOpen(false);
										copyToClipboard();
									}}
								>
									<Icon name="clipboard" /> Copy URL to clipboard
								</Button>
							</CopyToClipboard>
						</GridColumn>
						<GridColumn textAlign="right" width={6}>
							<Button
								color="orange"
								floated="right"
								onClick={() => {
									setOpen(false);
								}}
							>
								<Icon name="dont" /> Cancel
							</Button>
						</GridColumn>
					</GridRow>
				</Grid>
			</Modal.Content>
		</Modal>
	);
}
