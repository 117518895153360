import {useTForm} from '@thx/controls';
import debug from 'debug';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button, Dropdown, Form, FormInput} from 'semantic-ui-react';
import {useAsyncError} from '~lib/useAsyncError';
import type {routes} from '../../../routes';
import {useEditAccountStatusMutation} from './editAccountStatus';
import {useGetEditAccountStatusLazyQuery} from './getEditAccountStatus';

const d = debug('tacs.web.accounts.components.accounts.EditAccountStatus');

export function EditAccountStatus() {
	const throwError = useAsyncError();
	const {accountInfoId} = useParams<typeof routes.types.account>();
	const [editMutation, {loading: mutationLoading}] = useEditAccountStatusMutation();
	const [getQuery, {data, loading, error, refetch}] = useGetEditAccountStatusLazyQuery({variables: {accountInfoId}});
	const [editClicked, setEditClicked] = useState(false);
	const {hasErrors, hasWarnings, handleSubmit, setFieldValue, resetForm, values} = useTForm({
		initialValues: {status: data?.getAccountInfoById?.archived === true ? 'archived' : 'active'},
		onSubmit: formValues => {
			let archived = false;
			if (formValues.status === 'archived') {
				archived = true;
			}
			editMutation({
				variables: {accountInfoId, archived},
			})
				.then(() => {
					refetch().catch(throwError);
					toast.success('Status Saved');
					setEditClicked(false);
				})
				.catch(throwError);
		},
		enableReinitialize: true,
	});

	if (editClicked) {
		if (!loading && !error && !data?.getAccountInfoById) getQuery().catch(throwError);
		return (
			<Form error={hasErrors} warning={hasWarnings} onSubmit={handleSubmit}>
				<FormInput autoFocus loading={loading} label="Status">
					<Dropdown
						value={values.status}
						fluid
						selection
						error={!!error}
						loading={loading}
						options={[
							{key: 'active', value: 'active', text: 'Active'},
							{key: 'archived', value: 'archived', text: 'Archived'},
						]}
						onChange={(event, props) => setFieldValue('status', props.value)}
					/>
				</FormInput>
				<Button disabled={mutationLoading} positive type="submit" floated="right">
					Save
				</Button>
				<Button
					type="button"
					onClick={() => {
						resetForm();
						setEditClicked(false);
					}}
				>
					Cancel
				</Button>
			</Form>
		);
	}

	return (
		<Button fluid color="orange" onClick={() => setEditClicked(true)}>
			Change Status
		</Button>
	);
}
