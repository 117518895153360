import type {FieldPolicy} from '@apollo/client';
import type {LocalDate, LocalTime} from '@js-joda/core';
import {toLocalDate, toLocalTime} from '@thx/date';
import {toMoney} from '@thx/money';
import debug from 'debug';
import type Money from 'js-money';
import type {MoneyObject} from 'js-money';

const d = debug('tacs.web.core.scalarPolicies');

export const localDateTypePolicy: FieldPolicy<LocalDate, number> = {
	merge(existing, incoming) {
		if (incoming === undefined || incoming === null) return incoming;
		return toLocalDate(incoming);
	},
};

export const moneyTypePolicy: FieldPolicy<Money, MoneyObject> = {
	merge(existing, incoming) {
		if (incoming === undefined || incoming === null) return incoming;
		return toMoney(incoming);
	},
};

export const localTimeTypePolicy: FieldPolicy<LocalTime, number> = {
	merge(existing, incoming) {
		if (incoming === undefined || incoming === null) return incoming;
		return toLocalTime(incoming);
	},
};

export const dateTimeTypePolicy: FieldPolicy<Date, number> = {
	merge(existing, incoming) {
		if (incoming === undefined || incoming === null) return incoming;
		return new Date(incoming);
	},
};
