import {TForm, TFormProps} from '@thx/controls';
import {debug} from 'debug';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {Button, Form, FormCheckbox, FormField, FormInput} from 'semantic-ui-react';
import {object, string} from 'yup';
import type {AddDocumentSortingClassInput} from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import {DocumentTypeDropdown} from '../../../documents/components/documents/DocumentTypeDropdown';
import {routes} from '../../routes';
import {VendorDropdownGlobal} from '../VendorDropdownGlobal';
import {useAddDocumentSortingClassMutation} from './createDocumentSortingClassForm';

const d = debug('tacs.web.tools.components.DocumentSortingClassAddForm');

const documentTypeFormValidation = object().shape({
	className: string().required('Name is required.'),
	type: string().required('Type is required'),
	vendor: string().required('Vendor is required'),
});

export function DocumentSortingClassAddForm() {
	const throwError = useAsyncError();
	const {push} = useHistory();
	const [addDocumentSortingClass, {loading: mutationLoading}] = useAddDocumentSortingClassMutation();

	function handleFormSubmit(documentTypeData: AddDocumentSortingClassInput) {
		const data = {...documentTypeData};
		addDocumentSortingClass({variables: {data}})
			.then(() => push(routes.to.documentSortingClasses()))
			.catch(throwError);
	}

	return (
		<TForm<AddDocumentSortingClassInput>
			initialValues={{
				className: '',
				type: '',
				vendor: '',
				active: true,
				amountRequired: false,
				partialSortingRequired: true,
			}}
			onSubmit={handleFormSubmit}
			validationSchema={documentTypeFormValidation}
		>
			{(tFormProps: TFormProps<AddDocumentSortingClassInput>) => {
				const {handleSubmit, values, hasErrors, hasWarnings, renderWarnings, handleChange, fieldError, setFieldValue} = tFormProps;
				return (
					<Form onSubmit={handleSubmit} error={hasErrors} warning={hasWarnings}>
						<FormInput
							autoFocus
							onChange={handleChange}
							name="className"
							label="Class Name"
							error={fieldError('className')}
							value={values?.className || ''}
						/>
						<FormField required>
							<label>Type</label>
							<DocumentTypeDropdown
								selection
								onChange={val => {
									setFieldValue('type', val);
								}}
								value={values?.type}
							/>
						</FormField>
						<FormField required>
							<label>Vendor</label>
							<VendorDropdownGlobal addNewVendorButton onChange={val => setFieldValue('vendor', val)} value={values?.vendor} />
						</FormField>
						<FormInput label="Active" name="active">
							<FormCheckbox
								toggle
								onChange={(e, val) => setFieldValue('active', val.checked)}
								name="active"
								error={fieldError('active')}
								checked={values.active}
							/>
						</FormInput>
						<FormInput label="Require Partial Sorting" name="partialSortingRequired">
							<FormCheckbox
								toggle
								onChange={(e, val) => setFieldValue('partialSortingRequired', val.checked)}
								name="partialSortingRequired"
								error={fieldError('partialSortingRequired')}
								checked={values.partialSortingRequired}
							/>
						</FormInput>
						<FormInput label="Amount Required" name="amountRequired">
							<FormCheckbox
								toggle
								onChange={(e, val) => setFieldValue('amountRequired', val.checked)}
								name="amountRequired"
								error={fieldError('amountRequired')}
								checked={values.amountRequired}
							/>
						</FormInput>
						<Button type="submit" floated="right" positive disabled={mutationLoading}>
							Save
						</Button>
						<Button type="button" floated="right" onClick={() => push(routes.to.documentSortingClasses())} disabled={mutationLoading}>
							Cancel
						</Button>
					</Form>
				);
			}}
		</TForm>
	);
}
