import type {LayoutData} from '@imperium/layout';
import {debug} from 'debug';
import {Permission} from '~core/graphql';
import {needsPermission, needsRoute} from '~lib/permissionHelpers';
import {routes} from './routes';

const d = debug('tacs.web.support.layout');

export const layout: LayoutData = {
	permissions: [Permission.Sysadmin, Permission.ReadAllAccount],
	secondaryMenu: [
		{
			visible: {...needsPermission(Permission.Sysadmin), ...needsRoute('admin')},
			text: 'Ticket Management',
			to: routes.to.ticketManagement(),
		},
		{
			visible: {...needsPermission(Permission.Sysadmin), ...needsRoute('admin')},
			text: 'Ticket Category Management',
			to: routes.to.ticketCategoryManagement(),
		},
	],
};
