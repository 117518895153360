import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getInvitiationLink.graphql';

const defaultOptions = {} as const;
export type GetInvitationLinkQueryVariables = Types.Exact<{
	registrationPageEndpoint: Types.Scalars['String'];
	contactId: Types.Scalars['String'];
}>;

export type GetInvitationLinkQuery = {getRegistrationInviteLink: string};

export type GetInvitationLinkType = string;

/**
 * __useGetInvitationLinkQuery__
 *
 * To run a query within a React component, call `useGetInvitationLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvitationLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvitationLinkQuery({
 *   variables: {
 *      registrationPageEndpoint: // value for 'registrationPageEndpoint'
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useGetInvitationLinkQuery(baseOptions: Apollo.QueryHookOptions<GetInvitationLinkQuery, GetInvitationLinkQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetInvitationLinkQuery, GetInvitationLinkQueryVariables>(Operations, options);
}
export function useGetInvitationLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvitationLinkQuery, GetInvitationLinkQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetInvitationLinkQuery, GetInvitationLinkQueryVariables>(Operations, options);
}
export type GetInvitationLinkQueryHookResult = ReturnType<typeof useGetInvitationLinkQuery>;
export type GetInvitationLinkLazyQueryHookResult = ReturnType<typeof useGetInvitationLinkLazyQuery>;
export type GetInvitationLinkQueryResult = Apollo.QueryResult<GetInvitationLinkQuery, GetInvitationLinkQueryVariables>;
