import type {LocalDate} from '@js-joda/core';
import {DropdownCell, HoverCell, LocalDateEditCell, MoneyEditCell, StringEditCell, CheckboxEditCell} from '@thx/controls';
import {debug} from 'debug';
import type Money from 'js-money';
import {PropsWithChildren, useMemo} from 'react';
import type {CellProps, Column} from 'react-table';
import {Button} from 'semantic-ui-react';
import {RightHeader} from '~common/components/RightHeader';
import type {StatementStatusEnum} from '~core/graphql';
import {DocumentSelectorCell} from './DocumentSelectorCell';

const d = debug('tacs.web.documents.components.statement.useStatementColumns');

const statusOptions = [
	{key: 'DocumentRequired', value: 'DocumentRequired', text: 'Document Required'},
	{key: 'NotApplicable', value: 'NotApplicable', text: 'Not Applicable'},
	{key: 'NotProvided', value: 'NotProvided', text: "Won't be provided"},
	{key: 'Pending', value: 'Pending', text: 'Pending'},
];

export interface StatementLine extends Record<string, unknown> {
	id?: string;
	description: string;
	date: LocalDate | null;
	debit?: Money | null;
	credit?: Money | null;
	status?: StatementStatusEnum | null;
	document?: any | null;
	reconciled: boolean;
}

function TrashIcon({onClick}: {onClick: () => void}) {
	return <Button title="Delete Row" size="mini" icon="trash alternate" color="red" onClick={onClick} type="button" />;
}

export interface UseStatementColumnsProps {
	onRowDelete: (a: PropsWithChildren<CellProps<StatementLine, any>>) => void;
}

export const useStatementColumns = ({onRowDelete}: UseStatementColumnsProps): Column<StatementLine>[] => {
	return useMemo(
		() => [
			{
				Header: 'Description',
				accessor: 'description',
				Cell: StringEditCell(),
			},
			{
				Header: 'Date',
				accessor: 'date',
				Cell: LocalDateEditCell(),
				width: 1,
			},

			{
				Header: 'Debit',
				accessor: 'debit',
				Cell: MoneyEditCell(),
			},

			{
				Header: 'Credit',
				accessor: 'credit',
				Cell: MoneyEditCell(),
			},
			{
				Header: 'Document Status',
				accessor: 'status',
				Cell: DropdownCell({
					options: statusOptions,
					basic: true,
					search: true,
					fluid: true,
				}),
			},
			{
				Header: 'Document',
				accessor: 'document',
				Cell: DocumentSelectorCell(),
			},
			{
				Header: 'Reconciled',
				accessor: 'reconciled',
				Cell: CheckboxEditCell(),
			},
			{
				Header: <RightHeader title="Action" />,
				accessor: 'action',
				Cell: HoverCell({
					Action: props => {
						return (
							<TrashIcon
								onClick={() => {
									onRowDelete(props);
								}}
							/>
						);
					},
				}),
			},
		],
		[onRowDelete],
	);
};
