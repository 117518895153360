import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './deleteSearchIndex.graphql';

const defaultOptions = {} as const;
export type DeleteSearchIndexMutationVariables = Types.Exact<{[key: string]: never}>;

export type DeleteSearchIndexMutation = {deleteIndex?: boolean | null};

export type DeleteSearchIndexType = boolean;

export type DeleteSearchIndexMutationFn = Apollo.MutationFunction<DeleteSearchIndexMutation, DeleteSearchIndexMutationVariables>;

/**
 * __useDeleteSearchIndexMutation__
 *
 * To run a mutation, you first call `useDeleteSearchIndexMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSearchIndexMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSearchIndexMutation, { data, loading, error }] = useDeleteSearchIndexMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteSearchIndexMutation(
	baseOptions?: Apollo.MutationHookOptions<DeleteSearchIndexMutation, DeleteSearchIndexMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<DeleteSearchIndexMutation, DeleteSearchIndexMutationVariables>(Operations, options);
}
export type DeleteSearchIndexMutationHookResult = ReturnType<typeof useDeleteSearchIndexMutation>;
export type DeleteSearchIndexMutationResult = Apollo.MutationResult<DeleteSearchIndexMutation>;
export type DeleteSearchIndexMutationOptions = Apollo.BaseMutationOptions<DeleteSearchIndexMutation, DeleteSearchIndexMutationVariables>;
