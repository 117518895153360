import {JournalEntryTypeEnum} from '~core/graphql';
import {routes as documentRoutes} from '../routes';

interface JournalEntryType {
	id: string;
	type: JournalEntryTypeEnum;
	originalAccountingFormId: string;
}

export function generateJournalEntryRoute(je: JournalEntryType, accountInfoId: string) {
	const createRoute = (type: JournalEntryTypeEnum, id: string) => {
		if (type === JournalEntryTypeEnum.General) return documentRoutes.to.editGeneralJournalEntry({accountInfoId, journalEntryId: id});
		if (type === JournalEntryTypeEnum.Payable) return documentRoutes.to.editPayable({accountInfoId, payableId: id});
		if (type === JournalEntryTypeEnum.Receivable) return documentRoutes.to.editReceivable({accountInfoId, receivableId: id});
		if (type === JournalEntryTypeEnum.Payment) return documentRoutes.to.editPayment({accountInfoId, paymentId: id});
		if (type === JournalEntryTypeEnum.Receipt) return documentRoutes.to.editReceipt({accountInfoId, receiptId: id});
		return undefined;
	};

	if (je.type === JournalEntryTypeEnum.General) return createRoute(je.type, je.id);
	if (je.originalAccountingFormId) return createRoute(je.type, je.originalAccountingFormId);
	return undefined;
}
