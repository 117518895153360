import {createSliceHook} from '@imperium/state';
/* eslint-disable no-param-reassign */
import {LocalDate} from '@js-joda/core';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AccountTypeEnum, EmployeeStatusFilterEnum, LeadStatusEnum} from '~core/graphql';

export interface SetAccountPayload<DateType extends number | LocalDate> {
	accountInfoId: string;
	friendlyId: number;
	name: string;
	type: AccountTypeEnum;
	yearEnd: DateType;
	incorporationDate: DateType | null;
}

export const state = createSlice({
	name: 'account',
	initialState: {
		accountInfoId: null as string | null,
		friendlyId: null as number | null,
		name: null as string | null,
		type: null as AccountTypeEnum | null,
		yearEnd: null as number | null,
		incorporationDate: null as number | null,
		accountEventFeatureFilter: null,
		accountEventOffsetFilter: 0,
		employeeStatusFilter: EmployeeStatusFilterEnum.Employed,
		leadStatusFilter: LeadStatusEnum.FollowUp,
		unmanagedAccountsFilter: AccountTypeEnum.CorporateAccount,
		archivedCorporateAssets: false,
		archivedPersonalVehicles: false,
		archivedFinancialAccount: false,
	},
	reducers: {
		setAccount: {
			reducer: (st, {payload}: PayloadAction<SetAccountPayload<number> | null>) => {
				if (payload) {
					st.accountInfoId = payload.accountInfoId;
					st.type = payload.type;
					st.friendlyId = payload.friendlyId;
					st.name = payload.name;
					st.yearEnd = payload.yearEnd;
					st.incorporationDate = payload.incorporationDate;
				} else {
					st.accountInfoId = null;
					st.type = null;
					st.friendlyId = null;
					st.name = null;
					st.yearEnd = null;
					st.incorporationDate = null;
				}
			},
			prepare: (payload: SetAccountPayload<LocalDate> | null) => {
				if (payload) {
					return {
						payload: {
							...payload,
							yearEnd: payload.yearEnd?.toEpochDay(),
							incorporationDate: payload.incorporationDate?.toEpochDay() || null,
						},
					};
				}
				return {payload: null};
			},
		},
		setEmployeeStatusFilter: (st, action: PayloadAction<EmployeeStatusFilterEnum>) => {
			st.employeeStatusFilter = action.payload;
		},
		setAccountEventFeatureFilter: (st, action) => {
			st.accountEventFeatureFilter = action.payload;
		},
		setAccountEventOffsetFilter: (st, action: {payload: number}) => {
			st.accountEventOffsetFilter = action.payload;
		},
		setLeadStatusFilter: (st, action: PayloadAction<LeadStatusEnum>) => {
			st.leadStatusFilter = action.payload;
		},
		setUnmanagedAccountsFilter: (st, action: PayloadAction<AccountTypeEnum>) => {
			st.unmanagedAccountsFilter = action.payload;
		},
		setArchivedCorporateAsset: (st, action: PayloadAction<boolean>) => {
			st.archivedCorporateAssets = action.payload;
		},
		setArchivedPersonalVehicle: (st, action: PayloadAction<boolean>) => {
			st.archivedPersonalVehicles = action.payload;
		},
		setArchivedFinancialAccount: (st, action: PayloadAction<boolean>) => {
			st.archivedFinancialAccount = action.payload;
		},
	},
});

export const useAccountState = createSliceHook(state, {
	yearEnd: n => (n ? LocalDate.ofEpochDay(n) : undefined),
	incorporationDate: n => (n ? LocalDate.ofEpochDay(n) : undefined),
});

export const {
	setAccount,
	setEmployeeStatusFilter,
	setLeadStatusFilter,
	setUnmanagedAccountsFilter,
	setAccountEventFeatureFilter,
	setArchivedPersonalVehicle,
	setArchivedCorporateAsset,
	setAccountEventOffsetFilter,
	setArchivedFinancialAccount,
} = state.actions;
