import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getAccountOrganizationDropdown.graphql';

const defaultOptions = {} as const;
export type GetAccountOrganizationDropdownQueryVariables = Types.Exact<{[key: string]: never}>;

export type GetAccountOrganizationDropdownQuery = {getOrganizations?: Array<{id: string; version: number; name: string}> | null};

export type GetAccountOrganizationDropdownType = {id: string; version: number; name: string};

/**
 * __useGetAccountOrganizationDropdownQuery__
 *
 * To run a query within a React component, call `useGetAccountOrganizationDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountOrganizationDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountOrganizationDropdownQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountOrganizationDropdownQuery(
	baseOptions?: Apollo.QueryHookOptions<GetAccountOrganizationDropdownQuery, GetAccountOrganizationDropdownQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetAccountOrganizationDropdownQuery, GetAccountOrganizationDropdownQueryVariables>(Operations, options);
}
export function useGetAccountOrganizationDropdownLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetAccountOrganizationDropdownQuery, GetAccountOrganizationDropdownQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetAccountOrganizationDropdownQuery, GetAccountOrganizationDropdownQueryVariables>(Operations, options);
}
export type GetAccountOrganizationDropdownQueryHookResult = ReturnType<typeof useGetAccountOrganizationDropdownQuery>;
export type GetAccountOrganizationDropdownLazyQueryHookResult = ReturnType<typeof useGetAccountOrganizationDropdownLazyQuery>;
export type GetAccountOrganizationDropdownQueryResult = Apollo.QueryResult<
	GetAccountOrganizationDropdownQuery,
	GetAccountOrganizationDropdownQueryVariables
>;
