/* eslint-disable no-param-reassign */
import {createSliceHook} from '@imperium/state';
import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';

export const state = createSlice({
	name: 'calendar',
	initialState: {
		employeeId: null as string | null,
	},
	reducers: {
		setEmployeeId: (st, action: PayloadAction<string>) => {
			st.employeeId = action.payload;
		},
	},
});

export const useCalendarState = createSliceHook(state);

export const {setEmployeeId} = state.actions;
