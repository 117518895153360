import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './editAccountOrganizationAndOffice.graphql';

const defaultOptions = {} as const;
export type EditAccountOrganizationAndOfficeMutationVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
	organizationId: Types.Scalars['String'];
	officeId: Types.Scalars['String'];
}>;

export type EditAccountOrganizationAndOfficeMutation = {
	editAccountInfoOrganizationAndOffice?: {id: string; version: number; organization?: {id: string; version: number; name: string} | null} | null;
};

export type EditAccountOrganizationAndOfficeType = {id: string; version: number; organization?: {id: string; version: number; name: string} | null};

export type EditAccountOrganizationAndOfficeMutationFn = Apollo.MutationFunction<
	EditAccountOrganizationAndOfficeMutation,
	EditAccountOrganizationAndOfficeMutationVariables
>;

/**
 * __useEditAccountOrganizationAndOfficeMutation__
 *
 * To run a mutation, you first call `useEditAccountOrganizationAndOfficeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAccountOrganizationAndOfficeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAccountOrganizationAndOfficeMutation, { data, loading, error }] = useEditAccountOrganizationAndOfficeMutation({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *      organizationId: // value for 'organizationId'
 *      officeId: // value for 'officeId'
 *   },
 * });
 */
export function useEditAccountOrganizationAndOfficeMutation(
	baseOptions?: Apollo.MutationHookOptions<EditAccountOrganizationAndOfficeMutation, EditAccountOrganizationAndOfficeMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<EditAccountOrganizationAndOfficeMutation, EditAccountOrganizationAndOfficeMutationVariables>(Operations, options);
}
export type EditAccountOrganizationAndOfficeMutationHookResult = ReturnType<typeof useEditAccountOrganizationAndOfficeMutation>;
export type EditAccountOrganizationAndOfficeMutationResult = Apollo.MutationResult<EditAccountOrganizationAndOfficeMutation>;
export type EditAccountOrganizationAndOfficeMutationOptions = Apollo.BaseMutationOptions<
	EditAccountOrganizationAndOfficeMutation,
	EditAccountOrganizationAndOfficeMutationVariables
>;
