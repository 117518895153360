import * as Apollo from '@apollo/client';

import type {LocalDate} from '@js-joda/core';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getEditYearEnd.graphql';

const defaultOptions = {} as const;
export type GetEditYearEndQueryVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
}>;

export type GetEditYearEndQuery = {getYearEndByAccountInfoId?: LocalDate | null};

export type GetEditYearEndType = LocalDate;

/**
 * __useGetEditYearEndQuery__
 *
 * To run a query within a React component, call `useGetEditYearEndQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditYearEndQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditYearEndQuery({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *   },
 * });
 */
export function useGetEditYearEndQuery(baseOptions: Apollo.QueryHookOptions<GetEditYearEndQuery, GetEditYearEndQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetEditYearEndQuery, GetEditYearEndQueryVariables>(Operations, options);
}
export function useGetEditYearEndLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEditYearEndQuery, GetEditYearEndQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetEditYearEndQuery, GetEditYearEndQueryVariables>(Operations, options);
}
export type GetEditYearEndQueryHookResult = ReturnType<typeof useGetEditYearEndQuery>;
export type GetEditYearEndLazyQueryHookResult = ReturnType<typeof useGetEditYearEndLazyQuery>;
export type GetEditYearEndQueryResult = Apollo.QueryResult<GetEditYearEndQuery, GetEditYearEndQueryVariables>;
