import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getEditAccountOffice.graphql';

const defaultOptions = {} as const;
export type GetEditAccountOfficeQueryVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
}>;

export type GetEditAccountOfficeQuery = {
	getAccountInfoById?: {id: string; version: number; organization?: {id: string} | null; office?: {id: string} | null} | null;
};

export type GetEditAccountOfficeType = {id: string; version: number; organization?: {id: string} | null; office?: {id: string} | null};

/**
 * __useGetEditAccountOfficeQuery__
 *
 * To run a query within a React component, call `useGetEditAccountOfficeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditAccountOfficeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditAccountOfficeQuery({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *   },
 * });
 */
export function useGetEditAccountOfficeQuery(baseOptions: Apollo.QueryHookOptions<GetEditAccountOfficeQuery, GetEditAccountOfficeQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetEditAccountOfficeQuery, GetEditAccountOfficeQueryVariables>(Operations, options);
}
export function useGetEditAccountOfficeLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetEditAccountOfficeQuery, GetEditAccountOfficeQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetEditAccountOfficeQuery, GetEditAccountOfficeQueryVariables>(Operations, options);
}
export type GetEditAccountOfficeQueryHookResult = ReturnType<typeof useGetEditAccountOfficeQuery>;
export type GetEditAccountOfficeLazyQueryHookResult = ReturnType<typeof useGetEditAccountOfficeLazyQuery>;
export type GetEditAccountOfficeQueryResult = Apollo.QueryResult<GetEditAccountOfficeQuery, GetEditAccountOfficeQueryVariables>;
