import {MoneyInput} from '@thx/controls';
import {useDispatch} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {Button, Divider, Label, List, Input, Header} from 'semantic-ui-react';
import {useCommonState} from '~common/state';
import {useAccountState} from '../../../accounts/state';
import {VendorDropdown} from '../../../documents/components/documents/VendorDropdown';
import {routes} from '../../routes';
import {setPaymentsFilter, clearPaymentsFilter, useAccountingState} from '../../state';
import {PaymentGlAccountDropdown} from './PaymentGlAccountDropdown';

export function PaymentsTableSidebar() {
	const {accountInfoId} = useParams<typeof routes.types.payments>();
	const {fiscalOpenDate} = useAccountingState();
	const {yearEnd} = useAccountState();
	const {endDate} = useCommonState();

	const {paymentsFilter} = useAccountingState();
	const {invoiceNumber, paymentGlAccount, vendor, fromAmount, toAmount} = paymentsFilter;
	const {push} = useHistory();
	const dispatch = useDispatch();
	return (
		<List>
			{(!yearEnd || !fiscalOpenDate || (yearEnd && fiscalOpenDate && !endDate.isBefore(fiscalOpenDate))) && (
				<>
					<List.Item>
						<Button icon="add" content="Create Payment" positive fluid onClick={() => push(routes.to.createPayment({accountInfoId}))} />
					</List.Item>
					<Divider />
				</>
			)}
			<Header size="small">Filter Payments</Header>
			<List.Item>
				<Label>Receipt GL Account</Label>
				<PaymentGlAccountDropdown
					clearable
					fluid
					accountInfoId={accountInfoId}
					value={paymentGlAccount || ''}
					onChange={(e, {value}) => {
						dispatch(setPaymentsFilter({...paymentsFilter, paymentGlAccount: typeof value === 'string' ? value : undefined}));
					}}
				/>
			</List.Item>

			<List.Item>
				<Label>Vendor</Label>
				<VendorDropdown
					onChange={val => dispatch(setPaymentsFilter({vendor: val}))}
					fluid
					tabIndex={0}
					value={vendor || ''}
					clearable
					accountInfoId={accountInfoId}
				/>
			</List.Item>
			<List.Item>
				<Label>Invoice Number</Label>
				<Input
					value={invoiceNumber || ''}
					fluid
					onChange={e => {
						const val = e.target.value;
						dispatch(setPaymentsFilter({invoiceNumber: val}));
					}}
				/>
			</List.Item>
			<List.Item>
				<Label>From Total Amount</Label>
				<MoneyInput
					fluid
					placeholder="From Amount"
					value={fromAmount}
					onChange={value => value && dispatch(setPaymentsFilter({fromAmount: value}))}
				/>
			</List.Item>
			<List.Item>
				<Label>To Total Amount</Label>
				<MoneyInput fluid placeholder="To Amount" value={toAmount} onChange={value => value && dispatch(setPaymentsFilter({toAmount: value}))} />
			</List.Item>
			<List.Item>
				<Button content="Clear Filters" onClick={() => dispatch(clearPaymentsFilter())} />
			</List.Item>
		</List>
	);
}
