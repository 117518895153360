import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getLikeVendors.graphql';

const defaultOptions = {} as const;
export type GetLikeVendorsQueryVariables = Types.Exact<{
	name: Types.Scalars['String'];
	accountInfoId: Types.Scalars['String'];
}>;

export type GetLikeVendorsQuery = {getLikeVendors?: Array<{id: string; version: number; name: string}> | null};

export type GetLikeVendorsType = {id: string; version: number; name: string};

/**
 * __useGetLikeVendorsQuery__
 *
 * To run a query within a React component, call `useGetLikeVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLikeVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLikeVendorsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      accountInfoId: // value for 'accountInfoId'
 *   },
 * });
 */
export function useGetLikeVendorsQuery(baseOptions: Apollo.QueryHookOptions<GetLikeVendorsQuery, GetLikeVendorsQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetLikeVendorsQuery, GetLikeVendorsQueryVariables>(Operations, options);
}
export function useGetLikeVendorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLikeVendorsQuery, GetLikeVendorsQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetLikeVendorsQuery, GetLikeVendorsQueryVariables>(Operations, options);
}
export type GetLikeVendorsQueryHookResult = ReturnType<typeof useGetLikeVendorsQuery>;
export type GetLikeVendorsLazyQueryHookResult = ReturnType<typeof useGetLikeVendorsLazyQuery>;
export type GetLikeVendorsQueryResult = Apollo.QueryResult<GetLikeVendorsQuery, GetLikeVendorsQueryVariables>;
