import debug from 'debug';
import {Link} from 'react-router-dom';
import {List, Placeholder} from 'semantic-ui-react';
import {accountTypeDisplayName} from '~lib/accountTypeDisplayName';
import {routes as accountRoutes} from '../../../routes';
import {useGetDisplayRelatedAccountsListAccountsQuery} from './getDisplayRelatedAccountsListAccounts';

const d = debug('tacs.web.accounts.components.accounts.DisplayRelatedAccountsList');

interface DisplayRelatedAccountsListProps {
	accountInfoId: string;
}

export function DisplayRelatedAccountsList(props: DisplayRelatedAccountsListProps) {
	const {accountInfoId} = props;
	const {data, loading, error} = useGetDisplayRelatedAccountsListAccountsQuery({
		variables: {accountInfoId},
	});

	if (loading)
		return (
			<Placeholder>
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
			</Placeholder>
		);
	if (error) throw error;

	return (
		<List bulleted>
			{data?.getRelatedAccounts?.length ? (
				data?.getRelatedAccounts?.map(account => {
					return (
						<List.Item key={account.id}>
							<Link style={{color: account.archived ? 'red' : undefined}} to={accountRoutes.to.account({accountInfoId: account.id})}>
								{account.friendlyId.toString().concat(': ', account.name, ' (', accountTypeDisplayName(account.type), ')')}
							</Link>
						</List.Item>
					);
				})
			) : (
				<List.Item>(No Related accounts found)</List.Item>
			)}
		</List>
	);
}
