import {Header} from 'semantic-ui-react';
import {useGetAgentNameQuery} from './getAgentName';

interface Props {
	title: string;
	agentId: string;
}

export function AgentHeader({title, agentId}: Props) {
	const {data, error} = useGetAgentNameQuery({variables: {agentId}, fetchPolicy: 'cache-first'});
	if (error) throw error;

	return (
		<Header size="large">
			{title} - {data?.getAgentById?.name}
		</Header>
	);
}
