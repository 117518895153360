import debug from 'debug';
import {useHistory, useParams} from 'react-router-dom';
import {Button, List} from 'semantic-ui-react';
import {routes} from '../../routes';
import {RemovePartnerButton} from './RemovePartnerButton';

const d = debug('tacs.web.accounts.components.partners.PartnerViewSidebar');

export function PartnerViewSidebar() {
	const {partnerId, accountInfoId} = useParams<typeof routes.types.viewPartner>();
	const {push} = useHistory();

	return (
		<List>
			<List.Item>
				<Button fluid color="orange" onClick={() => push(routes.to.editPartner({accountInfoId, partnerId}))}>
					Edit Partner
				</Button>
			</List.Item>
			<List.Item>
				<RemovePartnerButton />
			</List.Item>
		</List>
	);
}
