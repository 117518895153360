import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './editAccountStatus.graphql';

const defaultOptions = {} as const;
export type EditAccountStatusMutationVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
	archived: Types.Scalars['Boolean'];
}>;

export type EditAccountStatusMutation = {editAccountInfoStatus?: {id: string; version: number; archived: boolean} | null};

export type EditAccountStatusType = {id: string; version: number; archived: boolean};

export type EditAccountStatusMutationFn = Apollo.MutationFunction<EditAccountStatusMutation, EditAccountStatusMutationVariables>;

/**
 * __useEditAccountStatusMutation__
 *
 * To run a mutation, you first call `useEditAccountStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAccountStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAccountStatusMutation, { data, loading, error }] = useEditAccountStatusMutation({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *      archived: // value for 'archived'
 *   },
 * });
 */
export function useEditAccountStatusMutation(
	baseOptions?: Apollo.MutationHookOptions<EditAccountStatusMutation, EditAccountStatusMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<EditAccountStatusMutation, EditAccountStatusMutationVariables>(Operations, options);
}
export type EditAccountStatusMutationHookResult = ReturnType<typeof useEditAccountStatusMutation>;
export type EditAccountStatusMutationResult = Apollo.MutationResult<EditAccountStatusMutation>;
export type EditAccountStatusMutationOptions = Apollo.BaseMutationOptions<EditAccountStatusMutation, EditAccountStatusMutationVariables>;
