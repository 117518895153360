import type {LayoutData} from '@imperium/layout';
import {debug} from 'debug';
import {Permission} from '~core/graphql';
import {isLoggedIn, needsPermission, needsRoute} from '~lib/permissionHelpers';
import {routes} from './routes';

const d = debug('tacs.web.rates.layout');

export const layout: LayoutData = {
	permissions: [Permission.Sysadmin, Permission.EditRates],
	primaryMenu: [
		{
			text: 'Rates',
			visible: {...isLoggedIn(), ...needsPermission(Permission.EditRates)},
			to: routes.to.taxRates(),
			weight: 5,
		},
	],
	secondaryMenu: [
		{
			text: 'Tax Rates',
			visible: {...needsPermission(Permission.EditRates), ...needsRoute('rates')},
			to: routes.to.taxRates(),
		},
		{
			text: 'Minimum Wages',
			visible: {...needsPermission(Permission.EditRates), ...needsRoute('rates')},
			to: routes.to.minimumWages(),
		},
		{
			text: 'Fuel Tax Rates',
			visible: {...needsPermission(Permission.EditRates), ...needsRoute('rates')},
			to: routes.to.fuelTaxRates(),
		},
		{
			text: 'EI',
			visible: {...needsPermission(Permission.EditRates), ...needsRoute('rates')},
			to: routes.to.ei(),
		},
		{
			text: 'PVL',
			visible: {...needsPermission(Permission.EditRates), ...needsRoute('rates')},
			to: routes.to.pvl(),
		},
		{
			text: 'Domestic Reduction Rate',
			visible: {...needsPermission(Permission.EditRates), ...needsRoute('rates')},
			to: routes.to.reductionRate(),
		},
		{
			text: 'Treasury Amount',
			visible: {...needsPermission(Permission.EditRates), ...needsRoute('rates')},
			to: routes.to.treasuryAmount(),
		},
		{
			text: 'Accommodation Amount',
			visible: {...needsPermission(Permission.EditRates), ...needsRoute('rates')},
			to: routes.to.accommodationAmount(),
		},
	],
};
