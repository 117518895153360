import {startCase} from 'lodash-es';
import {useState} from 'react';
import {Button, Dropdown, type DropdownItemProps, DropdownProps, Menu, Modal, ModalContent, ModalHeader, Popup} from 'semantic-ui-react';
import type {FinancialAccountTypeEnum} from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import {CreateFinancialAccountModal} from '../../../../assets/components/financialAccount/createFinancialAccount/CreateFinancialAccountModal';
import {type GetFinancialDropdownAccountsType, useGetFinancialDropdownAccountsQuery} from './getFinancialDropdownAccounts';

interface FinancialAccountsDropdownProps extends Omit<DropdownProps, 'onChange'> {
	passInGlAccIdAsValue?: boolean;
	onChange?: (val?: GetFinancialDropdownAccountsType) => void;
	accountInfoId: string;
	financialAccountsType: FinancialAccountTypeEnum;
	addNewFinancialAccountButton?: boolean;
}

export function FinancialAccountsDropdown(props: FinancialAccountsDropdownProps) {
	const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
	const [showCreateFinAccModal, setShowCreateFinAccModal] = useState(false);
	const {value, onChange, accountInfoId, financialAccountsType, fluid, addNewFinancialAccountButton, passInGlAccIdAsValue, rest} = props;
	const throwError = useAsyncError();
	const {data, loading, error, refetch} = useGetFinancialDropdownAccountsQuery({
		variables: {accountInfoId, filter: {type: financialAccountsType, archived: false}},
	});
	if (error) throwError(error);

	const options = data?.getFinancialAccountByAccountInfoId?.map(account => {
		return {value: account.id, text: account.accountNickname, description: account.glAccount?.code} as DropdownItemProps;
	});

	const initialValue = passInGlAccIdAsValue ? data?.getFinancialAccountByAccountInfoId?.find(acc => acc.glAccount?.id === value)?.id : value;

	function handleChange(e: React.SyntheticEvent<HTMLElement, Event>, val: DropdownProps) {
		if (val.value) {
			const financialAcc = data?.getFinancialAccountByAccountInfoId?.find(acc => acc.id === val.value);
			if (financialAcc) onChange && onChange(financialAcc);
		} else {
			onChange && onChange();
		}
	}

	return (
		<Menu style={{marginTop: 0}} fluid={fluid}>
			<Dropdown
				onSearchChange={(_e, dat) => {
					setSearchQuery(dat.searchQuery);
				}}
				{...rest}
				fluid={fluid}
				clearable
				search
				selection
				value={initialValue}
				loading={loading || props.loading}
				options={options || []}
				noResultsMessage={addNewFinancialAccountButton ? `Click ➕ to add this ${startCase(financialAccountsType)}` : 'No results found...'}
				onChange={handleChange}
			/>
			{addNewFinancialAccountButton && (
				<Popup
					content={`Add new ${startCase(financialAccountsType)}`}
					on="hover"
					trigger={
						<Button
							disabled={!searchQuery}
							onClick={() => setShowCreateFinAccModal(true)}
							type="button"
							tabIndex={-1}
							icon="plus"
							position="right"
							attached="right"
							basic
						/>
					}
				/>
			)}
			{searchQuery && (
				<Modal open={showCreateFinAccModal}>
					<ModalHeader>Create {startCase(financialAccountsType)}</ModalHeader>
					<ModalContent>
						<CreateFinancialAccountModal
							financialAccountName={searchQuery}
							financialAccountType={financialAccountsType}
							onCancel={() => setShowCreateFinAccModal(false)}
							onSave={val => {
								setShowCreateFinAccModal(false);
								refetch()
									.then(results => {
										const financialAcc = results.data?.getFinancialAccountByAccountInfoId?.find(acc => acc.id === val);
										if (financialAcc) onChange && onChange(financialAcc);
									})
									.catch(e => {
										throw e;
									});
							}}
						/>
					</ModalContent>
				</Modal>
			)}
		</Menu>
	);
}
