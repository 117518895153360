import {useCan} from '@imperium/auth-client';
import debug from 'debug';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button} from 'semantic-ui-react';
import {DeleteModal} from '~common/components';
import {Permission} from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import type {routes} from '../../../routes';
import {useDeleteAccountingDataCascadeMutation} from './deleteAccountingDataCascade';

const d = debug('tacs.web.accounts.components.accounts.ResetAccountingDataButton');

export function ResetAccountingDataButton() {
	const throwError = useAsyncError();
	const {accountInfoId} = useParams<typeof routes.types.account>();
	const [confirmMutation, {loading: mutationLoading}] = useDeleteAccountingDataCascadeMutation();
	const [sysadmin] = useCan(Permission.Sysadmin);

	if (!sysadmin) return null;

	function onConfirmClicked(checked?: boolean) {
		confirmMutation({
			variables: {accountInfoId, deleteHistoricEntries: !checked || false},
			refetchQueries: ['getFiscalDatePickerMinDate', 'getAccountState'],
		})
			.then(() => {
				toast.success('Accounting data was removed.');
			})
			.catch(throwError);
	}

	return (
		<DeleteModal
			onDelete={onConfirmClicked}
			headerText="Confirm Delete"
			text="This will reset all entered accounting data! Are you sure you want to proceed? This can not be undone."
			checkboxText="Preserve Historic Entries"
			checkboxDefault
			trigger={
				<Button disabled={mutationLoading} fluid negative>
					Reset Accounting Data
				</Button>
			}
		/>
	);
}
