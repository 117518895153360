/* eslint-disable no-param-reassign */
import {createSliceHook} from '@imperium/state';
import {LocalDate} from '@js-joda/core';
import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {formatDate, getFiscalYearRange, toLocalDate} from '@thx/date';
import debug from 'debug';
import type {DateRangeOptions} from '~common/hooks/dateRangeSelection/dateRangeSelectionSingleton';
import {DateRangeSelection} from '~common/types';
import {setAccount} from '../accounts/state';

const d = debug('tacs.web.common.state');

interface DatePayload<T extends number | LocalDate> {
	start: T;
	end: T;
}

interface DateRangeErrorPayload {
	error?: string;
}

function tweakDateBySelection(start: LocalDate, end: LocalDate, selection: DateRangeSelection) {
	const date = end.isAfter(LocalDate.now()) ? LocalDate.now() : end;
	switch (selection) {
		case DateRangeSelection.DateRange:
			return {start, end};
		case DateRangeSelection.FullMonth:
			return {start: date.withDayOfMonth(1), end: date.withDayOfMonth(date.lengthOfMonth())};
		case DateRangeSelection.SingleDate:
			return {start, end: start};
		case DateRangeSelection.FullYear:
			return {start: start.withDayOfYear(1), end: start.withDayOfYear(start.lengthOfYear())};
		case DateRangeSelection.None:
			return {start: start.withDayOfYear(1), end: start.withDayOfYear(start.lengthOfYear())};
		default:
			throw new Error('DateRangeSelection is outside allowed values.');
	}
}

function tweakDate(pStart: LocalDate, pEnd: LocalDate, fiscalYearEnd: LocalDate, {selection, allowFuture}: DateRangeOptions) {
	const {start, end} = tweakDateBySelection(pStart, pEnd, selection);
	const today = LocalDate.now();
	if (!allowFuture) {
		if (start.isAfter(today)) {
			const {start: fisStart, end: fisEnd} = getFiscalYearRange(today, fiscalYearEnd);
			return {start: fisStart, end: fisEnd};
		}
		if (end.isAfter(today)) {
			return {start, end: today};
		}
	}
	return {start, end};
}

export const state = createSlice({
	name: 'common',
	initialState: {
		fiscalYearEnd: LocalDate.now().withMonth(12).withDayOfMonth(31).toEpochDay(),
		startDate: LocalDate.now().withDayOfYear(1).toEpochDay(),
		endDate: LocalDate.now().withMonth(12).withDayOfMonth(31).toEpochDay(),
		dateRangeSelection: DateRangeSelection.DateRange,
		allowFuture: false,
		dateError: null as string | null,
		storedStartDate: LocalDate.now().withDayOfYear(1).toEpochDay(),
		storedEndDate: LocalDate.now().withMonth(12).withDayOfMonth(31).toEpochDay(),
	},
	reducers: {
		setDateError: (st, action: PayloadAction<DateRangeErrorPayload>) => {
			if (action.payload.error) {
				st.dateError = action.payload.error;
			} else {
				st.dateError = null;
			}
		},
		setDateRangeOption: (st, action: PayloadAction<DateRangeOptions>) => {
			const fromSelection = st.dateRangeSelection;
			const {allowFuture, selection: toSelection} = action.payload;
			const fiscalYearEnd = toLocalDate(st.fiscalYearEnd);

			if (fromSelection === toSelection) return;

			d(`From ${fromSelection} to ${toSelection}`);
			d(`Actual: ${formatDate(st.startDate)} - ${formatDate(st.endDate)}`);

			if (fromSelection === DateRangeSelection.DateRange) {
				d('Storing');
				st.storedStartDate = st.startDate;
				st.storedEndDate = st.endDate;
			}

			if (toSelection === DateRangeSelection.DateRange) {
				d(`Restoring Stored: ${formatDate(st.storedStartDate)} - ${formatDate(st.storedEndDate)}`);
				st.startDate = st.storedStartDate;
				st.endDate = st.storedEndDate;
				st.allowFuture = allowFuture || false;
				st.dateRangeSelection = DateRangeSelection.DateRange;
				return;
			}

			const {start: tweakedStart, end: tweakedEnd} = tweakDate(LocalDate.ofEpochDay(st.startDate), LocalDate.ofEpochDay(st.endDate), fiscalYearEnd, {
				selection: toSelection,
				allowFuture,
			});
			d(`Tweaked: ${formatDate(tweakedStart)} - ${formatDate(tweakedEnd)}`);

			st.startDate = tweakedStart.toEpochDay();
			st.endDate = tweakedEnd.toEpochDay();
			st.allowFuture = allowFuture || false;
			st.dateRangeSelection = toSelection;
		},
		setDate: {
			reducer: (st, action: PayloadAction<DatePayload<number>>) => {
				d(`From: ${formatDate(st.startDate)} to ${formatDate(st.endDate)}`);
				d(`To: ${formatDate(action.payload.start)} to ${formatDate(action.payload.end)}`);

				const {start, end} = tweakDate(
					LocalDate.ofEpochDay(action.payload.start),
					LocalDate.ofEpochDay(action.payload.end),
					toLocalDate(st.fiscalYearEnd),
					{
						selection: st.dateRangeSelection,
						allowFuture: st.allowFuture,
					},
				);
				d(`Tweaked: ${formatDate(start)} - ${formatDate(end)}`);

				st.startDate = start.toEpochDay();
				st.endDate = end.toEpochDay();

				if (st.dateRangeSelection === DateRangeSelection.DateRange) {
					d('Storing');
					st.storedStartDate = st.startDate;
					st.storedEndDate = st.endDate;
				}
			},
			prepare: (date: DatePayload<LocalDate>) => {
				return {payload: {start: date.start.toEpochDay(), end: date.end.toEpochDay()}};
			},
		},
	},
	extraReducers: builder => {
		builder.addCase(setAccount, (st, {payload}) => {
			if (payload) {
				d('Setting account');
				st.fiscalYearEnd = payload.yearEnd;
				st.dateError = null;
				const {start: fisStart, end: fisEnd} = getFiscalYearRange(toLocalDate(st.startDate), LocalDate.ofEpochDay(st.fiscalYearEnd));
				const {start, end} = tweakDate(fisStart, fisEnd, toLocalDate(st.fiscalYearEnd), {
					selection: st.dateRangeSelection,
					allowFuture: st.allowFuture,
				});
				st.startDate = start.toEpochDay();
				st.endDate = end.toEpochDay();
				st.storedStartDate = start.toEpochDay();
				st.endDate = end.toEpochDay();
			}
		});
	},
});

export const useCommonState = createSliceHook(state, {
	startDate: n => LocalDate.ofEpochDay(n),
	endDate: n => LocalDate.ofEpochDay(n),
	fiscalYearEnd: n => LocalDate.ofEpochDay(n),
	storedStartDate: n => LocalDate.ofEpochDay(n),
	storedEndDate: n => LocalDate.ofEpochDay(n),
	dateRangeSelection: n => n,
});

export const {setDateRangeOption, setDate, setDateError} = state.actions;
