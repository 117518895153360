import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './updateDocumentSortingClass.graphql';

const defaultOptions = {} as const;
export type UpdateDocumentSortingClassMutationVariables = Types.Exact<{
	data: Types.UpdateDocumentSortingClassInput;
}>;

export type UpdateDocumentSortingClassMutation = {
	updateDocumentSortingClass: {
		id: string;
		version: number;
		className: string;
		active: boolean;
		amountRequired: boolean;
		partialSortingRequired: boolean;
		count: number;
		type: {id: string};
		vendor: {id: string};
	};
};

export type UpdateDocumentSortingClassType = {
	id: string;
	version: number;
	className: string;
	active: boolean;
	amountRequired: boolean;
	partialSortingRequired: boolean;
	count: number;
	type: {id: string};
	vendor: {id: string};
};

export type UpdateDocumentSortingClassMutationFn = Apollo.MutationFunction<
	UpdateDocumentSortingClassMutation,
	UpdateDocumentSortingClassMutationVariables
>;

/**
 * __useUpdateDocumentSortingClassMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentSortingClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentSortingClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentSortingClassMutation, { data, loading, error }] = useUpdateDocumentSortingClassMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDocumentSortingClassMutation(
	baseOptions?: Apollo.MutationHookOptions<UpdateDocumentSortingClassMutation, UpdateDocumentSortingClassMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<UpdateDocumentSortingClassMutation, UpdateDocumentSortingClassMutationVariables>(Operations, options);
}
export type UpdateDocumentSortingClassMutationHookResult = ReturnType<typeof useUpdateDocumentSortingClassMutation>;
export type UpdateDocumentSortingClassMutationResult = Apollo.MutationResult<UpdateDocumentSortingClassMutation>;
export type UpdateDocumentSortingClassMutationOptions = Apollo.BaseMutationOptions<
	UpdateDocumentSortingClassMutation,
	UpdateDocumentSortingClassMutationVariables
>;
