import {useHistory, useParams} from 'react-router-dom';
import {Button, List} from 'semantic-ui-react';
import {routes} from '../../../../routes';

export function ImportSidebar() {
	const {accountInfoId} = useParams<typeof routes.types.import>();
	const {push} = useHistory();

	return (
		<List>
			<List.Item>
				<Button positive fluid onClick={() => push(routes.to.importUpload({accountInfoId}))}>
					Upload file
				</Button>
			</List.Item>
		</List>
	);
}
