import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './editStatementForm.graphql';

const defaultOptions = {} as const;
export type EditStatementFormMutationVariables = Types.Exact<{
	data: Types.EditStatementInput;
}>;

export type EditStatementFormMutation = {editStatement?: {id: string; version: number} | null};

export type EditStatementFormType = {id: string; version: number};

export type EditStatementFormMutationFn = Apollo.MutationFunction<EditStatementFormMutation, EditStatementFormMutationVariables>;

/**
 * __useEditStatementFormMutation__
 *
 * To run a mutation, you first call `useEditStatementFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditStatementFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editStatementFormMutation, { data, loading, error }] = useEditStatementFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditStatementFormMutation(
	baseOptions?: Apollo.MutationHookOptions<EditStatementFormMutation, EditStatementFormMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<EditStatementFormMutation, EditStatementFormMutationVariables>(Operations, options);
}
export type EditStatementFormMutationHookResult = ReturnType<typeof useEditStatementFormMutation>;
export type EditStatementFormMutationResult = Apollo.MutationResult<EditStatementFormMutation>;
export type EditStatementFormMutationOptions = Apollo.BaseMutationOptions<EditStatementFormMutation, EditStatementFormMutationVariables>;
