import {toast} from 'react-toastify';
import {Button, List} from 'semantic-ui-react';
import {ConfirmModal, Loading} from '~common/components';
import {useAsyncError} from '~lib/useAsyncError';
import {useGetCaftPendingItemsQuery} from '../CaftPendingItemsTable/getCaftPendingItems';
import {useProcessCaftBatchItemsMutation} from './processCaftBatchItems';

export function CaftPendingSidebar() {
	const throwError = useAsyncError();
	const {data, loading, error} = useGetCaftPendingItemsQuery();

	const [useProcessCaftBatchItems] = useProcessCaftBatchItemsMutation();

	function OnCaftPendingModalConfirmClicked() {
		useProcessCaftBatchItems()
			.then(fileData => {
				if (fileData.data) {
					const element = document.createElement('a');
					element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(fileData.data.processCaftPending)}`);
					element.setAttribute('download', 'caftBatchFile.txt');

					element.style.display = 'none';
					document.body.appendChild(element);

					element.click();

					document.body.removeChild(element);
					toast.success('Caft Process Started.');
				} else {
					toast.error('An empty file was returned.');
				}
			})
			.catch(throwError);
	}

	if (loading) return <Loading />;
	if (error) throw error;
	if (!data) throw new Error('The data could not be loaded.');

	return (
		<List>
			{(data.getCaftPendingItemsReport?.length || 0) > 0 && (
				<List.Item>
					<ConfirmModal
						onConfirm={OnCaftPendingModalConfirmClicked}
						headerText="Confirm Process"
						text="Are you sure you want to process the pending caft items?"
						trigger={
							<Button color="green" fluid>
								Process Pending Items
							</Button>
						}
					/>
				</List.Item>
			)}
		</List>
	);
}
