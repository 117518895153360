import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getEmployeeListEmployees.graphql';

const defaultOptions = {} as const;
export type GetEmployeeListEmployeesQueryVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
	filter?: Types.InputMaybe<Types.EmployeeFilterInput>;
}>;

export type GetEmployeeListEmployeesQuery = {
	getEmployeesByAccountInfoId?: Array<{
		id: string;
		version: number;
		contact: {id: string; version: number; givenName: string; surname: string; preferredName?: string | null};
	}> | null;
};

export type GetEmployeeListEmployeesType = {
	id: string;
	version: number;
	contact: {id: string; version: number; givenName: string; surname: string; preferredName?: string | null};
};

/**
 * __useGetEmployeeListEmployeesQuery__
 *
 * To run a query within a React component, call `useGetEmployeeListEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeListEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeListEmployeesQuery({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetEmployeeListEmployeesQuery(
	baseOptions: Apollo.QueryHookOptions<GetEmployeeListEmployeesQuery, GetEmployeeListEmployeesQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetEmployeeListEmployeesQuery, GetEmployeeListEmployeesQueryVariables>(Operations, options);
}
export function useGetEmployeeListEmployeesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeeListEmployeesQuery, GetEmployeeListEmployeesQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetEmployeeListEmployeesQuery, GetEmployeeListEmployeesQueryVariables>(Operations, options);
}
export type GetEmployeeListEmployeesQueryHookResult = ReturnType<typeof useGetEmployeeListEmployeesQuery>;
export type GetEmployeeListEmployeesLazyQueryHookResult = ReturnType<typeof useGetEmployeeListEmployeesLazyQuery>;
export type GetEmployeeListEmployeesQueryResult = Apollo.QueryResult<GetEmployeeListEmployeesQuery, GetEmployeeListEmployeesQueryVariables>;
