import {toast} from 'react-toastify';
import {Icon, Popup, SemanticCOLORS, SemanticICONS} from 'semantic-ui-react';

interface ColoredSpanProps {
	labelText?: string;
	contentText?: string | number | null | boolean;
	labelColor?: string;
	contentColor?: string;
	contentIcon?: SemanticICONS;
	contentIconColor?: SemanticCOLORS;
	copyTextOnClick?: boolean;
}

export function ColoredSpan({labelText, contentText, labelColor, contentColor, contentIcon, contentIconColor, copyTextOnClick}: ColoredSpanProps) {
	async function handleCopy() {
		if (contentText && copyTextOnClick) {
			await navigator.clipboard.writeText(contentText.toString()).then(() => {
				toast.info('Text copied to clipboard', {pauseOnFocusLoss: false});
			});
		}
	}

	return (
		<>
			<span style={{color: labelColor || 'black', fontWeight: 'bold'}}>{labelText}</span>
			{labelText ? ': ' : ''}
			<Popup
				disabled={!copyTextOnClick || !contentText}
				content="Copy to clipboard"
				trigger={
					<span
						role="button"
						tabIndex={0}
						onKeyDown={handleCopy}
						className={copyTextOnClick && contentText ? 'hoverable' : undefined}
						style={{
							cursor: copyTextOnClick && contentText ? 'copy' : undefined,
							padding: 3,
							color: contentColor,
						}}
						onClick={handleCopy}
					>
						{contentIcon && <Icon name={contentIcon as SemanticICONS} color={contentIconColor} />}
						{contentText}
					</span>
				}
			/>
		</>
	);
}
