import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './setActiveAgent.graphql';

const defaultOptions = {} as const;
export type SetActiveAgentMutationVariables = Types.Exact<{
	agentId: Types.Scalars['String'];
}>;

export type SetActiveAgentMutation = {setActiveAgent?: {id: string} | null};

export type SetActiveAgentType = {id: string};

export type SetActiveAgentMutationFn = Apollo.MutationFunction<SetActiveAgentMutation, SetActiveAgentMutationVariables>;

/**
 * __useSetActiveAgentMutation__
 *
 * To run a mutation, you first call `useSetActiveAgentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetActiveAgentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setActiveAgentMutation, { data, loading, error }] = useSetActiveAgentMutation({
 *   variables: {
 *      agentId: // value for 'agentId'
 *   },
 * });
 */
export function useSetActiveAgentMutation(baseOptions?: Apollo.MutationHookOptions<SetActiveAgentMutation, SetActiveAgentMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<SetActiveAgentMutation, SetActiveAgentMutationVariables>(Operations, options);
}
export type SetActiveAgentMutationHookResult = ReturnType<typeof useSetActiveAgentMutation>;
export type SetActiveAgentMutationResult = Apollo.MutationResult<SetActiveAgentMutation>;
export type SetActiveAgentMutationOptions = Apollo.BaseMutationOptions<SetActiveAgentMutation, SetActiveAgentMutationVariables>;
