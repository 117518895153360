import {MaskedInput} from '@thx/controls';
import debug from 'debug';
import type {FocusEvent} from 'react';
import {Dropdown, FormInput, Grid, GridColumn, GridRow, SemanticWIDTHSNUMBER} from 'semantic-ui-react';
import type {AddressAddSchemaType} from '~lib/addressSchema';
import {getProvinces} from '~lib/getProvinces';
import {getStates} from '~lib/getStates';

const d = debug('tacs.web.common.components.AddressInput');

interface AddressInputProps {
	name: string;
	value?: AddressAddSchemaType;
	onBlur: (e: FocusEvent<HTMLElement>) => void;
	setFieldValue: (name: string, value?: string) => void;
	fieldError: (name: string) => boolean;
	columnCount?: SemanticWIDTHSNUMBER;
	required?: boolean;
}

/**
 * AddressInput
 * @param props
 * @constructor
 */
export function AddressInput(props: AddressInputProps) {
	const {name, value, onBlur, required, setFieldValue, fieldError, columnCount = 2} = props;
	const isCanada = value?.country === 'ca' || value?.country === null || value?.country === undefined;

	return (
		<Grid columns={columnCount} stackable>
			<GridRow>
				<GridColumn>
					<FormInput
						required={required}
						name={`${name}.lineOne`}
						label="Address Line 1"
						error={fieldError(`${name}.lineOne`)}
						onChange={(e, v) => setFieldValue(`${name}.lineOne`, v.value)}
						value={value?.lineOne || ''}
					/>
				</GridColumn>
				<GridColumn>
					<FormInput
						name={`${name}.lineTwo`}
						label="Address Line 2"
						error={fieldError(`${name}.lineTwo`)}
						onChange={(e, v) => setFieldValue(`${name}.lineTwo`, v.value)}
						value={value?.lineTwo || ''}
					/>
				</GridColumn>
				<GridColumn>
					<FormInput
						required={required}
						name={`${name}.city`}
						label="City"
						error={fieldError(`${name}.city`)}
						onChange={(e, v) => setFieldValue(`${name}.city`, v.value)}
						value={value?.city || ''}
					/>
				</GridColumn>
				<GridColumn>
					<FormInput label={isCanada ? 'Province' : 'State'} required={required}>
						<Dropdown
							search
							fluid
							button
							clearable
							name={`${name}.province`}
							id={`${name}.province`}
							onBlur={onBlur}
							placeholder="select province"
							options={isCanada ? getProvinces() : getStates()}
							error={fieldError(`${name}.province`)}
							onChange={(c, b) => setFieldValue(`${name}.province`, b.value ? b.value.toString() : undefined)}
							value={value?.province || ''}
							autoComplete="off"
						/>
					</FormInput>
				</GridColumn>
				<GridColumn>
					<FormInput label="Country" required={required}>
						<Dropdown
							search
							fluid
							button
							clearable
							onBlur={onBlur}
							name={`${name}.country`}
							placeholder="select country"
							options={[
								{text: '', key: '', value: ''},
								{text: 'Canada', key: 'ca', value: 'ca', flag: 'ca'},
								{text: 'United States', key: 'us', value: 'us', flag: 'us'},
							]}
							error={fieldError(`${name}.country`)}
							onChange={(c, b) => setFieldValue(`${name}.country`, b.value ? b.value.toString() : undefined)}
							value={value?.country || ''}
							autoComplete="off"
						/>
					</FormInput>
				</GridColumn>
				<GridColumn>
					<FormInput label={isCanada ? 'Postal Code' : 'Zip Code'} error={fieldError(`${name}.postalCode`)} required={required}>
						<MaskedInput
							mask={{mask: isCanada ? 'A9A-9A9' : '99999', autoUnmask: true, showMaskOnHover: true}}
							onChange={(v: string) => setFieldValue(`${name}.postalCode`, v)}
							value={value?.postalCode || ''}
						/>
					</FormInput>
				</GridColumn>
			</GridRow>
		</Grid>
	);
}
