import debug from 'debug';
import {useDispatch} from 'react-redux';
import {Divider, Button, Dropdown, Form, List, FormInput} from 'semantic-ui-react';
import {CommunicationTypeEnum} from '~core/graphql';
import {setCommunicationFilter, setShowCommunicationForm, useCommunicationState} from '../state';

const d = debug('tacs.web.communications.components.CommunicationsSidebar');

export function CommunicationsSidebar() {
	const dispatch = useDispatch();
	const {communicationFilter, showCommunicationForm} = useCommunicationState();

	const communicationOptions = [
		{key: CommunicationTypeEnum.Email, value: CommunicationTypeEnum.Email, text: 'Emails'},
		{key: CommunicationTypeEnum.Call, value: CommunicationTypeEnum.Call, text: 'Calls'},
		{key: CommunicationTypeEnum.Note, value: CommunicationTypeEnum.Note, text: 'Notes'},
		{key: CommunicationTypeEnum.CorporateTaxes, value: CommunicationTypeEnum.CorporateTaxes, text: 'Corporate Taxes'},
	];

	return (
		<List>
			<List.Item>
				<Button fluid positive={!showCommunicationForm} onClick={() => dispatch(setShowCommunicationForm(!showCommunicationForm))}>
					{!showCommunicationForm ? 'Add Note' : 'Close'}
				</Button>
			</List.Item>
			<Divider />
			<List.Item>
				<Form>
					<FormInput label="Filter Communications">
						<Dropdown
							selection
							multiple
							options={communicationOptions}
							fluid
							onChange={(e, val) => {
								if (val.value) {
									dispatch(setCommunicationFilter(val.value as CommunicationTypeEnum[]));
								}
							}}
							value={communicationFilter}
						/>
					</FormInput>
				</Form>
			</List.Item>
		</List>
	);
}
