import {createPages} from '@imperium/layout';
import loadable from '@loadable/component';
import {routes} from './routes';

const CorporateTaxesEvents = loadable(() => import('./components/CorporateTaxesTable'));
const EditFiledT2Form = loadable(() => import('./components/EditFiledT2Form'));
const EditPaidCorporateTaxesForm = loadable(() => import('./components/EditPaidCorporateTaxesForm'));
const FileT2Form = loadable(() => import('./components/FileT2Form'));
const PayCorporateTaxesForm = loadable(() => import('./components/PayCorporateTaxesForm'));
const ManagementBonusForm = loadable(() => import('./components/ManagementBonusForm'));
const EditManagementBonusForm = loadable(() => import('./components/EditManagementBonusForm'));

export const routeProps = createPages(routes, {
	corporateTaxes: {
		header: 'Corporate Taxes',
		content: () => <CorporateTaxesEvents />,
		sidebar: [
			{
				to: dat => routes.to.setManagementBonus({accountInfoId: dat.params.accountInfoId}),
				color: 'olive',
				text: 'Set Management Bonus',
			},
			{
				to: dat => routes.to.fileT2({accountInfoId: dat.params.accountInfoId}),
				color: 'green',
				text: 'File T2',
			},
			{
				to: dat => routes.to.payCorporateTaxes({accountInfoId: dat.params.accountInfoId}),
				color: 'purple',
				text: 'Pay Corporate Taxes',
			},
		],
	},
	editFiledT2: {
		header: 'Edit Filed T2',
		content: () => <EditFiledT2Form />,
	},
	editPaidCorporateTaxes: {
		header: 'Edit Paid T2',
		content: () => <EditPaidCorporateTaxesForm />,
	},
	fileT2: {
		header: 'File T2',
		content: () => <FileT2Form />,
	},
	payCorporateTaxes: {
		header: 'Pay T2',
		content: () => <PayCorporateTaxesForm />,
	},
	setManagementBonus: {
		header: 'Set Management Bonus',
		content: () => <ManagementBonusForm />,
	},
	editManagementBonus: {
		header: 'Edit Management Bonus',
		content: () => <EditManagementBonusForm />,
	},
});
