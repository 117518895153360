import debug from 'debug';
import {useParams} from 'react-router-dom';
import {List} from 'semantic-ui-react';
import type {routes} from '../../../routes';
import {ContactList} from '../ContactList';
import {useGetEmployeeAddSidebarDataQuery} from './getEmployeeAddSidebarData';

const d = debug('tacs.web.accounts.components.employees.EmployeeAddSidebarExistingContactList');

export function EmployeeAddSidebarExistingContactList() {
	const {accountInfoId} = useParams<typeof routes.types.employees>();
	const {data, error} = useGetEmployeeAddSidebarDataQuery({variables: {accountInfoId}});

	if (error) throw error;

	return (
		<List>
			<List.Header as="h3">Choose From Existing Contacts</List.Header>
			{data?.getAuthorizedRepresentativesByAccountInfoId?.length
				? data.getAuthorizedRepresentativesByAccountInfoId.map(contact => {
						return <ContactList key={contact.id} contact={contact.contact} accountInfoId={accountInfoId} />;
				  })
				: 'none available...'}
		</List>
	);
}
