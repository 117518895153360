import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './sortFileInfo.graphql';

const defaultOptions = {} as const;
export type SortFileInfoMutationVariables = Types.Exact<{
	fileInfo: Types.Scalars['String'];
}>;

export type SortFileInfoMutation = {sortFileInfo?: {id: string} | null};

export type SortFileInfoType = {id: string};

export type SortFileInfoMutationFn = Apollo.MutationFunction<SortFileInfoMutation, SortFileInfoMutationVariables>;

/**
 * __useSortFileInfoMutation__
 *
 * To run a mutation, you first call `useSortFileInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSortFileInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sortFileInfoMutation, { data, loading, error }] = useSortFileInfoMutation({
 *   variables: {
 *      fileInfo: // value for 'fileInfo'
 *   },
 * });
 */
export function useSortFileInfoMutation(baseOptions?: Apollo.MutationHookOptions<SortFileInfoMutation, SortFileInfoMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<SortFileInfoMutation, SortFileInfoMutationVariables>(Operations, options);
}
export type SortFileInfoMutationHookResult = ReturnType<typeof useSortFileInfoMutation>;
export type SortFileInfoMutationResult = Apollo.MutationResult<SortFileInfoMutation>;
export type SortFileInfoMutationOptions = Apollo.BaseMutationOptions<SortFileInfoMutation, SortFileInfoMutationVariables>;
