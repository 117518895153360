import {formatMoney} from '@thx/money';
import debug from 'debug';
import {Label, Table, TableHeader, TableRow, TableHeaderCell, TableBody, TableCell} from 'semantic-ui-react';
import {useAccountingState} from '../../state';
import {useCalculatePayStatementLines} from './useCalculatePayStatementLines';

const d = debug('tacs.web.accounting.components.payStatement.PayStatementCreateSidebar');

export function PayStatementCreateSidebar() {
	const [calculateTotals] = useCalculatePayStatementLines();
	const {payStatementLines} = useAccountingState();
	const {totalDeposit, expenses, gstItc, liabilities, equity, gstCollected, revenue, asset} = calculateTotals(payStatementLines);

	return (
		<>
			<h3>Calculations:</h3>
			<Table compact="very" color="green">
				<TableHeader>
					<TableRow>
						<TableHeaderCell>Taxes</TableHeaderCell>
						<TableHeaderCell />
					</TableRow>
				</TableHeader>
				<TableBody>
					<TableRow positive>
						<TableCell>Revenue:</TableCell>
						<TableCell textAlign="right">{formatMoney(revenue, true)}</TableCell>
					</TableRow>
					<TableRow positive>
						<TableCell>GST Collected:</TableCell>
						<TableCell textAlign="right">{formatMoney(gstCollected, true)}</TableCell>
					</TableRow>
					<TableRow negative>
						<TableCell>Assets:</TableCell>
						<TableCell textAlign="right">{formatMoney(asset, true)}</TableCell>
					</TableRow>
					<TableRow negative>
						<TableCell>Liabilities:</TableCell>
						<TableCell textAlign="right">{formatMoney(liabilities, true)}</TableCell>
					</TableRow>
					<TableRow negative>
						<TableCell>Equity:</TableCell>
						<TableCell textAlign="right">{formatMoney(equity, true)}</TableCell>
					</TableRow>
					<TableRow negative>
						<TableCell>Expenses:</TableCell>
						<TableCell textAlign="right">{formatMoney(expenses, true)}</TableCell>
					</TableRow>
					<TableRow negative>
						<TableCell>GST ITC&apos;s:</TableCell>
						<TableCell textAlign="right">{formatMoney(gstItc, true)}</TableCell>
					</TableRow>
					<TableRow active>
						<TableCell>Total Deposit:</TableCell>
						<TableCell textAlign="right">{formatMoney(totalDeposit, true)}</TableCell>
					</TableRow>
				</TableBody>
			</Table>
			<Label color="orange">Note: All calculated values are in Canadian Dollars</Label>
		</>
	);
}
