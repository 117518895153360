import {useHistory, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button, List} from 'semantic-ui-react';
import {ConfirmModal, Loading, DeleteModal} from '~common/components';
import {useAsyncError} from '~lib/useAsyncError';
import {routes} from '../../../routes';
import {useGetContractByIdQuery} from '../ContractEditForm/getContractById';
import {useAuthorizeContractMutation} from './authorizeContract';
import {useDeleteContractMutation} from './deleteContract';
import {useEndContractMutation} from './endContract';

export function ContractEditSidebar() {
	const throwError = useAsyncError();
	const {push} = useHistory();
	const {contractId, accountInfoId} = useParams<typeof routes.types.contractEdit>();

	const {data, loading, error} = useGetContractByIdQuery({variables: {contractId}});
	const [deleteMutation, {loading: mutationLoading}] = useDeleteContractMutation();

	const [useAuthorize] = useAuthorizeContractMutation();
	const [useEnd] = useEndContractMutation();

	function OnAuthorizeContractModalConfirmClicked() {
		useAuthorize({variables: {contractId}})
			.then(() => {
				toast.success('Contract Authorized!');
				push(routes.to.contracts({accountInfoId}));
			})
			.catch(throwError);
	}

	function OnEndContractModalConfirmClicked() {
		useEnd({variables: {contractId}})
			.then(() => {
				toast.success('Contract Ended!');
				push(routes.to.contracts({accountInfoId}));
			})
			.catch(throwError);
	}

	function onDeleteClicked() {
		deleteMutation({
			variables: {contractId: data?.getContractById?.id || '', version: data?.getContractById?.version || 0},
		})
			.then(() => {
				toast.success('Document Contract was successfully deleted.');
				push(routes.to.contracts({accountInfoId}));
			})
			.catch(throwError);
	}

	if (loading) return <Loading />;
	if (error) throw error;
	if (!data?.getContractById) throw new Error('The data could not be loaded.');

	return (
		<List>
			{!data.getContractById.authorized && !data.getContractById.endDate && (
				<>
					<List.Item>
						<ConfirmModal
							onConfirm={OnAuthorizeContractModalConfirmClicked}
							headerText="Confirm Authorization"
							text="Are you sure you want to authorize this contract?"
							trigger={
								<Button color="green" fluid>
									Authorize Contract
								</Button>
							}
						/>
					</List.Item>
					<List.Item>
						<DeleteModal
							onDelete={onDeleteClicked}
							headerText="Confirm Delete"
							text="Are you sure you want to delete the contract template?"
							trigger={
								<Button disabled={mutationLoading} color="red" fluid>
									Delete Contract
								</Button>
							}
						/>
					</List.Item>
				</>
			)}
			{data.getContractById.authorized && !data.getContractById.endDate && (
				<List.Item>
					<ConfirmModal
						onConfirm={OnEndContractModalConfirmClicked}
						headerText="Confirm End"
						text="Are you sure you want to end this contract?"
						trigger={
							<Button color="red" fluid>
								End Contract
							</Button>
						}
					/>
				</List.Item>
			)}
		</List>
	);
}
