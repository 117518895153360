import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './createDocumentSortingClassForm.graphql';

const defaultOptions = {} as const;
export type AddDocumentSortingClassMutationVariables = Types.Exact<{
	data: Types.AddDocumentSortingClassInput;
}>;

export type AddDocumentSortingClassMutation = {
	addDocumentSortingClass: {
		id: string;
		version: number;
		active: boolean;
		className: string;
		amountRequired: boolean;
		partialSortingRequired: boolean;
		vendor: {id: string; name: string};
		type: {id: string; name: string};
	};
};

export type AddDocumentSortingClassType = {
	id: string;
	version: number;
	active: boolean;
	className: string;
	amountRequired: boolean;
	partialSortingRequired: boolean;
	vendor: {id: string; name: string};
	type: {id: string; name: string};
};

export type AddDocumentSortingClassMutationFn = Apollo.MutationFunction<AddDocumentSortingClassMutation, AddDocumentSortingClassMutationVariables>;

/**
 * __useAddDocumentSortingClassMutation__
 *
 * To run a mutation, you first call `useAddDocumentSortingClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDocumentSortingClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDocumentSortingClassMutation, { data, loading, error }] = useAddDocumentSortingClassMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddDocumentSortingClassMutation(
	baseOptions?: Apollo.MutationHookOptions<AddDocumentSortingClassMutation, AddDocumentSortingClassMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<AddDocumentSortingClassMutation, AddDocumentSortingClassMutationVariables>(Operations, options);
}
export type AddDocumentSortingClassMutationHookResult = ReturnType<typeof useAddDocumentSortingClassMutation>;
export type AddDocumentSortingClassMutationResult = Apollo.MutationResult<AddDocumentSortingClassMutation>;
export type AddDocumentSortingClassMutationOptions = Apollo.BaseMutationOptions<
	AddDocumentSortingClassMutation,
	AddDocumentSortingClassMutationVariables
>;
