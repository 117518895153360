import {useDispatch} from 'react-redux';
import {Dropdown, Form, FormInput} from 'semantic-ui-react';
import {AgentDropdown} from '~common/components/AgentDropdown';
import {AgentRole, TicketPriorityEnum, TicketStatusEnum} from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import {setTicketPriorityFilter, setTicketStatusFilter, useSupportState, setTicketAssignedToFilter, setTicketCategoryFilter} from '../state';
import {useGetAllTicketCategoriesQuery} from './CreateTicketForm/getAllTicketCategories';

export function TicketManagementSidebar() {
	const dispatch = useDispatch();
	const {ticketStatusFilter, ticketPriorityFilter} = useSupportState();

	const throwError = useAsyncError();

	const {data, error, loading: categoryLoading} = useGetAllTicketCategoriesQuery();

	if (error) throwError(error);

	const categoryList =
		data?.getAllTicketCategories?.map(item => {
			return {
				text: item.name,
				value: item.id,
			};
		}) || [];

	return (
		<Form>
			<FormInput label="Ticket Status">
				<Dropdown
					fluid
					selection
					options={[
						{id: 1, text: 'Open', value: TicketStatusEnum.Open},
						{id: 2, text: 'Resolved', value: TicketStatusEnum.Resolved},
						{id: 3, text: 'Closed', value: TicketStatusEnum.Closed},
					]}
					value={ticketStatusFilter}
					onChange={(event, val) => dispatch(setTicketStatusFilter(val.value as TicketStatusEnum))}
				/>
			</FormInput>
			<FormInput label="Priority">
				<Dropdown
					multiple
					fluid
					selection
					options={[
						{id: 1, text: 'Low', value: TicketPriorityEnum.Low},
						{id: 2, text: 'Normal', value: TicketPriorityEnum.Normal},
						{id: 3, text: 'High', value: TicketPriorityEnum.High},
						{id: 4, text: 'Emergency', value: TicketPriorityEnum.Emergency},
					]}
					value={ticketPriorityFilter}
					onChange={(event, val) => dispatch(setTicketPriorityFilter(val.value as TicketPriorityEnum[]))}
				/>
			</FormInput>
			<FormInput label="Assigned To">
				<AgentDropdown
					clearable
					fluid
					selection
					roles={[AgentRole.Sysadmin]}
					onChange={val => {
						dispatch(setTicketAssignedToFilter(val));
					}}
				/>
			</FormInput>
			<FormInput label="Category">
				<Dropdown
					clearable
					fluid
					selection
					options={categoryList}
					onChange={(event, val) => {
						dispatch(setTicketCategoryFilter(val.value));
					}}
				/>
			</FormInput>
		</Form>
	);
}
