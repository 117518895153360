import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getCorporateAssetDropdown.graphql';

const defaultOptions = {} as const;
export type GetCorporateAssetDropdownQueryVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
	filter?: Types.InputMaybe<Types.AssetControllerFilterType>;
}>;

export type GetCorporateAssetDropdownQuery = {
	getCorporateAssetsByAccountId?: Array<{id: string; version: number; name: string; archived: boolean; truckNumber?: string | null}> | null;
};

export type GetCorporateAssetDropdownType = {id: string; version: number; name: string; archived: boolean; truckNumber?: string | null};

/**
 * __useGetCorporateAssetDropdownQuery__
 *
 * To run a query within a React component, call `useGetCorporateAssetDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCorporateAssetDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCorporateAssetDropdownQuery({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCorporateAssetDropdownQuery(
	baseOptions: Apollo.QueryHookOptions<GetCorporateAssetDropdownQuery, GetCorporateAssetDropdownQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetCorporateAssetDropdownQuery, GetCorporateAssetDropdownQueryVariables>(Operations, options);
}
export function useGetCorporateAssetDropdownLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetCorporateAssetDropdownQuery, GetCorporateAssetDropdownQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetCorporateAssetDropdownQuery, GetCorporateAssetDropdownQueryVariables>(Operations, options);
}
export type GetCorporateAssetDropdownQueryHookResult = ReturnType<typeof useGetCorporateAssetDropdownQuery>;
export type GetCorporateAssetDropdownLazyQueryHookResult = ReturnType<typeof useGetCorporateAssetDropdownLazyQuery>;
export type GetCorporateAssetDropdownQueryResult = Apollo.QueryResult<GetCorporateAssetDropdownQuery, GetCorporateAssetDropdownQueryVariables>;
