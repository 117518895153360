import {defineRoutes} from '@imperium/router';

export const routes = defineRoutes({
	// payStubsRoutes
	payStubTable: {
		path: `/account/:accountInfoId/pay-roll/pay-stubs`,
		params: ['accountInfoId'] as const,
	},
	addPayStub: {
		path: `/account/:accountInfoId/pay-roll/pay-stubs/add`,
		params: ['accountInfoId'] as const,
	},
	editPayStub: {
		path: `/account/:accountInfoId/pay-roll/pay-stubs/edit/:payStubId`,
		params: ['accountInfoId', 'payStubId'] as const,
	},
	T4Summary: {
		path: `/account/:accountInfoId/pay-roll/t4-summary`,
		params: ['accountInfoId'] as const,
	},
	sourceDeductionTable: {
		path: `/account/:accountInfoId/pay-roll/source-deductions`,
		params: ['accountInfoId'] as const,
	},
	addSourceDeductionPayment: {
		path: `/account/:accountInfoId/pay-roll/source-deductions/addPayment`,
		params: ['accountInfoId'] as const,
	},
	editSourceDeductionPayment: {
		path: `/account/:accountInfoId/pay-roll/source-deductions/editPayment/:paymentId`,
		params: ['accountInfoId', 'paymentId'] as const,
	},
	addSourceDeductionPayable: {
		path: `/account/:accountInfoId/pay-roll/source-deductions/addPayable`,
		params: ['accountInfoId'] as const,
	},
	editSourceDeductionPayable: {
		path: `/account/:accountInfoId/pay-roll/source-deductions/editPayable/:payableId`,
		params: ['accountInfoId', 'payableId'] as const,
	},
	addSourceDeductionOpeningBalance: {
		path: `/account/:accountInfoId/pay-roll/source-deductions/addOpeningBalance`,
		params: ['accountInfoId'] as const,
	},
	editSourceDeductionOpeningBalance: {
		path: `/account/:accountInfoId/pay-roll/source-deductions/editOpeningBalance/:openingBalanceId`,
		params: ['accountInfoId', 'openingBalanceId'] as const,
	},
	addSourceDeductionStatementBalance: {
		path: `/account/:accountInfoId/pay-roll/source-deductions/addStatementBalance`,
		params: ['accountInfoId'] as const,
	},
	editSourceDeductionStatementBalance: {
		path: `/account/:accountInfoId/pay-roll/source-deductions/editStatementBalance/:statementBalanceId`,
		params: ['accountInfoId', 'statementBalanceId'] as const,
	},
	// t4 Routes
	t4s: {
		path: `/account/:accountInfoId/pay-roll/t4s`,
		params: ['accountInfoId'] as const,
	},
	createT4: {
		path: `/account/:accountInfoId/pay-roll/t4/create`,
		params: ['accountInfoId'] as const,
	},
	editT4: {
		path: `/account/:accountInfoId/pay-roll/t4/:t4Id/edit`,
		params: ['accountInfoId', 't4Id'] as const,
	},
});
