import {useCan} from '@imperium/auth-client';
import {formatDate} from '@thx/date';
import {useState} from 'react';
import {useParams, useHistory, Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button, Card, Divider, Icon, List} from 'semantic-ui-react';
import {ColoredSpan, ConfirmModal} from '~common/components';
import {Permission} from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import {routes} from '../../../routes';
import {MoveFile} from '../MoveFile';
import {useGetFileInfoByIdQuery} from './getFileInfoById';
import {useSortFileInfoMutation} from './sortFileInfo';

export function FileViewerSidebar() {
	const throwError = useAsyncError();
	const {accountInfoId, fileInfoId} = useParams<typeof routes.types.fileViewer>();
	const [clicked, setClicked] = useState(false);
	const {push} = useHistory();
	const [sortFile] = useSortFileInfoMutation();
	const [readAllAccount] = useCan(Permission.ReadAllAccount);

	const {data, error} = useGetFileInfoByIdQuery({variables: {fileInfoId}});

	if (error) throwError(error);
	if (!data?.getFileInfoById?.accountInfo.manager?.id) return null;

	let moveAction = <Button content="Move file to another account" color="orange" fluid onClick={() => setClicked(true)} />;
	if (clicked) {
		moveAction = (
			<MoveFile
				accountInfoId={accountInfoId}
				fileInfoId={fileInfoId}
				onSave={() => {
					setClicked(false);
					push(routes.to.receivedFiles({accountInfoId}));
				}}
				managerId={data?.getFileInfoById.accountInfo.manager.id}
			/>
		);
	}

	function sort(fileInfo: string) {
		sortFile({variables: {fileInfo}})
			.then(() => {
				toast.success(`This file has has been moved to unsorted files.`);
			})
			.catch(throwError);
	}

	return (
		<List>
			{readAllAccount && (
				<>
					<List.Item>
						<Card>
							<Card.Content>
								<Card.Header>File Details</Card.Header>
								<Divider />
								<List.Description>
									<List>
										<List.Item>
											<ColoredSpan labelText="Submitted by" contentText={data?.getFileInfoById?.submittedBy?.name || 'Unknown'} />
										</List.Item>
										<List.Item>
											<ColoredSpan labelText="Received by Method" contentText={data?.getFileInfoById?.receiveMethod} />
										</List.Item>
										<List.Item>
											<ColoredSpan labelText="Received on" contentText={formatDate(data?.getFileInfoById?.receivedOn)} />
										</List.Item>
									</List>
								</List.Description>
							</Card.Content>
						</Card>
					</List.Item>
					<Divider />
					{data?.getSortedDocsByFileInfoId && data?.getSortedDocsByFileInfoId?.length > 0 && (
						<>
							<List.Item>
								<Card>
									<Card.Content>
										<Card.Header>Document{data.getSortedDocsByFileInfoId.length === 1 ? '' : 's'} from this File</Card.Header>
										<Divider />
										<List.Description>
											<List>
												{data?.getSortedDocsByFileInfoId?.map(doc => {
													return (
														<List.Item
															as={Link}
															to={routes.to.documentViewer({
																accountInfoId,
																documentId: doc.id,
																tab: '0',
															})}
															className="hoverable"
															style={{cursor: 'pointer', padding: 5}}
															key={doc.id}
														>
															<div style={{display: 'flex', flexDirection: 'row'}}>
																<Icon size="big" name="file text" />
																<div style={{display: 'flex', flexDirection: 'column'}}>
																	<span style={{color: 'grey'}}>{formatDate(doc.date)}</span>
																	<div style={{display: 'flex', flexDirection: 'row', alignItems: 'baseline'}}>
																		<ColoredSpan labelText="Type" contentText={doc.type.name} contentColor="grey" />
																	</div>
																	<div style={{display: 'flex', flexDirection: 'row', alignItems: 'baseline'}}>
																		<ColoredSpan labelText="Vendor" contentText={doc.vendor.name} contentColor="grey" />
																	</div>
																</div>
															</div>
														</List.Item>
													);
												})}
											</List>
										</List.Description>
									</Card.Content>
								</Card>
							</List.Item>
							<Divider />
						</>
					)}
					<List.Item>
						<ConfirmModal
							onConfirm={() => {
								sort(fileInfoId);
							}}
							headerText="Confirm Sort"
							text="Would you like to move this file back to unsorted?"
							trigger={
								<Button size="small" color="blue" fluid>
									Sort File
								</Button>
							}
						/>
					</List.Item>
					<List.Item>{moveAction}</List.Item>
				</>
			)}
		</List>
	);
}
