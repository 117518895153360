import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './editYearEnd.graphql';

const defaultOptions = {} as const;
export type EditSelfEmployedYearEndMutationVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
	YearEnd: Types.Scalars['LocalDate'];
}>;

export type EditSelfEmployedYearEndMutation = {editYearEnd?: {id: string; version: number} | null};

export type EditSelfEmployedYearEndType = {id: string; version: number};

export type EditSelfEmployedYearEndMutationFn = Apollo.MutationFunction<EditSelfEmployedYearEndMutation, EditSelfEmployedYearEndMutationVariables>;

/**
 * __useEditSelfEmployedYearEndMutation__
 *
 * To run a mutation, you first call `useEditSelfEmployedYearEndMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSelfEmployedYearEndMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSelfEmployedYearEndMutation, { data, loading, error }] = useEditSelfEmployedYearEndMutation({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *      YearEnd: // value for 'YearEnd'
 *   },
 * });
 */
export function useEditSelfEmployedYearEndMutation(
	baseOptions?: Apollo.MutationHookOptions<EditSelfEmployedYearEndMutation, EditSelfEmployedYearEndMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<EditSelfEmployedYearEndMutation, EditSelfEmployedYearEndMutationVariables>(Operations, options);
}
export type EditSelfEmployedYearEndMutationHookResult = ReturnType<typeof useEditSelfEmployedYearEndMutation>;
export type EditSelfEmployedYearEndMutationResult = Apollo.MutationResult<EditSelfEmployedYearEndMutation>;
export type EditSelfEmployedYearEndMutationOptions = Apollo.BaseMutationOptions<
	EditSelfEmployedYearEndMutation,
	EditSelfEmployedYearEndMutationVariables
>;
