import {useAccessToken, useFetch} from '@imperium/auth-client';
import {env} from '@thx/env';
import debug from 'debug';
import {Button, Icon} from 'semantic-ui-react';
import {defaults} from '../../../../defaults';

const d = debug('tacs.web.payroll.components.payStubs.DownloadPayStubButton');

interface DownloadPayStubButtonProps {
	payStubIds?: string[];
	onClick?: () => void;
	onDownload?: () => void;
}

export function DownloadPayStubButton(props: DownloadPayStubButtonProps) {
	const {getToken} = useAccessToken();
	const payStubDownloadURL = env.getString('payStubDownloadURL', defaults.payStubDownloadURL);
	const {payStubIds, onClick, onDownload} = props;
	const fetch = useFetch();

	const downloadPayStubsPdf = async () => {
		const accessToken = await getToken();

		if (!accessToken) throw Error('Not logged in.');

		fetch(payStubDownloadURL, {
			method: 'POST',
			mode: 'cors',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				payStubIds,
			}),
		})
			.then(response => response.blob())
			.then(blob => {
				if (onClick) onClick();
				window.open(window.URL.createObjectURL(blob), '_blank')?.focus();
			})
			.catch(err => {
				throw Error(err.message);
			});
	};

	return (
		<Button disabled={!payStubIds?.length} icon color="blue" fluid onClick={downloadPayStubsPdf}>
			<Icon name="download" />
			Download
		</Button>
	);
}
