import {Grid, GridColumn, GridRow, Header, Segment, SegmentGroup} from 'semantic-ui-react';
import {DocumentGroupEnum} from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import {CorporateBinderTableRow} from '../CorporateBinderTableRow';
import {useGetDissolutionDocumentsQuery} from './getDissolutionDocuments';
import {useGetIncorporationDocumentsQuery} from './getIncorporationDocuments';

export function CorporateBinderTable() {
	const throwError = useAsyncError();
	const {data: incorporationData, error: incorporationError} = useGetIncorporationDocumentsQuery({
		variables: {documentTypeGroup: DocumentGroupEnum.Incorporation},
	});
	const {data: dissolutionData, error: dissolutionError} = useGetDissolutionDocumentsQuery({
		variables: {documentTypeGroup: DocumentGroupEnum.CorporateDissolution},
	});

	if (incorporationError) throwError(incorporationError);
	if (dissolutionError) throwError(dissolutionError);

	return (
		<Grid>
			<GridRow>
				<GridColumn>
					<SegmentGroup>
						<Segment color="green" secondary>
							<Header size="small">Incorporation</Header>
						</Segment>
						{incorporationData?.getDocumentTypesByGroup?.map(type => {
							return <CorporateBinderTableRow typeName={type.name} typeId={type.id} key={type.id} />;
						})}
					</SegmentGroup>
				</GridColumn>
			</GridRow>
			<GridRow>
				<GridColumn>
					<SegmentGroup>
						<Segment color="orange" secondary>
							<Header size="small">Dissolution</Header>
						</Segment>
						{dissolutionData?.getDocumentTypesByGroup?.map(type => {
							return <CorporateBinderTableRow typeName={type.name} typeId={type.id} key={type.id} />;
						})}
					</SegmentGroup>
				</GridColumn>
			</GridRow>
		</Grid>
	);
}
