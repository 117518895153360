import debug from 'debug';
import {useHistory, useParams} from 'react-router-dom';
import {Button, List} from 'semantic-ui-react';
import {routes} from '../../routes';
import {RemoveDirectorButton} from './RemoveDirectorButton';

const d = debug('tacs.web.accounts.components.directors.DirectorViewSidebar');

export function DirectorViewSidebar() {
	const {directorId, accountInfoId} = useParams<typeof routes.types.viewDirector>();
	const {push} = useHistory();

	return (
		<List>
			<List.Item>
				<Button fluid color="orange" onClick={() => push(routes.to.editDirector({accountInfoId, directorId}))}>
					Edit Director
				</Button>
			</List.Item>
			<List.Item>
				<RemoveDirectorButton />
			</List.Item>
		</List>
	);
}
