import debug from 'debug';
import {useHistory, useParams} from 'react-router-dom';
import {Button} from 'semantic-ui-react';
import {useAsyncError} from '~lib/useAsyncError';
import {routes} from '../../../routes';
import {useGetRemoveDirectorButtonDirectorQuery} from './getRemoveDirectorButtonDirector';
import {useRemoveDirectorMutation} from './removeDirector';

const d = debug('tacs.web.accounts.components.directors.RemoveDirectorButton');

export function RemoveDirectorButton() {
	const throwError = useAsyncError();
	const {directorId, accountInfoId} = useParams<typeof routes.types.viewDirector>();
	const {push} = useHistory();
	const [removeDirector] = useRemoveDirectorMutation();
	const {data, loading, error} = useGetRemoveDirectorButtonDirectorQuery({variables: {directorId}});

	if (error) throw error;

	function handleRemoveClick() {
		if (data?.getDirectorById) {
			removeDirector({
				variables: {id: data.getDirectorById.id, version: data.getDirectorById.version},
			})
				.then(() => {
					push(routes.to.directors({accountInfoId}));
				})
				.catch(throwError);
		}
	}

	return (
		<Button loading={loading} negative fluid onClick={handleRemoveClick}>
			Remove From Director List
		</Button>
	);
}
