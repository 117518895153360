import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './markNotificationReadById.graphql';

const defaultOptions = {} as const;
export type MarkNotificationReadByIdMutationVariables = Types.Exact<{
	id: Types.Scalars['String'];
	version: Types.Scalars['Int'];
}>;

export type MarkNotificationReadByIdMutation = {markNotificationReadById: {id: string}};

export type MarkNotificationReadByIdType = {id: string};

export type MarkNotificationReadByIdMutationFn = Apollo.MutationFunction<MarkNotificationReadByIdMutation, MarkNotificationReadByIdMutationVariables>;

/**
 * __useMarkNotificationReadByIdMutation__
 *
 * To run a mutation, you first call `useMarkNotificationReadByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationReadByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationReadByIdMutation, { data, loading, error }] = useMarkNotificationReadByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useMarkNotificationReadByIdMutation(
	baseOptions?: Apollo.MutationHookOptions<MarkNotificationReadByIdMutation, MarkNotificationReadByIdMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<MarkNotificationReadByIdMutation, MarkNotificationReadByIdMutationVariables>(Operations, options);
}
export type MarkNotificationReadByIdMutationHookResult = ReturnType<typeof useMarkNotificationReadByIdMutation>;
export type MarkNotificationReadByIdMutationResult = Apollo.MutationResult<MarkNotificationReadByIdMutation>;
export type MarkNotificationReadByIdMutationOptions = Apollo.BaseMutationOptions<
	MarkNotificationReadByIdMutation,
	MarkNotificationReadByIdMutationVariables
>;
