import * as Apollo from '@apollo/client';

import type Money from 'js-money';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './changeHourlyPayByAgentId.graphql';

const defaultOptions = {} as const;
export type ChangeHourlyPayByAgentIdMutationVariables = Types.Exact<{
	agentId: Types.Scalars['String'];
	hourlyPay: Types.Scalars['Money'];
	startingDate: Types.Scalars['LocalDate'];
}>;

export type ChangeHourlyPayByAgentIdMutation = {changeHourlyPayByAgentId: Money};

export type ChangeHourlyPayByAgentIdType = Money;

export type ChangeHourlyPayByAgentIdMutationFn = Apollo.MutationFunction<ChangeHourlyPayByAgentIdMutation, ChangeHourlyPayByAgentIdMutationVariables>;

/**
 * __useChangeHourlyPayByAgentIdMutation__
 *
 * To run a mutation, you first call `useChangeHourlyPayByAgentIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeHourlyPayByAgentIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeHourlyPayByAgentIdMutation, { data, loading, error }] = useChangeHourlyPayByAgentIdMutation({
 *   variables: {
 *      agentId: // value for 'agentId'
 *      hourlyPay: // value for 'hourlyPay'
 *      startingDate: // value for 'startingDate'
 *   },
 * });
 */
export function useChangeHourlyPayByAgentIdMutation(
	baseOptions?: Apollo.MutationHookOptions<ChangeHourlyPayByAgentIdMutation, ChangeHourlyPayByAgentIdMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<ChangeHourlyPayByAgentIdMutation, ChangeHourlyPayByAgentIdMutationVariables>(Operations, options);
}
export type ChangeHourlyPayByAgentIdMutationHookResult = ReturnType<typeof useChangeHourlyPayByAgentIdMutation>;
export type ChangeHourlyPayByAgentIdMutationResult = Apollo.MutationResult<ChangeHourlyPayByAgentIdMutation>;
export type ChangeHourlyPayByAgentIdMutationOptions = Apollo.BaseMutationOptions<
	ChangeHourlyPayByAgentIdMutation,
	ChangeHourlyPayByAgentIdMutationVariables
>;
