import {useCan} from '@imperium/auth-client';
import debug from 'debug';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Form, FormInput} from 'semantic-ui-react';
import {DocumentStatusEnum, Permission} from '~core/graphql';
import {EmployeesDropdown} from '../../../accounts/components/employees/EmployeesDropdown';
import {CorporateAssetDropdown} from '../../../assets/components/corporateAssets/CorporateAssetDropdown';
import type {routes} from '../../routes';
import {setFilteredVendor, setFilteredCorporateAsset, setFilteredEmployee, setFilteredStatus, setFilteredType, useDocumentState} from '../../state';
import {DocumentStatusDropdown} from './DocumentStatusDropdown';
import {DocumentTypeDropdown} from './DocumentTypeDropdown';
import {VendorDropdown} from './VendorDropdown';

const d = debug('tacs.web.documents.components.documents.DocumentsTableSidebar');

export function DocumentsTableSidebar() {
	const dispatch = useDispatch();
	const {filteredType, filteredEmployee, filteredVendor, filteredStatus, filteredCorporateAsset} = useDocumentState();
	const {accountInfoId} = useParams<typeof routes.types.documents>();
	const [writeAllAccounts] = useCan(Permission.WriteAllAccount);

	return (
		<Form>
			{writeAllAccounts && (
				<FormInput label="Type" fluid>
					<DocumentTypeDropdown fluid selection onChange={value => dispatch(setFilteredType(value || ''))} value={filteredType} />
				</FormInput>
			)}
			<FormInput label="Vendor" fluid>
				<VendorDropdown fluid onChange={value => dispatch(setFilteredVendor(value || ''))} value={filteredVendor} accountInfoId={accountInfoId} />
			</FormInput>
			{writeAllAccounts && (
				<FormInput label="Employee" fluid>
					<EmployeesDropdown
						clearable
						fluid
						selection
						accountInfoId={accountInfoId}
						value={filteredEmployee}
						onChange={value => dispatch(setFilteredEmployee(value || ''))}
						defaultNull
					/>
				</FormInput>
			)}
			{writeAllAccounts && (
				<FormInput label="Corporate Asset" fluid>
					<CorporateAssetDropdown
						clearable
						fluid
						selection
						accountInfoId={accountInfoId}
						value={filteredCorporateAsset}
						onChange={value => dispatch(setFilteredCorporateAsset(value || ''))}
						defaultNull
					/>
				</FormInput>
			)}
			{writeAllAccounts && (
				<FormInput label="Status" fluid>
					<DocumentStatusDropdown
						fluid
						multiple
						onChange={value => dispatch(setFilteredStatus(value as DocumentStatusEnum[]))}
						value={filteredStatus}
					/>
				</FormInput>
			)}
		</Form>
	);
}
