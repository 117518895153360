import {createPages} from '@imperium/layout';
import loadable from '@loadable/component';
import {routes} from './routes';

const AccommodationAmountTable = loadable(() => import('./components/AccommodationAmountTable'));
const DomesticReductionRateTable = loadable(() => import('./components/DomesticReductionRateTable'));
const EIRateTable = loadable(() => import('./components/EITable'));
const FuelTaxRateCreateForm = loadable(() => import('./components/FuelTaxRateCreateForm'));
const FuelTaxRateEditForm = loadable(() => import('./components/FuelTaxRateEditForm'));
const FuelTaxRatesTable = loadable(() => import('./components/FuelTaxRatesTable'));
const MinimumWageCreateForm = loadable(() => import('./components/MinimumWageCreateForm'));
const MinimumWageEditForm = loadable(() => import('./components/MinimumWageEditForm'));
const MinimumWagesTable = loadable(() => import('./components/MinimumWageTable'));
const PvlRateCreateForm = loadable(() => import('./components/PvlRateCreateForm'));
const PvlRateEditForm = loadable(() => import('./components/PvlRateEditForm'));
const PvlRateTable = loadable(() => import('./components/PvlRateTable'));
const TaxRateCreateForm = loadable(() => import('./components/TaxRateCreateForm'));
const TaxRateEditForm = loadable(() => import('./components/TaxRateEditForm'));
const TaxRatesTable = loadable(() => import('./components/TaxRatesTable'));
const TreasuryAmountCreateForm = loadable(() => import('./components/TreasuryAmountCreateForm'));
const TreasuryAmountEditForm = loadable(() => import('./components/TreasuryAmountEditForm'));
const TreasuryAmountTable = loadable(() => import('./components/TreasuryAmountTable'));
const DomesticReductionRateCreateForm = loadable(() => import('./components/DomesticReductionRateCreateForm'));
const DomesticReductionRateEditForm = loadable(() => import('./components/DomesticReductionRateEditForm'));
const AccommodationAmountCreateForm = loadable(() => import('./components/AccommodationAmountCreateForm'));
const AccommodationAmountEditForm = loadable(() => import('./components/AccommodationAmountEditForm'));
const EICreateForm = loadable(() => import('./components/EICreateForm'));
const EIEditForm = loadable(() => import('./components/EIEditForm'));

export const routeProps = createPages(routes, {
	accommodationAmount: {
		header: 'Accommodation Amount',
		content: () => <AccommodationAmountTable />,
		sidebar: [{text: 'Create Accommodation Amount', to: routes.to.accommodationAmountCreate(), color: 'green'}],
	},
	accommodationAmountCreate: {
		header: 'Create Accommodation Amount',
		content: () => <AccommodationAmountCreateForm />,
	},
	accommodationAmountEdit: {
		header: 'Edit Accommodation Amount',
		content: () => <AccommodationAmountEditForm />,
	},
	taxRates: {
		header: 'Tax Rates',
		content: () => <TaxRatesTable />,
		sidebar: [{text: 'Create Tax Rate', to: routes.to.taxRateCreate(), color: 'green'}],
	},
	taxRateEdit: {
		header: 'Edit Tax Rate',
		content: () => <TaxRateEditForm />,
	},
	taxRateCreate: {
		header: 'Create Tax Rate',
		content: () => <TaxRateCreateForm />,
	},
	minimumWages: {
		header: 'Minimum Wages',
		content: () => <MinimumWagesTable />,
		sidebar: [{text: 'Create Minimum Wage', to: routes.to.minimumWageCreate(), color: 'green'}],
	},
	minimumWageEdit: {
		header: 'Edit Minimum Wage',
		content: () => <MinimumWageEditForm />,
	},
	minimumWageCreate: {
		header: 'Create Minimum Wage',
		content: () => <MinimumWageCreateForm />,
	},
	fuelTaxRates: {
		header: 'Fuel Tax Rates',
		content: () => <FuelTaxRatesTable />,
		sidebar: [{text: 'Create Fuel Tax Rate', to: routes.to.fuelTaxRateCreate(), color: 'green'}],
	},
	fuelTaxRateEdit: {
		header: 'Edit Fuel Tax Rate',
		content: () => <FuelTaxRateEditForm />,
	},
	fuelTaxRateCreate: {
		header: 'Create Fuel Tax Rate',
		content: () => <FuelTaxRateCreateForm />,
	},
	pvl: {
		header: 'PVL Rate',
		content: () => <PvlRateTable />,
		sidebar: [{text: 'Create PVL Rate', to: routes.to.pvlCreate(), color: 'green'}],
	},
	pvlCreate: {
		header: 'Create PVL',
		content: () => <PvlRateCreateForm />,
	},
	pvlEdit: {
		header: 'Edit PVL',
		content: () => <PvlRateEditForm />,
	},
	reductionRate: {
		header: 'Domestic Reduction Rate',
		content: () => <DomesticReductionRateTable />,
		sidebar: [{text: 'Create Reduction Rate', to: routes.to.reductionRateCreate(), color: 'green'}],
	},
	reductionRateCreate: {
		header: 'Create Domestic Reduction Rate',
		content: () => <DomesticReductionRateCreateForm />,
	},
	reductionRateEdit: {
		header: 'Edit Domestic Reduction Rate',
		content: () => <DomesticReductionRateEditForm />,
	},
	ei: {
		header: 'EI Rates',
		content: () => <EIRateTable />,
		sidebar: [{text: 'Create EI Rate', to: routes.to.eiCreate(), color: 'green'}],
	},
	eiCreate: {
		header: 'Create EI Rate',
		content: () => <EICreateForm />,
	},
	eiEdit: {
		header: 'Edit EI Rate',
		content: () => <EIEditForm />,
	},
	treasuryAmount: {
		header: 'Treasury Amount',
		content: () => <TreasuryAmountTable />,
		sidebar: [{text: 'Create Treasury Amount', to: routes.to.treasuryAmountCreate(), color: 'green'}],
	},
	treasuryAmountCreate: {
		header: 'Create Treasury Amount',
		content: () => <TreasuryAmountCreateForm />,
	},
	treasuryAmountEdit: {
		header: 'Edit Treasury Amount',
		content: () => <TreasuryAmountEditForm />,
	},
});
