import {createPages} from '@imperium/layout';
import loadable from '@loadable/component';
import {routes} from './routes';

const Dashboard = loadable(() => import('./components/dashboard/Dashboard'));
const Podcasts = loadable(() => import('./components/dashboard/Podcasts'));

export const routeProps = createPages(routes, {
	agentDashboard: {
		content: () => <Dashboard />,
		full: true,
	},
	dashboard: {
		content: () => <Dashboard />,
		full: true,
	},
	podcastsPage: {
		content: () => <Podcasts />,
		full: true,
	},
});
