import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getAuthorizedRepresentativeById.graphql';

const defaultOptions = {} as const;
export type GetRemoveAuthorizedRepresentativeAuthorizedRepresentativeQueryVariables = Types.Exact<{
	authorizedRepresentativeId: Types.Scalars['String'];
}>;

export type GetRemoveAuthorizedRepresentativeAuthorizedRepresentativeQuery = {getAuthorizedRepresentativeById: {id: string; version: number}};

export type GetRemoveAuthorizedRepresentativeAuthorizedRepresentativeType = {id: string; version: number};

/**
 * __useGetRemoveAuthorizedRepresentativeAuthorizedRepresentativeQuery__
 *
 * To run a query within a React component, call `useGetRemoveAuthorizedRepresentativeAuthorizedRepresentativeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRemoveAuthorizedRepresentativeAuthorizedRepresentativeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRemoveAuthorizedRepresentativeAuthorizedRepresentativeQuery({
 *   variables: {
 *      authorizedRepresentativeId: // value for 'authorizedRepresentativeId'
 *   },
 * });
 */
export function useGetRemoveAuthorizedRepresentativeAuthorizedRepresentativeQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetRemoveAuthorizedRepresentativeAuthorizedRepresentativeQuery,
		GetRemoveAuthorizedRepresentativeAuthorizedRepresentativeQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<
		GetRemoveAuthorizedRepresentativeAuthorizedRepresentativeQuery,
		GetRemoveAuthorizedRepresentativeAuthorizedRepresentativeQueryVariables
	>(Operations, options);
}
export function useGetRemoveAuthorizedRepresentativeAuthorizedRepresentativeLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetRemoveAuthorizedRepresentativeAuthorizedRepresentativeQuery,
		GetRemoveAuthorizedRepresentativeAuthorizedRepresentativeQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		GetRemoveAuthorizedRepresentativeAuthorizedRepresentativeQuery,
		GetRemoveAuthorizedRepresentativeAuthorizedRepresentativeQueryVariables
	>(Operations, options);
}
export type GetRemoveAuthorizedRepresentativeAuthorizedRepresentativeQueryHookResult = ReturnType<
	typeof useGetRemoveAuthorizedRepresentativeAuthorizedRepresentativeQuery
>;
export type GetRemoveAuthorizedRepresentativeAuthorizedRepresentativeLazyQueryHookResult = ReturnType<
	typeof useGetRemoveAuthorizedRepresentativeAuthorizedRepresentativeLazyQuery
>;
export type GetRemoveAuthorizedRepresentativeAuthorizedRepresentativeQueryResult = Apollo.QueryResult<
	GetRemoveAuthorizedRepresentativeAuthorizedRepresentativeQuery,
	GetRemoveAuthorizedRepresentativeAuthorizedRepresentativeQueryVariables
>;
