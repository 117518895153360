import {useTForm} from '@thx/controls';
import debug from 'debug';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button, Form, FormInput} from 'semantic-ui-react';
import {OfficeDropdown} from '~common/components/OfficeDropdown';
import {useAsyncError} from '~lib/useAsyncError';
import type {routes} from '../../../routes';
import {AccountOrganizationDropdown} from '../AccountOrganizationDropdown';
import {useEditAccountOrganizationAndOfficeMutation} from './editAccountOrganizationAndOffice';
import {useGetEditAccountOrganizationLazyQuery} from './getEditAccountOrganizationAndOffice';

const d = debug('tacs.web.accounts.components.accounts.EditAccountOrganizationAndOffice');

export function EditAccountOrganizationAndOffice() {
	const throwError = useAsyncError();
	const {accountInfoId} = useParams<typeof routes.types.account>();
	const [editMutation] = useEditAccountOrganizationAndOfficeMutation();
	const [getQuery, {data, loading, error, refetch}] = useGetEditAccountOrganizationLazyQuery({variables: {accountInfoId}});
	const [editClicked, setEditClicked] = useState(false);
	const {hasErrors, hasWarnings, handleSubmit, setFieldValue, values, resetForm} = useTForm<{
		accountInfoId: string;
		organizationId: string;
		officeId: string;
	}>({
		initialValues: {organizationId: data?.getAccountInfoById?.organization?.id, officeId: data?.getAccountInfoById?.office?.id},
		onSubmit: formValues => {
			editMutation({
				variables: {accountInfoId, organizationId: formValues.organizationId, officeId: formValues.officeId},
			})
				.then(() => {
					toast.success('Organization and Office Saved');
					setEditClicked(false);
					refetch().catch(e => throwError(e));
				})
				.catch(throwError);
		},
		enableReinitialize: true,
	});

	if (editClicked) {
		if (!loading && !error && !data?.getAccountInfoById) getQuery().catch(throwError);
		return (
			<Form error={hasErrors} warning={hasWarnings} onSubmit={handleSubmit}>
				<FormInput autoFocus loading={loading} label="Organization">
					<AccountOrganizationDropdown
						fluid
						selection
						value={values.organizationId}
						onChange={val => {
							setFieldValue('organizationId', val).catch(e => throwError(e));
							setFieldValue('officeId', null).catch(e => throwError(e));
						}}
					/>
				</FormInput>
				<FormInput loading={loading} label="Office">
					<OfficeDropdown
						selection
						fluid
						value={values.officeId}
						organizationId={values.organizationId}
						onChange={val => setFieldValue('officeId', val)}
					/>
				</FormInput>
				<Button disabled={!values.officeId} positive type="submit" floated="right">
					Save
				</Button>
				<Button
					type="button"
					onClick={() => {
						resetForm();
						setEditClicked(false);
					}}
				>
					Cancel
				</Button>
			</Form>
		);
	}

	return (
		<Button fluid color="orange" onClick={() => setEditClicked(true)}>
			Change Organization
		</Button>
	);
}
