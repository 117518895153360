import {useAuthenticatedState} from '@imperium/auth-client';
import {useParams} from 'react-router-dom';
import {List, Divider, Placeholder} from 'semantic-ui-react';
import type {routes} from '../../routes';
import {CloneAccountButton} from './CloneAccountButton';
import {DeleteAccountButton} from './DeleteAccountButton';
import {EditAccountManager} from './EditAccountManager';
import {EditAccountName} from './EditAccountName';
import {EditAccountOffice} from './EditAccountOffice/EditAccountOffice';
import {EditAccountOrganizationAndOffice} from './EditAccountOrganizationAndOffice';
import {EditAccountStatus} from './EditAccountStatus/EditAccountStatus';
import {EditAddress} from './EditAddress';
import {EditBusinessNumber} from './EditBusinessNumber';
import {EditDefaultAgent} from './EditDefaultAgent';
import {EditFilingFrequency} from './EditFilingFrequency';
import {EditYearEnd} from './EditYearEnd/EditYearEnd';
import {ResetAccountingDataButton} from './ResetAccountingDataButton';
import {useGetSelfEmployedAccountInfoInformationQuery} from './SelfEmployedAccountInfo/getSelfEmployedAccountInfoInformation';

export function SelfEmployedAccountInfoSidebar() {
	const {accountInfoId} = useParams<typeof routes.types.account>();
	const {id} = useAuthenticatedState();
	const {data, error, loading, refetch} = useGetSelfEmployedAccountInfoInformationQuery({
		variables: {
			accountInfoId,
			currentlyLoggedInUserId: id || '',
		},
	});
	if (error) throw error;
	if (loading && !data?.getSelfEmployedAccountByAccountInfoId) {
		return (
			<Placeholder>
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
			</Placeholder>
		);
	}

	return (
		<List>
			<List.Header as="h3">Actions</List.Header>
			<List.Item>
				<EditAccountName />
			</List.Item>
			<List.Item>
				<EditBusinessNumber onSave={() => refetch()} />
			</List.Item>
			<List.Item>
				<EditYearEnd onSave={() => refetch()} />
			</List.Item>
			<List.Item>
				<EditFilingFrequency onSave={() => refetch()} />
			</List.Item>
			<List.Item>
				<EditAddress onSave={() => refetch()} />
			</List.Item>
			<Divider />
			<List.Item>
				<EditAccountStatus />
			</List.Item>
			<List.Item>
				<EditAccountOrganizationAndOffice />
			</List.Item>
			<List.Item>
				<EditAccountOffice />
			</List.Item>
			<List.Item>
				<EditAccountManager />
			</List.Item>
			<List.Item>
				<EditDefaultAgent />
			</List.Item>
			<Divider />
			<List.Item>
				<CloneAccountButton />
			</List.Item>
			<List.Item>
				<ResetAccountingDataButton />
			</List.Item>
			<List.Item>
				<DeleteAccountButton />
			</List.Item>
		</List>
	);
}
