import debug from 'debug';
import {List} from 'semantic-ui-react';
import {RemoveDirectorButton} from './RemoveDirectorButton';

const d = debug('tacs.web.accounts.components.directors.DirectorEditSidebar');

export function DirectorEditSidebar() {
	return (
		<List>
			<List.Item>
				<RemoveDirectorButton />
			</List.Item>
		</List>
	);
}
