import {useTForm} from '@thx/controls';
import {useState} from 'react';
import {Button, Form, FormInput} from 'semantic-ui-react';
import {AgentDropdown} from '~common/components/AgentDropdown';
import {AgentRole} from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import {useChangeTicketAssignedToMutation} from './ChangeTicketAssignedTo';

interface SetReasignProps {
	assignedTo: string;
	ticketId: string;
}

export function ReassignButton({assignedTo, ticketId}: SetReasignProps) {
	const [changeAssignedTo, {loading, data}] = useChangeTicketAssignedToMutation();

	const throwError = useAsyncError();

	const [editClicked, setEditClicked] = useState(false);

	const {hasErrors, hasWarnings, handleSubmit, setFieldValue, values, resetForm} = useTForm({
		initialValues: {assignedTo},
		onSubmit: formValues => {
			changeAssignedTo({
				variables: {data: {assignedTo: formValues.assignedTo, ticketId}},
				update: cache => {
					const toEvict = cache.identify({id: ticketId, __typename: 'Ticket'});
					cache.evict({id: toEvict}).valueOf();
					cache.gc();
				},
			})
				.then(() => {
					setEditClicked(false);
				})
				.catch(throwError);
		},
		enableReinitialize: true,
	});

	if (editClicked) {
		return (
			<Form error={hasErrors} warning={hasWarnings} onSubmit={handleSubmit}>
				<FormInput autoFocus loading={loading} label="Assign agent">
					<AgentDropdown
						defaultUserId={assignedTo}
						onChange={val => setFieldValue('assignedTo', val)}
						fluid
						selection
						value={values.assignedTo}
						roles={[AgentRole.Sysadmin]}
					/>
				</FormInput>
				<Button positive type="submit" floated="right">
					Save
				</Button>
				<Button
					type="button"
					onClick={() => {
						resetForm();
						setEditClicked(false);
					}}
				>
					Cancel
				</Button>
			</Form>
		);
	}

	return (
		<Button color="blue" fluid onClick={() => setEditClicked(true)}>
			Reassign
		</Button>
	);
}
