import {useCan} from '@imperium/auth-client';
import {LayoutData, useLayoutState} from '@imperium/layout';
import {debug} from 'debug';
import {matchPath} from 'react-router-dom';
import {dateRangeSelectionHook} from '~common/hooks/dateRangeSelection/dateRangeSelectionHook';
import {DateRangeSelection} from '~common/types';
import {AccountTypeEnum, Permission} from '~core/graphql';
import {isLoggedIn, needsPermission, needsRoute} from '~lib/permissionHelpers';
import {useAccountState} from '../accounts/state';
import {routes} from './routes';

const d = debug('tacs.web.payroll.layout');

export const layout: LayoutData = {
	permissions: [Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount],
	dataHooks: [
		dateRangeSelectionHook({
			routeMatch: path => matchPath(path, {path: '/account/:id/pay-roll/'}),
			selection: DateRangeSelection.FullYear,
			allowFuture: true,
		}),
	],
	secondaryMenu: [
		{
			text: 'Payroll',
			stateSelectorHook: [useLayoutState, useAccountState],
			permissionSelectorHook: [
				data => {
					const [viewAdminPayrollAccount] = useCan([
						{permission: Permission.ViewAdminPayrollAccount, data: {accountInfoId: data?.state.accountInfoId}},
					]);
					return {viewAdminPayrollAccount};
				},
			],
			visible: {
				...isLoggedIn(),
				...needsRoute('account'),
				'state.type': {$in: [AccountTypeEnum.CorporateAccount]},
				...needsPermission(Permission.ViewAdminPayrollAccount),
			},
			dropdown: [
				{
					visible: {...needsPermission(Permission.ReadAllAccount)},
					text: 'Pay Stubs',
					icon: 'money',
					stateSelectorHook: useLayoutState,
					to: data => (data.state.params?.accountInfoId ? routes.to.payStubTable({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
				{
					visible: {...needsPermission(Permission.ReadAllAccount)},
					text: 'Source Deductions',
					icon: 'calendar alternate outline',
					stateSelectorHook: useLayoutState,
					to: data => (data.state.params?.accountInfoId ? routes.to.sourceDeductionTable({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
				{
					visible: {...needsPermission(Permission.ReadAllAccount)},
					text: 'File T4',
					icon: 'table',
					stateSelectorHook: useLayoutState,
					to: data => (data.state.params?.accountInfoId ? routes.to.t4s({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
				{
					text: 'T4 Summary',
					icon: 'table',
					stateSelectorHook: useLayoutState,
					to: data => (data.state.params?.accountInfoId ? routes.to.T4Summary({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
			],
		},
	],
};
