import {TForm, TFormProps} from '@thx/controls';
import {debug} from 'debug';
import {useHistory, useParams} from 'react-router-dom';
import {Button, Dropdown, Form, FormCheckbox, FormInput, Grid, GridColumn, GridRow} from 'semantic-ui-react';
import {boolean, object, string} from 'yup';
import {Loading} from '~common/components';
import {DocumentGroupEnum, type EditDocumentTypeInput} from '~core/graphql';
import {createDropdownOptionsFromEnum} from '~lib/createDropdownOptionsFromEnum';
import {useAsyncError} from '~lib/useAsyncError';
import {routes} from '../../routes';
import {useGetDocumentTypeForUpdateQuery} from './getDocumentTypeForUpdate';
import {useUpdateDocumentTypeFormMutation} from './updateDocumentTypeForm';

const d = debug('tacs.web.tools.components.DocumentTypeEditForm');

const documentTypeFormValidation = object().shape({
	name: string().required('Name is required.'),
	group: string().required('Group is required.'),
	amount: boolean().required('Amount is required.'),
	statement: boolean().required('Statement field is required.'),
	expected: boolean().required('Expected field is required.'),
	archived: boolean().required('Archived field is required.'),
});

export function DocumentTypeEditForm() {
	const throwError = useAsyncError();
	const {push} = useHistory();
	const {typeId} = useParams<typeof routes.types.documentTypeEdit>();

	const {data, loading, error} = useGetDocumentTypeForUpdateQuery({
		variables: {documentTypeId: typeId},
	});

	const [updateDocumentTypeFormMutation] = useUpdateDocumentTypeFormMutation();

	if (loading) return <Loading />;
	if (error) throw error;
	if (!data) throw new Error('The document type could not be loaded');

	function handleFormSubmit(documentTypeData: EditDocumentTypeInput) {
		const receivedData: EditDocumentTypeInput = {
			...documentTypeData,
		};
		updateDocumentTypeFormMutation({variables: {data: receivedData}})
			.then(() => push(routes.to.documentTypes()))
			.catch(throwError);
	}

	return (
		<TForm<EditDocumentTypeInput>
			initialValues={{
				id: data.getDocumentTypeById?.id || '',
				version: data.getDocumentTypeById?.version || 0,
				name: data?.getDocumentTypeById?.name || '',
				amount: data?.getDocumentTypeById?.amount || false,
				statement: data?.getDocumentTypeById?.statement || false,
				expected: data?.getDocumentTypeById?.expected || false,
				archived: data?.getDocumentTypeById?.archived || false,
				group: data?.getDocumentTypeById?.group || false,
			}}
			onSubmit={handleFormSubmit}
			validationSchema={documentTypeFormValidation}
		>
			{(tFormProps: TFormProps<EditDocumentTypeInput>) => {
				const {handleSubmit, values, hasErrors, hasWarnings, renderWarnings, handleChange, fieldError, setFieldValue} = tFormProps;

				return (
					<Form onSubmit={handleSubmit} error={hasErrors} warning={hasWarnings}>
						<Grid columns="equal" stackable>
							<GridRow>
								<GridColumn>
									<FormInput
										autoFocus
										onChange={handleChange}
										name="name"
										label="Document Type Name"
										error={fieldError('name')}
										value={values?.name || ''}
									/>
								</GridColumn>
								<GridColumn>
									<FormInput required label="Group" error={fieldError('group')}>
										<Dropdown
											fluid
											selection
											options={createDropdownOptionsFromEnum(DocumentGroupEnum, true)}
											value={values.group}
											onChange={(e, val) => setFieldValue('group', val.value)}
										/>
									</FormInput>
								</GridColumn>
							</GridRow>
							<GridRow>
								<GridColumn>
									<FormInput label="Amount (Document that is entered into simply)" name="amount">
										<FormCheckbox
											toggle
											onChange={(e, val) => setFieldValue('amount', val.checked)}
											name="amount"
											error={fieldError('amount')}
											checked={values?.amount || false}
										/>
									</FormInput>
								</GridColumn>
								<GridColumn>
									<FormInput label="Statement (Usually only bank and credit card statements)" name="statement">
										<FormCheckbox
											toggle
											onChange={(e, val) => setFieldValue('statement', val.checked)}
											name="statement"
											error={fieldError('statement')}
											checked={values?.statement || false}
										/>
									</FormInput>
								</GridColumn>
								<FormInput label="Expected (Determines if document can be used in expected documents)" name="expected">
									<FormCheckbox
										toggle
										onChange={(e, val) => setFieldValue('expected', val.checked)}
										name="expected"
										error={fieldError('expected')}
										checked={values?.expected || false}
									/>
								</FormInput>
								<GridColumn>
									<FormInput label="Archived" name="archived">
										<FormCheckbox
											toggle
											onChange={(e, val) => setFieldValue('archived', val.checked)}
											name="archived"
											error={fieldError('archived')}
											checked={values?.archived || false}
										/>
									</FormInput>
								</GridColumn>
							</GridRow>
							<GridRow>
								<GridColumn>{renderWarnings()}</GridColumn>
							</GridRow>
							<GridRow>
								<GridColumn>
									<Button type="submit" floated="right" positive>
										Save
									</Button>
									<Button type="button" floated="right" onClick={() => push(routes.to.documentTypes())}>
										Cancel
									</Button>
								</GridColumn>
							</GridRow>
						</Grid>
					</Form>
				);
			}}
		</TForm>
	);
}
