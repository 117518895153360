import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getAgentsByAuthorizedUser.graphql';

const defaultOptions = {} as const;
export type GetAgentsByAuthorizedUserQueryVariables = Types.Exact<{
	userId?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type GetAgentsByAuthorizedUserQuery = {getAgentsByAuthorizedUser?: Array<{id: string; organization: {id: string; name: string}}> | null};

export type GetAgentsByAuthorizedUserType = {id: string; organization: {id: string; name: string}};

/**
 * __useGetAgentsByAuthorizedUserQuery__
 *
 * To run a query within a React component, call `useGetAgentsByAuthorizedUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgentsByAuthorizedUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgentsByAuthorizedUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetAgentsByAuthorizedUserQuery(
	baseOptions?: Apollo.QueryHookOptions<GetAgentsByAuthorizedUserQuery, GetAgentsByAuthorizedUserQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetAgentsByAuthorizedUserQuery, GetAgentsByAuthorizedUserQueryVariables>(Operations, options);
}
export function useGetAgentsByAuthorizedUserLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetAgentsByAuthorizedUserQuery, GetAgentsByAuthorizedUserQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetAgentsByAuthorizedUserQuery, GetAgentsByAuthorizedUserQueryVariables>(Operations, options);
}
export type GetAgentsByAuthorizedUserQueryHookResult = ReturnType<typeof useGetAgentsByAuthorizedUserQuery>;
export type GetAgentsByAuthorizedUserLazyQueryHookResult = ReturnType<typeof useGetAgentsByAuthorizedUserLazyQuery>;
export type GetAgentsByAuthorizedUserQueryResult = Apollo.QueryResult<GetAgentsByAuthorizedUserQuery, GetAgentsByAuthorizedUserQueryVariables>;
