import {useCan} from '@imperium/auth-client';
import {LayoutData, useLayoutState} from '@imperium/layout';
import {debug} from 'debug';
import {Permission} from '~core/graphql';
import {needsPermission, needsRoute} from '~lib/permissionHelpers';
import {useAccountState} from '../accounts/state';
import {routes} from './routes';

const d = debug('tacs.web.communications.layout');

export const layout: LayoutData = {
	permissions: [Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount],
	primaryMenu: [],
	secondaryMenu: [
		{
			text: 'Communications',
			stateSelectorHook: [useAccountState, useLayoutState],
			permissionSelectorHook: [
				data => {
					const [viewAdminPayrollAccount] = useCan([
						{permission: Permission.ViewAdminPayrollAccount, data: {accountInfoId: data?.state.accountInfoId}},
					]);
					return {viewAdminPayrollAccount};
				},
			],
			visible: {...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount]), ...needsRoute('account')},
			to: data => (data.state.params?.accountInfoId ? routes.to.communicationFeed({accountInfoId: data.state.params.accountInfoId}) : ''),
			icon: 'comments',
		},
	],
};
