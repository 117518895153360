import {useLayoutState} from '@imperium/layout';
import {LocalDate} from '@js-joda/core';
import {formatDate} from '@thx/date';
import debug from 'debug';
import {memo, useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Icon, MenuItem, Transition} from 'semantic-ui-react';
import styles from '~common/components/DateRangeSelector/style.module.css';
import {CustomTab} from '~common/components/DateRangeSelector/tabs/CustomTab';
import {FullMonthTab} from '~common/components/DateRangeSelector/tabs/FullMonthTab';
import {FullYearTab} from '~common/components/DateRangeSelector/tabs/FullYearTab';
import {PresetTab} from '~common/components/DateRangeSelector/tabs/PresetTab';
import {setDate, useCommonState} from '~common/state';
import {DateRangeSelection} from '~common/types';

const d = debug('tacs.web.common.components.DateRangeSelector');

function formatDateText(startDate: LocalDate, endDate: LocalDate, selection: DateRangeSelection) {
	switch (selection) {
		case DateRangeSelection.SingleDate:
			return formatDate(startDate);
		case DateRangeSelection.FullMonth:
			return formatDate(startDate, {format: 'MMMM yyyy'});
		case DateRangeSelection.FullYear:
			return formatDate(startDate, {format: 'yyyy'});
		case DateRangeSelection.DateRange:
		default:
			return `${formatDate(startDate)} - ${formatDate(endDate)}`;
	}
}

function selectTab(selection: DateRangeSelection, hidePanel: () => void) {
	switch (selection) {
		case DateRangeSelection.SingleDate:
			return <CustomTab hidePanel={hidePanel} />;
		case DateRangeSelection.FullMonth:
			return <FullMonthTab hidePanel={hidePanel} />;
		case DateRangeSelection.FullYear:
			return <FullYearTab hidePanel={hidePanel} />;
		case DateRangeSelection.DateRange:
		default:
			return <CustomTab hidePanel={hidePanel} />;
	}
}

export const DateRangeSelector = memo(function DateRangeSelector() {
	const dispatch = useDispatch();
	const {isMobile} = useLayoutState();
	const {startDate, endDate, dateRangeSelection} = useCommonState();
	const [panelVisible, setPanelVisible] = useState(false);

	const rotate = panelVisible ? 'rotate(180deg)' : 'rotate(0)';

	const hidePanel = useCallback(() => {
		setPanelVisible(false);
	}, []);

	function navigateBack() {
		switch (dateRangeSelection) {
			case DateRangeSelection.FullMonth:
				dispatch(setDate({start: startDate.minusMonths(1), end: endDate.minusMonths(1)}));
				break;
			case DateRangeSelection.FullYear:
				dispatch(setDate({start: startDate.minusYears(1), end: endDate.minusYears(1)}));
				break;
			case DateRangeSelection.SingleDate:
				dispatch(setDate({start: startDate.minusDays(1), end: endDate.minusDays(1)}));
				break;
			case DateRangeSelection.DateRange:
				dispatch(setDate({start: startDate.minusYears(1), end: endDate.minusYears(1)}));
				break;
			default:
				break;
		}
	}

	function navigateForward() {
		switch (dateRangeSelection) {
			case DateRangeSelection.FullMonth:
				dispatch(setDate({start: startDate.plusMonths(1), end: endDate.plusMonths(1)}));
				break;
			case DateRangeSelection.FullYear:
				dispatch(setDate({start: startDate.plusYears(1), end: endDate.plusYears(1)}));
				break;
			case DateRangeSelection.SingleDate:
				dispatch(setDate({start: startDate.plusDays(1), end: endDate.plusDays(1)}));
				break;
			case DateRangeSelection.DateRange:
				dispatch(setDate({start: startDate.plusYears(1), end: endDate.plusYears(1)}));
				break;
			default:
				break;
		}
	}

	if (dateRangeSelection === DateRangeSelection.None) {
		return null;
	}

	return (
		<>
			<MenuItem icon="arrow left" onClick={navigateBack} />
			{isMobile && (
				<MenuItem
					onClick={() => {
						setPanelVisible(prev => !prev);
					}}
					style={{justifyContent: 'center'}}
				>
					<Icon name="calendar alternate" />
				</MenuItem>
			)}
			{!isMobile && (
				<MenuItem
					onClick={() => {
						setPanelVisible(prev => !prev);
					}}
					style={{width: 220, justifyContent: 'center'}}
				>
					{formatDateText(startDate, endDate, dateRangeSelection)}
					<Icon name="arrow alternate circle down outline" style={{marginLeft: '10px', transform: rotate, transition: 'all 0.2s linear'}} />
				</MenuItem>
			)}
			<MenuItem icon="arrow right" onClick={navigateForward} disabled={endDate.isEqual(LocalDate.now())} />
			<Transition visible={panelVisible} animation="slide down" duration={250} unmountOnHide>
				<div className={styles.panel}>
					<div className={styles.panelContent}>
						<PresetTab hidePanel={hidePanel} />
						{selectTab(dateRangeSelection, hidePanel)}
					</div>
				</div>
			</Transition>
		</>
	);
});
