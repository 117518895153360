import {useState} from 'react';
import {Button, Checkbox, Grid, GridColumn, GridRow, Icon, Modal} from 'semantic-ui-react';

interface DeleteModalProps {
	onDelete: (value?: boolean) => void;
	headerText: string; // Should be a short phrase like "Confirm Action" -STT
	text: string; // Long phrase like "Are you sure you want to perform this action?" -STT
	trigger: JSX.Element;
	checkboxText?: string;
	checkboxDefault?: boolean;
}

export function DeleteModal(props: DeleteModalProps) {
	const [open, setOpen] = useState(false);
	const [checkboxValue, setCheckboxValue] = useState<boolean | undefined>(props.checkboxDefault);
	const {trigger, onDelete, headerText, text, checkboxText} = props;

	return (
		<Modal open={open} size="small" dimmer trigger={trigger} onClose={() => setOpen(false)} onOpen={() => setOpen(true)}>
			<Modal.Content>
				<Grid>
					<GridRow>
						<GridColumn>
							<h3>
								<Icon color="red" name="delete" />
								{headerText}
							</h3>
						</GridColumn>
					</GridRow>
					<GridRow>
						<GridColumn>
							<GridColumn>{text}</GridColumn>
						</GridColumn>
					</GridRow>
					{checkboxText && (
						<GridRow>
							<GridColumn>
								<GridColumn>
									<Checkbox label={checkboxText} checked={checkboxValue} onChange={v => setCheckboxValue(v.currentTarget.checked)} />
								</GridColumn>
							</GridColumn>
						</GridRow>
					)}
					<GridRow>
						<GridColumn textAlign="right">
							<Button
								color="red"
								onClick={() => {
									setOpen(false);
									onDelete(checkboxValue);
								}}
							>
								<Icon name="trash" /> Delete
							</Button>
							<Button
								color="green"
								floated="right"
								onClick={() => {
									setOpen(false);
								}}
							>
								<Icon name="dont" /> Cancel
							</Button>
						</GridColumn>
					</GridRow>
				</Grid>
			</Modal.Content>
		</Modal>
	);
}
