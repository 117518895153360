export type Province = 'AB' | 'BC' | 'MB' | 'NB' | 'NL' | 'NS' | 'ON' | 'PE' | 'QC' | 'SK' | 'NT' | 'NU' | 'YT';
export const Provinces: Province[] = ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'ON', 'PE', 'QC', 'SK', 'NT', 'NU', 'YT'];

export function getProvinceName(province: Province | string) {
	switch (province) {
		case 'AB':
			return 'Alberta';
		case 'BC':
			return 'British Columbia';
		case 'MB':
			return 'Manitoba';
		case 'NB':
			return 'New Brunswick';
		case 'NL':
			return 'Newfoundland and Labrador';
		case 'NS':
			return 'Nova Scotia';
		case 'ON':
			return 'Ontario';
		case 'PE':
			return 'Prince Edward Island';
		case 'QC':
			return 'Quebec';
		case 'SK':
			return 'Saskatchewan';
		case 'NT':
			return 'Northwest Territories';
		case 'NU':
			return 'Nunavut';
		case 'YT':
			return 'Yukon';
		default:
			throw new Error(`Province not found: ${province}`);
	}
}

export function getProvinces() {
	return Provinces.map((province: Province) => {
		return {
			key: province,
			value: province,
			text: getProvinceName(province),
		};
	});
}
