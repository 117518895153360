import {createPages} from '@imperium/layout';
import loadable from '@loadable/component';
import {routes} from './routes';

const AgentRegistration = loadable(() => import('./components/AgentRegistration'));
const ContactUserRegistration = loadable(() => import('./components/ContactUserRegistration'));
const PublicRegistrationForm = loadable(() => import('./components/PublicRegistrationForm'));
const PublicRegistrationConfirmation = loadable(() => import('./components/PublicRegistrationConfirmation'));
const ExistingUserRegistration = loadable(() => import('./components/ExistingUserRegistration'));

export const routeProps = createPages(routes, {
	register: {
		content: () => <ContactUserRegistration />,
	},
	registerPublic: {
		content: () => <PublicRegistrationForm />,
	},
	registerPublicConfirmation: {
		content: () => <PublicRegistrationConfirmation />,
	},
	registerExistingUser: {
		content: () => <ExistingUserRegistration />,
	},
	registerAgent: {
		content: () => <AgentRegistration />,
	},
});
