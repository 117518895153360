import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getEditAccountNameAccountName.graphql';

const defaultOptions = {} as const;
export type GetEditAccountNameAccountNameQueryVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
}>;

export type GetEditAccountNameAccountNameQuery = {getAccountInfoById?: {id: string; version: number; name: string} | null};

export type GetEditAccountNameAccountNameType = {id: string; version: number; name: string};

/**
 * __useGetEditAccountNameAccountNameQuery__
 *
 * To run a query within a React component, call `useGetEditAccountNameAccountNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditAccountNameAccountNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditAccountNameAccountNameQuery({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *   },
 * });
 */
export function useGetEditAccountNameAccountNameQuery(
	baseOptions: Apollo.QueryHookOptions<GetEditAccountNameAccountNameQuery, GetEditAccountNameAccountNameQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetEditAccountNameAccountNameQuery, GetEditAccountNameAccountNameQueryVariables>(Operations, options);
}
export function useGetEditAccountNameAccountNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetEditAccountNameAccountNameQuery, GetEditAccountNameAccountNameQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetEditAccountNameAccountNameQuery, GetEditAccountNameAccountNameQueryVariables>(Operations, options);
}
export type GetEditAccountNameAccountNameQueryHookResult = ReturnType<typeof useGetEditAccountNameAccountNameQuery>;
export type GetEditAccountNameAccountNameLazyQueryHookResult = ReturnType<typeof useGetEditAccountNameAccountNameLazyQuery>;
export type GetEditAccountNameAccountNameQueryResult = Apollo.QueryResult<
	GetEditAccountNameAccountNameQuery,
	GetEditAccountNameAccountNameQueryVariables
>;
