import {createPages} from '@imperium/layout';
import loadable from '@loadable/component';
import {routes} from './routes';

const T5Create = loadable(() => import('./components/T5CreateForm'));
const T5Edit = loadable(() => import('./components/T5EditForm'));
const T5Table = loadable(() => import('./components/T5sTable'));

export const routeProps = createPages(routes, {
	t5: {
		header: 'T5',
		content: () => <T5Table />,
		sidebar: [
			{
				to: dat => routes.to.createT5({accountInfoId: dat.params.accountInfoId}),
				color: 'green',
				text: 'File T5',
			},
		],
	},
	createT5: {
		header: 'File T5',
		content: () => <T5Create />,
	},
	editT5: {
		header: 'Edit T5',
		content: () => <T5Edit />,
	},
});
