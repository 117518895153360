import {useTForm} from '@thx/controls';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button, Form, FormInput} from 'semantic-ui-react';
import {useAsyncError} from '~lib/useAsyncError';
import {AccountRatingDropdown} from '../../../../tasks/components/accountRating/AccountRatingDropdown';
import type {routes} from '../../../routes';
import {useGetAccountRatingByAccountInfoIdQuery} from './getAccountRatingByAccountInfoId';
import {useSetAccountRatingByAccountInfoIdMutation} from './setAccountRatingByAccountInfoId';

export function EditAccountRating() {
	const throwError = useAsyncError();
	const {accountInfoId} = useParams<typeof routes.types.account>();
	const [editMutation, {loading: mutationLoading}] = useSetAccountRatingByAccountInfoIdMutation();
	const {data, loading, error, refetch} = useGetAccountRatingByAccountInfoIdQuery({variables: {accountInfoId}});
	const [editClicked, setEditClicked] = useState(false);
	const {hasErrors, hasWarnings, handleSubmit, setFieldValue, values, resetForm} = useTForm({
		initialValues: {accountRatingId: data?.getAccountRatingByAccountInfoId?.id},
		onSubmit: formValues => {
			editMutation({
				variables: {
					accountInfoId,
					accountRatingId: formValues.accountRatingId,
				},
				refetchQueries: ['getCorporateAccountInformation'],
			})
				.then(() => {
					toast.success('Account rating updated!');
					setEditClicked(false);
					refetch().catch(e => throwError(e));
				})
				.catch(throwError);
		},
		enableReinitialize: true,
	});
	if (error) throwError(error);

	if (editClicked) {
		return (
			<Form error={hasErrors} warning={hasWarnings} onSubmit={handleSubmit}>
				<FormInput autoFocus loading={loading} label="Account Rating">
					<AccountRatingDropdown value={values.accountRatingId} selection fluid onChange={val => setFieldValue('accountRatingId', val)} />
				</FormInput>
				<Button positive type="submit" floated="right" disabled={!values.accountRatingId}>
					Save
				</Button>
				<Button
					disabled={mutationLoading}
					type="button"
					onClick={() => {
						resetForm();
						setEditClicked(false);
					}}
				>
					Cancel
				</Button>
			</Form>
		);
	}

	return (
		<Button fluid color="orange" onClick={() => setEditClicked(true)}>
			Change Rating
		</Button>
	);
}
