import {useDispatch} from 'react-redux';
import {Dropdown, Form, FormInput} from 'semantic-ui-react';
import {ReminderFilter} from '~core/graphql';
import {setReminderType, useUserState} from '../state';

export function ReminderFilterDropdown() {
	const {reminderType} = useUserState();
	const dispatch = useDispatch();
	return (
		<Form>
			<FormInput label="Reminders Filter">
				<Dropdown
					fluid
					selection
					value={reminderType}
					options={[
						{text: 'Upcoming', value: ReminderFilter.Upcoming},
						{text: 'Previous', value: ReminderFilter.Past},
						{text: 'Other', value: ReminderFilter.Other},
					]}
					onChange={(event, data) => {
						dispatch(setReminderType(data.value as ReminderFilter));
					}}
				/>
			</FormInput>
		</Form>
	);
}
