import {LocalDate} from '@js-joda/core';
import {TForm, TFormProps} from '@thx/controls';
import {formatDate} from '@thx/date';
import {toMoney} from '@thx/money';
import {localDateSchemaType, moneySchemaType} from '@thx/yup-types';
import {debug} from 'debug';
import {useHistory, useParams} from 'react-router-dom';
import {Card, CardContent, CardDescription, CardGroup, CardMeta} from 'semantic-ui-react';
import {array, boolean, number, object, string} from 'yup';
import {Loading} from '~common/components';
import type {EditStatementInput} from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import type {routes} from '../../../routes';
import {StatementFormFields} from '../StatementFormFields';
import {useEditStatementFormMutation} from './editStatementForm';
import {useGetStatementByIdQuery} from './getStatementById';

const d = debug('tacs.web.documents.components.statement.StatementEditForm.StatementEdit');

const statementLinesEditType = object().shape({
	id: string().required('The id name is required'),
	version: number().required('The version name is required'),
	financialAccountId: string().required('The account is required'),
	openingBalance: moneySchemaType().required('The opening balance is required'),
	closingBalance: moneySchemaType().required('The closing balance is required'),
	statementLines: array().of(
		object().shape({
			description: string(),
			date: localDateSchemaType(),
			debit: moneySchemaType().nullable(),
			credit: moneySchemaType().nullable(),
			document: string(),
			status: string().required('Status is required'),
			reconciled: boolean().required('Reconciled is required'),
		}),
	),
});
export function StatementEdit() {
	const throwError = useAsyncError();
	const {goBack} = useHistory();
	const {accountInfoId, statementId} = useParams<typeof routes.types.statementEdit>();

	const {data, error, loading} = useGetStatementByIdQuery({variables: {statementId}});

	const [editStatementFormMutation, {called}] = useEditStatementFormMutation();

	if (error) throw error;
	if (loading) return <Loading />;

	function handleFormSubmit(statementLineData: EditStatementInput) {
		const statementLines = statementLineData.statementLines?.filter(dt => dt.description.trim().length !== 0);
		editStatementFormMutation({
			variables: {
				data: {
					...statementLineData,
					document: data?.getStatementById?.document?.id || '',
					id: data?.getStatementById?.id || '',
					version: data?.getStatementById?.version || 0,
					statementLines,
				},
			},
			refetchQueries: ['getStatementById'],
		})
			.then(() => goBack())
			.catch(throwError);
	}

	return (
		<>
			<CardGroup style={{paddingBottom: 10}}>
				<Card>
					<CardContent>
						<CardMeta>Date</CardMeta>
						<CardDescription>{formatDate(data?.getStatementById?.document?.date)}</CardDescription>
					</CardContent>
				</Card>
				<Card>
					<CardContent>
						<CardMeta>Type</CardMeta>
						<CardDescription>{data?.getStatementById?.document?.type.name}</CardDescription>
					</CardContent>
				</Card>
				{data?.getStatementById?.document?.vendor?.name && (
					<Card>
						<CardContent>
							<CardMeta>Vendor</CardMeta>
							<CardDescription>{data?.getStatementById.document?.vendor.name}</CardDescription>
						</CardContent>
					</Card>
				)}
			</CardGroup>
			<TForm<EditStatementInput>
				initialValues={{
					id: data?.getStatementById?.id,
					version: data?.getStatementById?.version,
					financialAccountId: data?.getStatementById?.financialAccount.id || '',
					openingBalance: data?.getStatementById?.openingBalance || 0,
					closingBalance: data?.getStatementById?.closingBalance || 0,
					statementLines: data?.getStatementById?.statementLines.map(line => {
						return {
							description: line.description,
							date: line?.date || LocalDate.now(),
							debit: line?.debit || toMoney(0),
							credit: line?.credit || toMoney(0),
							document: line?.document?.id || '',
							id: line?.id,
							version: line?.version,
							status: line?.status,
							reconciled: line?.reconciled,
						};
					}),
				}}
				onSubmit={handleFormSubmit}
				validationSchema={statementLinesEditType}
			>
				{(tFormProps: TFormProps<EditStatementInput>) => {
					return (
						<StatementFormFields
							{...tFormProps}
							called={called}
							accountInfoId={accountInfoId}
							documentType={data?.getStatementById?.document.type.id}
						/>
					);
				}}
			</TForm>
		</>
	);
}
