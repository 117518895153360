import {formatMoney, toMoney} from '@thx/money';
import {startCase} from 'lodash-es';
import {Link, useParams} from 'react-router-dom';
import {Button, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow} from 'semantic-ui-react';
import {JournalEntryTypeEnum} from '~core/graphql';
import {canEditJournalEntry} from '../../../../accounting/components/canEditJournalEntry';
import {generateJournalEntryRoute} from '../../../../accounting/components/generateJournalEntryRoute';
import type {routes} from '../../../routes';
import {useGetJournalEntriesByDocumentIdQuery} from './getJournalEntriesByDocumentId';

export function DocumentSidebarJournalEntries() {
	const {documentId, accountInfoId} = useParams<typeof routes.types.documentViewer>();
	const {data, error, loading} = useGetJournalEntriesByDocumentIdQuery({variables: {documentId}});

	if (loading) return null;
	if (error) throw error;
	if (!data?.getJournalEntriesByDocumentId) throw new Error('The unsorted doc meta-information could not be loaded');

	return (
		<Table selectable basic="very">
			<TableHeader>
				<TableRow>
					<TableHeaderCell>GL Account</TableHeaderCell>
					<TableHeaderCell>Debit</TableHeaderCell>
					<TableHeaderCell>Credit</TableHeaderCell>
				</TableRow>
			</TableHeader>
			<TableBody>
				{data?.getJournalEntriesByDocumentId?.map(journalEntry => {
					return (
						<>
							{journalEntry.journalEntryLines?.map(line => {
								return (
									<TableRow key={line.id}>
										<TableCell>{line.glAccount.name}</TableCell>
										<TableCell>{line.debit.isZero() ? '-' : formatMoney(line.debit)}</TableCell>
										<TableCell>{line.credit.isZero() ? '-' : formatMoney(line.credit)}</TableCell>
										<TableCell>
											{canEditJournalEntry(journalEntry) && (
												<Link to={generateJournalEntryRoute(journalEntry, accountInfoId) || ''}>
													<Button
														icon="edit"
														title={`Edit ${journalEntry.type === JournalEntryTypeEnum.General ? 'Journal Entry' : startCase(journalEntry.type)}`}
														size="mini"
														inverted
														color="blue"
													/>
												</Link>
											)}
										</TableCell>
									</TableRow>
								);
							})}

							<TableRow>
								<TableCell />
								<TableCell>
									<strong>{formatMoney(journalEntry.journalEntryLines?.reduce((debit, line) => debit.add(line.debit), toMoney(0)))}</strong>
								</TableCell>
								<TableCell>
									<strong>{formatMoney(journalEntry.journalEntryLines?.reduce((credit, line) => credit.add(line.credit), toMoney(0)))}</strong>
								</TableCell>
								<TableCell />
							</TableRow>
						</>
					);
				})}
			</TableBody>
		</Table>
	);
}
