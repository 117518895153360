import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './addCommunicationFeedItem.graphql';

const defaultOptions = {} as const;
export type AddCommunicationFeedItemMutationVariables = Types.Exact<{
	data: Types.AddCommunicationFeedItemNoteInput;
}>;

export type AddCommunicationFeedItemMutation = {
	addCommunicationFeedItemNote: {
		id: string;
		type: Types.CommunicationTypeEnum;
		contents: string;
		color?: string | null;
		createdAt: Date;
		pinned: boolean;
		version?: number | null;
		data?: {cc?: string | null; date?: string | null; from: string; subject?: string | null; text?: string | null; to: string} | null;
		createdBy: {id: string; version: number; name: string; roles: Array<Types.AgentRole>};
	};
};

export type AddCommunicationFeedItemType = {
	id: string;
	type: Types.CommunicationTypeEnum;
	contents: string;
	color?: string | null;
	createdAt: Date;
	pinned: boolean;
	version?: number | null;
	data?: {cc?: string | null; date?: string | null; from: string; subject?: string | null; text?: string | null; to: string} | null;
	createdBy: {id: string; version: number; name: string; roles: Array<Types.AgentRole>};
};

export type AddCommunicationFeedItemMutationFn = Apollo.MutationFunction<AddCommunicationFeedItemMutation, AddCommunicationFeedItemMutationVariables>;

/**
 * __useAddCommunicationFeedItemMutation__
 *
 * To run a mutation, you first call `useAddCommunicationFeedItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommunicationFeedItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommunicationFeedItemMutation, { data, loading, error }] = useAddCommunicationFeedItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddCommunicationFeedItemMutation(
	baseOptions?: Apollo.MutationHookOptions<AddCommunicationFeedItemMutation, AddCommunicationFeedItemMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<AddCommunicationFeedItemMutation, AddCommunicationFeedItemMutationVariables>(Operations, options);
}
export type AddCommunicationFeedItemMutationHookResult = ReturnType<typeof useAddCommunicationFeedItemMutation>;
export type AddCommunicationFeedItemMutationResult = Apollo.MutationResult<AddCommunicationFeedItemMutation>;
export type AddCommunicationFeedItemMutationOptions = Apollo.BaseMutationOptions<
	AddCommunicationFeedItemMutation,
	AddCommunicationFeedItemMutationVariables
>;
