import {useDispatch} from 'react-redux';
import {Button, List} from 'semantic-ui-react';
import {setShowExpensesDialog, useAccountingState} from '../../state';

export function ExpensesTableSidebar() {
	const dispatch = useDispatch();
	const {showExpensesDialog} = useAccountingState();
	return (
		<List>
			<List.Item>
				<Button
					disabled={showExpensesDialog}
					positive
					fluid
					onClick={() => {
						dispatch(setShowExpensesDialog(true));
					}}
				>
					Add Expense
				</Button>
			</List.Item>
		</List>
	);
}
