import * as Apollo from '@apollo/client';

import type Money from 'js-money';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getCaftPendingItems.graphql';

const defaultOptions = {} as const;
export type GetCaftPendingItemsQueryVariables = Types.Exact<{[key: string]: never}>;

export type GetCaftPendingItemsQuery = {
	getCaftPendingItemsReport?: Array<{
		id: string;
		amount: Money;
		createdOn: Date;
		bankAccountNumber: string;
		institutionNumber: string;
		transitNumber: string;
	}> | null;
};

export type GetCaftPendingItemsType = {
	id: string;
	amount: Money;
	createdOn: Date;
	bankAccountNumber: string;
	institutionNumber: string;
	transitNumber: string;
};

/**
 * __useGetCaftPendingItemsQuery__
 *
 * To run a query within a React component, call `useGetCaftPendingItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaftPendingItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaftPendingItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCaftPendingItemsQuery(baseOptions?: Apollo.QueryHookOptions<GetCaftPendingItemsQuery, GetCaftPendingItemsQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetCaftPendingItemsQuery, GetCaftPendingItemsQueryVariables>(Operations, options);
}
export function useGetCaftPendingItemsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetCaftPendingItemsQuery, GetCaftPendingItemsQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetCaftPendingItemsQuery, GetCaftPendingItemsQueryVariables>(Operations, options);
}
export type GetCaftPendingItemsQueryHookResult = ReturnType<typeof useGetCaftPendingItemsQuery>;
export type GetCaftPendingItemsLazyQueryHookResult = ReturnType<typeof useGetCaftPendingItemsLazyQuery>;
export type GetCaftPendingItemsQueryResult = Apollo.QueryResult<GetCaftPendingItemsQuery, GetCaftPendingItemsQueryVariables>;
