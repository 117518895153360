import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './editAccountInfoAddress.graphql';

const defaultOptions = {} as const;
export type EditAccountInfoAddressMutationVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
	data: Types.EditAddressInput;
}>;

export type EditAccountInfoAddressMutation = {editAccountInfoAddress?: {id: string} | null};

export type EditAccountInfoAddressType = {id: string};

export type EditAccountInfoAddressMutationFn = Apollo.MutationFunction<EditAccountInfoAddressMutation, EditAccountInfoAddressMutationVariables>;

/**
 * __useEditAccountInfoAddressMutation__
 *
 * To run a mutation, you first call `useEditAccountInfoAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAccountInfoAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAccountInfoAddressMutation, { data, loading, error }] = useEditAccountInfoAddressMutation({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditAccountInfoAddressMutation(
	baseOptions?: Apollo.MutationHookOptions<EditAccountInfoAddressMutation, EditAccountInfoAddressMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<EditAccountInfoAddressMutation, EditAccountInfoAddressMutationVariables>(Operations, options);
}
export type EditAccountInfoAddressMutationHookResult = ReturnType<typeof useEditAccountInfoAddressMutation>;
export type EditAccountInfoAddressMutationResult = Apollo.MutationResult<EditAccountInfoAddressMutation>;
export type EditAccountInfoAddressMutationOptions = Apollo.BaseMutationOptions<
	EditAccountInfoAddressMutation,
	EditAccountInfoAddressMutationVariables
>;
