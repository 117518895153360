import debug from 'debug';
import {Link} from 'react-router-dom';
import {List, Placeholder} from 'semantic-ui-react';
import {routes} from '../../../routes';
import {useGetAccountInfoAuthorizedRepresentativesByIdQuery} from './getAccountTableAccounts';

const d = debug('tacs.web.accounts.components.authorizedRepresentatives.DisplayAuthorizedRepresentativeList');

interface DisplayAuthorizedRepresentativeListProps {
	accountInfoId: string;
}

export function DisplayAuthorizedRepresentativeList(props: DisplayAuthorizedRepresentativeListProps) {
	const {accountInfoId} = props;
	const {data, loading, error} = useGetAccountInfoAuthorizedRepresentativesByIdQuery({
		variables: {id: accountInfoId},
	});

	if (error) throw error;

	if (loading)
		return (
			<Placeholder>
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
			</Placeholder>
		);
	if (!data) throw new Error("The data couldn't be loaded");

	return (
		<List bulleted>
			{data?.getAuthorizedRepresentativesByAccountInfoId?.length ? (
				data?.getAuthorizedRepresentativesByAccountInfoId?.map(authorizedRepresentative => {
					return (
						<List.Item key={authorizedRepresentative?.id}>
							<Link
								to={routes.to.viewAuthorizedRepresentative({
									accountInfoId,
									authorizedRepresentativeId: authorizedRepresentative.id,
								})}
							>
								{authorizedRepresentative.contact?.givenName} {authorizedRepresentative.contact?.surname}
							</Link>
						</List.Item>
					);
				})
			) : (
				<List.Item>(No authorizedRepresentatives assigned)</List.Item>
			)}
		</List>
	);
}
