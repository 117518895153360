import type {LocalDate} from '@js-joda/core';
import {formatDate} from '@thx/date';
import {formatMoney, toMoney} from '@thx/money';
import debug from 'debug';
import type Money from 'js-money';
import {useDispatch} from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import {Button, List, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow} from 'semantic-ui-react';
import {EmployeesDropdown} from '~common/components';
import {useCommonState} from '~common/state';
import type {PayStubsFilterInput} from '~core/graphql';
import {routes} from '../../../routes';
import {setSelectedEmployee, setSelectedPayStubs, usePayrollState} from '../../../state';
import {DownloadPayStubButton} from '../DownloadPayStubButton';
import {useGetPayStubTablePayStubsQuery} from './getPayStubTablePayStubs';

const d = debug('tacs.web.payroll.components.payStubs.PayStubTable.PayStubTableSideBar');

export function PayStubTableSideBar() {
	const dispatch = useDispatch();
	const {accountInfoId} = useParams<typeof routes.types.payStubTable>();
	const {endDate: sessionEndDate} = useCommonState();
	const {selectedEmployee, selectedPayStubs} = usePayrollState();

	const filter: PayStubsFilterInput = {
		employeeIds: !selectedEmployee || selectedEmployee === 'all' ? undefined : [selectedEmployee],
		startDate: sessionEndDate.minusMonths(3),
		endDate: sessionEndDate,
	};
	const {loading, data, error} = useGetPayStubTablePayStubsQuery({variables: {accountInfoId, filter}});
	const payPeriodTotals: {amount: Money; startDate: LocalDate; endDate: LocalDate}[] = [];

	if (error) throw error;

	data?.getPayStubsByAccount?.forEach(payStub => {
		const index = payPeriodTotals.findIndex(p => p.startDate.isEqual(payStub.startDate) && p.endDate.isEqual(payStub.endDate));

		let grossAmount = toMoney();
		payStub.grossIncome.forEach(g => {
			grossAmount = grossAmount.add(g.amount);
		});
		if (index >= 0) {
			payPeriodTotals[index] = {...payPeriodTotals[index], amount: payPeriodTotals[index].amount.add(grossAmount)};
		} else {
			payPeriodTotals.push({startDate: payStub.startDate, endDate: payStub.endDate, amount: grossAmount});
		}
	});

	return (
		<>
			<List>
				<List.Item>
					<EmployeesDropdown
						selection
						fluid
						accountInfoId={accountInfoId}
						placeholder="All Employees"
						value={selectedEmployee}
						onChange={employee => {
							if (employee) dispatch(setSelectedEmployee(employee));
							else dispatch(setSelectedEmployee('all'));
						}}
						defaultNull
						clearable
						active
					/>
				</List.Item>
				<List.Item>
					<Link to={routes.to.addPayStub({accountInfoId})}>
						<Button positive fluid>
							Add Pay Stub
						</Button>
					</Link>
				</List.Item>
				<List.Item>
					<DownloadPayStubButton
						payStubIds={selectedPayStubs}
						onDownload={() => {
							dispatch(setSelectedPayStubs([]));
						}}
					/>
				</List.Item>
			</List>
			<Table basic="very" compact>
				<TableHeader>
					<TableHeaderCell>Pay Period Totals</TableHeaderCell>
					<TableHeaderCell />
				</TableHeader>
				<TableBody>
					{payPeriodTotals
						?.sort((a, b) => b.startDate.toEpochDay() - a.startDate.toEpochDay())
						.map(payStub => {
							return (
								<TableRow key={payStub.startDate.toEpochDay()}>
									<TableCell>
										{formatDate(payStub.startDate, {format: 'MMM dd'})} - {formatDate(payStub.endDate, {format: 'MMM dd'})}
									</TableCell>
									<TableCell>{formatMoney(payStub.amount)}</TableCell>
								</TableRow>
							);
						})}
				</TableBody>
			</Table>
		</>
	);
}
