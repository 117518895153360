import {Card, CardContent, CardDescription, CardHeader, Divider, Icon} from 'semantic-ui-react';
import {DisplayAuthorizedRepresentativeList} from '../authorizedRepresentatives/DisplayAuthorizedRepresentativeList';

interface Props {
	accountInfoId: string;
}

export default function AuthorizedRepresentativesCard({accountInfoId}: Props) {
	return (
		<Card>
			<CardContent>
				<CardHeader>
					<Icon name="user secret" />
					Authorized Representatives
				</CardHeader>
				<Divider />
				<CardDescription>
					<DisplayAuthorizedRepresentativeList accountInfoId={accountInfoId} />
				</CardDescription>
			</CardContent>
		</Card>
	);
}
