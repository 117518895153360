import type React from 'react';
import {useDispatch} from 'react-redux';
import {Dropdown, Form, FormInput} from 'semantic-ui-react';
import {setArchivedVendors, useAccountingState} from '../../state';

export function VendorsSidebar() {
	const dispatch = useDispatch();
	const {archivedVendors} = useAccountingState();

	return (
		<Form>
			<FormInput label="Status">
				<Dropdown
					fluid
					selection
					options={[
						{id: 1, text: 'Active', value: false},
						{id: 2, text: 'Archived', value: true},
					]}
					value={archivedVendors}
					onChange={(event, data) => dispatch(setArchivedVendors(data.value as boolean))}
				/>
			</FormInput>
		</Form>
	);
}
