import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getIncorporationDocuments.graphql';

const defaultOptions = {} as const;
export type GetIncorporationDocumentsQueryVariables = Types.Exact<{
	documentTypeGroup: Types.DocumentGroupEnum;
}>;

export type GetIncorporationDocumentsQuery = {getDocumentTypesByGroup?: Array<{id: string; name: string}> | null};

export type GetIncorporationDocumentsType = {id: string; name: string};

/**
 * __useGetIncorporationDocumentsQuery__
 *
 * To run a query within a React component, call `useGetIncorporationDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIncorporationDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIncorporationDocumentsQuery({
 *   variables: {
 *      documentTypeGroup: // value for 'documentTypeGroup'
 *   },
 * });
 */
export function useGetIncorporationDocumentsQuery(
	baseOptions: Apollo.QueryHookOptions<GetIncorporationDocumentsQuery, GetIncorporationDocumentsQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetIncorporationDocumentsQuery, GetIncorporationDocumentsQueryVariables>(Operations, options);
}
export function useGetIncorporationDocumentsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetIncorporationDocumentsQuery, GetIncorporationDocumentsQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetIncorporationDocumentsQuery, GetIncorporationDocumentsQueryVariables>(Operations, options);
}
export type GetIncorporationDocumentsQueryHookResult = ReturnType<typeof useGetIncorporationDocumentsQuery>;
export type GetIncorporationDocumentsLazyQueryHookResult = ReturnType<typeof useGetIncorporationDocumentsLazyQuery>;
export type GetIncorporationDocumentsQueryResult = Apollo.QueryResult<GetIncorporationDocumentsQuery, GetIncorporationDocumentsQueryVariables>;
