import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getLoggedInUserDetails.graphql';

const defaultOptions = {} as const;
export type GetLoggedInUserDetailsQueryVariables = Types.Exact<{
	userId: Types.Scalars['String'];
}>;

export type GetLoggedInUserDetailsQuery = {
	getUserById?: {
		id: string;
		name: string;
		activeAgent?: {id: string; organization: {id: string; name: string}; office: {id: string; name: string}} | null;
	} | null;
};

export type GetLoggedInUserDetailsType = {
	id: string;
	name: string;
	activeAgent?: {id: string; organization: {id: string; name: string}; office: {id: string; name: string}} | null;
};

/**
 * __useGetLoggedInUserDetailsQuery__
 *
 * To run a query within a React component, call `useGetLoggedInUserDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoggedInUserDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoggedInUserDetailsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetLoggedInUserDetailsQuery(
	baseOptions: Apollo.QueryHookOptions<GetLoggedInUserDetailsQuery, GetLoggedInUserDetailsQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetLoggedInUserDetailsQuery, GetLoggedInUserDetailsQueryVariables>(Operations, options);
}
export function useGetLoggedInUserDetailsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetLoggedInUserDetailsQuery, GetLoggedInUserDetailsQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetLoggedInUserDetailsQuery, GetLoggedInUserDetailsQueryVariables>(Operations, options);
}
export type GetLoggedInUserDetailsQueryHookResult = ReturnType<typeof useGetLoggedInUserDetailsQuery>;
export type GetLoggedInUserDetailsLazyQueryHookResult = ReturnType<typeof useGetLoggedInUserDetailsLazyQuery>;
export type GetLoggedInUserDetailsQueryResult = Apollo.QueryResult<GetLoggedInUserDetailsQuery, GetLoggedInUserDetailsQueryVariables>;
