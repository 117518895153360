import type {LinkedGlAccountType} from '~core/graphql';
import type {GetChartOfAccountsLinkedType} from '../components/chartOfAccounts/GeneralLedgerAccountsTableSideBar/getChartOfAccountsLinked';

export function selectLinkedAccount(type: LinkedGlAccountType, linkedAccounts?: GetChartOfAccountsLinkedType['linkedAccounts']) {
	if (!linkedAccounts) return undefined;
	const ret = linkedAccounts.find(v => v.type === type);
	if (ret) {
		return ret.account;
	}
	return undefined;
}
