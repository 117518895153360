import debug from 'debug';
import {useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {DateRangeOptions, getInstance} from '~common/hooks/dateRangeSelection/dateRangeSelectionSingleton';
import {setDateRangeOption} from '~common/state';
import {DateRangeSelection} from '~common/types';

const d = debug('tacs.web.common.hooks.dateRangeSelection.useDateRangeSelectionSwitcher');

/**
 * This hook gets the route and options array and matches against the current pathname. If any of them match
 * it sets the required DateRangeSelection. If none of them match it resets to DateRangeSelection.DateRange.
 */
export function useDateRangeSelectionSwitcher() {
	const dispatch = useDispatch();
	const {pathname} = useLocation();
	const [firstLoad, setFirstLoad] = useState(true);
	const singleton = useMemo(() => {
		return getInstance();
	}, []);

	useEffect(() => {
		const result = singleton.getOptions().reduce(
			(memo, v) => {
				if (v.routeMatch(pathname)) {
					if (memo.matchedCount === 0) {
						return {...memo, matchedCount: 1, matchedOption: v};
					}
					// if (v.selection !== memo.matchedOption) {
					// 	throw new Error('Multiple matched');
					// } else {
					return {...memo, matchedCount: memo.matchedCount + 1};
					// }
				}
				return memo;
			},
			{matchedCount: 0, matchedOption: null} as {matchedCount: number; matchedOption: DateRangeOptions | null},
		);

		// This may seem useless at first, but it triggers a re-render which updates the date range option properly.
		if (firstLoad) setFirstLoad(false);

		if (result.matchedCount === 0) {
			dispatch(
				setDateRangeOption({
					selection: DateRangeSelection.DateRange,
					allowFuture: false,
				}),
			);
		} else {
			dispatch(
				setDateRangeOption({
					selection: result.matchedOption?.selection || DateRangeSelection.DateRange,
					allowFuture: result.matchedOption?.allowFuture,
				}),
			);
		}
	}, [dispatch, firstLoad, pathname, singleton]);
}
