import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getTicketByIdForSidebar.graphql';

const defaultOptions = {} as const;
export type GetTicketByIdForSidebarQueryVariables = Types.Exact<{
	id: Types.Scalars['String'];
}>;

export type GetTicketByIdForSidebarQuery = {
	getTicketById: {id: string; status: Types.TicketStatusEnum; priority: Types.TicketPriorityEnum; assignedTo: {id: string; name: string}};
};

export type GetTicketByIdForSidebarType = {
	id: string;
	status: Types.TicketStatusEnum;
	priority: Types.TicketPriorityEnum;
	assignedTo: {id: string; name: string};
};

/**
 * __useGetTicketByIdForSidebarQuery__
 *
 * To run a query within a React component, call `useGetTicketByIdForSidebarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketByIdForSidebarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketByIdForSidebarQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTicketByIdForSidebarQuery(
	baseOptions: Apollo.QueryHookOptions<GetTicketByIdForSidebarQuery, GetTicketByIdForSidebarQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetTicketByIdForSidebarQuery, GetTicketByIdForSidebarQueryVariables>(Operations, options);
}
export function useGetTicketByIdForSidebarLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetTicketByIdForSidebarQuery, GetTicketByIdForSidebarQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetTicketByIdForSidebarQuery, GetTicketByIdForSidebarQueryVariables>(Operations, options);
}
export type GetTicketByIdForSidebarQueryHookResult = ReturnType<typeof useGetTicketByIdForSidebarQuery>;
export type GetTicketByIdForSidebarLazyQueryHookResult = ReturnType<typeof useGetTicketByIdForSidebarLazyQuery>;
export type GetTicketByIdForSidebarQueryResult = Apollo.QueryResult<GetTicketByIdForSidebarQuery, GetTicketByIdForSidebarQueryVariables>;
