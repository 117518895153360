import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './pinCommunicationFeedItem.graphql';

const defaultOptions = {} as const;
export type PinCommunicationFeedItemMutationVariables = Types.Exact<{
	id: Types.Scalars['String'];
	version: Types.Scalars['Int'];
}>;

export type PinCommunicationFeedItemMutation = {pinCommunicationFeedItem: boolean};

export type PinCommunicationFeedItemType = boolean;

export type PinCommunicationFeedItemMutationFn = Apollo.MutationFunction<PinCommunicationFeedItemMutation, PinCommunicationFeedItemMutationVariables>;

/**
 * __usePinCommunicationFeedItemMutation__
 *
 * To run a mutation, you first call `usePinCommunicationFeedItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePinCommunicationFeedItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pinCommunicationFeedItemMutation, { data, loading, error }] = usePinCommunicationFeedItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      version: // value for 'version'
 *   },
 * });
 */
export function usePinCommunicationFeedItemMutation(
	baseOptions?: Apollo.MutationHookOptions<PinCommunicationFeedItemMutation, PinCommunicationFeedItemMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<PinCommunicationFeedItemMutation, PinCommunicationFeedItemMutationVariables>(Operations, options);
}
export type PinCommunicationFeedItemMutationHookResult = ReturnType<typeof usePinCommunicationFeedItemMutation>;
export type PinCommunicationFeedItemMutationResult = Apollo.MutationResult<PinCommunicationFeedItemMutation>;
export type PinCommunicationFeedItemMutationOptions = Apollo.BaseMutationOptions<
	PinCommunicationFeedItemMutation,
	PinCommunicationFeedItemMutationVariables
>;
