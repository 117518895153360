import * as Apollo from '@apollo/client';

import type {LocalDate} from '@js-joda/core';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getAuthorizedRepresentativeViewAuthorizedRepresentative.graphql';

const defaultOptions = {} as const;
export type GetAuthorizedRepresentativeViewAuthorizedRepresentativeQueryVariables = Types.Exact<{
	id: Types.Scalars['String'];
}>;

export type GetAuthorizedRepresentativeViewAuthorizedRepresentativeQuery = {
	getAuthorizedRepresentativeById: {
		id: string;
		version: number;
		contact: {
			id: string;
			version: number;
			givenName: string;
			surname: string;
			preferredName?: string | null;
			primaryPhone?: string | null;
			secondaryPhone?: string | null;
			sin?: string | null;
			dateOfBirth?: LocalDate | null;
			primaryEmail?: string | null;
			secondaryEmail?: string | null;
			physicalAddress?: {
				id: string;
				version: number;
				lineOne?: string | null;
				lineTwo?: string | null;
				city?: string | null;
				postalCode?: string | null;
				province?: string | null;
				country?: string | null;
			} | null;
			mailingAddress?: {
				id: string;
				version: number;
				lineOne?: string | null;
				lineTwo?: string | null;
				city?: string | null;
				postalCode?: string | null;
				province?: string | null;
				country?: string | null;
			} | null;
			authorizedUser?: {
				id: string;
				version: number;
				name: string;
				email: string;
				service?: {id: string} | null;
				devices?: Array<{id: string; registeredDate: Date; model: string; manufacturer: string}> | null;
			} | null;
		};
	};
};

export type GetAuthorizedRepresentativeViewAuthorizedRepresentativeType = {
	id: string;
	version: number;
	contact: {
		id: string;
		version: number;
		givenName: string;
		surname: string;
		preferredName?: string | null;
		primaryPhone?: string | null;
		secondaryPhone?: string | null;
		sin?: string | null;
		dateOfBirth?: LocalDate | null;
		primaryEmail?: string | null;
		secondaryEmail?: string | null;
		physicalAddress?: {
			id: string;
			version: number;
			lineOne?: string | null;
			lineTwo?: string | null;
			city?: string | null;
			postalCode?: string | null;
			province?: string | null;
			country?: string | null;
		} | null;
		mailingAddress?: {
			id: string;
			version: number;
			lineOne?: string | null;
			lineTwo?: string | null;
			city?: string | null;
			postalCode?: string | null;
			province?: string | null;
			country?: string | null;
		} | null;
		authorizedUser?: {
			id: string;
			version: number;
			name: string;
			email: string;
			service?: {id: string} | null;
			devices?: Array<{id: string; registeredDate: Date; model: string; manufacturer: string}> | null;
		} | null;
	};
};

/**
 * __useGetAuthorizedRepresentativeViewAuthorizedRepresentativeQuery__
 *
 * To run a query within a React component, call `useGetAuthorizedRepresentativeViewAuthorizedRepresentativeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthorizedRepresentativeViewAuthorizedRepresentativeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthorizedRepresentativeViewAuthorizedRepresentativeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAuthorizedRepresentativeViewAuthorizedRepresentativeQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetAuthorizedRepresentativeViewAuthorizedRepresentativeQuery,
		GetAuthorizedRepresentativeViewAuthorizedRepresentativeQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<
		GetAuthorizedRepresentativeViewAuthorizedRepresentativeQuery,
		GetAuthorizedRepresentativeViewAuthorizedRepresentativeQueryVariables
	>(Operations, options);
}
export function useGetAuthorizedRepresentativeViewAuthorizedRepresentativeLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetAuthorizedRepresentativeViewAuthorizedRepresentativeQuery,
		GetAuthorizedRepresentativeViewAuthorizedRepresentativeQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<
		GetAuthorizedRepresentativeViewAuthorizedRepresentativeQuery,
		GetAuthorizedRepresentativeViewAuthorizedRepresentativeQueryVariables
	>(Operations, options);
}
export type GetAuthorizedRepresentativeViewAuthorizedRepresentativeQueryHookResult = ReturnType<
	typeof useGetAuthorizedRepresentativeViewAuthorizedRepresentativeQuery
>;
export type GetAuthorizedRepresentativeViewAuthorizedRepresentativeLazyQueryHookResult = ReturnType<
	typeof useGetAuthorizedRepresentativeViewAuthorizedRepresentativeLazyQuery
>;
export type GetAuthorizedRepresentativeViewAuthorizedRepresentativeQueryResult = Apollo.QueryResult<
	GetAuthorizedRepresentativeViewAuthorizedRepresentativeQuery,
	GetAuthorizedRepresentativeViewAuthorizedRepresentativeQueryVariables
>;
