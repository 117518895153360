import {MaskedInput, MaskedInputProps} from '@thx/controls';
import debug from 'debug';
import type {InputProps} from 'semantic-ui-react';

const d = debug('tacs.web.common.components.BusinessNumberInput');

export function BusinessNumberInput(props: Omit<MaskedInputProps, 'mask'> & Omit<InputProps, 'onChange'>) {
	const mask = {
		mask: '999 999 999',
		greedy: false,
		autoUnmask: true,
	};

	return <MaskedInput {...props} mask={mask} />;
}
