import debug from 'debug';
import {useHistory, useParams} from 'react-router-dom';
import {Button, List} from 'semantic-ui-react';
import {routes} from '../../routes';

const d = debug('tacs.web.accounting.components.payStatement.PayStatementTableSidebar');

export function PayStatementTableSidebar() {
	const {push} = useHistory();
	const {accountInfoId} = useParams<typeof routes.types.payStatements>();

	return (
		<List>
			<List.Item>
				<Button positive fluid onClick={() => push(routes.to.addPayStatement({accountInfoId}))}>
					Add Pay Statement
				</Button>
			</List.Item>
		</List>
	);
}
