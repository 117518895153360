import {defineRoutes} from '@imperium/router';

export const routes = defineRoutes({
	account: {
		path: '/account/:accountInfoId',
		params: ['accountInfoId'] as const,
	},
	accountStatus: {
		path: '/account/:accountInfoId/account-status',
		params: ['accountInfoId'] as const,
	},
	createAccount: {
		path: '/register/create-account/',
		exact: true,
	},
	createAccountFromLead: {
		path: '/register/create-account/:leadId',
		params: ['leadId'] as const,
	},
	timeline: {
		path: '/account/:accountInfoId/settings/timeline',
		params: ['accountInfoId'] as const,
	},
	employees: {
		path: '/account/:accountInfoId/contacts/employees',
		params: ['accountInfoId'] as const,
	},
	createEmployee: {
		path: '/account/:accountInfoId/contacts/employee/create',
		params: ['accountInfoId'] as const,
	},
	viewEmployee: {
		path: '/account/:accountInfoId/contacts/employee/:employeeId/view',
		params: ['accountInfoId', 'employeeId'] as const,
	},
	editEmployee: {
		path: '/account/:accountInfoId/contacts/employee/:employeeId/edit',
		params: ['accountInfoId', 'employeeId'] as const,
	},
	partners: {
		path: '/account/:accountInfoId/contacts/partners',
		params: ['accountInfoId'] as const,
	},
	createPartner: {
		path: '/account/:accountInfoId/contacts/partner/create',
		params: ['accountInfoId'] as const,
	},
	viewPartner: {
		path: '/account/:accountInfoId/contacts/partner/:partnerId/view',
		params: ['accountInfoId', 'partnerId'] as const,
	},
	editPartner: {
		path: '/account/:accountInfoId/contacts/partner/:partnerId/edit',
		params: ['accountInfoId', 'partnerId'] as const,
	},
	directors: {
		path: '/account/:accountInfoId/contacts/directors',
		params: ['accountInfoId'] as const,
	},
	createDirector: {
		path: '/account/:accountInfoId/contacts/director/create',
		params: ['accountInfoId'] as const,
	},
	viewDirector: {
		path: '/account/:accountInfoId/contacts/director/:directorId/view',
		params: ['accountInfoId', 'directorId'] as const,
	},
	editDirector: {
		path: '/account/:accountInfoId/contacts/director/:directorId/edit',
		params: ['accountInfoId', 'directorId'] as const,
	},
	authorizedRepresentatives: {
		path: '/account/:accountInfoId/contacts/account-owners',
		params: ['accountInfoId'] as const,
	},
	createAuthorizedRepresentative: {
		path: '/account/:accountInfoId/contacts/account-owner/create',
		params: ['accountInfoId'] as const,
	},
	viewAuthorizedRepresentative: {
		path: '/account/:accountInfoId/contacts/account-owner/:authorizedRepresentativeId/view',
		params: ['accountInfoId', 'authorizedRepresentativeId'] as const,
	},
	editAuthorizedRepresentative: {
		path: '/account/:accountInfoId/contacts/account-owner/:authorizedRepresentativeId/edit',
		params: ['accountInfoId', 'authorizedRepresentativeId'] as const,
	},
	managerAccountsTransfer: {
		path: '/management/transfer-accountTransfer-accounts',
		exact: true,
	},
	agentAccountsTransfer: {
		path: '/management/transfer-agents-accounts',
		exact: true,
	},
	createLead: {
		path: '/register/lead/',
		exact: true,
	},
	leads: {
		path: '/management/leads',
		exact: true,
	},
	viewLead: {
		path: '/management/lead/:leadId/view',
		params: ['leadId'] as const,
	},
	leadReminder: {
		path: '/management/lead/:leadId/reminder',
		params: ['leadId'] as const,
	},
	editLead: {
		path: '/management/lead/:leadId/edit',
		params: ['leadId'] as const,
	},
	unmanagedAccounts: {
		path: '/admin/unmanaged-accounts',
		exact: true,
	},
});
