import type {SemanticCOLORS, SemanticICONS} from 'semantic-ui-react';
import {TicketPriorityEnum} from '~core/graphql';

interface IconProps {
	color: SemanticCOLORS;
	icon: SemanticICONS;
}
export function ticketPriorityIcon(priorityEnum?: TicketPriorityEnum): IconProps {
	switch (priorityEnum) {
		case TicketPriorityEnum.Low:
			return {color: 'teal', icon: 'circle'};
		case TicketPriorityEnum.Normal:
			return {color: 'green', icon: 'circle'};
		case TicketPriorityEnum.High:
			return {color: 'orange', icon: 'circle'};
		case TicketPriorityEnum.Emergency:
			return {color: 'red', icon: 'circle'};
		default:
			return {color: 'grey', icon: 'circle'};
	}
}
