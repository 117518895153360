import {createPages} from '@imperium/layout';
import loadable from '@loadable/component';
import {DocumentSortingClassAddForm} from './components/DocumentSortingClassAddForm';
import {DocumentSortingClassEditForm} from './components/DocumentSortingClassEditForm/DocumentSortingClassEditForm';
import {DocumentTypeAddForm} from './components/DocumentTypeAddForm';
import {DocumentTypeEditForm} from './components/DocumentTypeEditForm';
import {IndexerSidebar} from './components/IndexerSidebar';
import {SpacesCleanupSidebar} from './components/SpacesCleanupSidebar';
import {routes} from './routes';

const ProcessorQueueTable = loadable(() => import('./components/ProcessorQueueTable'), {
	resolveComponent: c => c.ProcessorQueueTable,
});
const ProcessorErrorLogTable = loadable(() => import('./components/ProcessorErrorLogTable'), {
	resolveComponent: c => c.ProcessorErrorLogTable,
});
const TaxCalculatorTool = loadable(() => import('./components/TaxCalculatorTool'), {
	resolveComponent: c => c.TaxCalculatorTool,
});
const ErrorTraceDecode = loadable(() => import('./components/ErrorTraceDecode'), {
	resolveComponent: c => c.ErrorTraceDecode,
});
const DocumentTypesTable = loadable(() => import('./components/DocumentTypesTable'), {
	resolveComponent: c => c.DocumentTypesTable,
});
const DocumentSortingClassTable = loadable(() => import('./components/DocumentSortingClassTable'), {
	resolveComponent: c => c.DocumentSortingClassTable,
});
const IndexStatistics = loadable(() => import('./components/IndexStatistics'), {
	resolveComponent: c => c.IndexStatistics,
});
const SpacesCleanupTable = loadable(() => import('./components/SpacesCleanupTable'), {
	resolveComponent: c => c.SpacesCleanupTable,
});
const AccountEmails = loadable(() => import('./components/AccountEmails'), {
	resolveComponent: c => c.AccountEmails,
});
const AnnouncementsTable = loadable(() => import('./components/AnnouncementsTable'), {
	resolveComponent: c => c.AnnouncementsTable,
});
const CreateAnnouncement = loadable(() => import('./components/CreateAnnouncement'), {
	resolveComponent: c => c.CreateAnnouncement,
});
const PodcastsTable = loadable(() => import('./components/PodcastTable'), {
	resolveComponent: c => c.PodcastTable,
});
const PodcastAddForm = loadable(() => import('./components/PodcastAddForm'), {
	resolveComponent: c => c.PodcastAddForm,
});
const PodcastEditForm = loadable(() => import('./components/PodcastEditForm'), {
	resolveComponent: c => c.PodcastEditForm,
});

const FuelPrice = loadable(() => import('./components/FuelPrice'));

export const routeProps = createPages(routes, {
	taxCalculator: {
		header: 'Tax Calculator',
		content: () => <TaxCalculatorTool />,
	},
	documentTypes: {
		header: 'Document Types',
		content: () => <DocumentTypesTable />,
		sidebar: [
			{
				text: 'Add Document Type',
				color: 'green',
				to: routes.to.documentTypeAdd,
			},
		],
	},
	documentTypeAdd: {
		header: 'Add Document Type',
		content: () => <DocumentTypeAddForm />,
	},
	documentSortingClasses: {
		header: 'Document Sorting Classes',
		content: () => <DocumentSortingClassTable />,
		sidebar: [
			{
				text: 'Add Document Sorting Classes',
				color: 'green',
				to: routes.to.documentSortingClassAdd,
			},
		],
	},
	documentTypeEdit: {
		content: () => <DocumentTypeEditForm />,
		header: 'Edit Document Type',
	},
	documentSortingClassEdit: {
		content: () => <DocumentSortingClassEditForm />,
		header: 'Edit Document Sorting Class',
	},
	documentSortingClassAdd: {
		content: () => <DocumentSortingClassAddForm />,
		header: 'Create Document Sorting Class',
	},
	indexer: {
		header: 'Indexer',
		content: () => <IndexStatistics />,
		sidebar: [
			{
				render: IndexerSidebar,
			},
		],
	},
	processorQueue: {
		header: 'Processor Queue',
		content: () => <ProcessorQueueTable />,
	},
	processorErrorLog: {
		header: 'Processor Error Log',
		content: () => <ProcessorErrorLogTable />,
	},
	spacesCleanup: {
		header: 'Spaces Cleanup',
		content: () => <SpacesCleanupTable />,
		sidebar: [
			{
				render: SpacesCleanupSidebar,
			},
		],
	},
	announcements: {
		header: 'Announcements',
		content: () => <AnnouncementsTable />,
		sidebar: [
			{
				text: 'Create Announcement',
				to: routes.to.createAnnouncement(),
			},
		],
	},
	podcasts: {
		header: 'Podcasts',
		content: () => <PodcastsTable />,
		sidebar: [
			{
				to: () => routes.to.podcastAdd(),
				color: 'green',
				text: 'Add Podcast',
			},
		],
	},
	podcastAdd: {
		header: 'Add Podcast',
		content: () => <PodcastAddForm />,
	},
	podcastEdit: {
		header: 'Edit Podcast',
		content: () => <PodcastEditForm />,
	},
	createAnnouncement: {
		header: 'Create Announcement',
		content: () => <CreateAnnouncement />,
	},
	errorTraceDecode: {
		header: 'User Profile',
		content: () => <ErrorTraceDecode />,
	},
	accountEmails: {
		header: 'Account Emails',
		content: () => <AccountEmails />,
	},
	fuelPrice: {
		header: 'Fuel Price',
		content: () => <FuelPrice />,
	},
});
