import {createPages} from '@imperium/layout';
import loadable from '@loadable/component';
import {SearchResultsSidebar} from './components/SearchResultsSidebar';
import {routes} from './routes';
import {useSearchState} from './state';

const SearchResults = loadable(() => import('./components/SearchResults'));

export const routeProps = createPages(routes, {
	searchResultsPage: {
		stateSelectorHook: useSearchState,
		header: 'Search',
		content: () => <SearchResults />,
		sidebar: [{render: SearchResultsSidebar}],
	},
});
