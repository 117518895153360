import {useAuthenticatedState} from '@imperium/auth-client';
import {useParams} from 'react-router-dom';
import {List, ListItem} from 'semantic-ui-react';
import {TicketStatusEnum} from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import type {routes} from '../../routes';
import {ChangeStatusButton} from '../ChangeStatusButton';
import {ReassignButton} from '../ReassignButton';
import {SetPriorityButton} from '../SetPriorityButton';
import {useGetTicketByIdForSidebarQuery} from './getTicketByIdForSidebar';

export function TicketSidebar() {
	const {ticketId} = useParams<typeof routes.types.viewTicketDetails>();
	const {id} = useAuthenticatedState();
	if (!id) throw new Error('user not logged in!');

	const throwError = useAsyncError();

	const {loading, error, data} = useGetTicketByIdForSidebarQuery({variables: {id: ticketId}});

	if (error) throwError(error);

	if (data)
		return (
			<List>
				<ListItem>
					<ChangeStatusButton ticketId={ticketId} userId={id} ticketStatus={data.getTicketById.status} />
				</ListItem>
				{data.getTicketById.status === TicketStatusEnum.Open && (
					<>
						<ListItem>
							<SetPriorityButton ticketId={ticketId} ticketPriority={data.getTicketById.priority} />
						</ListItem>
						<ListItem>
							<ReassignButton ticketId={ticketId} assignedTo={data.getTicketById.assignedTo.id} />
						</ListItem>
					</>
				)}
			</List>
		);
	return null;
}
