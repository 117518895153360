import * as Apollo from '@apollo/client';

import type {LocalDate} from '@js-joda/core';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getEndEmploymentEmployee.graphql';

const defaultOptions = {} as const;
export type GetEndEmploymentEmployeeQueryVariables = Types.Exact<{
	employeeId: Types.Scalars['String'];
}>;

export type GetEndEmploymentEmployeeQuery = {
	getEmployeeById: {
		id: string;
		version: number;
		endDate?: LocalDate | null;
		roeConfirmationNumber?: string | null;
		contact: {id: string; version: number; accountInfo: {id: string; version: number}};
	};
};

export type GetEndEmploymentEmployeeType = {
	id: string;
	version: number;
	endDate?: LocalDate | null;
	roeConfirmationNumber?: string | null;
	contact: {id: string; version: number; accountInfo: {id: string; version: number}};
};

/**
 * __useGetEndEmploymentEmployeeQuery__
 *
 * To run a query within a React component, call `useGetEndEmploymentEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEndEmploymentEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEndEmploymentEmployeeQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useGetEndEmploymentEmployeeQuery(
	baseOptions: Apollo.QueryHookOptions<GetEndEmploymentEmployeeQuery, GetEndEmploymentEmployeeQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetEndEmploymentEmployeeQuery, GetEndEmploymentEmployeeQueryVariables>(Operations, options);
}
export function useGetEndEmploymentEmployeeLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetEndEmploymentEmployeeQuery, GetEndEmploymentEmployeeQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetEndEmploymentEmployeeQuery, GetEndEmploymentEmployeeQueryVariables>(Operations, options);
}
export type GetEndEmploymentEmployeeQueryHookResult = ReturnType<typeof useGetEndEmploymentEmployeeQuery>;
export type GetEndEmploymentEmployeeLazyQueryHookResult = ReturnType<typeof useGetEndEmploymentEmployeeLazyQuery>;
export type GetEndEmploymentEmployeeQueryResult = Apollo.QueryResult<GetEndEmploymentEmployeeQuery, GetEndEmploymentEmployeeQueryVariables>;
