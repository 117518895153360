import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './createStatementForm.graphql';

const defaultOptions = {} as const;
export type CreateStatementFormMutationVariables = Types.Exact<{
	data: Types.CreateStatementInput;
}>;

export type CreateStatementFormMutation = {createStatement?: {id: string; version: number} | null};

export type CreateStatementFormType = {id: string; version: number};

export type CreateStatementFormMutationFn = Apollo.MutationFunction<CreateStatementFormMutation, CreateStatementFormMutationVariables>;

/**
 * __useCreateStatementFormMutation__
 *
 * To run a mutation, you first call `useCreateStatementFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStatementFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStatementFormMutation, { data, loading, error }] = useCreateStatementFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateStatementFormMutation(
	baseOptions?: Apollo.MutationHookOptions<CreateStatementFormMutation, CreateStatementFormMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<CreateStatementFormMutation, CreateStatementFormMutationVariables>(Operations, options);
}
export type CreateStatementFormMutationHookResult = ReturnType<typeof useCreateStatementFormMutation>;
export type CreateStatementFormMutationResult = Apollo.MutationResult<CreateStatementFormMutation>;
export type CreateStatementFormMutationOptions = Apollo.BaseMutationOptions<CreateStatementFormMutation, CreateStatementFormMutationVariables>;
