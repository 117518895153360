import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './editAccountName.graphql';

const defaultOptions = {} as const;
export type EditAccountNameMutationVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
	name: Types.Scalars['String'];
}>;

export type EditAccountNameMutation = {editAccountInfoName?: {id: string; version: number; name: string} | null};

export type EditAccountNameType = {id: string; version: number; name: string};

export type EditAccountNameMutationFn = Apollo.MutationFunction<EditAccountNameMutation, EditAccountNameMutationVariables>;

/**
 * __useEditAccountNameMutation__
 *
 * To run a mutation, you first call `useEditAccountNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAccountNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAccountNameMutation, { data, loading, error }] = useEditAccountNameMutation({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useEditAccountNameMutation(baseOptions?: Apollo.MutationHookOptions<EditAccountNameMutation, EditAccountNameMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<EditAccountNameMutation, EditAccountNameMutationVariables>(Operations, options);
}
export type EditAccountNameMutationHookResult = ReturnType<typeof useEditAccountNameMutation>;
export type EditAccountNameMutationResult = Apollo.MutationResult<EditAccountNameMutation>;
export type EditAccountNameMutationOptions = Apollo.BaseMutationOptions<EditAccountNameMutation, EditAccountNameMutationVariables>;
