import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getUserByIdEdit.graphql';

const defaultOptions = {} as const;
export type GetUserByIdEditQueryVariables = Types.Exact<{
	userId: Types.Scalars['String'];
}>;

export type GetUserByIdEditQuery = {getUserById?: {id: string; version: number; name: string; email: string; phone?: string | null} | null};

export type GetUserByIdEditType = {id: string; version: number; name: string; email: string; phone?: string | null};

/**
 * __useGetUserByIdEditQuery__
 *
 * To run a query within a React component, call `useGetUserByIdEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByIdEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByIdEditQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserByIdEditQuery(baseOptions: Apollo.QueryHookOptions<GetUserByIdEditQuery, GetUserByIdEditQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetUserByIdEditQuery, GetUserByIdEditQueryVariables>(Operations, options);
}
export function useGetUserByIdEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserByIdEditQuery, GetUserByIdEditQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetUserByIdEditQuery, GetUserByIdEditQueryVariables>(Operations, options);
}
export type GetUserByIdEditQueryHookResult = ReturnType<typeof useGetUserByIdEditQuery>;
export type GetUserByIdEditLazyQueryHookResult = ReturnType<typeof useGetUserByIdEditLazyQuery>;
export type GetUserByIdEditQueryResult = Apollo.QueryResult<GetUserByIdEditQuery, GetUserByIdEditQueryVariables>;
