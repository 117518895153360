import {toDate, toLocalDate} from '@thx/date';
import DatePicker from 'react-datepicker';
import {useDispatch} from 'react-redux';
import styles from '~common/components/DateRangeSelector/tabs/style.module.css';
import {setDate, useCommonState} from '~common/state';

export function FullMonthTab({hidePanel}: {hidePanel: () => void}) {
	const dispatch = useDispatch();
	const {startDate, endDate} = useCommonState();

	return (
		<div style={{minWidth: '230px'}} className={styles.tab}>
			<DatePicker
				showMonthYearPicker
				selected={toDate(startDate)}
				inline
				maxDate={new Date()}
				onChange={v => {
					if (v) {
						dispatch(setDate({start: toLocalDate(v), end: toLocalDate(v)}));
						hidePanel();
					}
				}}
			/>
		</div>
	);
}
