import debug from 'debug';
import {Dispatch, SetStateAction, type SyntheticEvent, useState} from 'react';
import {Button, Dropdown, DropdownProps, Menu, Popup} from 'semantic-ui-react';
import {useGetVendorDropdownQuery} from './getVendorDropdown';

const d = debug('tacs.web.documents.components.documents.VendorDropdown.VendorDropdownField');

interface VendorDropdownFieldProps extends Omit<DropdownProps, 'onChange'> {
	addNewVendorButton?: boolean;
	accountInfoId: string;
	includeGlobalVendors?: boolean;
	setNewVendorName?: Dispatch<SetStateAction<string>>;
	newVendorName?: string;
	setAddNewVendor?: Dispatch<SetStateAction<boolean>>;
}

export function VendorDropdownField(props: VendorDropdownFieldProps) {
	const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
	const {
		onChange,
		disabled,
		loading: propsLoading,
		newVendorName,
		setAddNewVendor,
		setNewVendorName,
		addNewVendorButton,
		fluid,
		accountInfoId,
		...rest
	} = props;
	const {data, loading} = useGetVendorDropdownQuery({
		variables: {accountInfoId, archived: false, includeGlobal: props.includeGlobalVendors ?? true},
	});

	const options = data?.getVendorsByAccountInfo?.reduce((acc, docType) => {
		if (docType?.name) {
			acc.push({key: docType.id, value: docType.id, text: docType.name});
		}
		return acc;
	}, [] as {key: string; value: string; text: string}[]);

	function handleChange(e: SyntheticEvent<HTMLElement, Event>, val: DropdownProps) {
		if (val.value) {
			onChange && onChange(val.value.toString());
		} else {
			onChange && onChange();
		}
	}

	return (
		<Menu style={{marginTop: 0}} fluid={fluid} size={props.size}>
			<Dropdown
				{...rest}
				selection
				search
				onSearchChange={(_e, dat) => {
					setSearchQuery(dat.searchQuery);
					setNewVendorName && setNewVendorName(dat.searchQuery);
				}}
				noResultsMessage={addNewVendorButton ? 'Click ➕ to add this Vendor' : 'No Results found...'}
				options={options || []}
				disabled={disabled}
				loading={propsLoading || loading}
				onChange={handleChange}
				clearable
				fluid
			/>
			{addNewVendorButton && (
				<Popup
					content="Add new vendor"
					on="hover"
					trigger={
						<Button
							disabled={!searchQuery}
							type="submit"
							tabIndex={-1}
							icon="plus"
							position="right"
							attached="right"
							basic
							onClick={() => setAddNewVendor && setAddNewVendor(true)}
						/>
					}
				/>
			)}
		</Menu>
	);
}
