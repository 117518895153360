import {MaskedInput, TForm} from '@thx/controls';
import debug from 'debug';
import {useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Button, Divider, Form, FormInput, List} from 'semantic-ui-react';
import {number, object} from 'yup';
import {Loading} from '~common/components';
import {getName} from '~lib/getName';
import {useAsyncError} from '~lib/useAsyncError';
import {routes} from '../../../routes';
import {useCreatePartnerMutation} from '../PartnerCreateForm/createPartner';
import {useGetPartnerAddSidebarExistingContactListDataQuery} from './getPartnerAddSidebarExistingContactListData';

const d = debug('tacs.web.accounts.components.partners.PartnerAddSidebarExistingContactList');

const partnerAddSidebarContactListValidation = object().shape({
	ownershipPercentage: number().required('The partner ownership percentage is required.'),
});

interface PartnerAddSidebarContactListValidationSchemaType {
	ownershipPercentage: string;
}

export function PartnerAddSidebarExistingContactList() {
	const throwError = useAsyncError();
	const {push} = useHistory();
	const [selectedContactId, setSelectedContactId] = useState('');
	const {accountInfoId} = useParams<typeof routes.types.createPartner>();
	const {data, loading, error} = useGetPartnerAddSidebarExistingContactListDataQuery({variables: {accountInfoId}});

	const [createPartnerMutation, {loading: mutationLoading}] = useCreatePartnerMutation();

	if (loading) return <Loading />;
	if (error) throw error;
	if (!data) throw new Error("The data couldn't be loaded");

	const contacts = data.getSelfEmployedAccountByAccountInfoId?.accountInfo.contacts?.filter(contact => {
		return (
			// filter out the partners
			!data.getSelfEmployedAccountByAccountInfoId?.partners?.find(part => part?.contact?.id === contact?.id) &&
			// filter out the employees
			!data.getSelfEmployedAccountByAccountInfoId?.accountInfo.employees?.find(emp => emp?.contact?.id === contact?.id)
		);
	});

	const onCreatePartnerClick = (contactId: string, ownershipPercentage: string) => {
		createPartnerMutation({
			variables: {
				data: {
					contactId,
					accountInfoId,
					partner: {
						ownershipPercentage: parseInt(ownershipPercentage, 10),
					},
				},
			},
		})
			.then(() => push(routes.to.partners({accountInfoId})))
			.catch(throwError);
	};

	return (
		<List>
			<List.Header as="h3">Choose From Existing Contacts</List.Header>
			{contacts?.length
				? contacts.map(contact => {
						return (
							<List.Item key={contact.id}>
								{selectedContactId === contact.id ? (
									<TForm<PartnerAddSidebarContactListValidationSchemaType>
										initialValues={{}}
										onSubmit={vals => onCreatePartnerClick(contact.id, vals.ownershipPercentage)}
										validationSchema={partnerAddSidebarContactListValidation}
									>
										{tFormProps => {
											const {setFieldValue, handleSubmit, values, hasErrors, hasWarnings, renderWarnings, fieldError} = tFormProps;
											return (
												<Form onSubmit={handleSubmit} error={hasErrors} warning={hasWarnings}>
													<Divider />
													<List>
														<List.Item>
															<FormInput label="Partner Ownership Percentage" required error={fieldError('partner.ownershipPercentage')}>
																<MaskedInput
																	mask={{mask: '99[9]', autoUnmask: true, numericInput: true}}
																	onChange={(val: string) => setFieldValue('ownershipPercentage', parseInt(val, 10))}
																	value={values.ownershipPercentage || ''}
																/>
															</FormInput>
														</List.Item>
														<List.Item>{renderWarnings()}</List.Item>
														<List.Item>
															<Button disabled={mutationLoading} floated="right" positive type="submit">
																Save
															</Button>
															<Button type="button" floated="right" onClick={() => setSelectedContactId('')}>
																Cancel
															</Button>
														</List.Item>
													</List>
													<Divider />
												</Form>
											);
										}}
									</TForm>
								) : (
									<Button fluid color="blue" onClick={() => setSelectedContactId(contact.id)}>
										Add {getName(contact)} as a Partner
									</Button>
								)}
							</List.Item>
						);
				  })
				: 'none available...'}
		</List>
	);
}
