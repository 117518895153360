import type {LayoutData} from '@imperium/layout';
import {debug} from 'debug';
import {Permission} from '~core/graphql';
import {isLoggedIn, needsPermission, needsRoute} from '~lib/permissionHelpers';
import {Development} from './components/Development';
import {routes} from './routes';

const d = debug('tacs.web.tools.layout');

export const layout: LayoutData = {
	permissions: [Permission.Sysadmin, Permission.MarketingTools],
	primaryMenu: [
		{
			visible: {...isLoggedIn()},
			text: 'Tools',
			to: routes.to.taxCalculator(),
			weight: 10,
		},
	],
	statusbar: [
		{
			visible: {...isLoggedIn()},
			render: () => <Development />,
			position: 'left',
			weight: 99,
		},
	],
	secondaryMenu: [
		{
			text: 'Tax Calculator',
			visible: {...needsRoute('tools')},
			to: routes.to.taxCalculator(),
		},
		{
			visible: {...needsRoute('tools')},
			text: 'Fuel Price Calculator',
			to: routes.to.fuelPrice(),
		},
		{
			text: 'Account Emails',
			visible: {...needsPermission([Permission.Sysadmin, Permission.MarketingTools]), ...needsRoute('tools')},
			to: routes.to.accountEmails(),
		},
		{
			text: 'Document Types',
			visible: {...needsPermission(Permission.Sysadmin), ...needsRoute('tools')},
			to: routes.to.documentTypes(),
		},
		{
			text: 'Document Sorting Classes',
			visible: {...needsPermission(Permission.Sysadmin), ...needsRoute('tools')},
			to: routes.to.documentSortingClasses(),
		},
		{
			text: 'Indexer',
			visible: {...needsPermission(Permission.Sysadmin), ...needsRoute('tools')},
			to: routes.to.indexer(),
		},
		{
			text: 'Processor Queue',
			visible: {...needsPermission(Permission.Sysadmin), ...needsRoute('tools')},
			to: routes.to.processorQueue(),
		},
		{
			text: 'Processor Error Log',
			visible: {...needsPermission(Permission.Sysadmin), ...needsRoute('tools')},
			to: routes.to.processorErrorLog(),
		},
		{
			text: 'Announcements',
			visible: {...needsPermission(Permission.Sysadmin), ...needsPermission(Permission.MarketingTools), ...needsRoute('admin')},
			to: routes.to.announcements(),
		},
		{
			text: 'Podcasts',
			visible: {...needsPermission(Permission.Sysadmin), ...needsPermission(Permission.MarketingTools), ...needsRoute('admin')},
			to: routes.to.podcasts(),
		},
		{
			text: 'Spaces Cleanup',
			visible: {...needsPermission(Permission.Sysadmin), ...needsRoute('tools')},
			to: routes.to.spacesCleanup(),
		},
		{
			text: 'Error Trace Decode',
			visible: {...needsPermission(Permission.Sysadmin), ...needsRoute('tools')},
			to: routes.to.errorTraceDecode(),
		},
	],
};
