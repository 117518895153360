import type {LayoutData} from '@imperium/layout';
import {debug} from 'debug';
import {DateRangeSelector} from '~common/components/DateRangeSelector';
import {useDateRangeSelectionSwitcher} from '~common/hooks/dateRangeSelection/useDateRangeSelectionSwitcher';
import {Permission} from '~core/graphql';
import {needsRoute} from '~lib/permissionHelpers';

const d = debug('tacs.web.common.layout');

export const layout: LayoutData = {
	permissions: [Permission.ReadAllAccount],
	dataHooks: [useDateRangeSelectionSwitcher],
	statusbar: [
		{
			visible: {...needsRoute('account')},
			render: () => <DateRangeSelector />,
			position: 'right',
		},
	],
};
