import debug from 'debug';
import {Dropdown, DropdownProps} from 'semantic-ui-react';
import {useGetGstFilingFrequencyDropdownFilingFrequenciesQuery} from './getGstFilingFrequencyDropdownFilingFrequencies';

const d = debug('tacs.web.accounts.components.accounts.GstFilingFrequencyDropdown');

interface GSTFilingFrequencyDropdownProps extends Omit<DropdownProps, 'options' | 'onChange'> {
	onChange?: (val: string) => void;
	loading?: boolean;
}

export function GstFilingFrequencyDropdown(props: GSTFilingFrequencyDropdownProps) {
	const {data, loading, error} = useGetGstFilingFrequencyDropdownFilingFrequenciesQuery();
	const {onChange, loading: propLoading, ...rest} = props;

	function handleChange(val?: string) {
		if (val && onChange) onChange(val);
	}

	const options =
		data?.getGstFilingFrequencies?.map(type => ({
			key: type?.value,
			text: type?.text,
			value: type?.value,
		})) || [];

	return (
		<Dropdown
			{...rest}
			error={!!error}
			loading={propLoading || loading}
			options={options}
			onChange={(e, val) => handleChange(val.value?.toString())}
		/>
	);
}
