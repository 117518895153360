import {LocalDate} from '@js-joda/core';
import {LocalDatePicker} from '@thx/controls';
import debug from 'debug';
import {useDispatch} from 'react-redux';
import styles from '~common/components/DateRangeSelector/tabs/style.module.css';
import {setDate, useCommonState} from '~common/state';
import {DateRangeSelection} from '~common/types';

const d = debug('tacs.web.common.components.DateRangeSelector.tabs.CustomTab');

export function CustomTab({hidePanel}: {hidePanel: () => void}) {
	const dispatch = useDispatch();
	const {startDate, endDate, dateRangeSelection} = useCommonState();

	const startPart = (
		<div>
			<div>Start</div>
			<LocalDatePicker
				value={startDate}
				startSelected
				maxDate={LocalDate.now()}
				onChange={date => {
					if (date) {
						dispatch(setDate({start: date, end: endDate}));
						if (dateRangeSelection !== DateRangeSelection.DateRange && !date.isEqual(startDate)) {
							hidePanel();
						}
					}
				}}
			/>
		</div>
	);

	const endPart =
		dateRangeSelection === DateRangeSelection.DateRange ? (
			<div style={{marginTop: '1rem'}}>
				<div>End</div>
				<LocalDatePicker
					value={endDate}
					maxDate={LocalDate.now()}
					minDate={startDate}
					onChange={date => {
						if (date) {
							dispatch(setDate({start: startDate, end: date}));
							if (!date.isEqual(endDate)) {
								hidePanel();
							}
						}
					}}
				/>
			</div>
		) : null;

	return (
		<div className={styles.tab}>
			{startPart}
			{endPart}
		</div>
	);
}
