import debug from 'debug';
import type {SyntheticEvent} from 'react';
import {Dropdown, DropdownProps} from 'semantic-ui-react';
import {useGetDocumentTypeDropdownDocumentTypesQuery} from './getDocumentTypeDropdownDocumentTypes';

const d = debug('tacs.web.documents.components.documents.DocumentTypeDropdown');

interface DocumentTypeDropdownProps extends Omit<DropdownProps, 'onChange'> {
	onChange?: (val?: string) => void;
}

export function DocumentTypeDropdown(props: DocumentTypeDropdownProps) {
	const {onChange, ...rest} = props;
	const {data, loading} = useGetDocumentTypeDropdownDocumentTypesQuery();

	const options = data?.getDocumentTypes?.reduce((acc, docType) => {
		if (docType?.name) {
			acc.push({key: docType.id, value: docType.id, text: docType.name});
		}
		return acc;
	}, [] as {key: string; value: string; text: string}[]);

	function handleChange(e: SyntheticEvent<HTMLElement, Event>, val: DropdownProps) {
		if (val.value) {
			onChange && onChange(val.value.toString());
		} else {
			onChange && onChange();
		}
	}

	return <Dropdown search {...rest} options={options || []} loading={loading} onChange={handleChange} clearable />;
}
