import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getEmployeesDropdownEmployees.graphql';

const defaultOptions = {} as const;
export type GetEmployeesDropdownEmployeesQueryVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
	filter: Types.EmployeeFilterInput;
}>;

export type GetEmployeesDropdownEmployeesQuery = {
	getEmployeesByAccountInfoId?: Array<{
		id: string;
		version: number;
		homeTerminal: Types.ProvinceEnum;
		contact: {id: string; version: number; surname: string; givenName: string; preferredName?: string | null; authorizedUser?: {id: string} | null};
	}> | null;
};

export type GetEmployeesDropdownEmployeesType = {
	id: string;
	version: number;
	homeTerminal: Types.ProvinceEnum;
	contact: {id: string; version: number; surname: string; givenName: string; preferredName?: string | null; authorizedUser?: {id: string} | null};
};

/**
 * __useGetEmployeesDropdownEmployeesQuery__
 *
 * To run a query within a React component, call `useGetEmployeesDropdownEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeesDropdownEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeesDropdownEmployeesQuery({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetEmployeesDropdownEmployeesQuery(
	baseOptions: Apollo.QueryHookOptions<GetEmployeesDropdownEmployeesQuery, GetEmployeesDropdownEmployeesQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetEmployeesDropdownEmployeesQuery, GetEmployeesDropdownEmployeesQueryVariables>(Operations, options);
}
export function useGetEmployeesDropdownEmployeesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeesDropdownEmployeesQuery, GetEmployeesDropdownEmployeesQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetEmployeesDropdownEmployeesQuery, GetEmployeesDropdownEmployeesQueryVariables>(Operations, options);
}
export type GetEmployeesDropdownEmployeesQueryHookResult = ReturnType<typeof useGetEmployeesDropdownEmployeesQuery>;
export type GetEmployeesDropdownEmployeesLazyQueryHookResult = ReturnType<typeof useGetEmployeesDropdownEmployeesLazyQuery>;
export type GetEmployeesDropdownEmployeesQueryResult = Apollo.QueryResult<
	GetEmployeesDropdownEmployeesQuery,
	GetEmployeesDropdownEmployeesQueryVariables
>;
