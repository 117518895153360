import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './recreateSearchIndex.graphql';

const defaultOptions = {} as const;
export type RecreateSearchIndexMutationVariables = Types.Exact<{[key: string]: never}>;

export type RecreateSearchIndexMutation = {runIndexer?: boolean | null};

export type RecreateSearchIndexType = boolean;

export type RecreateSearchIndexMutationFn = Apollo.MutationFunction<RecreateSearchIndexMutation, RecreateSearchIndexMutationVariables>;

/**
 * __useRecreateSearchIndexMutation__
 *
 * To run a mutation, you first call `useRecreateSearchIndexMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecreateSearchIndexMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recreateSearchIndexMutation, { data, loading, error }] = useRecreateSearchIndexMutation({
 *   variables: {
 *   },
 * });
 */
export function useRecreateSearchIndexMutation(
	baseOptions?: Apollo.MutationHookOptions<RecreateSearchIndexMutation, RecreateSearchIndexMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<RecreateSearchIndexMutation, RecreateSearchIndexMutationVariables>(Operations, options);
}
export type RecreateSearchIndexMutationHookResult = ReturnType<typeof useRecreateSearchIndexMutation>;
export type RecreateSearchIndexMutationResult = Apollo.MutationResult<RecreateSearchIndexMutation>;
export type RecreateSearchIndexMutationOptions = Apollo.BaseMutationOptions<RecreateSearchIndexMutation, RecreateSearchIndexMutationVariables>;
