import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getDocumentTypeForUpdate.graphql';

const defaultOptions = {} as const;
export type GetDocumentTypeForUpdateQueryVariables = Types.Exact<{
	documentTypeId: Types.Scalars['String'];
}>;

export type GetDocumentTypeForUpdateQuery = {
	getDocumentTypeById?: {
		id: string;
		version: number;
		amount: boolean;
		statement: boolean;
		name: string;
		archived: boolean;
		expected: boolean;
		group: Types.DocumentGroupEnum;
	} | null;
};

export type GetDocumentTypeForUpdateType = {
	id: string;
	version: number;
	amount: boolean;
	statement: boolean;
	name: string;
	archived: boolean;
	expected: boolean;
	group: Types.DocumentGroupEnum;
};

/**
 * __useGetDocumentTypeForUpdateQuery__
 *
 * To run a query within a React component, call `useGetDocumentTypeForUpdateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentTypeForUpdateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentTypeForUpdateQuery({
 *   variables: {
 *      documentTypeId: // value for 'documentTypeId'
 *   },
 * });
 */
export function useGetDocumentTypeForUpdateQuery(
	baseOptions: Apollo.QueryHookOptions<GetDocumentTypeForUpdateQuery, GetDocumentTypeForUpdateQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetDocumentTypeForUpdateQuery, GetDocumentTypeForUpdateQueryVariables>(Operations, options);
}
export function useGetDocumentTypeForUpdateLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentTypeForUpdateQuery, GetDocumentTypeForUpdateQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetDocumentTypeForUpdateQuery, GetDocumentTypeForUpdateQueryVariables>(Operations, options);
}
export type GetDocumentTypeForUpdateQueryHookResult = ReturnType<typeof useGetDocumentTypeForUpdateQuery>;
export type GetDocumentTypeForUpdateLazyQueryHookResult = ReturnType<typeof useGetDocumentTypeForUpdateLazyQuery>;
export type GetDocumentTypeForUpdateQueryResult = Apollo.QueryResult<GetDocumentTypeForUpdateQuery, GetDocumentTypeForUpdateQueryVariables>;
