import debug from 'debug';
import {Dropdown, DropdownProps} from 'semantic-ui-react';
import {employeeStatusFilterEnumLookup} from '~core/graphql';
import type {EmployeeStatusFilterEnum} from '~core/graphql';
import {getEmployeeStatus} from '~lib/getEmployeeStatus';

const d = debug('tacs.web.accounts.components.employees.EmployeeStatusDropdown');

interface EmployeeStatusDropdownProps extends Omit<DropdownProps, 'onChange' | 'value'> {
	onChange: (val: EmployeeStatusFilterEnum) => void;
	value?: EmployeeStatusFilterEnum;
}

export function EmployeeStatusDropdown(props: EmployeeStatusDropdownProps) {
	const {onChange, value, placeholder, ...rest} = props;

	function handleChange(val: string) {
		onChange && onChange(employeeStatusFilterEnumLookup(val));
	}

	return (
		<Dropdown
			{...rest}
			placeholder={placeholder || 'sort by status type...'}
			onChange={(c, v) => {
				if (v.value) {
					handleChange(v.value as string);
				}
			}}
			options={getEmployeeStatus()}
			value={value}
		/>
	);
}
