import type {CommunicationTypeEnum} from '~core/graphql';

export const icon = (type?: CommunicationTypeEnum) => {
	// Semantic ui icons - https://semantic-ui.com/elements/icon.html
	switch (type) {
		case 'Email':
			return 'mail';
		case 'Call':
			return 'call';
		case 'Note':
			return 'sticky note';
		default:
			return 'plus';
	}
};

export const description = (type?: CommunicationTypeEnum) => {
	switch (type) {
		case 'Email':
			return 'emailed';
		case 'Call':
			return 'called';
		case 'Note':
			return 'wrote';
		default:
			return 'is creating a new note';
	}
};
