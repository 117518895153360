import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {EmployeesDropdown} from '../../accounts/components/employees/EmployeesDropdown';
import type {routes} from '../routes';
import {setEmployeeId, useCalendarState} from '../state';

export function EmployeeDropdownSidebar() {
	const dispatch = useDispatch();
	const {accountInfoId} = useParams<typeof routes.types.calendar>();
	const {employeeId} = useCalendarState();

	return (
		<EmployeesDropdown
			selection
			fluid
			accountInfoId={accountInfoId}
			placeholder="All Employees"
			value={employeeId}
			onChange={employee => {
				if (employee) dispatch(setEmployeeId(employee));
				else dispatch(setEmployeeId('all'));
			}}
			defaultNull
			clearable
			active
		/>
	);
}
