import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './editAccountOffice.graphql';

const defaultOptions = {} as const;
export type EditAccountOfficeMutationVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
	officeId: Types.Scalars['String'];
}>;

export type EditAccountOfficeMutation = {editAccountInfoOffice?: {id: string; version: number; office?: {id: string} | null} | null};

export type EditAccountOfficeType = {id: string; version: number; office?: {id: string} | null};

export type EditAccountOfficeMutationFn = Apollo.MutationFunction<EditAccountOfficeMutation, EditAccountOfficeMutationVariables>;

/**
 * __useEditAccountOfficeMutation__
 *
 * To run a mutation, you first call `useEditAccountOfficeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAccountOfficeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAccountOfficeMutation, { data, loading, error }] = useEditAccountOfficeMutation({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *      officeId: // value for 'officeId'
 *   },
 * });
 */
export function useEditAccountOfficeMutation(
	baseOptions?: Apollo.MutationHookOptions<EditAccountOfficeMutation, EditAccountOfficeMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<EditAccountOfficeMutation, EditAccountOfficeMutationVariables>(Operations, options);
}
export type EditAccountOfficeMutationHookResult = ReturnType<typeof useEditAccountOfficeMutation>;
export type EditAccountOfficeMutationResult = Apollo.MutationResult<EditAccountOfficeMutation>;
export type EditAccountOfficeMutationOptions = Apollo.BaseMutationOptions<EditAccountOfficeMutation, EditAccountOfficeMutationVariables>;
