import {createSliceHook} from '@imperium/state';
/* eslint-disable no-param-reassign */
import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {TicketPriorityEnum, TicketStatusEnum} from '~core/graphql';

export const state = createSlice({
	name: 'support',
	initialState: {
		ticketStatusFilter: TicketStatusEnum.Open,
		ticketPriorityFilter: [TicketPriorityEnum.Normal, TicketPriorityEnum.Low, TicketPriorityEnum.High, TicketPriorityEnum.Emergency],
		ticketAssignedToFilter: null,
		ticketCategoryFilter: null,
	},
	reducers: {
		setTicketStatusFilter: (st, action: PayloadAction<TicketStatusEnum>) => {
			st.ticketStatusFilter = action.payload;
		},
		setTicketPriorityFilter: (st, action: PayloadAction<TicketPriorityEnum[]>) => {
			st.ticketPriorityFilter = action.payload;
		},
		setTicketAssignedToFilter: (st, action) => {
			st.ticketAssignedToFilter = action.payload;
		},
		setTicketCategoryFilter: (st, action) => {
			st.ticketCategoryFilter = action.payload;
		},
	},
});

export const useSupportState = createSliceHook(state);

export const {setTicketStatusFilter, setTicketPriorityFilter, setTicketAssignedToFilter, setTicketCategoryFilter} = state.actions;
