import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './ChangeTicketStatus.graphql';

const defaultOptions = {} as const;
export type ChangeTicketStatusMutationVariables = Types.Exact<{
	data: Types.ChangeStatusInput;
}>;

export type ChangeTicketStatusMutation = {changeStatus: string};

export type ChangeTicketStatusType = string;

export type ChangeTicketStatusMutationFn = Apollo.MutationFunction<ChangeTicketStatusMutation, ChangeTicketStatusMutationVariables>;

/**
 * __useChangeTicketStatusMutation__
 *
 * To run a mutation, you first call `useChangeTicketStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTicketStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTicketStatusMutation, { data, loading, error }] = useChangeTicketStatusMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useChangeTicketStatusMutation(
	baseOptions?: Apollo.MutationHookOptions<ChangeTicketStatusMutation, ChangeTicketStatusMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<ChangeTicketStatusMutation, ChangeTicketStatusMutationVariables>(Operations, options);
}
export type ChangeTicketStatusMutationHookResult = ReturnType<typeof useChangeTicketStatusMutation>;
export type ChangeTicketStatusMutationResult = Apollo.MutationResult<ChangeTicketStatusMutation>;
export type ChangeTicketStatusMutationOptions = Apollo.BaseMutationOptions<ChangeTicketStatusMutation, ChangeTicketStatusMutationVariables>;
