import {useParams} from 'react-router-dom';
import {Button} from 'semantic-ui-react';
import {useAsyncError} from '~lib/useAsyncError';
import type {routes} from '../../routes';
import {useMarkAllNotificationsReadByUserIdMutation} from './markAllNotificationsReadByUserId';

export function NotificationFeedSidebar() {
	const throwError = useAsyncError();
	const {userId} = useParams<typeof routes.types.userNotifications>();
	const [editMutation, {loading: mutationLoading}] = useMarkAllNotificationsReadByUserIdMutation();

	function markAllRead() {
		editMutation({
			variables: {userId},
			refetchQueries: ['getNotificationsByUserId'],
		})
			.then(() => {})
			.catch(throwError);
	}
	return (
		<Button fluid color="blue" compact disabled={mutationLoading} onClick={markAllRead}>
			Mark all Read
		</Button>
	);
}
