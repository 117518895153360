/* eslint-disable no-param-reassign */
import {createSliceHook} from '@imperium/state';
import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';

export const state = createSlice({
	name: 'payroll',
	initialState: {
		selectedEmployee: 'all',
		selectedPayStubs: [] as string[],
	},
	reducers: {
		setSelectedEmployee: (st, action: PayloadAction<string>) => {
			st.selectedEmployee = action.payload;
		},
		setSelectedPayStubs: (st, action: PayloadAction<string[]>) => {
			st.selectedPayStubs = action.payload;
		},
	},
});

export const usePayrollState = createSliceHook(state);

export const {setSelectedEmployee, setSelectedPayStubs} = state.actions;
