import type React from 'react';
import {useDispatch} from 'react-redux';
import {Divider, Dropdown, Form, FormInput} from 'semantic-ui-react';
import {ColoredSpan} from '~common/components';
import {LeadStatusEnum} from '~core/graphql';
import {setLeadStatusFilter, useAccountState} from '../../state';
import {useGetLeadsTableQuery} from './LeadsTable/getLeadsTable';

export function LeadsTableSidebar() {
	const dispatch = useDispatch();
	const {leadStatusFilter} = useAccountState();
	const {data, error} = useGetLeadsTableQuery({variables: {status: leadStatusFilter}});

	if (error) throw error;

	return (
		<>
			<ColoredSpan labelText="Total Leads" contentText={data?.getLeadsReport.length || '00'} />
			<Divider />
			<Form>
				<FormInput label="Lead Status">
					<Dropdown
						fluid
						selection
						options={[
							{id: 1, text: 'Follow Up', value: LeadStatusEnum.FollowUp},
							{id: 2, text: 'Converted', value: LeadStatusEnum.Converted},
							{id: 3, text: 'Disqualified', value: LeadStatusEnum.Disqualified},
							{id: 4, text: 'Not Interested', value: LeadStatusEnum.NotInterested},
						]}
						value={leadStatusFilter}
						onChange={(event, dropdownProps) => dispatch(setLeadStatusFilter(dropdownProps.value as LeadStatusEnum))}
					/>
				</FormInput>
			</Form>
			<Divider />
		</>
	);
}
