import type {LayoutData} from '@imperium/layout';
import {debug} from 'debug';
import {Permission} from '~core/graphql';
import {isLoggedIn, needsPermission, needsRoute} from '~lib/permissionHelpers';
import {routes as accountRoutes} from '../accounts/routes';

const d = debug('tacs.web.registration.layout');

export const layout: LayoutData = {
	primaryMenu: [
		{
			text: 'Register',
			visible: {...isLoggedIn(), ...needsPermission(Permission.ReadAllAccount)},
			to: accountRoutes.to.createAccount(),
		},
	],
	secondaryMenu: [
		{
			text: 'Account',
			visible: {...isLoggedIn(), ...needsRoute('register'), ...needsPermission(Permission.ReadAllAccount)},
			to: accountRoutes.to.createAccount(),
		},
		{
			text: 'Lead',
			visible: {...isLoggedIn(), ...needsRoute('register'), ...needsPermission(Permission.ReadAllAccount)},
			to: accountRoutes.to.createLead(),
		},
	],
};
