import debug from 'debug';
import {Link, useParams} from 'react-router-dom';
import {Button, Divider, List} from 'semantic-ui-react';
import {useCommonState} from '~common/state';
import {routes} from '../../../../routes';
import {useGetSourceDeductionTableDataQuery} from '../../graphql/getSourceDeductionTableData';
import {SourceDeductionTableOpeningBalance} from '../SourceDeductionTableOpeningBalance';

const d = debug('tacs.web.payroll.components.sourceDeductions.sourceDeductionTable.SourceDeductionTableSidebar');

export function SourceDeductionTableSidebar() {
	const {accountInfoId} = useParams<typeof routes.types.sourceDeductionTable>();
	const {endDate} = useCommonState();
	const {data, error} = useGetSourceDeductionTableDataQuery({variables: {accountInfoId, year: endDate.year()}});

	if (error) throw error;
	const openingBalance = data?.getSourceDeductionByAccountInfoAndYear?.openingBalance;

	return (
		<>
			<SourceDeductionTableOpeningBalance values={openingBalance} year={endDate.year()} accountInfoId={accountInfoId} />
			<Divider />
			<List>
				{openingBalance ? null : (
					<List.Item>
						<Button color="blue" fluid as={Link} to={routes.to.addSourceDeductionOpeningBalance({accountInfoId})}>
							Add Opening Balance
						</Button>
					</List.Item>
				)}

				<List.Item>
					<Button positive fluid as={Link} to={routes.to.addSourceDeductionPayment({accountInfoId})}>
						Add Payment
					</Button>
				</List.Item>
				<List.Item>
					<Button color="orange" fluid as={Link} to={routes.to.addSourceDeductionPayable({accountInfoId})}>
						Add Payable
					</Button>
				</List.Item>
				<List.Item>
					<Button color="teal" fluid as={Link} to={routes.to.addSourceDeductionStatementBalance({accountInfoId})}>
						Add Statement Balance
					</Button>
				</List.Item>
			</List>
		</>
	);
}
