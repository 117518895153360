import {defineRoutes} from '@imperium/router';

export const routes = defineRoutes({
	documents: {
		path: `/account/:accountInfoId/documents`,
		params: ['accountInfoId'] as const,
	},
	documentSorter: {
		path: '/account/:accountInfoId/draft-documents/:fileInfoId/:draftDocumentSourceId/sorter',
		params: ['accountInfoId', 'fileInfoId', 'draftDocumentSourceId'] as const,
	},
	documentUpload: {
		path: `/account/:accountInfoId/documents/upload`,
		params: ['accountInfoId'] as const,
	},
	logBookUpload: {
		path: `/account/:accountInfoId/documents/log-book-upload`,
		params: ['accountInfoId'] as const,
	},
	documentViewer: {
		path: '/account/:accountInfoId/documents/:documentId/viewer/:tab',
		params: ['accountInfoId', 'documentId', 'tab'] as const,
	},
	receivedFiles: {
		path: `/account/:accountInfoId/documents/received-files`,
		params: ['accountInfoId'] as const,
	},
	draftDocuments: {
		path: `/account/:accountInfoId/draft-documents`,
		params: ['accountInfoId'] as const,
	},
	fileViewer: {
		path: `/account/:accountInfoId/documents/file/:fileInfoId/viewer`,
		params: ['accountInfoId', 'fileInfoId'] as const,
	},
	viewExpectedDocumentSettings: {
		path: `/account/:accountInfoId/settings/documents/expected`,
		params: ['accountInfoId'] as const,
	},
	addExpectedDocument: {
		path: `/account/:accountInfoId/settings/documents/expected/add`,
		params: ['accountInfoId'] as const,
	},
	editExpectedDocument: {
		path: `/account/:accountInfoId/settings/documents/expected/edit/:expectedDocumentId`,
		params: ['accountInfoId', 'expectedDocumentId'] as const,
	},
	missingDocuments: {
		path: `/account/:accountInfoId/documents/missing`,
		params: ['accountInfoId'] as const,
	},
	missingPaperworkEmail: {
		path: `/account/:accountInfoId/documents/missing/email`,
		params: ['accountInfoId'] as const,
	},
	addExpectedDocumentException: {
		path: `/account/:accountInfoId/documents/expected/:documentId/:date/exception/add`,
		params: ['accountInfoId', 'documentId', 'date'] as const,
	},
	editExpectedDocumentException: {
		path: `/account/:accountInfoId/documents/expected/exception/:expectedDocumentExceptionId/edit`,
		params: ['accountInfoId', 'expectedDocumentExceptionId'] as const,
	},
	statementCreate: {
		path: '/account/:accountInfoId/documents/:documentId/statement/create',
		params: ['accountInfoId', 'documentId'] as const,
	},
	statementEdit: {
		path: '/account/:accountInfoId/documents/:documentId/statement/edit/:statementId',
		params: ['accountInfoId', 'documentId', 'statementId'] as const,
	},
});
