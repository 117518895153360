import {useTForm} from '@thx/controls';
import {useState} from 'react';
import {Button, Dropdown, Form, FormInput} from 'semantic-ui-react';
import {TicketStatusEnum} from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import {useChangeTicketStatusMutation} from './ChangeTicketStatus';

interface ChangeStatusProps {
	ticketStatus: TicketStatusEnum;
	ticketId: string;
	userId: string;
}

export function ChangeStatusButton({ticketStatus, ticketId, userId}: ChangeStatusProps) {
	const [changeStatus, {loading, data}] = useChangeTicketStatusMutation();

	const throwError = useAsyncError();

	const [editClicked, setEditClicked] = useState(false);

	const options = [
		{value: TicketStatusEnum.Closed, text: 'Closed'},
		{value: TicketStatusEnum.Open, text: 'Open'},
		{value: TicketStatusEnum.Resolved, text: 'Resolved'},
	];

	const {hasErrors, hasWarnings, handleSubmit, setFieldValue, values, resetForm} = useTForm({
		initialValues: {status: ticketStatus},
		onSubmit: formValues => {
			changeStatus({
				variables: {data: {ticketStatus: formValues.status, ticketId, userId}},
				update: cache => {
					const toEvict = cache.identify({id: ticketId, __typename: 'Ticket'});
					cache.evict({id: toEvict}).valueOf();
					cache.gc();
				},
			})
				.then(() => {
					setEditClicked(false);
				})
				.catch(throwError);
		},
		enableReinitialize: true,
	});

	if (editClicked) {
		return (
			<Form error={hasErrors} warning={hasWarnings} onSubmit={handleSubmit}>
				<FormInput autoFocus loading={loading} label="Set status">
					<Dropdown fluid options={options} selection value={values.status} onChange={(val, d) => setFieldValue('status', d.value)} />
				</FormInput>
				<Button positive type="submit" floated="right">
					Save
				</Button>
				<Button
					type="button"
					onClick={() => {
						resetForm();
						setEditClicked(false);
					}}
				>
					Cancel
				</Button>
			</Form>
		);
	}

	return (
		<Button color="blue" fluid onClick={() => setEditClicked(true)}>
			Change Status
		</Button>
	);
}
