import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getDisplayPartnerListAccount.graphql';

const defaultOptions = {} as const;
export type GetDisplayPartnerListAccountQueryVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
}>;

export type GetDisplayPartnerListAccountQuery = {
	getPartnersByAccountInfoId?: Array<{
		id: string;
		version: number;
		ownershipPercentage?: number | null;
		contact: {
			id: string;
			version: number;
			givenName: string;
			surname: string;
			preferredName?: string | null;
			primaryPhone?: string | null;
			sin?: string | null;
		};
	}> | null;
};

export type GetDisplayPartnerListAccountType = {
	id: string;
	version: number;
	ownershipPercentage?: number | null;
	contact: {
		id: string;
		version: number;
		givenName: string;
		surname: string;
		preferredName?: string | null;
		primaryPhone?: string | null;
		sin?: string | null;
	};
};

/**
 * __useGetDisplayPartnerListAccountQuery__
 *
 * To run a query within a React component, call `useGetDisplayPartnerListAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisplayPartnerListAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisplayPartnerListAccountQuery({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *   },
 * });
 */
export function useGetDisplayPartnerListAccountQuery(
	baseOptions: Apollo.QueryHookOptions<GetDisplayPartnerListAccountQuery, GetDisplayPartnerListAccountQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetDisplayPartnerListAccountQuery, GetDisplayPartnerListAccountQueryVariables>(Operations, options);
}
export function useGetDisplayPartnerListAccountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetDisplayPartnerListAccountQuery, GetDisplayPartnerListAccountQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetDisplayPartnerListAccountQuery, GetDisplayPartnerListAccountQueryVariables>(Operations, options);
}
export type GetDisplayPartnerListAccountQueryHookResult = ReturnType<typeof useGetDisplayPartnerListAccountQuery>;
export type GetDisplayPartnerListAccountLazyQueryHookResult = ReturnType<typeof useGetDisplayPartnerListAccountLazyQuery>;
export type GetDisplayPartnerListAccountQueryResult = Apollo.QueryResult<
	GetDisplayPartnerListAccountQuery,
	GetDisplayPartnerListAccountQueryVariables
>;
