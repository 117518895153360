import {useDispatch} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {Button, List} from 'semantic-ui-react';
import {routes} from '../../routes';
import {setEmployeeStatusFilter, useAccountState} from '../../state';
import {EmployeeStatusDropdown} from './EmployeeStatusDropdown';

export function EmployeesSidebar() {
	const dispatch = useDispatch();
	const {push} = useHistory();
	const {accountInfoId} = useParams<typeof routes.types.employees>();
	const {employeeStatusFilter} = useAccountState();

	return (
		<List>
			<List.Item>
				Filter By:
				<EmployeeStatusDropdown selection fluid value={employeeStatusFilter} onChange={val => dispatch(setEmployeeStatusFilter(val))} />
			</List.Item>
			<List.Item>
				<Button positive fluid onClick={() => push(routes.to.createEmployee({accountInfoId}))}>
					Add Employee
				</Button>
			</List.Item>
		</List>
	);
}
