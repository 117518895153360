import {defineRoutes} from '@imperium/router';

export const routes = defineRoutes({
	register: {
		path: '/register',
		exact: true,
		isPublic: true,
	},
	registerPublic: {
		path: '/register-public',
		exact: true,
		isPublic: true,
	},
	registerPublicConfirmation: {
		path: '/register-confirmation',
		exact: true,
		isPublic: true,
	},
	registerExistingUser: {
		path: '/register-existing-user',
		exact: true,
		isPublic: true,
	},
	registerAgent: {
		path: '/register-agent',
		exact: true,
		isPublic: true,
	},
});
