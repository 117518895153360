import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './createVendorForm.graphql';

const defaultOptions = {} as const;
export type CreateVendorFormMutationVariables = Types.Exact<{
	data: Types.CreateVendorInput;
}>;

export type CreateVendorFormMutation = {createVendor?: {id: string; version: number} | null};

export type CreateVendorFormType = {id: string; version: number};

export type CreateVendorFormMutationFn = Apollo.MutationFunction<CreateVendorFormMutation, CreateVendorFormMutationVariables>;

/**
 * __useCreateVendorFormMutation__
 *
 * To run a mutation, you first call `useCreateVendorFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVendorFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVendorFormMutation, { data, loading, error }] = useCreateVendorFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateVendorFormMutation(baseOptions?: Apollo.MutationHookOptions<CreateVendorFormMutation, CreateVendorFormMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<CreateVendorFormMutation, CreateVendorFormMutationVariables>(Operations, options);
}
export type CreateVendorFormMutationHookResult = ReturnType<typeof useCreateVendorFormMutation>;
export type CreateVendorFormMutationResult = Apollo.MutationResult<CreateVendorFormMutation>;
export type CreateVendorFormMutationOptions = Apollo.BaseMutationOptions<CreateVendorFormMutation, CreateVendorFormMutationVariables>;
