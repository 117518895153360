import * as Apollo from '@apollo/client';

import type {LocalDate} from '@js-joda/core';
import type Money from 'js-money';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getStatementById.graphql';

const defaultOptions = {} as const;
export type GetStatementByIdQueryVariables = Types.Exact<{
	statementId: Types.Scalars['String'];
}>;

export type GetStatementByIdQuery = {
	getStatementById?: {
		id: string;
		version: number;
		openingBalance: Money;
		closingBalance: Money;
		financialAccount: {id: string; accountNickname: string; institution: {id: string; name: string}};
		statementLines: Array<{
			id: string;
			version: number;
			date?: LocalDate | null;
			debit?: Money | null;
			credit?: Money | null;
			description?: string | null;
			status?: Types.StatementStatusEnum | null;
			reconciled: boolean;
			document?: {id: string} | null;
		}>;
		document: {id: string; date: LocalDate; type: {id: string; name: string}; vendor: {id: string; name: string}};
	} | null;
};

export type GetStatementByIdType = {
	id: string;
	version: number;
	openingBalance: Money;
	closingBalance: Money;
	financialAccount: {id: string; accountNickname: string; institution: {id: string; name: string}};
	statementLines: Array<{
		id: string;
		version: number;
		date?: LocalDate | null;
		debit?: Money | null;
		credit?: Money | null;
		description?: string | null;
		status?: Types.StatementStatusEnum | null;
		reconciled: boolean;
		document?: {id: string} | null;
	}>;
	document: {id: string; date: LocalDate; type: {id: string; name: string}; vendor: {id: string; name: string}};
};

/**
 * __useGetStatementByIdQuery__
 *
 * To run a query within a React component, call `useGetStatementByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatementByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatementByIdQuery({
 *   variables: {
 *      statementId: // value for 'statementId'
 *   },
 * });
 */
export function useGetStatementByIdQuery(baseOptions: Apollo.QueryHookOptions<GetStatementByIdQuery, GetStatementByIdQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetStatementByIdQuery, GetStatementByIdQueryVariables>(Operations, options);
}
export function useGetStatementByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStatementByIdQuery, GetStatementByIdQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetStatementByIdQuery, GetStatementByIdQueryVariables>(Operations, options);
}
export type GetStatementByIdQueryHookResult = ReturnType<typeof useGetStatementByIdQuery>;
export type GetStatementByIdLazyQueryHookResult = ReturnType<typeof useGetStatementByIdLazyQuery>;
export type GetStatementByIdQueryResult = Apollo.QueryResult<GetStatementByIdQuery, GetStatementByIdQueryVariables>;
