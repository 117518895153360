import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getAccountRatingByAccountInfoId.graphql';

const defaultOptions = {} as const;
export type GetAccountRatingByAccountInfoIdQueryVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
}>;

export type GetAccountRatingByAccountInfoIdQuery = {getAccountRatingByAccountInfoId?: {id: string} | null};

export type GetAccountRatingByAccountInfoIdType = {id: string};

/**
 * __useGetAccountRatingByAccountInfoIdQuery__
 *
 * To run a query within a React component, call `useGetAccountRatingByAccountInfoIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountRatingByAccountInfoIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountRatingByAccountInfoIdQuery({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *   },
 * });
 */
export function useGetAccountRatingByAccountInfoIdQuery(
	baseOptions: Apollo.QueryHookOptions<GetAccountRatingByAccountInfoIdQuery, GetAccountRatingByAccountInfoIdQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetAccountRatingByAccountInfoIdQuery, GetAccountRatingByAccountInfoIdQueryVariables>(Operations, options);
}
export function useGetAccountRatingByAccountInfoIdLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetAccountRatingByAccountInfoIdQuery, GetAccountRatingByAccountInfoIdQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetAccountRatingByAccountInfoIdQuery, GetAccountRatingByAccountInfoIdQueryVariables>(Operations, options);
}
export type GetAccountRatingByAccountInfoIdQueryHookResult = ReturnType<typeof useGetAccountRatingByAccountInfoIdQuery>;
export type GetAccountRatingByAccountInfoIdLazyQueryHookResult = ReturnType<typeof useGetAccountRatingByAccountInfoIdLazyQuery>;
export type GetAccountRatingByAccountInfoIdQueryResult = Apollo.QueryResult<
	GetAccountRatingByAccountInfoIdQuery,
	GetAccountRatingByAccountInfoIdQueryVariables
>;
