import {ImperiumClient} from '@imperium/client';
import '@thx/mingo-types';
import debug from 'debug';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
// @ts-ignore
import {render} from 'react-dom';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Root} from '~core/Root';
import {clientModules} from '~core/clientModules';
import './styles.css';

const d = debug('tacs.web');

toast.configure({theme: 'colored', position: 'bottom-right'});

const index = new ImperiumClient({
	clientModules,
	rootComponent: Root,
});

index
	.start()
	.then(Tacs => {
		render(<Tacs />, document.getElementById('root'));
	})
	.catch(err => {
		d(err);
	});
