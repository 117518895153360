import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getFinancialAccountPreviewData.graphql';

const defaultOptions = {} as const;
export type GetFinancialAccountPreviewDataQueryVariables = Types.Exact<{
	data: Types.GetAccountsPreviewInput;
}>;

export type GetFinancialAccountPreviewDataQuery = {
	getFinancialAccountPreviewData?: Array<{type: Types.GeneralLedgerAccountTypeEnum; name: string; code: number}> | null;
};

export type GetFinancialAccountPreviewDataType = {type: Types.GeneralLedgerAccountTypeEnum; name: string; code: number};

/**
 * __useGetFinancialAccountPreviewDataQuery__
 *
 * To run a query within a React component, call `useGetFinancialAccountPreviewDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinancialAccountPreviewDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinancialAccountPreviewDataQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetFinancialAccountPreviewDataQuery(
	baseOptions: Apollo.QueryHookOptions<GetFinancialAccountPreviewDataQuery, GetFinancialAccountPreviewDataQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetFinancialAccountPreviewDataQuery, GetFinancialAccountPreviewDataQueryVariables>(Operations, options);
}
export function useGetFinancialAccountPreviewDataLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetFinancialAccountPreviewDataQuery, GetFinancialAccountPreviewDataQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetFinancialAccountPreviewDataQuery, GetFinancialAccountPreviewDataQueryVariables>(Operations, options);
}
export type GetFinancialAccountPreviewDataQueryHookResult = ReturnType<typeof useGetFinancialAccountPreviewDataQuery>;
export type GetFinancialAccountPreviewDataLazyQueryHookResult = ReturnType<typeof useGetFinancialAccountPreviewDataLazyQuery>;
export type GetFinancialAccountPreviewDataQueryResult = Apollo.QueryResult<
	GetFinancialAccountPreviewDataQuery,
	GetFinancialAccountPreviewDataQueryVariables
>;
