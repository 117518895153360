import * as Apollo from '@apollo/client';

import Operations from '~common/components/AgentDropdown/getAgentDropdown.graphql';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

const defaultOptions = {} as const;
export type GetAgentDropdownQueryVariables = Types.Exact<{
	roles: Array<Types.AgentRole> | Types.AgentRole;
}>;

export type GetAgentDropdownQuery = {
	getActiveAgentsByRoles?: Array<{id: string; version: number; name: string; authorizedUser?: {id: string} | null}> | null;
};

export type GetAgentDropdownType = {id: string; version: number; name: string; authorizedUser?: {id: string} | null};

/**
 * __useGetAgentDropdownQuery__
 *
 * To run a query within a React component, call `useGetAgentDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgentDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgentDropdownQuery({
 *   variables: {
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useGetAgentDropdownQuery(baseOptions: Apollo.QueryHookOptions<GetAgentDropdownQuery, GetAgentDropdownQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetAgentDropdownQuery, GetAgentDropdownQueryVariables>(Operations, options);
}
export function useGetAgentDropdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAgentDropdownQuery, GetAgentDropdownQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetAgentDropdownQuery, GetAgentDropdownQueryVariables>(Operations, options);
}
export type GetAgentDropdownQueryHookResult = ReturnType<typeof useGetAgentDropdownQuery>;
export type GetAgentDropdownLazyQueryHookResult = ReturnType<typeof useGetAgentDropdownLazyQuery>;
export type GetAgentDropdownQueryResult = Apollo.QueryResult<GetAgentDropdownQuery, GetAgentDropdownQueryVariables>;
