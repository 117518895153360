import debug from 'debug';
import {useHistory, useParams} from 'react-router-dom';
import {Button, List} from 'semantic-ui-react';
import {Loading} from '~common/components';
import {getName} from '~lib/getName';
import {useAsyncError} from '~lib/useAsyncError';
import {routes} from '../../../routes';
import {useCreateAuthorizedRepresentativeMutation} from '../AuthorizedRepresentativeCreateForm/createAuthorizedRepresentative';
import {useGetAuthorizedRepresentativeAddSidebarAuthorizedRepresentativesAndContactsQuery} from './getAuthorizedRepresentativeAddSidebarAuthorizedRepresentativesAndContacts';

const d = debug('tacs.web.accounts.components.authorizedRepresentatives.AuthorizedRepresentativeAddSidebar');

export function AuthorizedRepresentativeAddSidebar() {
	const throwError = useAsyncError();
	const {push} = useHistory();
	const {accountInfoId} = useParams<typeof routes.types.createAuthorizedRepresentative>();
	const {data, loading, error} = useGetAuthorizedRepresentativeAddSidebarAuthorizedRepresentativesAndContactsQuery({
		variables: {accountInfoId},
	});
	const [createAuthorizedRepresentativeMutation, {loading: mutationLoading}] = useCreateAuthorizedRepresentativeMutation();

	if (loading) return <Loading />;
	if (error) throw error;
	if (!data) throw new Error("The data couldn't be loaded");

	const contacts = data.getAccountInfoById?.contacts?.filter(
		contact => !data.getAccountInfoById?.authorizedRepresentatives?.find(own => own?.contact?.id === contact?.id),
	);

	const onCreateAuthorizedRepresentativeClick = (contactId: string) => {
		createAuthorizedRepresentativeMutation({variables: {data: {contactId, accountInfoId}}})
			.then(() => push(routes.to.authorizedRepresentatives({accountInfoId})))
			.catch(throwError);
	};

	return (
		<List>
			<List.Header as="h3">Choose From Existing Contacts</List.Header>
			{contacts?.length
				? contacts.map(contact => {
						return (
							<List.Item key={contact.id}>
								<Button disabled={mutationLoading} fluid color="blue" onClick={() => onCreateAuthorizedRepresentativeClick(contact.id)}>
									Add {getName(contact)} as an AuthorizedRepresentative
								</Button>
							</List.Item>
						);
				  })
				: 'none available...'}
		</List>
	);
}
