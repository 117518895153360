import React, {type SyntheticEvent, useEffect} from 'react';
import {Dropdown, DropdownItem, DropdownProps, Icon, Popup} from 'semantic-ui-react';
import {useAsyncError} from '~lib/useAsyncError';
import {useGetAllAccountRatingsLazyQuery} from './getAllAccountRatings';

interface AccountRatingDropdownProps extends Omit<DropdownProps, 'options'> {
	onChange?: (value?: string) => void;
	value: string | undefined;
}

export function AccountRatingDropdown(props: AccountRatingDropdownProps) {
	const throwError = useAsyncError();
	const {onChange, accountInfoId, value, ...rest} = props;
	const [runQuery, {data, error}] = useGetAllAccountRatingsLazyQuery();

	useEffect(() => {
		runQuery().catch(throwError);
	}, [runQuery, throwError]);
	if (error) throw error;

	function handleChange(e: SyntheticEvent<HTMLElement, Event>, val: DropdownProps) {
		if (val.value && onChange) {
			onChange && onChange(val.value.toString());
		}
	}

	const options = data?.getAllAccountRatings?.map(rating => {
		return {
			key: rating.id,
			value: rating.id,
			text: `${rating.letter} -  ${rating.description}`,
			content: (
				<DropdownItem>
					{rating?.details && (
						<Popup
							trigger={<Icon circular name="info" size="small" style={{float: 'right'}} />}
							content={<div style={{whiteSpace: 'pre'}}>{rating.details}</div>}
						/>
					)}
					{rating.letter} - {rating.description}
				</DropdownItem>
			),
		};
	});

	if (!data) return <Dropdown {...rest} options={[]} disabled placeholder="The data couldn't be loaded" />;

	return <Dropdown disabled={!data.getAllAccountRatings?.length} value={value} options={options} {...rest} onChange={handleChange} />;
}
