import {debug} from 'debug';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Dropdown, Form, FormInput} from 'semantic-ui-react';
import {AccountEventFeatureEnum} from '~core/graphql';
import type {routes} from '../../routes';
import {setUserEventFeatureFilter, setUserEventOffsetFilter, useUserState} from '../../state';

const d = debug('tacs.web.user.components.UserEventTimeline.UserEventTimelineSidebar');

export function UserEventTimelineSidebar() {
	const {userId} = useParams<typeof routes.types.timeline>();
	const dispatch = useDispatch();
	const {userEventFeatureFilter} = useUserState();

	useEffect(() => {
		dispatch(setUserEventFeatureFilter(''));
	}, [dispatch, userId]);

	useEffect(() => {
		dispatch(setUserEventOffsetFilter(0));
	}, [dispatch, userEventFeatureFilter]);

	return (
		<Form>
			<FormInput label="Filter by Feature">
				<Dropdown
					fluid
					clearable
					placeholder="No Filter"
					selection
					search
					options={[
						{id: 1, text: 'Accounting', value: AccountEventFeatureEnum.Accounting},
						{id: 2, text: 'Accounts', value: AccountEventFeatureEnum.Accounts},
						{id: 3, text: 'Assets', value: AccountEventFeatureEnum.Assets},
						{id: 4, text: 'Calendar', value: AccountEventFeatureEnum.Calendar},
						{id: 5, text: 'Communications', value: AccountEventFeatureEnum.Communications},
						{id: 6, text: 'CorporateTaxes', value: AccountEventFeatureEnum.CorporateTaxes},
						{id: 7, text: 'Documents', value: AccountEventFeatureEnum.Documents},
						{id: 8, text: 'Gst', value: AccountEventFeatureEnum.Gst},
						{id: 9, text: 'Notifications', value: AccountEventFeatureEnum.Notifications},
						{id: 10, text: 'Payroll', value: AccountEventFeatureEnum.Payroll},
						{id: 11, text: 'PersonalTaxes', value: AccountEventFeatureEnum.PersonalTaxes},
						{id: 12, text: 'Rates', value: AccountEventFeatureEnum.Rates},
						{id: 13, text: 'Receivables', value: AccountEventFeatureEnum.Receivables},
						{id: 14, text: 'Support', value: AccountEventFeatureEnum.Support},
						{id: 15, text: 'System', value: AccountEventFeatureEnum.System},
						{id: 16, text: 'T5', value: AccountEventFeatureEnum.T5},
						{id: 17, text: 'Tasks', value: AccountEventFeatureEnum.Tasks},
						{id: 18, text: 'Users', value: AccountEventFeatureEnum.Users},
					]}
					value={userEventFeatureFilter || ''}
					onChange={(event, val) => dispatch(setUserEventFeatureFilter(val.value as AccountEventFeatureEnum))}
				/>
			</FormInput>
		</Form>
	);
}
