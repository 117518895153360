import {useLayoutState} from '@imperium/layout';
import debug from 'debug';
import {Menu} from 'semantic-ui-react';
import {useAccountState} from '../../state';

const d = debug('tacs.web.accounts.components.accounts.AccountNameStatusBarItem');

export function AccountNameStatusBarItem() {
	const {accountInfoId, friendlyId, name} = useAccountState();
	const {isMobile} = useLayoutState();

	if (accountInfoId && name && friendlyId !== undefined) {
		if (isMobile) {
			return (
				<Menu.Item fitted="vertically" style={{color: 'rgb(196, 209, 22)', fontSize: '1.2em'}}>
					{name}
				</Menu.Item>
			);
		}
		return (
			<Menu.Item fitted="vertically" style={{color: 'rgb(196, 209, 22)', fontSize: '1.5em'}}>
				{friendlyId} - {name}
			</Menu.Item>
		);
	}
	return null;
}
