import {LocalDate} from '@js-joda/core';
import {formatDate, FormatDateType, getFiscalQuarter, getFiscalQuarterRange, getFiscalYear, getFiscalYearRange} from '@thx/date';
import debug from 'debug';
import {getQuarter} from '~common/components/DateRangeSelector/tabs/tools';
import {DateRangeSelection} from '~common/types';

const d = debug('tacs.web.common.components.DateRangeSelector.tabs.generatePresets');

export interface PresetItem {
	title: string;
	start: LocalDate;
	end: LocalDate;
	group?: string;
	hint?: string;
}

export interface GroupedPresetItems {
	[group: string]: PresetItem[];
}

interface Params {
	startDate: LocalDate;
	endDate: LocalDate;
	fiscalYearEnd: LocalDate;
	dateRangeSelection: DateRangeSelection;
}

/**
 * Calculates the first and last day of the month for a given date. Assumes month alignment.
 * @param date
 */
function getMonthDates(date: LocalDate) {
	const startOfMonth = date.withDayOfMonth(1);
	const endOfMonth = date.withDayOfMonth(date.lengthOfMonth());
	return {start: startOfMonth, end: endOfMonth};
}

/**
 * Calculates the first and last day of the year for a given date. Assumes month alignment.
 * @param date
 */
function getYearDates(date: LocalDate) {
	const startOfYear = date.withDayOfYear(1);
	const endOfYear = date.withDayOfYear(date.lengthOfYear());
	return {start: startOfYear, end: endOfYear};
}

/**
 * Calculates the first and last day of the quarter for a given date. Assumes month alignment.
 * @param date
 */
function getQuarterDates(date: LocalDate) {
	const quarterStartMonth = Math.floor((date.monthValue() - 1) / 3) * 3 + 1; // Calculate the start month of the quarter

	const startOfQuarter = LocalDate.of(date.year(), quarterStartMonth, 1);
	const endOfQuarterMonth = startOfQuarter.plusMonths(2).withDayOfMonth(1);
	const endOfQuarter = endOfQuarterMonth.withDayOfMonth(endOfQuarterMonth.lengthOfMonth());

	return {start: startOfQuarter, end: endOfQuarter};
}

/**
 * Generates preset entries based on today's date
 * @param dateRangeSelection
 */
function generateNowEntry({dateRangeSelection}: Params): PresetItem[] {
	const today = LocalDate.now();

	switch (dateRangeSelection) {
		case DateRangeSelection.FullMonth:
			return [
				{
					title: 'This Month',
					...getMonthDates(today),
				},
			];
		case DateRangeSelection.SingleDate:
			return [
				{
					title: 'Today',
					start: today,
					end: today,
				},
			];
		case DateRangeSelection.DateRange:
			return [
				{
					title: 'Today',
					start: LocalDate.now(),
					end: LocalDate.now(),
				},
				{
					title: 'This Month',
					...getMonthDates(today),
				},
			];
		default:
			return [];
	}
}

/**
 * Generate preset entries for starting and ending dates (for single date selection)
 * @param dateRangeSelection
 * @param fiscalYearEnd
 */
function generateBeginning({dateRangeSelection, fiscalYearEnd}: Params): PresetItem[] {
	const calendarStart = LocalDate.now().withDayOfYear(1);
	const {start: fiscalStart} = getFiscalYearRange(LocalDate.now(), fiscalYearEnd);
	const calendarLastEnd = calendarStart.minusDays(1);
	const fiscalLastEnd = fiscalStart.minusDays(1);

	const items = [];

	if (dateRangeSelection === DateRangeSelection.SingleDate) {
		items.push({
			start: fiscalStart,
			end: fiscalStart,
			title: formatDate(fiscalStart, {type: FormatDateType.medium}),
			hint: `${getFiscalYear(fiscalStart, fiscalYearEnd)} year start`,
			group: 'Fiscal',
		});
		items.push({
			start: fiscalLastEnd,
			end: fiscalLastEnd,
			title: formatDate(fiscalLastEnd, {type: FormatDateType.medium}),
			hint: `${getFiscalYear(fiscalLastEnd, fiscalYearEnd)} year end`,
			group: 'Fiscal',
		});
		items.push({start: calendarStart, end: calendarStart, title: formatDate(calendarStart, {type: FormatDateType.medium})});
		items.push({start: calendarLastEnd, end: calendarLastEnd, title: formatDate(calendarLastEnd, {type: FormatDateType.medium})});
	}
	return items;
}

/**
 * Generates preset entries for quarters
 * @param dateRangeSelection
 * @param fiscalYearEnd
 */
function generateQuarter({dateRangeSelection, fiscalYearEnd}: Params): PresetItem[] {
	const {start: startNow, end: endNow} = getQuarterDates(LocalDate.now());
	const {start: startFiscal, end: endFiscal} = getFiscalQuarterRange(LocalDate.now(), fiscalYearEnd);
	const {start: startLast, end: endLast} = getQuarterDates(startNow.minusDays(1));
	const {start: startFiscalLast, end: endFiscalLast} = getFiscalQuarterRange(startFiscal.minusDays(1), fiscalYearEnd);

	const items = [];

	if (dateRangeSelection === DateRangeSelection.DateRange) {
		items.push({start: startNow, end: endNow, title: 'This Quarter', hint: `Q${getQuarter(startNow)}`});
		items.push({start: startLast, end: endLast, title: 'Last Quarter', hint: `Q${getQuarter(startLast)}`});
		if (!(startFiscal.equals(startNow) && endFiscal.equals(endNow))) {
			items.push({
				start: startFiscal,
				end: endFiscal,
				title: 'This Quarter',
				hint: `Q${getFiscalQuarter(startFiscal, fiscalYearEnd)} ${getFiscalYear(startFiscal, fiscalYearEnd)}`,
				group: 'Fiscal',
			});
		}
		if (!(startFiscalLast.equals(startLast) && endFiscalLast.equals(endLast))) {
			items.push({
				start: startFiscalLast,
				end: endFiscalLast,
				title: 'Last Quarter',
				hint: `Q${getFiscalQuarter(startFiscalLast, fiscalYearEnd)} ${getFiscalYear(startFiscalLast, fiscalYearEnd)}`,
				group: 'Fiscal',
			});
		}
	}
	return items;
}

/**
 * Generates preset entries for years
 * @param fiscalYearEnd
 * @param dateRangeSelection
 */
function generateYear({fiscalYearEnd, dateRangeSelection}: Params): PresetItem[] {
	const today = LocalDate.now();
	const {start: startNow, end: endNow} = getYearDates(today);
	const {start: startLast, end: endLast} = getYearDates(startNow.minusDays(1));
	const {start: startFiscal, end: endFiscal} = getFiscalYearRange(today, fiscalYearEnd);
	const {start: startFiscalLast, end: endFiscalLast} = getFiscalYearRange(startFiscal.minusDays(1), fiscalYearEnd);

	const items = [];

	if (dateRangeSelection === DateRangeSelection.DateRange) {
		if (!(startFiscal.equals(startNow) && endFiscal.equals(endNow))) {
			items.push({start: startFiscal, end: endFiscal, title: 'This Year', hint: `${getFiscalYear(startFiscal, fiscalYearEnd)}`, group: 'Fiscal'});
		}
		if (!(startFiscalLast.equals(startLast) && endFiscalLast.equals(endLast))) {
			items.push({
				start: startFiscalLast,
				end: endFiscalLast,
				title: 'Last Year',
				hint: `${getFiscalYear(startFiscalLast, fiscalYearEnd)}`,
				group: 'Fiscal',
			});
		}
	}

	if (dateRangeSelection === DateRangeSelection.DateRange || dateRangeSelection === DateRangeSelection.FullYear) {
		items.push({start: startNow, end: endNow, title: 'This Year', hint: `${startNow.year()}`});
		items.push({start: startLast, end: endLast, title: 'Last Year', hint: `${startLast.year()}`});
	}
	return items;
}

/**
 * Generate the preset entries
 * @param params
 */
export function generateList(params: Params): PresetItem[] {
	const list: (PresetItem | null)[] = [];

	list.push(...generateNowEntry(params));
	list.push(...generateBeginning(params));
	list.push(...generateQuarter(params));
	list.push(...generateYear(params));

	return list.filter((v): v is PresetItem => v !== null);
}
