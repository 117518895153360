import type {LocalDate} from '@js-joda/core';
import {LocalDatePicker, TForm} from '@thx/controls';
import {localDateSchemaType} from '@thx/yup-types';
import debug from 'debug';
import {useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button, Form, FormInput, Input, Placeholder} from 'semantic-ui-react';
import {object} from 'yup';
import {useAsyncError} from '~lib/useAsyncError';
import {routes} from '../../../routes';
import {useEndEmployeeEmploymentMutation} from './endEmployeeEmployment';
import {useGetEndEmploymentEmployeeQuery} from './getEndEmploymentEmployee';
import {useReemployCorporateEmployeeMutation} from './reemployCorporateEmployee';

const d = debug('tacs.web.accounts.components.employees.EndEmploymentForm');

interface EndEmploymentProps {
	employeeId?: string;
}

const endEmploymentValidation = object().shape({endDate: localDateSchemaType().required()});

interface EndEmploymentValidationSchemaType {
	endDate: LocalDate;
	roeConfirmationNumber?: string | null;
}

export function EndEmploymentForm(props: EndEmploymentProps) {
	const throwError = useAsyncError();
	const {push} = useHistory();
	const {employeeId: paramsEmployeeId} = useParams<typeof routes.types.viewEmployee>();
	const employeeId = props.employeeId || paramsEmployeeId;
	const [endEmploymentClicked, setEndEmploymentClicked] = useState(false);

	const [endEmployeeEmploymentMutation, {called: calledEnd}] = useEndEmployeeEmploymentMutation();
	const [reemployCorporateEmployeeMutation, {called: calledEmploy}] = useReemployCorporateEmployeeMutation();
	const {data, loading, error} = useGetEndEmploymentEmployeeQuery({
		variables: {employeeId},
	});

	if (loading)
		return (
			<Placeholder>
				<Placeholder.Line />
				<Placeholder.Line />
			</Placeholder>
		);
	if (error) throw error;
	if (!data) throw new Error("The data couldn't be loaded");

	const handleDeleteClick = (formValues: EndEmploymentValidationSchemaType) => {
		endEmployeeEmploymentMutation({
			variables: {
				data: {
					id: employeeId,
					version: data?.getEmployeeById?.version || 0,
					date: formValues.endDate,
					roeConfirmationNumber: formValues.roeConfirmationNumber,
				},
			},
		})
			.then(() => {
				toast.success('Employment has ended.');
				push(
					routes.to.viewEmployee({
						accountInfoId: data?.getEmployeeById?.contact?.accountInfo?.id || '',
						employeeId,
					}),
				);
			})
			.catch(throwError);
	};

	const handleReEmployClick = () => {
		d(employeeId);
		reemployCorporateEmployeeMutation({
			variables: {employee: {id: employeeId, version: data?.getEmployeeById?.version || 0}},
		})
			.then(() => {
				toast.success('Employee has been re-employed.');
				push(routes.to.viewEmployee({accountInfoId: data?.getEmployeeById?.contact?.accountInfo?.id || '', employeeId}));
			})
			.catch(throwError);
	};

	if (!data?.getEmployeeById?.endDate && !endEmploymentClicked) {
		return (
			<Button disabled={calledEnd} fluid negative onClick={() => setEndEmploymentClicked(true)}>
				End Employment
			</Button>
		);
	}

	if (data?.getEmployeeById?.endDate) {
		return (
			<Button disabled={calledEmploy} fluid color="orange" onClick={handleReEmployClick}>
				Re-Employ
			</Button>
		);
	}

	return (
		<TForm<EndEmploymentValidationSchemaType>
			onSubmit={handleDeleteClick}
			initialValues={{roeConfirmationNumber: data.getEmployeeById.roeConfirmationNumber || ''}}
			validationSchema={endEmploymentValidation}
		>
			{({handleSubmit, setFieldValue, values, fieldError}) => {
				return (
					<Form onSubmit={handleSubmit}>
						<FormInput error={fieldError('endDate')} required label="Enter End Date">
							<LocalDatePicker onChange={val => setFieldValue('endDate', val)} value={values.endDate} todayButton="Today" />
						</FormInput>
						<FormInput error={fieldError('roeConfirmationNumber')} label="ROE Confirmation Number">
							<Input onChange={val => setFieldValue('roeConfirmationNumber', val.target.value)} value={values.roeConfirmationNumber} />
						</FormInput>
						<Button positive floated="right">
							Save
						</Button>
						<Button type="button" onClick={() => setEndEmploymentClicked(false)}>
							Cancel
						</Button>
					</Form>
				);
			}}
		</TForm>
	);
}
