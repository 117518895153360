import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {
	Button,
	FormInput,
	Grid,
	GridColumn,
	GridRow,
	Label,
	List,
	ListItem,
	Modal,
	ModalActions,
	ModalContent,
	ModalDescription,
	ModalHeader,
	Segment,
} from 'semantic-ui-react';
import {useAsyncError} from '~lib/useAsyncError';
import {VendorAddFormInputLabel} from '../../../accounting/components/vendors/VendorAddForm/VendorAddFormInputLabel';
import {useCreateVendorFormMutation} from '../../../accounting/components/vendors/VendorAddForm/createVendorForm';
import {useGetLikeVendorsLazyQuery} from '../../../accounting/components/vendors/VendorAddForm/getLikeVendors';

interface VendorAddFieldProps {
	setAddNewVendor: Dispatch<SetStateAction<boolean>>;
	newVendorName: string;
	onChange?: (val?: string) => void;
}

export function VendorAddField(props: VendorAddFieldProps) {
	const {setAddNewVendor, newVendorName, onChange} = props;
	const [errorMessage, setErrorMessage] = useState<boolean>(false);
	const throwError = useAsyncError();
	const [verificationRequired, setVerificationRequired] = useState(false);
	const [name, setName] = useState('');

	const [createVendorFormMutation, {loading: mutationLoading}] = useCreateVendorFormMutation();
	const [runQuery, {data, loading, error}] = useGetLikeVendorsLazyQuery();
	if (error) throwError(error);

	useEffect(() => {
		setName(newVendorName);
	}, [newVendorName, setName]);

	function createVendor(submittedName: string) {
		createVendorFormMutation({
			variables: {data: {name: submittedName}},
			update: cache => {
				cache.evict({id: cache.identify({__typename: 'getVendorsByAccountInfo'})});
			},
		})
			.then(result => {
				setAddNewVendor(false);
				onChange && onChange(result.data?.createVendor?.id || '');
			})
			.catch(throwError);
	}

	async function handleFormSubmit() {
		const {data: localData, error: localError} = await runQuery({variables: {name, accountInfoId: ''}});
		if (localError) throwError(localError);
		if (!name) {
			setErrorMessage(true);
		} else {
			(localData?.getLikeVendors?.length || 0) > 0 ? setVerificationRequired(true) : createVendor(name);
		}
	}

	return (
		<Segment>
			<Grid>
				<GridRow>
					<GridColumn>
						<FormInput
							error={errorMessage && <Label content="Name can not be blank" />}
							required
							autoFocus
							onChange={(event, value) => {
								if (errorMessage) setErrorMessage(false);
								setName(value.value);
							}}
							label="Enter Vendors full legal name"
							value={name}
						/>
						{!errorMessage && <VendorAddFormInputLabel />}
					</GridColumn>
				</GridRow>
				<GridRow>
					<GridColumn>
						<Button type="button" floated="right" positive disabled={mutationLoading} onClick={handleFormSubmit}>
							Save
						</Button>
						<Button type="button" floated="right" onClick={() => setAddNewVendor(false)} disabled={mutationLoading}>
							Cancel
						</Button>
					</GridColumn>
				</GridRow>
			</Grid>
			<Modal closeOnDimmerClick={false} open={verificationRequired}>
				<ModalHeader>Confirm Vendor Name</ModalHeader>
				<ModalContent scrolling>
					<ModalDescription>
						<ListItem>
							The following vendors have names that are similar to <i>{name}</i>.
						</ListItem>
						<List bulleted>
							{data?.getLikeVendors?.map(vendor => {
								return <ListItem key={vendor.id}>{vendor.name}</ListItem>;
							})}
						</List>
						<ListItem>Are you sure you want to create a new vendor?</ListItem>
					</ModalDescription>
				</ModalContent>
				<ModalActions>
					<Grid>
						<GridRow>
							<GridColumn>
								<Button type="button" floated="right" positive disabled={mutationLoading} onClick={() => createVendor(name)}>
									Confirm
								</Button>
								<Button type="button" floated="right" onClick={() => setVerificationRequired(false)}>
									Cancel
								</Button>
							</GridColumn>
						</GridRow>
					</Grid>
				</ModalActions>
			</Modal>
		</Segment>
	);
}
