import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './editIncorporationDate.graphql';

const defaultOptions = {} as const;
export type EditSelfEmployedIncorporationDateMutationVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
	incorporationDate: Types.Scalars['LocalDate'];
}>;

export type EditSelfEmployedIncorporationDateMutation = {editIncorporationDate?: {id: string; version: number} | null};

export type EditSelfEmployedIncorporationDateType = {id: string; version: number};

export type EditSelfEmployedIncorporationDateMutationFn = Apollo.MutationFunction<
	EditSelfEmployedIncorporationDateMutation,
	EditSelfEmployedIncorporationDateMutationVariables
>;

/**
 * __useEditSelfEmployedIncorporationDateMutation__
 *
 * To run a mutation, you first call `useEditSelfEmployedIncorporationDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSelfEmployedIncorporationDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSelfEmployedIncorporationDateMutation, { data, loading, error }] = useEditSelfEmployedIncorporationDateMutation({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *      incorporationDate: // value for 'incorporationDate'
 *   },
 * });
 */
export function useEditSelfEmployedIncorporationDateMutation(
	baseOptions?: Apollo.MutationHookOptions<EditSelfEmployedIncorporationDateMutation, EditSelfEmployedIncorporationDateMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<EditSelfEmployedIncorporationDateMutation, EditSelfEmployedIncorporationDateMutationVariables>(Operations, options);
}
export type EditSelfEmployedIncorporationDateMutationHookResult = ReturnType<typeof useEditSelfEmployedIncorporationDateMutation>;
export type EditSelfEmployedIncorporationDateMutationResult = Apollo.MutationResult<EditSelfEmployedIncorporationDateMutation>;
export type EditSelfEmployedIncorporationDateMutationOptions = Apollo.BaseMutationOptions<
	EditSelfEmployedIncorporationDateMutation,
	EditSelfEmployedIncorporationDateMutationVariables
>;
