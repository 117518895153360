import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './setAccountRatingByAccountInfoId.graphql';

const defaultOptions = {} as const;
export type SetAccountRatingByAccountInfoIdMutationVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
	accountRatingId: Types.Scalars['String'];
}>;

export type SetAccountRatingByAccountInfoIdMutation = {setAccountRatingByAccountInfoId: {id: string}};

export type SetAccountRatingByAccountInfoIdType = {id: string};

export type SetAccountRatingByAccountInfoIdMutationFn = Apollo.MutationFunction<
	SetAccountRatingByAccountInfoIdMutation,
	SetAccountRatingByAccountInfoIdMutationVariables
>;

/**
 * __useSetAccountRatingByAccountInfoIdMutation__
 *
 * To run a mutation, you first call `useSetAccountRatingByAccountInfoIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAccountRatingByAccountInfoIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAccountRatingByAccountInfoIdMutation, { data, loading, error }] = useSetAccountRatingByAccountInfoIdMutation({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *      accountRatingId: // value for 'accountRatingId'
 *   },
 * });
 */
export function useSetAccountRatingByAccountInfoIdMutation(
	baseOptions?: Apollo.MutationHookOptions<SetAccountRatingByAccountInfoIdMutation, SetAccountRatingByAccountInfoIdMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<SetAccountRatingByAccountInfoIdMutation, SetAccountRatingByAccountInfoIdMutationVariables>(Operations, options);
}
export type SetAccountRatingByAccountInfoIdMutationHookResult = ReturnType<typeof useSetAccountRatingByAccountInfoIdMutation>;
export type SetAccountRatingByAccountInfoIdMutationResult = Apollo.MutationResult<SetAccountRatingByAccountInfoIdMutation>;
export type SetAccountRatingByAccountInfoIdMutationOptions = Apollo.BaseMutationOptions<
	SetAccountRatingByAccountInfoIdMutation,
	SetAccountRatingByAccountInfoIdMutationVariables
>;
