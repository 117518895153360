import {toDate, toLocalDate} from '@thx/date';
import debug from 'debug';
import DatePicker from 'react-datepicker';
import {useDispatch} from 'react-redux';
import styles from '~common/components/DateRangeSelector/tabs/style.module.css';
import {setDate, useCommonState} from '~common/state';

const d = debug('tacs.web.common.components.DateRangeSelector.tabs.FullYearTab');

export function FullYearTab({hidePanel}: {hidePanel: () => void}) {
	const dispatch = useDispatch();
	const {startDate, allowFuture} = useCommonState();

	return (
		<div style={{minWidth: '230px'}} className={styles.tab}>
			<p>Calendar Year:</p>
			<DatePicker
				showYearPicker
				selected={toDate(startDate)}
				maxDate={allowFuture ? undefined : new Date()}
				inline
				onChange={v => {
					if (v) {
						const dt = toLocalDate(v);
						dispatch(setDate({start: dt, end: dt.withDayOfYear(dt.lengthOfYear())}));
						hidePanel();
					}
				}}
			/>
		</div>
	);
}
