import {defineRoutes} from '@imperium/router';

export const routes = defineRoutes({
	corporateAssets: {
		path: `/account/:accountInfoId/settings/corporate-assets`,
		params: ['accountInfoId'] as const,
	},
	createCorporateAsset: {
		path: `/account/:accountInfoId/settings/corporate-assets/create`,
		params: ['accountInfoId'] as const,
	},
	editCorporateAsset: {
		path: `/account/:accountInfoId/settings/corporate-assets/:corporateAssetId/edit`,
		params: ['accountInfoId', 'corporateAssetId'] as const,
	},
	personalVehicles: {
		path: `/account/:accountInfoId/settings/personal-vehicles`,
		params: ['accountInfoId'] as const,
	},
	createPersonalVehicle: {
		path: `/account/:accountInfoId/settings/personal-vehicles/create`,
		params: ['accountInfoId'] as const,
	},
	editPersonalVehicle: {
		path: `/account/:accountInfoId/settings/personal-vehicles/edit/:personalVehicleId`,
		params: ['accountInfoId', 'personalVehicleId'] as const,
	},
	financialAccounts: {
		path: '/account/:accountInfoId/financial-accounts',
		params: ['accountInfoId'] as const,
	},
	viewFinancialAccount: {
		path: '/account/:accountInfoId/financial-accounts/bank-account/:financialAccountId/view',
		params: ['accountInfoId', 'financialAccountId'] as const,
	},
	viewCreditCardAccount: {
		path: '/account/:accountInfoId/financial-accounts/credit-card/:financialAccountId/view',
		params: ['accountInfoId', 'financialAccountId'] as const,
	},
	viewShortTermLoan: {
		path: '/account/:accountInfoId/financial-accounts/short-term-loan/:financialAccountId/view',
		params: ['accountInfoId', 'financialAccountId'] as const,
	},
	viewLongTermLoan: {
		path: '/account/:accountInfoId/financial-accounts/long-term-loan/:financialAccountId/view',
		params: ['accountInfoId', 'financialAccountId'] as const,
	},
	createFinancialAccount: {
		path: '/account/:accountInfoId/financial-accounts/create',
		params: ['accountInfoId'] as const,
	},
	editFinancialAccount: {
		path: '/account/:accountInfoId/financial-accounts/:financialAccountId/edit',
		params: ['accountInfoId', 'financialAccountId'] as const,
	},
});
