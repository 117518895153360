import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getCommunicationFeedItems.graphql';

const defaultOptions = {} as const;
export type GetCommunicationFeedItemsQueryVariables = Types.Exact<{
	filter?: Types.InputMaybe<Array<Types.CommunicationTypeEnum> | Types.CommunicationTypeEnum>;
	limit: Types.Scalars['Int'];
	offset: Types.Scalars['Int'];
	startDate: Types.Scalars['LocalDate'];
	endDate: Types.Scalars['LocalDate'];
	accountInfoId?: Types.InputMaybe<Types.Scalars['String']>;
	leadId?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type GetCommunicationFeedItemsQuery = {
	getCommunicationFeedItems?: Array<{
		id: string;
		type: Types.CommunicationTypeEnum;
		contents: string;
		color?: string | null;
		createdAt: Date;
		pinned: boolean;
		version?: number | null;
		data?: {cc?: string | null; date?: string | null; from: string; subject?: string | null; text?: string | null; to: string} | null;
		createdBy: {id: string; version: number; name: string; roles: Array<Types.AgentRole>};
	}> | null;
};

export type GetCommunicationFeedItemsType = {
	id: string;
	type: Types.CommunicationTypeEnum;
	contents: string;
	color?: string | null;
	createdAt: Date;
	pinned: boolean;
	version?: number | null;
	data?: {cc?: string | null; date?: string | null; from: string; subject?: string | null; text?: string | null; to: string} | null;
	createdBy: {id: string; version: number; name: string; roles: Array<Types.AgentRole>};
};

/**
 * __useGetCommunicationFeedItemsQuery__
 *
 * To run a query within a React component, call `useGetCommunicationFeedItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunicationFeedItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunicationFeedItemsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      accountInfoId: // value for 'accountInfoId'
 *      leadId: // value for 'leadId'
 *   },
 * });
 */
export function useGetCommunicationFeedItemsQuery(
	baseOptions: Apollo.QueryHookOptions<GetCommunicationFeedItemsQuery, GetCommunicationFeedItemsQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetCommunicationFeedItemsQuery, GetCommunicationFeedItemsQueryVariables>(Operations, options);
}
export function useGetCommunicationFeedItemsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetCommunicationFeedItemsQuery, GetCommunicationFeedItemsQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetCommunicationFeedItemsQuery, GetCommunicationFeedItemsQueryVariables>(Operations, options);
}
export type GetCommunicationFeedItemsQueryHookResult = ReturnType<typeof useGetCommunicationFeedItemsQuery>;
export type GetCommunicationFeedItemsLazyQueryHookResult = ReturnType<typeof useGetCommunicationFeedItemsLazyQuery>;
export type GetCommunicationFeedItemsQueryResult = Apollo.QueryResult<GetCommunicationFeedItemsQuery, GetCommunicationFeedItemsQueryVariables>;
