import {createPages} from '@imperium/layout';
import loadable from '@loadable/component';
import {CommunicationsSidebar} from './components/CommunicationsSidebar';
import {routes} from './routes';

const CommunicationsFeed = loadable(() => import('./components/CommunicationFeed'));

export const routeProps = createPages(routes, {
	communicationFeed: {
		header: 'Communications',
		content: () => <CommunicationsFeed />,
		sidebar: [{render: CommunicationsSidebar}],
	},
});
