import debug from 'debug';
import {EmployeeAddSidebarExistingContactList} from './EmployeeAddSidebarExistingContactList';
import {EmployeeAddSidebarPreviousEmployeeList} from './EmployeeAddSidebarPreviousEmployeeList';

const d = debug('tacs.web.accounts.components.employees.EmployeeAddSidebar');

export function EmployeeAddSidebar() {
	return (
		<>
			<EmployeeAddSidebarExistingContactList />
			<EmployeeAddSidebarPreviousEmployeeList />
		</>
	);
}
