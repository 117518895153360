import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getFinancialAccountById.graphql';

const defaultOptions = {} as const;
export type GetFinancialAccountByIdQueryVariables = Types.Exact<{
	id: Types.Scalars['String'];
}>;

export type GetFinancialAccountByIdQuery = {
	getFinancialAccountById: {
		id: string;
		version: number;
		accountNickname: string;
		accountNumber: string;
		accountCurrency: Types.CurrencyCountryEnum;
		archived: boolean;
		type: Types.FinancialAccountTypeEnum;
		institution: {id: string; name: string};
		accountInfo: {id: string};
		glAccount?: {id: string; code: number; name: string} | null;
	};
};

export type GetFinancialAccountByIdType = {
	id: string;
	version: number;
	accountNickname: string;
	accountNumber: string;
	accountCurrency: Types.CurrencyCountryEnum;
	archived: boolean;
	type: Types.FinancialAccountTypeEnum;
	institution: {id: string; name: string};
	accountInfo: {id: string};
	glAccount?: {id: string; code: number; name: string} | null;
};

/**
 * __useGetFinancialAccountByIdQuery__
 *
 * To run a query within a React component, call `useGetFinancialAccountByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinancialAccountByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinancialAccountByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFinancialAccountByIdQuery(
	baseOptions: Apollo.QueryHookOptions<GetFinancialAccountByIdQuery, GetFinancialAccountByIdQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetFinancialAccountByIdQuery, GetFinancialAccountByIdQueryVariables>(Operations, options);
}
export function useGetFinancialAccountByIdLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetFinancialAccountByIdQuery, GetFinancialAccountByIdQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetFinancialAccountByIdQuery, GetFinancialAccountByIdQueryVariables>(Operations, options);
}
export type GetFinancialAccountByIdQueryHookResult = ReturnType<typeof useGetFinancialAccountByIdQuery>;
export type GetFinancialAccountByIdLazyQueryHookResult = ReturnType<typeof useGetFinancialAccountByIdLazyQuery>;
export type GetFinancialAccountByIdQueryResult = Apollo.QueryResult<GetFinancialAccountByIdQuery, GetFinancialAccountByIdQueryVariables>;
