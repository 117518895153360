import type {LayoutData} from '@imperium/layout';
import {debug} from 'debug';
import {isLoggedIn} from '~lib/permissionHelpers';
import {MymcButton} from './components/dashboard/MymcButton';
import {routes} from './routes';

const d = debug('tacs.web.tasks.layout');

export const layout: LayoutData = {
	permissions: [],
	primaryMenu: [
		{
			stickOnMobile: true,
			render: MymcButton,
			visible: {...isLoggedIn()},
		},
		{
			visible: {...isLoggedIn()},
			text: 'Podcasts',
			to: routes.to.podcastsPage({videoId: 'latest'}),
			weight: 8,
		},
	],
};
