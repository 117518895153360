import {useCan} from '@imperium/auth-client';
import debug from 'debug';
import {useHistory, useParams} from 'react-router-dom';
import {Button, List} from 'semantic-ui-react';
import {ColoredSpan} from '~common/components';
import {Permission} from '~core/graphql';
import {formatGlAccount} from '~lib/formatGlAccount';
import {routes} from '../../routes';
import {useGetGeneralLedgerAccountsTemplateTableTableQuery} from './GeneralLedgerAccountsTemplateTable/getGeneralLedgerAccountsTemplateTable';

const d = debug('tacs.web.accounting.components.chartOfAccountsTemplates.GeneralLedgerAccountsTemplateTableSideBar');

export function GeneralLedgerAccountsTemplateTableSideBar() {
	const [sysadmin] = useCan(Permission.Sysadmin);
	const {push} = useHistory();
	const {chartOfAccountsTemplateId} = useParams<typeof routes.types.chartOfAccountsTemplates>();
	const {data, error} = useGetGeneralLedgerAccountsTemplateTableTableQuery({variables: {chartOfAccountsTemplateId}});

	if (error) throw error;

	const returnedData = data?.getChartOfAccountsTemplateById;

	return (
		<List>
			<List.Item>
				<Button disabled={!sysadmin} fluid color="green" onClick={() => push(routes.to.addGeneralLedgerAccountTemplate({chartOfAccountsTemplateId}))}>
					Add General Ledger Account Template
				</Button>
			</List.Item>
			<List.Item>
				<ColoredSpan labelText="GST Collected Account" contentText={formatGlAccount(returnedData?.gstCollectedAccount) || 'Not Set'} />
			</List.Item>
			<List.Item>
				<ColoredSpan labelText="GST Paid Account" contentText={formatGlAccount(returnedData?.gstPaidAccount) || 'Not Set'} />
			</List.Item>
			<List.Item>
				<ColoredSpan labelText="Retained Earnings Account" contentText={formatGlAccount(returnedData?.retainedEarningsAccount) || 'Not Set'} />
			</List.Item>
			<List.Item>
				<ColoredSpan labelText="Current Earnings Account" contentText={formatGlAccount(returnedData?.currentEarningsAccount) || 'Not Set'} />
			</List.Item>
			<List.Item>
				<ColoredSpan labelText="Receivables Account" contentText={formatGlAccount(returnedData?.receivablesAccount) || 'Not Set'} />
			</List.Item>
			<List.Item>
				<ColoredSpan labelText="Payables Account" contentText={formatGlAccount(returnedData?.payablesAccount) || 'Not Set'} />
			</List.Item>
		</List>
	);
}
