import {EmployeePaymentIntervalEnum} from '~core/graphql';

export function getPaymentIntervalOptions() {
	return [
		{key: EmployeePaymentIntervalEnum.Weekly52, value: EmployeePaymentIntervalEnum.Weekly52, text: 'Weekly (52 pay periods a year)'},
		{key: EmployeePaymentIntervalEnum.Biweekly26, value: EmployeePaymentIntervalEnum.Biweekly26, text: 'Biweekly (26 pay periods a year)'},
		{key: EmployeePaymentIntervalEnum.SemiMonthly24, value: EmployeePaymentIntervalEnum.SemiMonthly24, text: 'SemiMonthly (24 pay periods a year)'},
		{key: EmployeePaymentIntervalEnum.Monthly12, value: EmployeePaymentIntervalEnum.Monthly12, text: 'Monthly (12 pay periods a year)'},
		{key: EmployeePaymentIntervalEnum.Weekly53, value: EmployeePaymentIntervalEnum.Weekly53, text: 'Weekly (53 pay periods a year)'},
		{key: EmployeePaymentIntervalEnum.Biweekly27, value: EmployeePaymentIntervalEnum.Biweekly27, text: 'Biweekly (27 pay periods a year)'},
	];
}
