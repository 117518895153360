import debug from 'debug';
import {useEffect} from 'react';
import {Dropdown, DropdownProps} from 'semantic-ui-react';
import {useAsyncError} from '~lib/useAsyncError';
import {useGetAccountOrganizationDropdownLazyQuery} from './getAccountOrganizationDropdown';

const d = debug('tacs.web.accounts.components.accounts.AccountOrganizationDropdown');

interface AccountOrganizationDropdownProps extends Omit<DropdownProps, 'onChange'> {
	onChange?: (val?: string) => void;
}

export function AccountOrganizationDropdown(props: AccountOrganizationDropdownProps) {
	const throwError = useAsyncError();
	const {onChange, disabled, loading: propsLoading, setVendorEnabled, ...rest} = props;
	const [runQuery, {data, loading}] = useGetAccountOrganizationDropdownLazyQuery();

	useEffect(() => {
		runQuery().catch(throwError);
	}, [runQuery, throwError]);

	const options = data?.getOrganizations?.reduce((acc, docType) => {
		if (docType?.name) {
			acc.push({key: docType.id, value: docType.id, text: docType.name});
		}
		return acc;
	}, [] as {key: string; value: string; text: string}[]);

	function handleChange(e: React.SyntheticEvent<HTMLElement, Event>, val: DropdownProps) {
		if (val.value) {
			onChange && onChange(val.value.toString());
		} else {
			onChange && onChange();
		}
	}

	return (
		<Dropdown {...rest} options={options || []} disabled={disabled || !options?.length} loading={propsLoading || loading} onChange={handleChange} />
	);
}
