import debug from 'debug';
import {useEffect, useMemo} from 'react';
import {type DateRangeOptionsHookParams, getInstance} from '~common/hooks/dateRangeSelection/dateRangeSelectionSingleton';

const d = debug('tacs.web.common.hooks.dateRangeSelection.dateRangeSelectionHook');

/**
 * This function returns a hook that is only used to register the route and option with a singleton.
 * @param params
 */
export function dateRangeSelectionHook(params: DateRangeOptionsHookParams) {
	return function useDateRangeSelection() {
		const singleton = useMemo(() => {
			return getInstance();
		}, []);

		useEffect(() => {
			singleton.register(params);
		}, [singleton]);
	};
}
