import React from 'react';
import {Card, CardContent, CardDescription, CardHeader, Label, Popup, type SemanticCOLORS} from 'semantic-ui-react';

interface Props {
	accountRating?: {
		letter: string;
		description: string;
		details: string;
	} | null;
	corner?: boolean;
}

function getRatingColor(letter: string): SemanticCOLORS {
	switch (letter) {
		case 'A':
			return 'green';
		case 'B':
			return 'olive';
		case 'C':
			return 'yellow';
		case 'D':
			return 'orange';
		case 'F':
			return 'red';
		default:
			return 'grey';
	}
}

export function AccountRatingLabel({accountRating, corner}: Props) {
	if (accountRating?.letter) {
		if (corner) {
			return (
				<Popup
					trigger={
						<Label style={{cursor: 'pointer'}} title="Account Rating" corner="right" color={getRatingColor(accountRating.letter)} size="big">
							<strong className="icon" style={{cursor: 'pointer'}}>
								{accountRating.letter}
							</strong>
						</Label>
					}
					content={
						<Card>
							<CardContent>
								<CardHeader>
									{accountRating.letter} - {accountRating.description}
								</CardHeader>
							</CardContent>
							<CardContent>
								<CardDescription style={{whiteSpace: 'pre-wrap'}}>{accountRating.details}</CardDescription>
							</CardContent>
						</Card>
					}
				/>
			);
		}
		return (
			<Popup
				trigger={<Label style={{cursor: 'pointer'}} content={accountRating?.letter} color={getRatingColor(accountRating.letter)} />}
				content={
					<Card>
						<CardContent>
							<CardHeader>
								{accountRating.letter} - {accountRating.description}
							</CardHeader>
						</CardContent>
						<CardContent>
							<CardDescription style={{whiteSpace: 'pre-wrap'}}>{accountRating.details}</CardDescription>
						</CardContent>
					</Card>
				}
			/>
		);
	}
	return null;
}
