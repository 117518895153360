import {useCan} from '@imperium/auth-client';
import type {LayoutData} from '@imperium/layout';
import {useLayoutState} from '@imperium/layout';
import debug from 'debug';
import {Permission} from '~core/graphql';
import {isLoggedIn, needsPermission} from '~lib/permissionHelpers';
import {routes as accountsRoutes} from '../accounts/routes';
import {useAccountState} from '../accounts/state';
import {UserMenuItem} from './components/UserMenuItem';
import {UserNotificationMenuItem} from './components/UserNotificationMenuItem';
import {useUserInfo} from './hooks/useUserInfo';
import {useUserState} from './state';

const d = debug('tacs.web.user.layout');

export const layout: LayoutData = {
	permissions: [Permission.ManageAllAgents, Permission.ViewAdminPayrollAccount],
	dataHooks: [useUserInfo],
	primaryMenu: [
		{
			stateSelectorHook: [useUserState],
			stickOnMobile: true,
			render: UserNotificationMenuItem,
			position: 'right',
			visible: isLoggedIn(),
		},
		{
			stateSelectorHook: [useUserState],
			stickOnMobile: true,
			render: UserMenuItem,
			position: 'right',
			visible: isLoggedIn(),
		},
	],
	secondaryMenu: [
		{
			text: 'Timeline',
			icon: 'file',
			stateSelectorHook: [useAccountState, useLayoutState],
			permissionSelectorHook: [
				data => {
					const [viewAdminPayrollAccount] = useCan([
						{permission: Permission.ViewAdminPayrollAccount, data: {accountInfoId: data?.state.accountInfoId}},
					]);
					return {viewAdminPayrollAccount};
				},
			],
			visible: {...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount])},
			to: data => (data.state.params?.accountInfoId ? accountsRoutes.to.timeline({accountInfoId: data.state.params.accountInfoId}) : ''),
			moveToKey: 'settings',
		},
	],
};
