import {useTForm} from '@thx/controls';
import debug from 'debug';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button, Form, FormInput} from 'semantic-ui-react';
import {OfficeDropdown} from '~common/components/OfficeDropdown';
import {useAsyncError} from '~lib/useAsyncError';
import type {routes} from '../../../routes';
import {useEditAccountOfficeMutation} from './editAccountOffice';
import {useGetEditAccountOfficeLazyQuery} from './getEditAccountOffice';

const d = debug('tacs.web.accounts.components.accounts.EditAccountOffice');

export function EditAccountOffice() {
	const throwError = useAsyncError();
	const {accountInfoId} = useParams<typeof routes.types.account>();
	const [editClicked, setEditClicked] = useState(false);

	const [getQuery, {data, loading, error, refetch}] = useGetEditAccountOfficeLazyQuery({variables: {accountInfoId}});
	const [editMutation, {loading: mutationLoading}] = useEditAccountOfficeMutation();
	const {hasErrors, hasWarnings, handleSubmit, setFieldValue, values, resetForm} = useTForm<{officeId: string}>({
		initialValues: {officeId: data?.getAccountInfoById?.office?.id},
		onSubmit: formValues => {
			editMutation({
				variables: {accountInfoId, officeId: formValues.officeId},
			})
				.then(() => {
					toast.success('Office Saved');
					setEditClicked(false);
					refetch().catch(e => throwError(e));
				})
				.catch(throwError);
		},
		enableReinitialize: true,
	});

	if (editClicked) {
		if (!loading && !error && !data?.getAccountInfoById) getQuery().catch(throwError);
		return (
			<Form onSubmit={handleSubmit} error={hasErrors} warning={hasWarnings}>
				<FormInput autoFocus label="Office">
					<OfficeDropdown
						selection
						fluid
						organizationId={data?.getAccountInfoById?.organization?.id}
						value={values.officeId}
						onChange={val => setFieldValue('officeId', val)}
					/>
				</FormInput>
				<Button
					floated="left"
					type="button"
					onClick={() => {
						resetForm();
						setEditClicked(false);
					}}
				>
					Cancel
				</Button>
				<Button positive type="submit" floated="right" disabled={loading || mutationLoading}>
					Save
				</Button>
			</Form>
		);
	}

	return (
		<Button fluid color="orange" onClick={() => setEditClicked(true)}>
			Change Office
		</Button>
	);
}
