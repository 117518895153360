import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './exportArchive.graphql';

const defaultOptions = {} as const;
export type ExportArchiveQueryVariables = Types.Exact<{
	data: Types.ExportArchivesFilterInput;
}>;

export type ExportArchiveQuery = {exportArchive: string};

export type ExportArchiveType = string;

/**
 * __useExportArchiveQuery__
 *
 * To run a query within a React component, call `useExportArchiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportArchiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportArchiveQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useExportArchiveQuery(baseOptions: Apollo.QueryHookOptions<ExportArchiveQuery, ExportArchiveQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<ExportArchiveQuery, ExportArchiveQueryVariables>(Operations, options);
}
export function useExportArchiveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportArchiveQuery, ExportArchiveQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<ExportArchiveQuery, ExportArchiveQueryVariables>(Operations, options);
}
export type ExportArchiveQueryHookResult = ReturnType<typeof useExportArchiveQuery>;
export type ExportArchiveLazyQueryHookResult = ReturnType<typeof useExportArchiveLazyQuery>;
export type ExportArchiveQueryResult = Apollo.QueryResult<ExportArchiveQuery, ExportArchiveQueryVariables>;
