import {number, object, string} from 'yup';

export function addressAddSchema() {
	return object().shape({
		lineOne: string().nullable().notRequired(),
		lineTwo: string().nullable().notRequired(),
		city: string().nullable().notRequired(),
		province: string().nullable().notRequired(),
		postalCode: string().nullable().notRequired(),
		country: string().nullable().notRequired(),
	});
}

export function addressEditSchema() {
	return object().shape({
		id: string().nullable(), // this is not required because when editing a contact we might not have the address id or version
		version: number().nullable(), // this is not required because when editing a contact we might not have the address id or version
		lineOne: string().nullable().notRequired(),
		lineTwo: string().nullable().notRequired(),
		city: string().nullable().notRequired(),
		province: string().nullable().notRequired(),
		postalCode: string().nullable().notRequired(),
		country: string().nullable().notRequired(),
	});
}

export interface AddressEditSchemaType {
	id: string;
	version: number;
	lineOne?: string | null;
	lineTwo?: string | null;
	city?: string | null;
	province?: string | null;
	postalCode?: string | null;
	country?: string | null;
}

export interface AddressAddSchemaType {
	lineOne?: string | null;
	lineTwo?: string | null;
	city?: string | null;
	province?: string | null;
	postalCode?: string | null;
	country?: string | null;
}
