import type {LocalDate} from '@js-joda/core';

/**
 * Gets a quarter for calendar dates
 * @param date
 */
export function getQuarter(date: LocalDate): number {
	const month = date.monthValue();
	if (month >= 1 && month <= 3) {
		return 1;
	}
	if (month >= 4 && month <= 6) {
		return 2;
	}
	if (month >= 7 && month <= 9) {
		return 3;
	}
	return 4;
}
