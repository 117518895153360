import * as Apollo from '@apollo/client';

import type {LocalDate} from '@js-joda/core';
import type Money from 'js-money';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getDocumentsForStatement.graphql';

const defaultOptions = {} as const;
export type GetDocumentsForStatementQueryVariables = Types.Exact<{
	documentId: Types.Scalars['String'];
}>;

export type GetDocumentsForStatementQuery = {
	getDocumentsByStatement?: Array<{
		id: string;
		version: number;
		amount?: Money | null;
		date: LocalDate;
		status: Types.DocumentStatusEnum;
		type: {id: string; name: string};
		vendor: {id: string; name: string};
	}> | null;
};

export type GetDocumentsForStatementType = {
	id: string;
	version: number;
	amount?: Money | null;
	date: LocalDate;
	status: Types.DocumentStatusEnum;
	type: {id: string; name: string};
	vendor: {id: string; name: string};
};

/**
 * __useGetDocumentsForStatementQuery__
 *
 * To run a query within a React component, call `useGetDocumentsForStatementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentsForStatementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentsForStatementQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useGetDocumentsForStatementQuery(
	baseOptions: Apollo.QueryHookOptions<GetDocumentsForStatementQuery, GetDocumentsForStatementQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetDocumentsForStatementQuery, GetDocumentsForStatementQueryVariables>(Operations, options);
}
export function useGetDocumentsForStatementLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentsForStatementQuery, GetDocumentsForStatementQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetDocumentsForStatementQuery, GetDocumentsForStatementQueryVariables>(Operations, options);
}
export type GetDocumentsForStatementQueryHookResult = ReturnType<typeof useGetDocumentsForStatementQuery>;
export type GetDocumentsForStatementLazyQueryHookResult = ReturnType<typeof useGetDocumentsForStatementLazyQuery>;
export type GetDocumentsForStatementQueryResult = Apollo.QueryResult<GetDocumentsForStatementQuery, GetDocumentsForStatementQueryVariables>;
