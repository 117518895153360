import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getEmployeeAddSidebarPreviousEmployees.graphql';

const defaultOptions = {} as const;
export type GetEmployeeAddSidebarPreviousEmployeesQueryVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
}>;

export type GetEmployeeAddSidebarPreviousEmployeesQuery = {
	getEmployeesByAccountInfoId?: Array<{
		id: string;
		version: number;
		contact: {id: string; version: number; givenName: string; surname: string; preferredName?: string | null};
	}> | null;
};

export type GetEmployeeAddSidebarPreviousEmployeesType = {
	id: string;
	version: number;
	contact: {id: string; version: number; givenName: string; surname: string; preferredName?: string | null};
};

/**
 * __useGetEmployeeAddSidebarPreviousEmployeesQuery__
 *
 * To run a query within a React component, call `useGetEmployeeAddSidebarPreviousEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeAddSidebarPreviousEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeAddSidebarPreviousEmployeesQuery({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *   },
 * });
 */
export function useGetEmployeeAddSidebarPreviousEmployeesQuery(
	baseOptions: Apollo.QueryHookOptions<GetEmployeeAddSidebarPreviousEmployeesQuery, GetEmployeeAddSidebarPreviousEmployeesQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetEmployeeAddSidebarPreviousEmployeesQuery, GetEmployeeAddSidebarPreviousEmployeesQueryVariables>(Operations, options);
}
export function useGetEmployeeAddSidebarPreviousEmployeesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeeAddSidebarPreviousEmployeesQuery, GetEmployeeAddSidebarPreviousEmployeesQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetEmployeeAddSidebarPreviousEmployeesQuery, GetEmployeeAddSidebarPreviousEmployeesQueryVariables>(Operations, options);
}
export type GetEmployeeAddSidebarPreviousEmployeesQueryHookResult = ReturnType<typeof useGetEmployeeAddSidebarPreviousEmployeesQuery>;
export type GetEmployeeAddSidebarPreviousEmployeesLazyQueryHookResult = ReturnType<typeof useGetEmployeeAddSidebarPreviousEmployeesLazyQuery>;
export type GetEmployeeAddSidebarPreviousEmployeesQueryResult = Apollo.QueryResult<
	GetEmployeeAddSidebarPreviousEmployeesQuery,
	GetEmployeeAddSidebarPreviousEmployeesQueryVariables
>;
