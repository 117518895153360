import {createPages} from '@imperium/layout';
import CorporateBinderActions from './components/CorporateBinderActions';
import {CorporateBinderTable} from './components/CorporateBinderTable';
import {routes} from './routes';

export const routeProps = createPages(routes, {
	corporateBinder: {
		header: 'Corporate Binder',
		content: () => <CorporateBinderTable />,
		sidebar: [{render: CorporateBinderActions}],
	},
});
