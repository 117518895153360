import type {ReactNode} from 'react';
import {Link} from 'react-router-dom';
import {TableCell} from 'semantic-ui-react';
import '~common/components/LinkedTableCell/styles.css';

interface Props {
	to?: string;
	value?: string | JSX.Element | null | number;
	inverted?: boolean | null;
	disabled?: boolean | null;
	textAlign?: 'center' | 'left' | 'right';
	colSpan?: string | number | null;
	target?: '_blank' | '_self' | '_parent' | '_top';
	children?: ReactNode;
	className?: string;
	collapsing?: boolean;
	selectable?: boolean;
	style?: React.CSSProperties;
	onClick?: () => void;
}

export function LinkedTableCell(props: Props) {
	const linkClasses = ['tableCellLink', props.disabled && 'tableCellLinkDisabled', props.inverted && 'tableCellLinkInverted']
		.filter(c => !!c)
		.join(' ');

	const link = props.to ? (
		<Link target={props.target} to={props.to} className={linkClasses} onClick={props.onClick}>
			{props.value ? props.value : props.children || <br />}
		</Link>
	) : (
		<span className={linkClasses}>{props.value ? props.value : props.children || <br />}</span>
	);

	return (
		<TableCell
			style={props.style}
			className={props.className}
			inverted={props.inverted?.toString()}
			disabled={props.disabled || false}
			textAlign={props.textAlign}
			colSpan={props.colSpan}
			collapsing={props.collapsing}
			selectable={props.selectable}
		>
			{link}
		</TableCell>
	);
}
