import {Card, CardContent, CardDescription, CardHeader, Divider, Icon} from 'semantic-ui-react';
import {DisplayPartnerList} from '../partners/DisplayPartnerList';

interface Props {
	accountInfoId: string;
}
export function PartnersCard({accountInfoId}: Props) {
	return (
		<Card>
			<CardContent>
				<CardHeader>
					<Icon name="user" />
					Partners
				</CardHeader>
				<Divider />
				<CardDescription>
					<DisplayPartnerList accountInfoId={accountInfoId} />
				</CardDescription>
			</CardContent>
		</Card>
	);
}
