import {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Icon, Menu, MenuItem, Message, Popup, SemanticICONS} from 'semantic-ui-react';
import {useAsyncError} from '~lib/useAsyncError';
import {GetNotificationsByUserIdType, useGetNotificationsByUserIdLazyQuery} from '../graphql/getNotificationsByUserId';
import {useMarkNotificationReadByIdMutation} from '../graphql/markNotificationReadById';
import {routes} from '../routes';
import {useUserState} from '../state';
import {notificationDisplayDate} from './notificationDisplayDate';

export function UserNotificationMenuItem() {
	const {push} = useHistory();
	const throwError = useAsyncError();
	const {userId} = useUserState();
	const [open, setOpen] = useState(false);
	const {pathname} = useLocation();

	const [getNotifications, {data, error, refetch}] = useGetNotificationsByUserIdLazyQuery();
	const [editMutation] = useMarkNotificationReadByIdMutation();

	if (error) throwError(error);

	const unread = !!data?.getNotificationsByUserId?.find(item => item.dateTimeRead === null);

	async function markDismissed(feedItem: GetNotificationsByUserIdType) {
		if (!feedItem.dateTimeRead) {
			await editMutation({
				variables: {id: feedItem.id, version: feedItem.version},
			});
			await refetch();
		}
	}

	async function selectNotification(feedItem: GetNotificationsByUserIdType) {
		if (feedItem.webRoute) {
			await markDismissed(feedItem);
			setOpen(false);
			push(feedItem.webRoute);
		}
	}

	useEffect(() => {
		if (userId) {
			getNotifications({variables: {userId: userId || '', limit: 50, offset: 0}})
				.then(response => {
					if (response.data?.getNotificationsByUserId !== data?.getNotificationsByUserId) {
						response.data?.getNotificationsByUserId?.every(notification => {
							if (!notification.dateTimeRead) {
								toast.success(`${notification.title}: ${notification.description}`, {
									onClick: () => push(routes.to.userNotifications({userId})),
									type: 'info',
								});
								return false;
							}
							return true;
						});
					}
				})
				.catch(throwError);
		}
	}, [getNotifications, throwError, userId, pathname, data?.getNotificationsByUserId, push]);

	if (!userId) return null;

	return (
		<Popup
			on="click"
			inverted
			content={
				<Menu inverted vertical>
					{data?.getNotificationsByUserId?.slice(0, 3).map(feedItem => {
						return (
							<Message
								key={feedItem.id}
								color="black"
								onDismiss={!feedItem.dateTimeRead ? () => markDismissed(feedItem) : undefined}
								style={feedItem.webRoute ? {cursor: 'pointer'} : undefined}
							>
								<Message.Header onClick={() => selectNotification(feedItem)}>
									<Icon.Group>
										<Icon name={feedItem.type.icon as SemanticICONS} />
										{!feedItem.dateTimeRead && <Icon corner="top left" name="circle" color="blue" />}
									</Icon.Group>
									{`${notificationDisplayDate(feedItem.dateTimeCreated)} - ${feedItem.title}`}
								</Message.Header>
								<Message.Content onClick={() => selectNotification(feedItem)}>{feedItem.description}</Message.Content>
							</Message>
						);
					})}
					<MenuItem
						onClick={() => {
							setOpen(false);
							push(routes.to.userNotifications({userId: userId || ''}));
						}}
					>
						{(data?.getNotificationsByUserId?.length || 0) > 0 ? 'View more...' : 'No more notifications...'}
					</MenuItem>
				</Menu>
			}
			open={open}
			onClose={() => setOpen(false)}
			onOpen={() => setOpen(true)}
			trigger={
				<MenuItem>
					<Icon.Group>
						<Icon name="bell" />
						{unread && <Icon corner="top left" name="circle" color="blue" />}
					</Icon.Group>
				</MenuItem>
			}
			closeOnTriggerClick={false}
		/>
	);
}
