import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getEditAccountStatus.graphql';

const defaultOptions = {} as const;
export type GetEditAccountStatusQueryVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
}>;

export type GetEditAccountStatusQuery = {getAccountInfoById?: {id: string; version: number; archived: boolean} | null};

export type GetEditAccountStatusType = {id: string; version: number; archived: boolean};

/**
 * __useGetEditAccountStatusQuery__
 *
 * To run a query within a React component, call `useGetEditAccountStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditAccountStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditAccountStatusQuery({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *   },
 * });
 */
export function useGetEditAccountStatusQuery(baseOptions: Apollo.QueryHookOptions<GetEditAccountStatusQuery, GetEditAccountStatusQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetEditAccountStatusQuery, GetEditAccountStatusQueryVariables>(Operations, options);
}
export function useGetEditAccountStatusLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetEditAccountStatusQuery, GetEditAccountStatusQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetEditAccountStatusQuery, GetEditAccountStatusQueryVariables>(Operations, options);
}
export type GetEditAccountStatusQueryHookResult = ReturnType<typeof useGetEditAccountStatusQuery>;
export type GetEditAccountStatusLazyQueryHookResult = ReturnType<typeof useGetEditAccountStatusLazyQuery>;
export type GetEditAccountStatusQueryResult = Apollo.QueryResult<GetEditAccountStatusQuery, GetEditAccountStatusQueryVariables>;
