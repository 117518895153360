import type React from 'react';
import {useDispatch} from 'react-redux';
import {Dropdown, Form, FormInput} from 'semantic-ui-react';
import {AccountTypeEnum} from '~core/graphql';
import {setUnmanagedAccountsFilter, useAccountState} from '../../state';

export default function UnmanagedAccountsSidebar() {
	const dispatch = useDispatch();
	const {unmanagedAccountsFilter} = useAccountState();

	return (
		<Form>
			<FormInput label="Account Type">
				<Dropdown
					fluid
					selection
					options={[
						{id: 1, text: 'Corporate', value: AccountTypeEnum.CorporateAccount},
						{id: 2, text: 'Personal', value: AccountTypeEnum.PersonalAccount},
						{id: 3, text: 'Self Employed', value: AccountTypeEnum.SelfEmployedAccount},
					]}
					value={unmanagedAccountsFilter}
					onChange={(event, data) => dispatch(setUnmanagedAccountsFilter(data.value as AccountTypeEnum))}
				/>
			</FormInput>
		</Form>
	);
}
