import {useTForm} from '@thx/controls';
import {useState} from 'react';
import {Button, Dropdown, Form, FormInput} from 'semantic-ui-react';
import {TicketPriorityEnum} from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import {ticketPriorityIcon} from '../ticketPriorityIcon';
import {useChangeTicketPriorityMutation} from './changeTicketPriority';

interface SetPriorityProps {
	ticketPriority: TicketPriorityEnum;
	ticketId: string;
}

export function SetPriorityButton({ticketPriority, ticketId}: SetPriorityProps) {
	const [changePriority, {loading, data}] = useChangeTicketPriorityMutation();

	const throwError = useAsyncError();

	const [editClicked, setEditClicked] = useState(false);

	const {hasErrors, hasWarnings, handleSubmit, setFieldValue, values, resetForm} = useTForm({
		initialValues: {priority: ticketPriority},
		onSubmit: formValues => {
			changePriority({
				variables: {data: {ticketPriority: formValues.priority, ticketId}},
				update: cache => {
					const toEvict = cache.identify({id: ticketId, __typename: 'Ticket'});
					cache.evict({id: toEvict}).valueOf();
					cache.gc();
				},
			})
				.then(() => {
					setEditClicked(false);
				})
				.catch(throwError);
		},
		enableReinitialize: true,
	});

	if (editClicked) {
		return (
			<Form error={hasErrors} warning={hasWarnings} onSubmit={handleSubmit}>
				<FormInput autoFocus loading={loading} label="Set priority">
					<Dropdown
						fluid
						value={values.priority}
						icon={ticketPriorityIcon(values.priority).icon}
						text={values.priority}
						labeled
						button
						className="icon"
					>
						<Dropdown.Menu>
							<Dropdown.Item
								icon={ticketPriorityIcon(TicketPriorityEnum.Low).icon}
								text="Low"
								value={TicketPriorityEnum.Low}
								onClick={() => setFieldValue('priority', TicketPriorityEnum.Low)}
								style={{color: ticketPriorityIcon(TicketPriorityEnum.Low).color}}
							/>
							<Dropdown.Item
								icon={ticketPriorityIcon(TicketPriorityEnum.Normal).icon}
								text="Normal"
								value={TicketPriorityEnum.Normal}
								onClick={() => setFieldValue('priority', TicketPriorityEnum.Normal)}
								style={{color: ticketPriorityIcon(TicketPriorityEnum.Normal).color}}
							/>
							<Dropdown.Item
								icon={ticketPriorityIcon(TicketPriorityEnum.Normal).icon}
								text="High"
								value={TicketPriorityEnum.High}
								onClick={() => setFieldValue('priority', TicketPriorityEnum.High)}
								style={{color: ticketPriorityIcon(TicketPriorityEnum.High).color}}
							/>
							<Dropdown.Item
								icon={ticketPriorityIcon(TicketPriorityEnum.Emergency).icon}
								text="Emergency"
								value={TicketPriorityEnum.Emergency}
								onClick={() => setFieldValue('priority', TicketPriorityEnum.Emergency)}
								style={{color: ticketPriorityIcon(TicketPriorityEnum.Emergency).color}}
							/>
						</Dropdown.Menu>
					</Dropdown>
				</FormInput>
				<Button positive type="submit" floated="right">
					Save
				</Button>
				<Button
					type="button"
					onClick={() => {
						resetForm();
						setEditClicked(false);
					}}
				>
					Cancel
				</Button>
			</Form>
		);
	}

	return (
		<Button color="blue" fluid onClick={() => setEditClicked(true)}>
			Set Priority
		</Button>
	);
}
