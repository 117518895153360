import type {LocalDate} from '@js-joda/core';
import {toMoney} from '@thx/money';
import debug from 'debug';
import type Money from 'js-money';
import type {CurrencyString} from 'js-money';
import {useMemo} from 'react';
import {useGetExchangeRateQuery} from '~lib/useConvertMoney/getExchangeRate';

const d = debug('tacs.web.lib.useConvertMoney');

export function useConvertMoney(fromCurrency: string, toCurrency: CurrencyString, date: LocalDate, skip?: boolean) {
	const {loading, data, error} = useGetExchangeRateQuery({
		skip,
		fetchPolicy: 'cache-first',
		variables: {baseCurrency: fromCurrency, currency: toCurrency, date},
	});

	const convert = useMemo(() => {
		return (amount: Money) => {
			// If the from currency and to currency are the same, we just return the value we were sent. -STT
			if (amount.getCurrency() === toCurrency) return amount;

			// from and to currencies are not the same, so now we know we need to use the exchange rate service.
			// So we check to see if there is a connection error -STT
			if (error) {
				d(error);
				throw new Error('error getting exchange rate');
			}

			if (!data?.getExchangeRate) throw new Error('exchange rate not found');
			if (loading) throw new Error('exchange rate not yet loaded');

			return toMoney(amount.multiply(data.getExchangeRate).toDecimal(), toCurrency);
		};
	}, [loading, data, toCurrency, error]);

	return {convert, loading};
}
