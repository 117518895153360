import {useParams} from 'react-router-dom';
import {List, Placeholder, Divider} from 'semantic-ui-react';
import type {routes} from '../../routes';
import {useGetBasicAccountInfoInformationQuery} from './BasicAccountInfo/getBasicAccountInfoInformation';
import {DeleteAccountButton} from './DeleteAccountButton/DeleteAccountButton';
import {EditAccountManager} from './EditAccountManager';
import {EditAccountName} from './EditAccountName';
import {EditAccountOffice} from './EditAccountOffice/EditAccountOffice';
import {EditAccountOrganizationAndOffice} from './EditAccountOrganizationAndOffice';
import {EditAccountStatus} from './EditAccountStatus/EditAccountStatus';
import {EditDefaultAgent} from './EditDefaultAgent';

export function BasicAccountInfoSidebar() {
	const {accountInfoId} = useParams<typeof routes.types.account>();
	const {data, loading, error} = useGetBasicAccountInfoInformationQuery({
		variables: {id: accountInfoId},
	});
	if (error) throw error;
	if (loading && !data?.getBasicAccountByAccountInfoId)
		return (
			<Placeholder>
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
			</Placeholder>
		);

	return (
		<List>
			<List.Header as="h3">Actions</List.Header>
			<Divider />
			<List.Item>
				<EditAccountName />
			</List.Item>
			<Divider />
			<List.Item>
				<EditAccountStatus />
			</List.Item>
			<List.Item>
				<EditAccountOrganizationAndOffice />
			</List.Item>
			<List.Item>
				<EditAccountOffice />
			</List.Item>
			<List.Item>
				<EditAccountManager />
			</List.Item>
			<List.Item>
				<EditDefaultAgent />
			</List.Item>
			<Divider />
			<List.Item>
				<DeleteAccountButton />
			</List.Item>
		</List>
	);
}
