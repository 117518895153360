import {env} from '@thx/env';
import {useEffect, useState} from 'react';
import {defaults} from '../defaults';

export function useVersionPoll(currentVersion: string | null) {
	const [hasNewVersion, setHasNewVersion] = useState(false);

	useEffect(() => {
		if (!currentVersion) return () => {};

		const checkVersion = async () => {
			try {
				const cacheBuster = new Date().getTime();
				const response = await fetch(`/release.txt?cb=${cacheBuster}`);
				const text = await response.text();
				const newVersion = text.trim();
				if (newVersion.length === 0) return;
				if (newVersion !== currentVersion) {
					setHasNewVersion(true);
				}
			} catch (error) {
				// do nothing on error
			}
		};

		const intervalId = setInterval(checkVersion, env.getInt('releaseCheckPollInterval', defaults.releaseCheckPollInterval));

		return () => clearInterval(intervalId);
	}, [currentVersion]);

	return hasNewVersion;
}
