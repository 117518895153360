import debug from 'debug';
import {FieldArray, FieldArrayRenderProps} from 'formik';
import {isEmpty} from 'lodash-es';
import {ReactElement, Children, ReactNode} from 'react';

const d = debug('tacs.web.common.components.AddDynamicFormInput');

// copied this from @thr/addons/TFormInner
// eslint-disable-next-line @typescript-eslint/ban-types
const isFunction = (obj: unknown): obj is Function => typeof obj === 'function';
const isEmptyChildren = (children: ReactNode): boolean => Children.count(children) === 0;

type AddRow<Values> = (values?: Values) => void;
export interface HandleProps<Values> {
	index: number;
	values?: Values;
	removeRow: () => void; // removes the corresponding row
	addRow: AddRow<Values>; //  adds a row
	buildFieldName: (fieldName: string) => string; // returns the field at the right index in the array
}
export interface AddDynamicFormInputProps<Values> {
	name: string;
	values: Values[];
	initialRow?: (addRow: AddRow<Values>) => ReactElement;
	finalRow?: (addRow: AddRow<Values>) => ReactElement | null;
	children?: ((props: HandleProps<Values>) => ReactNode) | ReactNode;
}

export function AddDynamicFormInput<Values>(props: AddDynamicFormInputProps<Values>) {
	const {name, children, initialRow, finalRow} = props;

	function addRow(arrayHelpers: FieldArrayRenderProps, values?: Values) {
		const vals = values || {};

		// There are no objects in the arrayHelpers.form.values on initial load, so we have to add two objects on
		// the first AddButton click.
		if (isEmpty(arrayHelpers.form.values[name])) arrayHelpers.push(vals);
		arrayHelpers.push(vals);
	}

	// copied this from @thr/addons/TFormInner
	function renderChild(arrayHelpers: FieldArrayRenderProps, index = 0) {
		const buildFieldName = (fieldName: string) => `${props.name}.[${index}].${fieldName}`;
		if (children) {
			if (isFunction(children)) {
				return children({
					index,
					values: props.values?.[index],
					removeRow: () => arrayHelpers.remove(index),
					addRow: (values?: Values) => addRow(arrayHelpers, values),
					buildFieldName,
				});
			}
			if (!isEmptyChildren(children)) {
				return Children.only(children);
			}
		}
		return null;
	}

	return (
		<FieldArray
			name={props.name}
			render={(arrayHelpers: FieldArrayRenderProps) => {
				return isEmpty(props.values) ? (
					<>
						{initialRow && initialRow((values?: Values) => addRow(arrayHelpers, values))}
						{renderChild(arrayHelpers)}
						{finalRow && finalRow((values?: Values) => addRow(arrayHelpers, values))}
					</>
				) : (
					<>
						{initialRow && initialRow((values?: Values) => addRow(arrayHelpers, values))}
						{props.values.map((vals, index) => renderChild(arrayHelpers, index))}
						{finalRow && finalRow((values?: Values) => addRow(arrayHelpers, values))}
					</>
				);
			}}
		/>
	);
}
