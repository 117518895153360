import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './editDefaultAgent.graphql';

const defaultOptions = {} as const;
export type EditDefaultAgentMutationVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
	defaultAgentId: Types.Scalars['String'];
}>;

export type EditDefaultAgentMutation = {
	editAccountInfoDefaultAgent?: {id: string; version: number; defaultAgent?: {id: string; version: number; name: string} | null} | null;
};

export type EditDefaultAgentType = {id: string; version: number; defaultAgent?: {id: string; version: number; name: string} | null};

export type EditDefaultAgentMutationFn = Apollo.MutationFunction<EditDefaultAgentMutation, EditDefaultAgentMutationVariables>;

/**
 * __useEditDefaultAgentMutation__
 *
 * To run a mutation, you first call `useEditDefaultAgentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDefaultAgentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDefaultAgentMutation, { data, loading, error }] = useEditDefaultAgentMutation({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *      defaultAgentId: // value for 'defaultAgentId'
 *   },
 * });
 */
export function useEditDefaultAgentMutation(baseOptions?: Apollo.MutationHookOptions<EditDefaultAgentMutation, EditDefaultAgentMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<EditDefaultAgentMutation, EditDefaultAgentMutationVariables>(Operations, options);
}
export type EditDefaultAgentMutationHookResult = ReturnType<typeof useEditDefaultAgentMutation>;
export type EditDefaultAgentMutationResult = Apollo.MutationResult<EditDefaultAgentMutation>;
export type EditDefaultAgentMutationOptions = Apollo.BaseMutationOptions<EditDefaultAgentMutation, EditDefaultAgentMutationVariables>;
