import debug from 'debug';
import {List} from 'semantic-ui-react';
import {RemovePartnerButton} from './RemovePartnerButton';

const d = debug('tacs.web.accounts.components.partners.PartnerEditSidebar');

export function PartnerEditSidebar() {
	return (
		<List>
			<List.Item>
				<RemovePartnerButton />
			</List.Item>
		</List>
	);
}
