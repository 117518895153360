import {Icon} from 'semantic-ui-react';
import {DocumentStatusEnum} from '~core/graphql';

export function documentStatusToIconConverter(status?: DocumentStatusEnum | null) {
	if (status === DocumentStatusEnum.Pending) return 'wait';
	if (status === DocumentStatusEnum.Entered) return 'clipboard check';
	if (status === DocumentStatusEnum.Reviewed) return 'check circle';
	if (status === DocumentStatusEnum.Reconciled) return 'r circle';
	return 'question';
}

export function DocumentStatusIcon({status}: {status?: DocumentStatusEnum | null}) {
	if (DocumentStatusEnum.Pending === status) return <Icon name={documentStatusToIconConverter(status)} color="orange" />;
	if (DocumentStatusEnum.Entered === status) return <Icon name={documentStatusToIconConverter(status)} color="green" />;
	if (DocumentStatusEnum.Reviewed === status) return <Icon name={documentStatusToIconConverter(status)} color="green" />;
	if (DocumentStatusEnum.Reconciled === status) return <Icon name={documentStatusToIconConverter(status)} color="green" />;
	return null;
}
