import {Label, List} from 'semantic-ui-react';

export function VendorAddFormInputLabel() {
	return (
		<Label pointing="above">
			<List bulleted>
				<List.Item>Capitalize the first letter of each word.</List.Item>
				<List.Item>Avoid parenthesis in the first word.</List.Item>
			</List>
		</Label>
	);
}
