import * as Apollo from '@apollo/client';

import type {LocalDate} from '@js-joda/core';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './reemployCorporateEmployee.graphql';

const defaultOptions = {} as const;
export type ReemployCorporateEmployeeMutationVariables = Types.Exact<{
	employee: Types.EditMeta;
}>;

export type ReemployCorporateEmployeeMutation = {reemployEmployee?: {id: string; version: number; endDate?: LocalDate | null} | null};

export type ReemployCorporateEmployeeType = {id: string; version: number; endDate?: LocalDate | null};

export type ReemployCorporateEmployeeMutationFn = Apollo.MutationFunction<
	ReemployCorporateEmployeeMutation,
	ReemployCorporateEmployeeMutationVariables
>;

/**
 * __useReemployCorporateEmployeeMutation__
 *
 * To run a mutation, you first call `useReemployCorporateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReemployCorporateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reemployCorporateEmployeeMutation, { data, loading, error }] = useReemployCorporateEmployeeMutation({
 *   variables: {
 *      employee: // value for 'employee'
 *   },
 * });
 */
export function useReemployCorporateEmployeeMutation(
	baseOptions?: Apollo.MutationHookOptions<ReemployCorporateEmployeeMutation, ReemployCorporateEmployeeMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<ReemployCorporateEmployeeMutation, ReemployCorporateEmployeeMutationVariables>(Operations, options);
}
export type ReemployCorporateEmployeeMutationHookResult = ReturnType<typeof useReemployCorporateEmployeeMutation>;
export type ReemployCorporateEmployeeMutationResult = Apollo.MutationResult<ReemployCorporateEmployeeMutation>;
export type ReemployCorporateEmployeeMutationOptions = Apollo.BaseMutationOptions<
	ReemployCorporateEmployeeMutation,
	ReemployCorporateEmployeeMutationVariables
>;
