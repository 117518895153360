import {defineRoutes} from '@imperium/router';

export const routes = defineRoutes({
	support: {
		path: `/support/:userId`,
		params: ['userId'] as const,
	},
	ticketManagement: {
		path: `/admin/ticket-management/`,
		exact: true,
	},
	ticketCategoryManagement: {
		path: `/admin/ticket-category-management/`,
		exact: true,
	},
	createTicket: {
		path: `/support/ticket/create`,
		exact: true,
	},
	viewTicketDetails: {
		path: `/support/ticket/view/:ticketId`,
		params: ['ticketId'] as const,
	},
	editTicketCategory: {
		path: `/admin/ticket-category-management/edit-category/:categoryId`,
		params: ['categoryId'] as const,
	},
	createTicketCategory: {
		path: `/admin/ticket-category-management/create-category`,
		exact: true,
	},
});
